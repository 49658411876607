import React from 'react'

import Teacher from 'pages/Teacher/Home/Common/Teacher'
import StudentsTable from 'pages/Teacher/Home/Tables/StudentsTable'
import { getInstructorStudents } from 'store/actions/student'

const Students = () => {
  return (
    <div className="content-wrapper">
      <div className="content-wrapper-before" />
      <div className="content-header row" />
      <div className="content-body">
        <Teacher />
        <StudentsTable getStudentData={getInstructorStudents} />
      </div>
    </div>
  )
}

export default Students
