/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
/** componets * */

import RecoverPasswordForm from './RecoverPasswordForm'
import { recoverPassword } from '../../../store/actions/auth'
import config from '../../../config'

class RecoverPasswordContainer extends React.Component {
  constructor() {
    super()
    this.state = {
      emailField: true,
      successMessage: '',
    }
  }

  componentWillMount() {
    if (this.props.user) {
      return this.props.history.push(`${config.adminUrl}/home`)
    }
  }

  handleSubmit = async values => {
    await this.props.recoverPassword(values)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.response) {
      // console.log('step 1')
      if (nextProps.response.data != null) {
        // console.log('step 2')
        // console.log(nextProps.response.data);
        if (nextProps.response.data.status === 'success') {
          // console.log('step 3')
          // return this.props.history.push('/login')
          this.setState({
            emailField: false,
            successMessage: nextProps.response.data.message,
          })
        }
      }
    }
  }

  render() {
    return (
      <RecoverPasswordForm
        onSubmit={this.handleSubmit}
        emailField={this.state.emailField}
        successMessage={this.state.successMessage}
      />
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  response: state.recoverPassword,
})

const mapDispatchToProps = dispatch => ({
  recoverPassword: values => dispatch(recoverPassword(values)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecoverPasswordContainer)
