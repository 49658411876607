import {
  GET_ORGANIZATION_LIST,
  GET_ORGANIZATION_LIST_LOADING,
  GET_ORGANIZATION_LIST_LOADED,
} from 'store/actions/organizations'

const initialState = {
  organizations: [],
}

const organizationListReducer = (state = initialState, action = {}) => {
  const newState = state
  switch (action.type) {
    case GET_ORGANIZATION_LIST:
      if (action.payload.currentPage === 0) {
        newState.organizations = action.payload.organizations
        return {
          organizations: newState.organizations,
          ...newState,
          data: action.payload,
        }
      }
      newState.organizations = action.payload.organizations
      return {
        organizations: newState.organizations,
        ...newState,
        data: action.payload,
      }

    case GET_ORGANIZATION_LIST_LOADING:
      return {
        ...newState,
        loading: true,
      }
    case GET_ORGANIZATION_LIST_LOADED:
      return {
        ...newState,
        loading: false,
      }
    default:
      return newState
  }
}

export default organizationListReducer
