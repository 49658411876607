/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'
import CommonAdventureStats from '../../../../../Common/Report/AdventureStats'
import { getAdminAdventureStatsReportByReportId } from '../../../../../../store/actions/report'
/**
 * import translation related files
 *
 */

class AdventureStats extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  componentWillMount() {
    const loadingTable = this.state.loading
    this.props.getAdminAdventureStatsReportByReportId(
      this.props.organizationId,
      this.props.reportId,
    )
  }

  render() {
    const {
      translate,
      data,
      loading,
      handleChartClassAdventureStatsWrapper,
      dataAssessment,
    } = this.props
    return (
      <CommonAdventureStats
        data={data}
        loading={loading}
        dataAssessment={dataAssessment}
        handleChartClassAdventureStatsWrapper={
          handleChartClassAdventureStatsWrapper
        }
      />
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.adminAdventureStats.data.adventures,
  loading: state.adminAdventureStats.loading,
})

const mapDispatchToProps = dispatch => ({
  getAdminAdventureStatsReportByReportId: (organizationId, reportId) => {
    dispatch(getAdminAdventureStatsReportByReportId(organizationId, reportId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AdventureStats)
