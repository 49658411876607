/* TODO: Fix eslint */
/* eslint-disable */

import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'

const validateForm = (values, props) => {
  const errors = {}
  // alias
  if (!values.alias) {
    errors.alias = [`errorMsgStudentServiceUpdateNameMissing`]
  } else if (values.alias.length < 2) {
    errors.alias = [`errorMsgStudentServiceUpdateNameIsLength`]
  } else if (values.alias.length > 50) {
    errors.alias = [`errorMsgStudentServiceUpdateNameIsLength`]
  }

  return errors
}

export default validateForm
