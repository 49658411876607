import React from 'react'

const Footer = () => (
  <footer className="footer footer-static footer-light navbar-border navbar-shadow">
    <div className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
      <span className="float-md-left d-block d-md-inline-block" />
    </div>
  </footer>
)

export default Footer
