import { GET_ORGANIZATION_STATES_LIST } from '../../actions/country'

const stateListReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_STATES_LIST:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default stateListReducer
