const TextTruncate = ({ str, _length, _ending }) => {
  let length = _length
  let ending = _ending
  if (length == null) {
    length = 20
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  }
  return str
}
export default TextTruncate
