import {
  GET_ORGANIZATION_ORGANIZATION_STATS,
  GET_ORGANIZATION_ORGANIZATION_STATS_LOADING,
  GET_ORGANIZATION_ORGANIZATION_STATS_LOADED,
} from '../actions/organizations'

const organizationOrganizationStatsReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_ORGANIZATION_STATS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_ORGANIZATION_ORGANIZATION_STATS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_ORGANIZATION_ORGANIZATION_STATS_LOADED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default organizationOrganizationStatsReducer
