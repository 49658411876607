import {
  GET_TEACHER_PROFILE,
  GET_TEACHER_PROFILE_LOADING,
  GET_TEACHER_PROFILE_LOADED,
} from '../../actions/instructors'

const teacherProfileReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_TEACHER_PROFILE:
      return {
        ...state,
        data: action.payload,
      }
    case GET_TEACHER_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_TEACHER_PROFILE_LOADED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default teacherProfileReducer
