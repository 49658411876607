/** Import CSS File * */

// BEGIN: Vendor CSS
import './app-assets/vendors/css/vendors.min.css'
// END: Vendor CSS

// BEGIN: Theme CSS
import './app-assets/css/bootstrap.css'
import './app-assets/css/bootstrap-extended.css'
import './app-assets/css/colors.css'
import './app-assets/css/components.css'
// END: Theme CSS

// import './app-assets/css/core/menu/menu-types/vertical-menu.css';

// BEGIN: Custom CSS
// import './assets/css/style.css';
import './assets/css/media-style.css'
// END: Custom CSS

/** Import Js File * */
// import "./app-assets/js/jquery-3.4.1.min.js";

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, withRouter } from 'react-router-dom'

import { LocalizeProvider } from 'react-localize-redux'
import App from './App'
import store from './store'

import * as serviceWorker from './serviceWorker'
// ReactDOM.render(<App />, document.getElementById('root'));
//  <LocalizeProvider  store={store} >

const AppWithRouter = withRouter(App)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <LocalizeProvider store={store}>
        <AppWithRouter />
      </LocalizeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
