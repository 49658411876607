import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import RenderImage from 'components/RenderImage'

const Detail = props => {
  const { row } = props
  const tagArr = row && row.tags ? row.tags.split(',') : []
  return (
    <div className="col-md-12 col-lg-8 pull-left">
      <div className="card class_card">
        {row && (
          <RenderImage
            className="profile_img"
            image={row && row.photo_path}
            apiUrl={config.apiUrl}
            source="2"
            name={row && row.name}
          />
        )}
        <div className="class_description">
          <div className="card-header card-header_name">
            <h4 className="card-title">{row && row.name}</h4>
            <h4 className="org-id student_name">{row && row.email}</h4>
          </div>
          <div className="card-content collapse show">
            <div className="card-body pb-0 card_margin student_s_name">
              <p className="address_Org">School/Dept</p>
              <h6 className="contact_heading">{row && row.school_name}</h6>
            </div>
          </div>
          <div className="row">
            <div>
              <p className="lighten-2 mb-0 text-left tag_headings">Tags</p>
              <div className="tags_div class_p_tags">
                {tagArr && tagArr.map(tag => <p key={`tag-${tag}`}>{tag}</p>)}
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  )
}

Detail.propTypes = {
  row: PropTypes.shape({
    tags: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    school_name: PropTypes.string,
    photo_path: PropTypes.string,
  })
}

export default Detail
