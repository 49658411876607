/* TODO: Fix eslint */
/* eslint-disable */

import axios from 'axios'
import { saveAs } from 'file-saver'
import { reset } from 'redux-form'
import config from '../../config'
import handleResponse from './handleResponse'
import handleResponseTranslate from './handleResponseTranslate'
import handleResponsePrincipalTranslate from './handleResponsePrincipalTranslate'
import handleResponseTeacherTranslate from './handleResponseTeacherTranslate'

/**
 * Define actions
 *
 */
export const GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID =
  'GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID'
export const GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADING =
  'GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADING'
export const GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADED =
  'GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADED'
export const GET_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID =
  'GET_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID'
export const GET_INSTRUCTOR_LIST_BY_SCHOOL_ID =
  'GET_INSTRUCTOR_LIST_BY_SCHOOL_ID'
export const GET_INSTRUCTOR_LIST_BY_SCHOOL_ID_LOADING =
  'GET_INSTRUCTOR_LIST_BY_SCHOOL_ID_LOADING'
export const GET_INSTRUCTOR_LIST_BY_SCHOOL_ID_LOADED =
  'GET_INSTRUCTOR_LIST_BY_SCHOOL_ID_LOADED'
export const GET_ORGANIZATION_INSTRUCTOR_LIST =
  'GET_ORGANIZATION_INSTRUCTOR_LIST'
export const GET_ORGANIZATION_INSTRUCTOR_LIST_LOADING =
  'GET_ORGANIZATION_INSTRUCTOR_LIST_LOADING'
export const GET_ORGANIZATION_INSTRUCTOR_LIST_LOADED =
  'GET_ORGANIZATION_INSTRUCTOR_LIST_LOADED'
export const GET_ORGANIZATION_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID =
  'GET_ORGANIZATION_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID'
export const GET_PRINCIPAL_INSTRUCTOR_LIST = 'GET_PRINCIPAL_INSTRUCTOR_LIST'
export const GET_PRINCIPAL_INSTRUCTOR_LIST_LOADING =
  'GET_PRINCIPAL_INSTRUCTOR_LIST_LOADING'
export const GET_PRINCIPAL_INSTRUCTOR_LIST_LOADED =
  'GET_PRINCIPAL_INSTRUCTOR_LIST_LOADED'
export const GET_PRINCIPAL_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID =
  'GET_PRINCIPAL_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID'
export const GET_PRINCIPAL_INSTRUCTOR_DROPDOWN_LIST =
  'GET_PRINCIPAL_INSTRUCTOR_DROPDOWN_LIST'
export const STORE_INSTRUCTOR = 'STORE_INSTRUCTOR'
export const UPDATE_INSTRUCTOR = 'UPDATE_INSTRUCTOR'
export const STORE_INSTRUCTOR_MULTIPLE_ALL = 'STORE_INSTRUCTOR_MULTIPLE_ALL'
export const STORE_INSTRUCTOR_MULTIPLE_EXIST = 'STORE_INSTRUCTOR_MULTIPLE_EXIST'
export const GET_TEACHER_PROFILE = 'GET_TEACHER_PROFILE'
export const GET_TEACHER_PROFILE_LOADING = 'GET_TEACHER_PROFILE_LOADING'
export const GET_TEACHER_PROFILE_LOADED = 'GET_TEACHER_PROFILE_LOADED'
export const TEACHER_CHANGE_PASSWORD = 'TEACHER_CHANGE_PASSWORD'
export const GET_TEACHER_STATS = 'GET_TEACHER_STATS'
export const TEACHER_UPDATE_PROFILE = 'TEACHER_UPDATE_PROFILE'

/**
 * To get instructors of an organization  for admin
 *
 * @param  int organizationId
 * @return data
 */
export const getInstructorsByOrganizationId = (
  organizationId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/instructors/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get instructor details for admin
 *
 * @param  int organizationId
 * @param  int instructorId
 * @return data
 */
export const getDetailByinstructorId = (organizationId, instructorId) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/instructor/${instructorId}`,
      headers,
    )
    dispatch({
      type: GET_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get instructors of a school  for admin
 *
 * @param  int organizationId
 * @param  int schoolId
 * @return data
 */
export const getInstructorsByschoolId = (
  organizationId,
  schoolId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_INSTRUCTOR_LIST_BY_SCHOOL_ID_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/schoolinstructors/${schoolId}/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_INSTRUCTOR_LIST_BY_SCHOOL_ID,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_INSTRUCTOR_LIST_BY_SCHOOL_ID_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get instructors of an organization for organization
 *
 * @param  object props
 * @return data
 */
export const getOrganizationInstructors = (
  props,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_INSTRUCTOR_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/instructors/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_INSTRUCTOR_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_INSTRUCTOR_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get instructor details for organization
 *
 * @param  object props
 * @param  int instructorId
 * @return data
 */
export const getOrganizationInstructorDetailByinstructorId = (
  instructorId,
  props,
) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiOrganizationUrl}/instructor/${instructorId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get instructors of a principal for principal
 *
 * @param  object props
 * @return data
 */
export const getPrincipalInstructors = (props, page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_PRINCIPAL_INSTRUCTOR_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/instructor/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_INSTRUCTOR_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_PRINCIPAL_INSTRUCTOR_LIST_LOADED,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}

/**
 * To get instructors of a principal for principal
 *
 * @param  object props
 * @return data
 */
export const getPrincipalInstructorList = props => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/instructor/list`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_INSTRUCTOR_DROPDOWN_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}

/**
 * To get instructor details for principal
 *
 * @param  object props
 * @param  int instructorId
 * @return data
 */
export const getPrincipalInstructorDetailByinstructorId = (
  instructorId,
  props,
) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiPrincipalUrl}/instructor/${instructorId}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}

/**
 * To store/save instructor
 *
 * @param  object  values
 * @param  object  file
 * @param  object  props
 * @return instructorId
 */
export const storeInstructor = (values, file, props) => async (
  dispatch,
  getState,
) => {
  try {
    const tag_ids = []
    const new_tags = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        if (item.id) {
          tag_ids.push(item.id)
        }
        if (item.newid) {
          new_tags.push(item.name)
        }
      })
    }
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }

    const instructorValues = {
      name: values.name,
      email: values.email,
      image: values.image,
      file,
      tags: tag_ids.length > 0 ? tag_ids.toString() : null,
      new_tags: new_tags.length > 0 ? new_tags.toString() : null,
    }
    // console.log(instructorValues);
    const response = await axios.post(
      `${config.apiPrincipalUrl}/instructor`,
      instructorValues,
      headers,
    )
    console.log(response)
    dispatch({
      type: STORE_INSTRUCTOR,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    console.log(error)
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}

/**
 * To update instructor
 *
 * @param  int  instructorId
 * @param  object  values
 * @param  object  file
 * @param  object  props
 * @return instructorId
 */
export const updateInstructor = (instructorId, values, file, props) => async (
  dispatch,
  getState,
) => {
  try {
    const tag_ids = []
    const new_tags = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        if (item.id) {
          tag_ids.push(item.id)
        }
        if (item.newid) {
          new_tags.push(item.name)
        }
      })
    }

    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }

    const instructorValues = {
      name: values.name,
      email: values.email,
      status: values.status,
      image: values.image,
      file,
      tags: tag_ids.length > 0 ? tag_ids.toString() : null,
      new_tags: new_tags.length > 0 ? new_tags.toString() : null,
    }
    const response = await axios.put(
      `${config.apiPrincipalUrl}/instructor/${instructorId}`,
      instructorValues,
      headers,
    )
    dispatch({
      type: UPDATE_INSTRUCTOR,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}

/**
 * To store/save instructor Multiple from imported CSV,XLSX
 *
 * @param  object  values
 * @param  object  file
 * @param  object  props
 */
export const storeInstructorMultiple = (file, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }

    const instructorValues = {
      file,
    }
    // console.log(instructorValues);
    const response = await axios.post(
      `${config.apiPrincipalUrl}/instructor/multiple`,
      instructorValues,
      headers,
    )
    // console.log(response);
    // console.log(response.data.result.data);
    const created = response.data.result.data.created.length
    const exist = response.data.result.data.exist.length
    //    console.log(`created=${created}`);
    // console.log(`exist=${exist}`);
    // if(response.data.result.data.)
    if (exist === 0) {
      dispatch({
        type: STORE_INSTRUCTOR_MULTIPLE_ALL,
        payload: `successMsgInstructorServiceCreateAll_${created}`,
      })
    } else {
      dispatch({
        type: STORE_INSTRUCTOR_MULTIPLE_EXIST,
        payload: `successMsgInstructorServiceCreateExist_${created}_${exist}`,
      })
    }

    return response.data.result.data.id
  } catch (error) {
    console.log(error)
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}

/**
 * To download sample file
 *
 * @param  string  key
 * @return pdf file
 */
export const sampleFileInstructor = (name, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      access_token: `${getState().auth.accessToken}`,
      responseType: 'blob',
    }
    // console.log(`${config.apiPrincipalUrl}/download/sample/${name}`);
    const fileTypeArr = name.split('.')
    const type = fileTypeArr[fileTypeArr.length - 1]
    let applicationType = ''
    if (type === 'xlsx') {
      applicationType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    } else {
      applicationType = 'text/csv'
    }

    await axios
      .get(`${config.apiPrincipalUrl}/download/sample/${name}`, headers)
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: applicationType })
        saveAs(pdfBlob, name)
      })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 2)
  }
}

/**
 * To get instructor/teacher profile for instructor
 *
 * @param  object  props
 * @return data
 */
export const getTeacherProfile = props => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    dispatch({
      type: GET_TEACHER_PROFILE_LOADING,
    })
    const response = await axios.get(`${config.apiTeacherUrl}/profile`, headers)
    dispatch({
      type: GET_TEACHER_PROFILE,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_TEACHER_PROFILE_LOADED,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, props, 3)
  }
}

/**
 * To update password of an instructor/teacher
 *
 * @param  object values
 * @param  object  props
 */
export const updateTeacherPassword = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const userValues = {
      current_password: values.current_password,
      password: values.password,
      confirm_password: values.confirm_password,
    }
    const response = await axios.put(
      `${config.apiTeacherUrl}/change-password`,
      userValues,
      headers,
    )

    dispatch({
      type: TEACHER_CHANGE_PASSWORD,
      payload: response.data.result,
    })
    dispatch(reset('teacher-change-password'))
    return response.data.result.data.id
  } catch (error) {
    await handleResponseTeacherTranslate(error, props, 1)
  }
}

/**
 * To get total stats of a teacher
 *
 * @return data
 */
export const getStats = () => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const response = await axios.get(`${config.apiTeacherUrl}/stats`, headers)
    dispatch({
      type: GET_TEACHER_STATS,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, 3)
  }
}

/**
 * To update profile of an instructor/teacher
 *
 * @param  object values
 * @param  object  props
 */
export const updateTeacherProfile = (values, file, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const userValues = {
      name: values.name,
      email: values.email,
      bio: values.bio,
      file,
    }
    // console.log(userValues);
    const response = await axios.put(
      `${config.apiTeacherUrl}`,
      userValues,
      headers,
    )

    dispatch({
      type: TEACHER_UPDATE_PROFILE,
      payload: response.data.result,
    })
    dispatch(reset('teacher-change-profile'))
    return response.data.result.data.id
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}
