import { GET_PRINCIPAL_CLASS_DETAILS_BY_CLASS_ID } from '../../actions/class'

const principalClassDetailReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_PRINCIPAL_CLASS_DETAILS_BY_CLASS_ID:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default principalClassDetailReducer
