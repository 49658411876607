import { ORGANIZATION_UPDATE } from '../actions/organizations'

const organizationUpdateReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case ORGANIZATION_UPDATE:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationUpdateReducer
