import isEmail from 'validator/lib/isEmail'

const validateForm = values => {
  const errors = {}

  // name
  if (!values.name) {
    errors.name = [`Name is required`]
  } else if (values.name.length < 2) {
    errors.name = [`Name be in between 2 to 50 characters in length`]
  } else if (values.name.length > 50) {
    errors.name = [`Name be in between 2 to 50 characters in length`]
  }

  // email
  if (!values.email) {
    errors.email = [`Email address is required`]
  } else if (!isEmail(values.email)) {
    errors.email = [`Please provide valid email address`]
  } else if (values.email.length > 100) {
    errors.phone = [
      `Email address should be less than 100 characters in length`,
    ]
  }

  return errors
}

export default validateForm
