import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { studentGetPhonemeScores } from 'store/actions/student'
import CombinedPronunciationCharts from 'components/Data/CombinedPronunciationCharts'

class PhonemeScores extends React.Component {
  constructor(props) {
    super(props)

    const { studentGetPhonemeScores } = this.props
    studentGetPhonemeScores(this.props)
  }

  render() {
    const { data: propData, loading: propLoading } = this.props
    return (
      <Fragment>
        <CombinedPronunciationCharts loading={propLoading} data={propData} />
      </Fragment>
    )
  }
}

PhonemeScores.defaultProps = {
  loading: false,
}

PhonemeScores.propTypes = {
  studentGetPhonemeScores: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
}

const mapStateToProps = state => ({
  data: state.studentPhonemeScores.phonemeScoresData,
  loading: state.studentPhonemeScores.loading,
})

const mapDispatchToProps = dispatch => ({
  studentGetPhonemeScores: props => dispatch(studentGetPhonemeScores(props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PhonemeScores)
