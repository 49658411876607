import React from 'react'

import Teacher from 'pages/Teacher/Home/Common/Teacher'
import ClassesTable from 'pages/Teacher/Home/Tables/Classes'

const Classes = () => {
  return (
    <div className="content-wrapper">
      <div className="content-wrapper-before" />
      <div className="content-header row" />
      <div className="content-body">
        <Teacher />
        <ClassesTable />
      </div>
    </div>
  )
}

export default Classes
