/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import Moment from 'react-moment'
import { confirmAlert } from 'react-confirm-alert' // Import
import { getTranslate } from 'react-localize-redux'
import config from '../../../../config'
import EditOrganization from './EditOrganization'
import MyAccounts from './MyAccounts'
import ChnagePasswordModelBox from './ChnagePasswordModelBox'
import {
  getOrganizationOrganizationDetails,
  OrganizationdownloadOrganizationDOC,
} from '../../../../store/actions/organizations'
import {
  getOrganizationUserProfile,
  updateOrganizationPassword,
} from '../../../../store/actions/organizationUser'
import { resetForm } from '../../../../store/actions/form'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

/**
 * import translation related files
 *
 */

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowChangePassword: false,
    }
  }

  componentWillMount() {
    this.props.getOrganizationOrganizationDetails(this.props)
    this.props.getOrganizationUserProfile(this.props)
  }

  handleDownload = async () => {
    const { organizationRow } = this.props
    // console.log(organizationRow.documents_path);
    const filePaths = JSON.parse(organizationRow.documents_path)
    const fileName = filePaths[filePaths.length - 1]
    // console.log(fileName);
    await this.props.OrganizationdownloadOrganizationDOC(fileName, this.props)
  }

  handleChangePasswordModelShow = () => {
    // this.props.resetForm('organization-change-password');
    this.setState({
      isShowChangePassword: true,
    })
  }

  handleChangePasswordModelClose = () => {
    // this.props.resetForm('organization-change-password');
    this.setState({
      isShowChangePassword: false,
    })
  }

  handleSubmit = async values => {
    // console.log('handleSubmit');
    await this.props.updateOrganizationPassword(values, this.props)
    // this.props.resetForm('organization-change-password');
    this.setState({ isShowChangePassword: false })
    // window.location.reload();
    // this.props.history.push(`/home`);
  }

  handleCancel = () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: () => {
            this.props.resetForm('organization-change-password')
            this.setState({ isShowChangePassword: false })
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  render() {
    const { translate, organizationRow, organizationUserRow } = this.props

    let datePercentage = 0
    if (
      organizationRow &&
      organizationRow.contract_start_date &&
      organizationRow.contract_end_date
    ) {
      let totalDay = 0
      let leftDay = 0
      const startDate = moment(organizationRow.contract_start_date)
      const currentDate = moment()
      const endDate = moment(organizationRow.contract_end_date)
      totalDay = endDate.diff(startDate, 'days')
      leftDay = currentDate.diff(startDate, 'days')
      // console.log(totalDay);
      // console.log(leftDay);
      if (totalDay === 0) {
        datePercentage = 100
      } else if (leftDay === 0 && totalDay == 0) {
        datePercentage = 100
      } else if (leftDay > totalDay) {
        datePercentage = 100
      } else {
        datePercentage = parseInt(Number((leftDay * 100) / totalDay))
      }
    }
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-4 col-12 mb-2">
              <h3 className="content-header-title">
                {translate('breadCrumbEditOrganization')}
              </h3>
            </div>
            <div className="content-header-right col-md-8 col-12">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`${config.organizationUrl}/home`}>
                        {translate('breadCrumbHome')}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {translate('breadCrumbEditOrganization')}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="org-id">
                      {translate('id')}:{organizationRow && organizationRow.id}
                    </h4>
                    <h4 className="card-title">
                      {translate('organizationName')}:{' '}
                      {organizationRow && organizationRow.organization_name}
                    </h4>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="row">
                      <div className="col-6">
                        <div className="card pull-up">
                          <div className="card-header">
                            <h6 className="card-title float-left">
                              {translate('startDate')}
                              <span className="start_date_color">
                                <Moment format={`${config.dateFormat}`}>
                                  {organizationRow &&
                                    organizationRow.contract_start_date}
                                </Moment>
                              </span>
                            </h6>
                            <h6 className="card-title float-right">
                              {translate('endDate')}
                              <span
                                className="end_date_color"
                                style={{
                                  color: `${
                                    datePercentage >= 90 ? '#FF0000' : ''
                                  }`,
                                }}
                              >
                                <Moment format={`${config.dateFormat}`}>
                                  {organizationRow &&
                                    organizationRow.contract_end_date}
                                </Moment>
                              </span>
                            </h6>
                          </div>
                          <div className="card-content collapse show">
                            <div className="card-body pt-0 pb-1">
                              <h6 className="text-muted font-small-3">
                                <span className="l_acti">
                                  {translate('licenses')}{' '}
                                  {translate('activated')} :
                                </span>{' '}
                                <NumberFormat
                                  thousandSeparator
                                  displayType="text"
                                  value={
                                    organizationRow &&
                                    Number(organizationRow.totalActiveLicense)
                                  }
                                />{' '}
                                /{' '}
                                <NumberFormat
                                  thousandSeparator
                                  displayType="text"
                                  value={
                                    organizationRow &&
                                    Number(organizationRow.totalLicense)
                                  }
                                />
                              </h6>
                              <div className="progress progress-sm mt-1 mb-0 box-shadow-2">
                                <div
                                  className="progress-bar bg-gradient-x-info"
                                  role="progressbar"
                                  style={{
                                    width: `${organizationRow &&
                                      parseInt(
                                        organizationRow.activeLicenseRation,
                                      )}%`,
                                    backgroundImage: `${
                                      organizationRow &&
                                      organizationRow.activeLicenseRation >= 90
                                        ? 'linear-gradient(to right, #ec6b6b 0%, #ff4747 100%)'
                                        : ''
                                    }`,
                                  }}
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <Link to="#" onClick={this.handleDownload}>
                          <div className="card-body pull-up contract_pdf pb-1">
                            <i className="fa fa-file-pdf pdf_files" />
                            <h6 className="text-muted font-small-3">
                              {translate('viewPDFContract')}
                            </h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="row">
                  <div className="col-12">
                    <h4 className="card-title">
                      {translate('organizatoinUserDetailTitle')}
                    </h4>
                    <div className="card pull-up">
                      <div className="card-header">
                        <h6 className="card-title c_title_t">
                          {translate('organizatoinUserProfileTitle')}
                        </h6>
                        <br />
                        <h6 className="card-title ">
                          {organizationUserRow && organizationUserRow.email}
                        </h6>
                      </div>
                      {/* }
                        <div className="card-content collapse show">
                          <div className="card-body pt-0 pb-1">
                            <button type="button" className="btn btn-primary" onClick={this.handleChangePasswordModelShow} >{translate("btnChangePassword")}</button>
                          </div>
                        </div>
                         { */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {organizationUserRow && organizationUserRow.is_admin_created === 1 && (
              <div className="row">
                <EditOrganization />
                <MyAccounts />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  organizationRow: state.organizationOrganizationDetail.data.row,
  organizationUserRow: state.organizationUserProfile.data.row,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationOrganizationDetails: props => {
    dispatch(getOrganizationOrganizationDetails(props))
  },
  OrganizationdownloadOrganizationDOC: (file, props) =>
    dispatch(OrganizationdownloadOrganizationDOC(file, props)),
  getOrganizationUserProfile: props =>
    dispatch(getOrganizationUserProfile(props)),
  updateOrganizationPassword: (values, props) =>
    dispatch(updateOrganizationPassword(values, props)),
  resetForm: formName => dispatch(resetForm(formName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
