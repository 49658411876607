import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'

import reducer from './reducers'

const middleware =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(thunk)
    : applyMiddleware(thunk, createLogger())

export const routerHistory = createBrowserHistory()

const store = createStore(
  reducer,
  compose(middleware, applyMiddleware(routerMiddleware(routerHistory))),
)

export default store
