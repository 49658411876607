/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'

/** componets * */

import { withLocalize, getTranslate } from 'react-localize-redux'
import LoginForm from './LoginForm'
import { studentLoginUser } from '../../../store/actions/auth'
import config from '../../../config'

/**
 * import translation related files
 *
 */
import { initializeLanguage } from '../../../store/actions/language'
import englishOrganizationLoginTranslations from '../../../lang/organization/login/en.json'
import spanishOrganizationLoginTranslations from '../../../lang/organization/login/es.json'
import chineseOrganizationLoginTranslations from '../../../lang/organization/login/zh.json'
import englishGlobalTranslations from '../../../lang/en.global.json'
import spanishGlobalTranslations from '../../../lang/es.global.json'
import chineseGlobalTranslations from '../../../lang/zh.global.json'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.props.initializeLanguage()
    this.props.addTranslationForLanguage(
      englishOrganizationLoginTranslations,
      'en',
    )
    this.props.addTranslationForLanguage(
      spanishOrganizationLoginTranslations,
      'es',
    )
    this.props.addTranslationForLanguage(
      chineseOrganizationLoginTranslations,
      'zh',
    )
    this.props.addTranslationForLanguage(englishGlobalTranslations, 'en')
    this.props.addTranslationForLanguage(spanishGlobalTranslations, 'es')
    this.props.addTranslationForLanguage(chineseGlobalTranslations, 'zh')
  }

  componentWillMount() {
    if (this.props.user) {
      return this.props.history.push(`${config.studentUrl}/home`)
    }
  }

  handleSubmit = async values => {
    await this.props.studentLoginUser(values, this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      return this.props.history.push(`${config.studentUrl}/home`)
    }
  }

  onSelectFlag = countryCode => {
    let langCode = 'en'
    if (countryCode === 'US') {
      langCode = 'en'
    } else if (countryCode === 'ES') {
      langCode = 'es'
    } else if (countryCode === 'CN') {
      langCode = 'zh'
    }
    this.props.setActiveLanguage(langCode)
    const languages = {
      defaultLanguage: langCode,
      defaultCountry: countryCode,
    }
    localStorage.setItem('currentLanguage', JSON.stringify(languages))
  }

  render() {
    return (
      <LoginForm
        onSubmit={this.handleSubmit}
        onSelectFlag={this.onSelectFlag}
      />
    )
  }
}

const mapStateToProps = state => ({
  user: state.authStudent.user,
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  studentLoginUser: (values, props) =>
    dispatch(studentLoginUser(values, props)),
  initializeLanguage: () => dispatch(initializeLanguage()),
})

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Login))
