/* TODO: Fix eslint */
/* eslint-disable */

import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'

const validateForm = (values, props) => {
  const errors = {}
  // name
  if (!values.name) {
    errors.name = [`errorMsgPrincipalServiceUpdateNameMissing`]
  } else if (values.name.length < 2) {
    errors.name = [`errorMsgPrincipalServiceUpdateNameIsLength`]
  } else if (values.name.length > 50) {
    errors.name = [`errorMsgPrincipalServiceUpdateNameIsLength`]
  }

  // email
  if (!values.email) {
    errors.email = [`errorMsgPrincipalServiceUpdateEmailMissing`]
  } else if (!isEmail(values.email)) {
    errors.email = [`errorMsgPrincipalServiceUpdateEmailIsEmail`]
  } else if (values.email.length > 100) {
    errors.email = [`errorMsgPrincipalServiceUpdateEmailIsLength`]
  }

  return errors
}

export default validateForm
