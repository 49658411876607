/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'

export const CheckBox = props => {
  return (
    <label key={props.id} className="container_kkk">
      {props.name}
      <input
        key={props.id}
        onClick={props.handleCheckChieldElement}
        type="checkbox"
        checked={props.isChecked}
        value={props.id}
      />{' '}
      {props.value}
      <span className="checkmark" />
    </label>
  )
}

export default CheckBox
