/* TODO: Fix eslint */
/* eslint-disable */

/** images * */

import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import logo from '../../../app-assets/images/logo/fluent-world-logo.png'
import Loader from '../../../components/Loader'
import RenderField from '../../../components/RenderField'
import config from '../../../config'
import validateLoginForm from './helpers'

const LoginForm = ({ pristine, handleSubmit, submitting, error }) => {
  return (
    <React.Fragment>
      <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
        <div className="card-header border-0">
          <div className="text-center mb-1">
            <img src={logo} alt="branding logo" width="60%;" />
          </div>
          <div className="font-large-1  text-center">Admin Login</div>
        </div>
        <div className="card-content">
          <div className="card-body">
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <fieldset className="form-group position-relative has-icon-left">
                <Field
                  type="text"
                  className="form-control round"
                  placeholder="Email"
                  component={RenderField}
                  name="email"
                  id="email"
                  autoFocus
                />
                <div className="form-control-position">
                  <i className="ft-user" />
                </div>
              </fieldset>
              <fieldset className="form-group position-relative has-icon-left">
                <Field
                  type="password"
                  className="form-control round"
                  placeholder="Password"
                  component={RenderField}
                  name="password"
                  id="password"
                />
                <div className="form-control-position">
                  <i className="ft-lock" />
                </div>
              </fieldset>
              <div className="form-group row">
                <div className="col-md-6 col-12 text-center text-sm-left" />
                <div className="col-md-6 col-12 float-sm-left text-center text-sm-right">
                  <Link className="card-link" to={`${config.adminUrl}/recover-password`}>
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="form-group text-center">
                {error && (
                  <h4 className="text-danger login-danger font-weight-bold mb-1">{error}</h4>
                )}
                <button
                  type="submit"
                  className="btn round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1"
                  disabled={pristine || submitting}
                >
                  {submitting ? <Loader /> : 'Login'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default reduxForm({
  form: 'login-form',
  validate: validateLoginForm,
})(LoginForm)
