import { GET_ORGANIZATION_SCHOOL_DETAILS_BY_SCHOOL_ID } from '../actions/school'

const organizationOrganizationSchoolDetailReducer = (
  state = { data: [] },
  action,
) => {
  switch (action.type) {
    case GET_ORGANIZATION_SCHOOL_DETAILS_BY_SCHOOL_ID:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationOrganizationSchoolDetailReducer
