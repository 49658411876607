import {
  GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADING,
  GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST,
  GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADED,
} from '../../actions/report'

const classAttendanceReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST:
      return {
        ...state,
        data: action.payload,
      }
    case GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default classAttendanceReducer
