/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Link, withRouter } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { confirmAlert } from 'react-confirm-alert' // Import
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { withLocalize, getTranslate } from 'react-localize-redux'
import {
  getPrincipalInstructors,
  storeInstructor,
  storeInstructorMultiple,
  sampleFileInstructor,
} from '../../../../../store/actions/instructors'
import config from '../../../../../config'
import instructorImg from '../../../../../app-assets/images/instructor.png'
import { resetForm, dispatchFormField } from '../../../../../store/actions/form'
import CreateNewFormModelBox from '../../Instructors/CreateNewFormModelBox'
import CreateNewMultipleFormModelBox from '../../Instructors/CreateNewMultipleFormModelBox'

import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import RenderImage from '../../../../../components/RenderImage'
import TableRowLicenseProgressBar from '../../../../../components/TableRowLicenseProgressBar'
import TextTruncate from '../../../../../components/TextTruncate'
/**
 * import translation related files
 *
 */
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class Instructors extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowNewInstructorForm: false,
      isShowNewMultipleInstructorForm: false,
      files: [],
      imagePreviewUrl: instructorImg,
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
      loading: false,
    }
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentWillMount() {
    this.setState({ loading: true })
    this.props.getPrincipalInstructors(
      this.props,
      this.state.page,
      this.state.orderBy,
      this.state.order,
    )
    this.setState({ page: this.state.page + 1 })
  }

  handlePageChange = async e => {
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: this.state.page + 1 })
        if (this.props.data.totalPage > this.state.page) {
          this.setState({ counter: this.state.counter + 1 })
          this.setState({ loading: true })
          this.setState({ scrollTop: element.scrollTop })
          await this.props.getPrincipalInstructors(
            this.props,
            this.state.page,
            this.state.orderBy,
            this.state.order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    let order = 'ASC'
    if (this.state.orderBy === field) {
      order = this.state.order === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    this.setState({ loading: true })
    await this.props.getPrincipalInstructors(this.props, 0, field, order)
    this.setState({ page: 1 })
  }

  handleNewInstructorModelShow = () => {
    this.props.resetForm('principal-create-instructor')
    this.props.dispatchFormField('principal-create-instructor', 'image', null)
    this.setState({
      isShowNewInstructorForm: true,
      isShowNewMultipleInstructorForm: false,
      files: null,
      imagePreviewUrl: instructorImg,
    })
  }

  handleNewInstructorModelClose = () => {
    this.props.resetForm('principal-create-instructor')
    this.setState({
      isShowNewInstructorForm: false,
      isShowNewMultipleInstructorForm: false,
    })
  }

  handleNewMultipleInstructorModelShow = () => {
    this.props.resetForm('principal-create-instructor-multiple')
    this.setState({
      isShowNewMultipleInstructorForm: true,
      isShowNewInstructorForm: false,
      files: null,
    })
  }

  handleNewMultipleInstructorModelClose = () => {
    this.props.resetForm('principal-create-instructor-multiple')
    this.setState({
      isShowNewMultipleInstructorForm: false,
      isShowNewInstructorForm: false,
    })
  }

  handleSubmit = async values => {
    const file = this.state.files
    const instructorId = await this.props.storeInstructor(
      values,
      file,
      this.props,
    )
    this.setState({ isShowNewInstructorForm: false })
    return this.props.history.push(
      `${config.principalUrl}/instructor/${instructorId}`,
    )
  }

  handleMultipleSubmit = async values => {
    const file = this.state.files
    await this.props.storeInstructorMultiple(file, this.props)
    this.props.getPrincipalInstructors(this.props)
    this.setState({ isShowNewMultipleInstructorForm: false })
    return this.props.history.push(`${config.principalUrl}/instructors`)
  }

  handleCancel = async () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            this.props.resetForm('principal-create-instructor')
            this.props.resetForm('principal-create-instructor-multiple')
            this.setState({ isShowNewInstructorForm: false })
            this.setState({ isShowNewMultipleInstructorForm: false })
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  handleInputChange = e => {
    e.preventDefault()
    const { files } = e.target
    const allFiles = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // Make new FileReader
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        }
        this.setState({ files: fileInfo })
        this.setState({
          imagePreviewUrl: reader.result,
        })
        this.props.dispatchFormField(
          'principal-create-instructor',
          'image',
          fileInfo,
        )
      } // reader.onload
    } // for
  }

  downloadSampelXLSX = async e => {
    e.preventDefault()
    await this.props.sampleFileInstructor(`Instructors_Sample.xlsx`, this.props)
  }

  downloadSampelCSV = async e => {
    // console.log('downloadSampelCSV');
    e.preventDefault()
    await this.props.sampleFileInstructor(`Instructors_Sample.csv`, this.props)
  }

  handleInputChangeMultiple = e => {
    e.preventDefault()
    const { files } = e.target
    const allFiles = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // Make new FileReader
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        }
        this.setState({ files: fileInfo })
        this.props.dispatchFormField(
          'principal-create-instructor-multiple',
          'file',
          fileInfo,
        )
      } // reader.onload
    } // for
  }

  render() {
    const { translate } = this.props
    let loadingTable = this.state.loading
    loadingTable = this.props.loading
    const sortClass = this.state.order === 'ASC' ? 'ascending' : 'desending'
    return (
      <React.Fragment>
        <div className="row match-height">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('instructorTableTitle')}{' '}
              <Link
                className="add_btn pull-right"
                to="#"
                onClick={this.handleNewInstructorModelShow}
              >
                Add Instructor
              </Link>
            </h5>
            <div className="card" style={{}}>
              <div
                className={
                  this.props.data && this.props.data.totalPage > 0
                    ? 'card-content '
                    : 'card-content'
                }
              >
                <div
                  id="recent-projects"
                  className="media-list position-relative"
                  onScroll={this.handlePageChange.bind(this)}
                >
                  <LoadingOverlay
                    active={loadingTable}
                    spinner
                    text={translate('loadingOverlayText')}
                  >
                    <div
                      className={
                        this.props.instructors.length === 0
                          ? 'table-responsive table-height'
                          : 'table-responsive'
                      }
                    >
                      {this.props.instructors &&
                        this.props.instructors.length > 0 && (
                          <div id="tableContainer" className="tableContainer">
                            <table
                              className="scrollTable instructors-list"
                              width="100%"
                              cellSpacing={0}
                              cellPadding={0}
                              border={0}
                            >
                              <thead className="fixedHeader">
                                <tr>
                                  <th align="left" width="10%">
                                    {translate('instructorTableRowImage')}
                                  </th>
                                  <th
                                    align="left"
                                    width="15%"
                                    onClick={() => this.handleSorting('name')}
                                    className={
                                      this.state.orderBy === 'name'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    {translate('instructorTableRowName')}
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="left"
                                    width="15%"
                                    onClick={() => this.handleSorting('email')}
                                    className={
                                      this.state.orderBy === 'email'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    {translate('instructorTableRowEmail')}
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="10%"
                                    onClick={() =>
                                      this.handleSorting('total_class')
                                    }
                                    className={
                                      this.state.orderBy === 'total_class'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    {translate('instructorTableRowClasses')}
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="16%"
                                    onClick={() =>
                                      this.handleSorting('total_license')
                                    }
                                    className={
                                      this.state.orderBy === 'total_license'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    {translate('instructorTableRowLicenses')}
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="left"
                                    width="14%"
                                    onClick={() =>
                                      this.handleSorting('school_name')
                                    }
                                    className={
                                      this.state.orderBy === 'school_name'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    {translate('instructorTableRowSchool')}
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="15%"
                                    onClick={() => this.handleSorting('tags')}
                                    className={
                                      this.state.orderBy === 'tags'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    {translate('instructorTableRowTags')}
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th align="left" width="5%">
                                    {translate('instructorTableRowView')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="scrollContent">
                                {this.props.instructors &&
                                  this.props.instructors.length > 0 &&
                                  this.props.instructors.map(row => (
                                    <tr>
                                      <td align="left" width="10%">
                                        <RenderImage
                                          className="instructor_img"
                                          image={row.photo_path}
                                          apiUrl={config.apiPrincipalUrl}
                                          source="1"
                                          name={row.name}
                                        />
                                      </td>
                                      <td align="left" width="15%">
                                        <Link
                                          to={`${config.principalUrl}/instructor/${row.id}`}
                                        >
                                          {row.name}
                                        </Link>
                                      </td>
                                      <td align="left" width="15%">
                                        <TextTruncate
                                          str={row.email}
                                          length={null}
                                          ending={null}
                                        />
                                      </td>
                                      <td align="center" width="10%">
                                        <NumberFormat
                                          thousandSeparator
                                          value={
                                            row.total_class
                                              ? row.total_class
                                              : 0
                                          }
                                          displayType="text"
                                        />
                                      </td>
                                      <td align="center" width="16%">
                                        <span className="text-center">
                                          <NumberFormat
                                            thousandSeparator
                                            value={
                                              row.total_active_license
                                                ? row.total_active_license
                                                : 0
                                            }
                                            displayType="text"
                                          />{' '}
                                          /{' '}
                                          <NumberFormat
                                            thousandSeparator
                                            value={
                                              row.total_license
                                                ? row.total_license
                                                : 0
                                            }
                                            displayType="text"
                                          />
                                        </span>
                                        <TableRowLicenseProgressBar
                                          activeLicense={
                                            row && row.total_active_license
                                          }
                                          totalLicense={
                                            row && row.total_license
                                          }
                                        />
                                      </td>
                                      <td align="left" width="14%">
                                        {row.school_name}
                                      </td>
                                      <td align="center" width="15%">
                                        {row.tags}
                                      </td>
                                      <td align="center" width="5%">
                                        <Link
                                          to={`${config.principalUrl}/instructor/${row.id}`}
                                        >
                                          <i className="fa fa-eye" />
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      {this.props.instructors &&
                        this.props.instructors.length === 0 &&
                        loadingTable === false && (
                          <div className="table-no-record">
                            {translate('tableNoRecord')}
                          </div>
                        )}
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateNewFormModelBox
          isShowNewInstructorForm={this.state.isShowNewInstructorForm}
          handleNewInstructorModelClose={this.handleNewInstructorModelClose}
          onSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
          handleInputChange={this.handleInputChange}
          imagePreviewUrl={this.state.imagePreviewUrl}
          handleMultipleInstructor={this.handleNewMultipleInstructorModelShow}
        />
        <CreateNewMultipleFormModelBox
          isShowNewMultipleInstructorForm={
            this.state.isShowNewMultipleInstructorForm
          }
          handleNewMultipleInstructorModelClose={
            this.handleNewMultipleInstructorModelClose
          }
          onSubmit={this.handleMultipleSubmit}
          handleCancel={this.handleCancel}
          handleInputChange={this.handleInputChangeMultiple}
          handleSingleInstructor={this.handleNewInstructorModelShow}
          downloadSampelXLSX={this.downloadSampelXLSX}
          downloadSampelCSV={this.downloadSampelCSV}
        />
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.principalInstructors.data,
  instructors: state.principalInstructors.instructors,
  loading: state.principalInstructors.loading,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalInstructors: (props, page, orderBy, order) =>
    dispatch(getPrincipalInstructors(props, page, orderBy, order)),
  storeInstructor: (values, file, props) =>
    dispatch(storeInstructor(values, file, props)),
  storeInstructorMultiple: (file, props) =>
    dispatch(storeInstructorMultiple(file, props)),
  resetForm: formName => dispatch(resetForm(formName)),
  sampleFileInstructor: (name, props) =>
    dispatch(sampleFileInstructor(name, props)),
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default withRouter(
  withLocalize(connect(mapStateToProps, mapDispatchToProps)(Instructors)),
)
