/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'
import CommonClassAttendance from '../../../../../Common/Report/ClassAttendance'
import { getPrincipalClassAttendencesReportByReportId } from '../../../../../../store/actions/report'
/**
 * import translation related files
 *
 */

class ClassAttendance extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  componentWillMount() {
    const loadingTable = this.state.loading
    this.props.getPrincipalClassAttendencesReportByReportId(
      this.props.reportId,
      this.props,
    )
  }

  render() {
    const {
      translate,
      data,
      loading,
      handleChartClassAttendanceWrapper,
    } = this.props
    return (
      <CommonClassAttendance
        data={data}
        loading={loading}
        handleChartClassAttendanceWrapper={handleChartClassAttendanceWrapper}
      />
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.principalClassAttendance.data.attendances,
  loading: state.principalClassAttendance.loading,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalClassAttendencesReportByReportId: (reportId, props) => {
    dispatch(getPrincipalClassAttendencesReportByReportId(reportId, props))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassAttendance)
