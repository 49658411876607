/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import jsPDF from 'jspdf'
import Chart from 'react-google-charts'
import { renderToString } from 'react-dom/server'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../config'
import ClassAttendance from './ClassAttendance'
import PhonemeScores from './PhonemeScores'
import AdventureStats from './AdventureStats'
import AggregateDate from './AggregateDate'
import 'jspdf-autotable'
import {
  getTeacherAdventureStatsReportByReportId,
  getTeacherAssessmentAdventureStatsReportByReportId,
} from '../../../../store/actions/report'
/**
 * import translation related files
 *
 */

class Reports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checkAll: '',
      chartClassAttendanceWrapper: null,
      chartClassPhonemeScoresWrapper: null,
      loading: true,
    }
  }

  componentDidMount() {
    if (this.props.user && this.props.user.is_admin_report_creation === 0) {
      this.props.history.push(`${config.teacherUrl}/home`)
    }
  }

  componentWillMount() {
    const loadingTable = this.state.loading
    this.props.getTeacherAdventureStatsReportByReportId(this.props)
    this.props.getTeacherAssessmentAdventureStatsReportByReportId(this.props)
  }

  handleCheck = () => {
    const currentStatus = this.state.checkAll
    const setStatus = currentStatus === '' ? 'checked' : ''
    this.setState({ checkAll: setStatus })
  }

  print = () => {
    let height = 10
    const width = 10
    const pdf = new jsPDF('p', 'mm', 'a3')
    const columns = [
      this.props.translate('reportAdventureAssessmentStatsTableRowAdventure'),
      this.props.translate('reportAdventureAssessmentStatsTableRowFocusWords'),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowGrammarPrincipal',
      ),
      this.props.translate('reportAdventureAssessmentStatsTableRowAverageTime'),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowAverageScore',
      ),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowAverageStars',
      ),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowNumberClassesPlayed',
      ),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowNumberStudentsPlayed',
      ),
    ]
    const rows = []
    if (this.props.dataAdventures) {
      for (const row of this.props.dataAdventures) {
        // const averageQuestionsCorrect =  row.id/row.completed;
        rows.push([
          row.name,
          row.focus_words,
          row.grammar_principles,
          (Math.round(row.time * 100) / 100).toFixed(1),
          (Math.round(row.score * 100) / 100).toFixed(1),
          (Math.round(row.stars * 100) / 100).toFixed(1),
          parseInt(row.classPlayed),
          parseInt(row.studentPlayed),
        ])
      }
    }

    const rows1 = []
    if (this.props.dataAssessmentAdventures) {
      for (const row of this.props.dataAssessmentAdventures) {
        // const averageQuestionsCorrect =  row.id/row.completed;
        rows1.push([
          row.name,
          row.focus_words,
          row.grammar_principles,
          (Math.round(row.time * 100) / 100).toFixed(1),
          (Math.round(row.score * 100) / 100).toFixed(1),
          (Math.round(row.stars * 100) / 100).toFixed(1),
          parseInt(row.classPlayed),
          parseInt(row.studentPlayed),
        ])
      }
    }

    pdf.text(this.props.translate('breadCrumbReportView'), width, height)
    height += 10

    height += 40
    if (this.state.chartClassAttendanceWrapper !== null) {
      const classAttendanceImage = this.state.chartClassAttendanceWrapper
        .getChart()
        .getImageURI()
      pdf.text(this.props.translate('reportClassAttendance'), width, height)
      height += 1
      pdf.addImage(classAttendanceImage, 'JPEG', width, height)
    }
    if (this.state.chartClassPhonemeScoresWrapper !== null) {
      const classPhonemeScoresImage = this.state.chartClassPhonemeScoresWrapper
        .getChart()
        .getImageURI()
      height += 100
      pdf.text(this.props.translate('reportPhonemeScores'), width, height)
      height += 1
      pdf.addImage(classPhonemeScoresImage, 'JPEG', width, height)
    }

    height += 120
    pdf.text(this.props.translate('reportAdventureStats'), width, height)
    height += 5
    pdf.autoTable({
      columnStyles: {
        0: { columnWidth: 30 },
        3: { columnWidth: 20 },
        4: { columnWidth: 20 },
        5: { columnWidth: 20 },
      },
      startY: height,
      head: [columns],
      body: rows,
    })

    height += 120
    pdf.text(
      this.props.translate('reportAssessmentAdventureStats'),
      width,
      height,
    )
    height += 5
    pdf.autoTable({
      columnStyles: {
        0: { columnWidth: 30 },
        3: { columnWidth: 20 },
        4: { columnWidth: 20 },
        5: { columnWidth: 20 },
      },
      startY: height,
      head: [columns],
      body: rows1,
    })

    pdf.save('report' + '.pdf')
  }

  handleChartClassAttendanceWrapper = chartWrapper => {
    this.setState({ chartClassAttendanceWrapper: chartWrapper })
  }

  handleChartClassPhonemeScoresWrapper = chartWrapper => {
    this.setState({ chartClassPhonemeScoresWrapper: chartWrapper })
  }

  render() {
    const {
      translate,
      dataAdventures,
      loading,
      dataAssessmentAdventures,
    } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row" />
        <div className="content-body">
          <ClassAttendance
            handleChartClassAttendanceWrapper={
              this.handleChartClassAttendanceWrapper
            }
          />
          <PhonemeScores
            handleChartClassPhonemeScoresWrapper={
              this.handleChartClassPhonemeScoresWrapper
            }
          />
          <AdventureStats
            dataAssessment={dataAssessmentAdventures}
            data={dataAdventures}
            loading={loading}
          />
          <AggregateDate print={this.print} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  user: state.authTeacher.user,
  dataAdventures: state.teacherAdventureStats.data.adventures,
  dataAssessmentAdventures:
    state.teacherAssessmentAdventureStats.data.adventures,
  loading: state.teacherAdventureStats.loading,
})

const mapDispatchToProps = dispatch => ({
  getTeacherAdventureStatsReportByReportId: props => {
    dispatch(getTeacherAdventureStatsReportByReportId(props))
  },
  getTeacherAssessmentAdventureStatsReportByReportId: props => {
    dispatch(getTeacherAssessmentAdventureStatsReportByReportId(props))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
