/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'

// BEGIN: Image
import { withLocalize } from 'react-localize-redux'
import innerBannerImg from '../../../assets/root/images/inner-banner.jpg'
// END: Image

import englishRootPrivacyPolicyTranslations from '../../../lang/root/privacypolicy/en.json'
import spanishRootPrivacyPolicyTranslations from '../../../lang/root/privacypolicy/es.json'
import chineseRootPrivacyPolicyTranslations from '../../../lang/root/privacypolicy/zh.json'

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(
      englishRootPrivacyPolicyTranslations,
      'en',
    )
    this.props.addTranslationForLanguage(
      spanishRootPrivacyPolicyTranslations,
      'es',
    )
    this.props.addTranslationForLanguage(
      chineseRootPrivacyPolicyTranslations,
      'zh',
    )
  }

  render() {
    const { translate } = this.props
    return (
      <React.Fragment>
        <div id="banner_section">
          <div className="yellowbox">
            <center>
              <h2 className="taglines">{translate('title')}</h2>
            </center>
          </div>
          <img
            src={innerBannerImg}
            className="img-responsive"
            alt={translate('title')}
          />
        </div>
        <div id="about" className="aboutus inner-abtus">
          <div className="container">
            <h3 className="wow animate fadeInDown delay-06s">
              {translate('title')}
            </h3>
            <h5>{translate('title2')}</h5>
            {translate('details')}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withLocalize(PrivacyPolicy)
