import isEmail from 'validator/lib/isEmail'

const validateLoginForm = values => {
  const errors = {}
  // email
  if (!values.email) {
    errors.email = [`Email is required`]
  } else if (!isEmail(values.email)) {
    errors.email = [`Please provide valid email address`]
  }

  // email
  if (!values.password) {
    errors.password = [`Password is required`]
  } else {
    // if (!isEmail(values.email)) {
    //   errors.email = [`Please provide valid email id`]
    // }
  }

  return errors
}

export default validateLoginForm
