/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../config'

class Student extends React.Component {
  render() {
    const { row, translate } = this.props
    return (
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-md-12 col-lg-12 pull-left">
              <div className="card class_card student_card">
                <div className="card-header">
                  <h4 className="org-id">
                    {translate('id')}:{row && row.id}
                  </h4>
                  <br />
                  <h4 className="org-id">
                    {translate('studentDetailRowLicense')}{' '}
                  </h4>
                  <h4 className="card-title">{row && row.code}</h4>
                  <a className="heading-elements-toggle">
                    <i className="la la-ellipsis-v font-medium-3" />
                  </a>
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  row: state.studentProfile.data.row,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Student)
