import {
  GET_PRINCIPAL_STATS,
  GET_PRINCIPAL_STATS_LOADING,
  GET_PRINCIPAL_STATS_LOADED,
} from '../../actions/principal'

const principalStatsReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_PRINCIPAL_STATS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_PRINCIPAL_STATS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_PRINCIPAL_STATS_LOADED:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export default principalStatsReducer
