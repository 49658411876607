/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import Moment from 'react-moment'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'
import TableRowDateProgressBar from '../../../../../../components/TableRowDateProgressBar'
import TableRowLicenseProgressBar from '../../../../../../components/TableRowLicenseProgressBar'
import DateTimeWithTimezone from '../../../../../../components/DateTimeWithTimezone'
/**
 * import translation related files
 *
 */

class Detail extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { translate, row, handleModelShow } = this.props
    const tagArr = row && row.tags ? row.tags.split(',') : []
    const status = row && (row.status === 'Active' ? 'fa fa-check-square' : '')
    const datePercentage = 0

    return (
      <React.Fragment>
        <h5 className="card-title text-bold-700 my-2">
          {translate('classDetailTitle')}
          <Link class="add_btn pull-right" to="#" onClick={handleModelShow}>
            {translate('formClassTitleEdit')}
          </Link>
        </h5>
        <div className="card">
          <div className="card-content collapse show">
            <div className="card-footer text-center p-1">
              <div className="row">
                <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-left">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowName')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.name}
                  </p>
                </div>
                <div className="col-md-6 col-12 text-left">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classTableRowStatus')}
                  </p>
                  <p
                    className={
                      row && row.status === 'Active'
                        ? 'font-medium-6 text-bold-400 status_approved'
                        : 'font-medium-6 text-bold-400 status_pending'
                    }
                  >
                    {row && row.status} <i className={status} />
                  </p>
                </div>
                <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-left">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowDescription')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.description}
                  </p>
                </div>
                <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-left">
                  <div className="row">
                    <div className="col-12">
                      <div className="card pull-up">
                        <div className="card-header">
                          <h6 className="card-title float-left">
                            {translate('classDetailRowStartDate')}
                            <span className="start_date_color">
                              <DateTimeWithTimezone
                                dateTime={row && row.start_date}
                                isTime="false"
                                isRed="false"
                              />
                            </span>
                          </h6>
                          <h6 className="card-title float-right">
                            {translate('classDetailRowEndDate')}
                            <span className="end_date_color">
                              <DateTimeWithTimezone
                                dateTime={row && row.end_date}
                                isTime="false"
                                isRed="true"
                                start_date={row && row.start_date}
                                end_date={row && row.end_date}
                              />
                            </span>
                          </h6>
                        </div>
                        <div className="card-content collapse show">
                          <div className="card-body pt-0 pb-1">
                            <TableRowDateProgressBar
                              start_date={row && row.start_date}
                              end_date={row && row.end_date}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-4 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowSchoolName')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.school_name}
                  </p>
                </div>
                <div className="col-md-4 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowTeacherName')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    <Link
                      to={`${config.principalUrl}/instructor/${row &&
                        row.instructor}`}
                    >
                      {row && row.instructor_name}
                    </Link>
                  </p>
                </div>
                <div className="col-md-4 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowLicensesActivated')}
                  </p>
                  <span className="text-center">
                    <NumberFormat
                      thousandSeparator
                      value={
                        row && row.total_active_license
                          ? row.total_active_license
                          : 0
                      }
                      displayType="text"
                    />{' '}
                    /{' '}
                    <NumberFormat
                      thousandSeparator
                      value={row && row.total_license ? row.total_license : 0}
                      displayType="text"
                    />
                  </span>
                  <TableRowLicenseProgressBar
                    activeLicense={row && row.total_active_license}
                    totalLicense={row && row.total_license}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-12">
                  <p className="blue-grey lighten-2 mb-0 text-left">
                    {translate('classTableRowTags')}
                  </p>
                  <div className="tags_div">
                    {tagArr && tagArr.map(tag => <p>{tag}</p>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
