import { GET_PRINCIPAL_INSTRUCTOR_DROPDOWN_LIST } from '../../actions/instructors'

const principalInstructorDropdownReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_PRINCIPAL_INSTRUCTOR_DROPDOWN_LIST:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default principalInstructorDropdownReducer
