/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { reduxForm, Field, reset } from 'redux-form'
import { Modal } from 'react-bootstrap'
import { getTranslate } from 'react-localize-redux'
import Loader from '../../../../../components/Loader'
import RenderField from '../../../../../components/RenderFieldTranlate'
import RenderSelectField from '../../../../../components/RenderSelectFieldTranlate'
import RenderTextField from '../../../../../components/RenderTextFieldTranlate'
import RenderDateStartPicker from '../../../../../components/RenderDateRangePickerTranlate/start'
import RenderDateEndtPicker from '../../../../../components/RenderDateRangePickerTranlate/end'
import RenderMultipleSelectWithCreateField from '../../../../../components/RenderMultipleSelectWithCreateFieldTranslate'
import validateForm from '../CreateNewFormModelBox/helpers'
import { getPrincipalTags } from '../../../../../store/actions/tag'
import { getPrincipalInstructorList } from '../../../../../store/actions/instructors'

/**
 * import translation related files
 *
 */

class CreateNewFormModelBox extends React.Component {
  constructor(props) {
    super(props)
    const date = moment().format('YYYY-MM-DD')
    this.state = {
      startDate: new Date(date),
      endDate: new Date(date),
      today: new Date(date),
    }
  }

  componentWillMount() {
    this.props.getPrincipalTags(this.props)
    this.props.getPrincipalInstructorList(this.props)
  }

  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate
    endDate = endDate || this.state.endDate
    this.setState({ startDate, endDate })
  }

  handleChangeStart = startDate => this.handleChange({ startDate })

  handleChangeEnd = endDate => this.handleChange({ endDate })

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      error,
      reset,
      handleCancel,
      isShowNewClassForm,
      handleNewClassModelClose,
      translate,
      tags,
      handleCreateTag,
      instructors,
      principalProfile,
    } = this.props
    return (
      <Modal
        show={isShowNewClassForm}
        onHide={handleNewClassModelClose}
        animation={false}
        id="changePassword"
        dialogClassName=""
        aria-labelledby="basicModalLabel1"
      >
        <div className="modal-content">
          <form
            className="form-horizontal"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="modal-header">
              <h4 className="modal-title" id="basicModalLabel1">
                {translate('formClassTitleEdit')}
              </h4>
              <button
                type="button"
                className="close"
                onClick={handleNewClassModelClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="name">
                  <span>*</span>
                  {translate('formClassName')}
                </label>
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={translate('formClassName')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="instructor">
                  <span>*</span>
                  {translate('formClassInstructor')}
                </label>
                <Field name="instructor" component={RenderSelectField}>
                  <option value="">
                    {translate('formClassInstructorSelect')}
                  </option>
                  {instructors &&
                    instructors.map(row => (
                      <option value={row.id}>{row.name}</option>
                    ))}
                </Field>
              </div>
              <div className="form-group">
                <label htmlFor="school_name">
                  <span>*</span>
                  {translate('formClassSchool')}
                </label>
                <Field
                  name="school_name"
                  component={RenderSelectField}
                  disabled="disabled"
                >
                  {principalProfile && (
                    <option value={principalProfile.id}>
                      {principalProfile.school_name}
                    </option>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <label htmlFor="description">
                  {translate('formClassDescription')}
                </label>
                <Field
                  name="description"
                  component={RenderTextField}
                  placeholder={translate('formClassDescription')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="status">
                  <span>*</span>
                  {translate('formClassStatus')}
                </label>
                <Field name="status" component={RenderSelectField}>
                  <option value="Active">Active</option>
                  <option value="Deactivated">Deactivate</option>
                </Field>
              </div>
              <div className="form-group date_ranger">
                <label>
                  <span>*</span>
                  {translate('formClassDate')}
                </label>
                <div className="input-group">
                  <Field
                    name="start_date"
                    component={RenderDateStartPicker}
                    id="start_date"
                    placeholder="Start Date"
                    required
                    selected={this.state.startDate}
                    selectsStart
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={this.handleChangeStart}
                    readonly
                    edit="true"
                    dbDate={
                      this.props.classRow && this.props.classRow.start_date
                    }
                    formName="principal-edit-class"
                    fieldName="start_date"
                  />
                  <Field
                    name="end_date"
                    component={RenderDateEndtPicker}
                    id="end_date"
                    placeholder="End Date"
                    selected={this.state.endDate}
                    selectsEnd
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={this.handleChangeEnd}
                    minDate={this.state.startDate}
                    required
                    readonly
                    edit="true"
                    dbDate={this.props.classRow && this.props.classRow.end_date}
                    formName="principal-edit-class"
                    fieldName="end_date"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="total_license">
                  <span>*</span>
                  {translate('formClassLicenses')}
                </label>
                <Field
                  name="total_license"
                  component={RenderField}
                  type="text"
                  placeholder={translate('formClassLicenses')}
                />
              </div>
              <div className="form-group select_p_tags">
                <label htmlFor="reportName">{translate('formClassTag')}</label>
                <Field
                  name="tag_ids"
                  component={RenderMultipleSelectWithCreateField}
                  data={tags}
                  textField="name"
                  valueField="id"
                  type="text"
                  formName="principal-edit-class"
                  fieldName="tag_ids"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={
                  pristine || submitting
                    ? handleNewClassModelClose
                    : handleCancel
                }
              >
                {translate('formClassBtnCancel')}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {submitting ? <Loader /> : translate('formClassBtnSave')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

CreateNewFormModelBox = reduxForm({
  form: 'principal-edit-class',
  validate: validateForm,
  enableReinitialize: true,
})(CreateNewFormModelBox)

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  instructors: state.principalInstructorDropdownList.data.instructors,
  tags: state.principalTags.data.tags,
  principalProfile: state.principalProfile.data.row,
  classRow: state.principalClassDetail.data.row,
  initialValues: state.principalClassDetail.data.row,
})

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset('principal-edit-class')),
  getPrincipalTags: props => dispatch(getPrincipalTags(props)),
  getPrincipalInstructorList: props =>
    dispatch(getPrincipalInstructorList(props)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewFormModelBox)
