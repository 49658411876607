import { ORGANIZATION_USER_STORE } from '../actions/organizationUser'

const organizationUserStoreReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case ORGANIZATION_USER_STORE:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationUserStoreReducer
