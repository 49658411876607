/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { getOrganizationReport } from '../../../../../store/actions/report'
import config from '../../../../../config'
import DateTimeWithTimezone from '../../../../../components/DateTimeWithTimezone'
/**
 * import translation related files
 *
 */
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'

class Reports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
      loading: false,
    }
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }

  componentWillMount() {
    this.setState({ loading: true })
    this.props.getOrganizationReport(
      this.props,
      this.state.page,
      this.state.orderBy,
      this.state.order,
    )
    this.setState({ page: this.state.page + 1 })
  }

  handlePageChange = async e => {
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: this.state.page + 1 })
        if (this.props.data.totalPage > this.state.page) {
          this.setState({ counter: this.state.counter + 1 })
          this.setState({ loading: true })
          this.setState({ scrollTop: element.scrollTop })
          await this.props.getOrganizationReport(
            this.props,
            this.state.page,
            this.state.orderBy,
            this.state.order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    let order = 'ASC'
    if (this.state.orderBy === field) {
      order = this.state.order === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    this.setState({ loading: true })
    await this.props.getOrganizationReport(this.props, 0, field, order)
    this.setState({ page: 1 })
  }

  render() {
    const { translate } = this.props
    let loadingTable = this.state.loading
    loadingTable = this.props.loading
    const sortClass = this.state.order === 'ASC' ? 'ascending' : 'desending'
    return (
      <React.Fragment>
        <div className="row match-height">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('reportTableTitle')}
              <Link
                className="add_btn pull-right"
                to={`${config.organizationUrl}/create-report`}
              >
                {translate('btnReportCreate')}
              </Link>
            </h5>
            <div className="card" style={{}}>
              <div
                className={
                  this.props.data && this.props.data.totalPage > 0
                    ? 'card-content '
                    : 'card-content'
                }
              >
                <div
                  id="recent-projects"
                  className="media-list position-relative"
                  onScroll={this.handlePageChange.bind(this)}
                >
                  <LoadingOverlay
                    active={loadingTable}
                    spinner
                    text={translate('loadingOverlayText')}
                  >
                    <div
                      className={
                        this.props.reports.length === 0
                          ? 'table-responsive table-height'
                          : 'table-responsive'
                      }
                    >
                      {this.props.reports && this.props.reports.length > 0 && (
                        <div id="tableContainer" className="tableContainer">
                          <table
                            className="scrollTable reports-list"
                            width="100%"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                          >
                            <thead className="fixedHeader">
                              <tr>
                                <th
                                  align="left"
                                  width="20%"
                                  onClick={() =>
                                    this.handleSorting('report_name')
                                  }
                                  className={
                                    this.state.orderBy === 'report_name'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('reportTableRowName')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="left"
                                  width="20%"
                                  onClick={() =>
                                    this.handleSorting('school_name')
                                  }
                                  className={
                                    this.state.orderBy === 'school_name'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('reportTableRowSchool')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() =>
                                    this.handleSorting('start_date')
                                  }
                                  className={
                                    this.state.orderBy === 'start_date'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('reportTableRowStart')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() => this.handleSorting('tags')}
                                  className={
                                    this.state.orderBy === 'tags'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('reportTableRowTags')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th align="center" width="20%">
                                  {translate('reportTableRowView')}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="scrollContent">
                              {this.props.reports &&
                                this.props.reports.length > 0 &&
                                this.props.reports.map(row => (
                                  <tr>
                                    <td align="left" width="20%">
                                      <Link
                                        to={`${config.organizationUrl}/report/${row.id}`}
                                      >
                                        {row.report_name}
                                      </Link>
                                    </td>
                                    <td align="left" width="20%">
                                      {row.school_name}
                                    </td>
                                    <td align="center" width="20%">
                                      <span className="text-center">
                                        {row.start_date == null && 'None'}
                                        {row.start_date != null && (
                                          <DateTimeWithTimezone
                                            dateTime={row && row.start_date}
                                            isTime="false"
                                            isRed="false"
                                          />
                                        )}{' '}
                                        - {row.end_date === null && 'None'}
                                        {row.end_date != null && (
                                          <DateTimeWithTimezone
                                            dateTime={row && row.end_date}
                                            isTime="false"
                                            isRed="false"
                                          />
                                        )}
                                      </span>
                                    </td>
                                    <td align="center" width="20%">
                                      {row.tags ? row.tags : '-'}
                                    </td>
                                    <td align="center" width="20%">
                                      <Link
                                        to={`${config.organizationUrl}/report/${row.id}`}
                                      >
                                        <i className="fa fa-eye" />
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {this.props.reports &&
                        this.props.reports.length === 0 &&
                        loadingTable === false && (
                          <div className="table-no-record">
                            {translate('tableNoRecord')}
                          </div>
                        )}
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.organizationOrganizationReport.data,
  reports: state.organizationOrganizationReport.reports,
  loading: state.organizationOrganizationReport.loading,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationReport: (props, page, orderBy, order) =>
    dispatch(getOrganizationReport(props, page, orderBy, order)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Reports),
)
