import React from 'react'
import PropTypes from 'prop-types'

import { withLocalize } from 'react-localize-redux'
import logoImg from 'assets/root/images/logo.png'
import config from 'config'

const Footer = ({ translate }) => (
  <React.Fragment>
    <div id="footerWrap" className="footer">
      <div className="container">
        <a id="scroll-top" href="#top" style={{ display: 'block' }}>
          <button type="button">
            <strong className="fa fa-arrow-up" aria-hidden="true" />
          </button>
        </a>
        <a className="footer_logo" href="/">
          <img
            src={logoImg}
            className="img-responsive"
            style={{ width: 'auto', maxHeight: '80px' }}
            alt="img"
          />{' '}
        </a>
        <ul className="footer_ul">
          <li>
            <a href={`${config.aboutUsUrl}`}>{translate('footerAboutUs')}</a>
          </li>
          <li>
            <a href={`${config.contactUsUrl}`}>
              {translate('footerContactUs')}
            </a>
          </li>
          <li>
            <a href={`${config.privacyPolicyUrl}`}>
              {translate('footerPrivacyPolicy')}
            </a>
          </li>
          <li>
            <a href={`${config.termofUseUrl}`}>
              {translate('footerTermsofUse')}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="lower_footer">
      <div className="container text-center">
        <p>{translate('copyRight')}</p>
      </div>
    </div>
  </React.Fragment>
)

Footer.propTypes = {
  translate: PropTypes.func.isRequired,
}

export default withLocalize(Footer)
