import isEmail from 'validator/lib/isEmail'

const validateRecoverPasswordForm = values => {
  const errors = {}
  // email
  if (!values.email) {
    errors.email = [`Email is required`]
  } else if (!isEmail(values.email)) {
    errors.email = [`Please provide valid email address`]
  }
  return errors
}

export default validateRecoverPasswordForm
