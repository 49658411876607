/* TODO: Fix eslint */
/* eslint-disable */

import isEmail from 'validator/lib/isEmail'
import isEquals from 'validator/lib/equals'

const validateForm = (values, props) => {
  const errors = {}

  // name
  if (!values.name) {
    errors.name = [`errorMsgUserServiceCreateNameMissing`]
  } else if (values.name.length < 2) {
    errors.name = [`errorMsgUserServiceCreateNameIsLength`]
  } else if (values.name.length > 50) {
    errors.name = [`errorMsgUserServiceCreateNameIsLength`]
  }

  // email
  if (!values.email) {
    errors.email = [`errorMsgUserServiceCreateEmailMissing`]
  } else if (!isEmail(values.email)) {
    errors.email = [`errorMsgUserServiceCreateEmailIsEmail`]
  } else if (values.email.length > 100) {
    errors.phone = [`errorMsgUserServiceCreateEmailIsLength`]
  }

  // email
  if (!values.verify_email) {
    errors.verify_email = [`errorMsgUserServiceCreateVerifyEmailMissing`]
  } else if (!isEquals(values.verify_email, values.email)) {
    errors.verify_email = [`errorMsgUserServiceCreateVerifyEmailEquals`]
  }

  return errors
}

export default validateForm
