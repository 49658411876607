/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert' // Import
import jsPDF from 'jspdf'
import { renderToString } from 'react-dom/server'
import Chart from 'react-google-charts'
import moment from 'moment'
import { getTranslate, withLocalize } from 'react-localize-redux'
import config from '../../../../../config'
import Organization from '../../Common/Organization'
import Detail from './Detail'
import ClassAttendance from './ClassAttendance'
import PhonemeScores from './PhonemeScores'
import AdventureStats from './AdventureStats'
import AggregateDate from './AggregateDate'
import {
  getOrganizationReportDetails,
  removeOrganizationReport,
  getOrganizationAdventureStatsReportByReportId,
  getOrganizationAssessmentAdventureStatsReportByReportId,
} from '../../../../../store/actions/report'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import 'jspdf-autotable'
/**
 * import translation related files
 *
 */
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'

class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reportId: '',
      chartClassAttendanceWrapper: null,
      chartClassPhonemeScoresWrapper: null,
      loading: true,
    }

    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
    localStorage.removeItem('organization-create-report')
  }

  componentDidMount() {
    if (this.props.user && this.props.user.is_admin_report_creation === 0) {
      this.props.history.push(`${config.organizationUrl}/home`)
    }
  }

  componentWillMount() {
    const { match } = this.props
    const reportId = match.params.rid
    if (reportId === ' ' || reportId === 'undefined') {
      this.props.history.push(`${config.organizationUrl}/home`)
    }
    this.setState({ reportId })
    this.props.getOrganizationReportDetails(reportId, this.props)
    const loadingTable = this.state.loading
    this.props.getOrganizationAdventureStatsReportByReportId(
      reportId,
      this.props,
    )
    this.props.getOrganizationAssessmentAdventureStatsReportByReportId(
      reportId,
      this.props,
    )
  }

  handleDelete = async () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertDeleteText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            await this.props.removeOrganizationReport(
              this.state.reportId,
              this.props,
            )
            this.props.history.push(`${config.organizationUrl}/reports`)
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  print = () => {
    let height = 10
    const width = 10
    const pdf = new jsPDF('p', 'mm', 'a3')
    //        this.props.translate("reportAdventureAssessmentStatsTableRowAverageQuestionsCorrect"),
    const columns = [
      this.props.translate('reportAdventureAssessmentStatsTableRowAdventure'),
      this.props.translate('reportAdventureAssessmentStatsTableRowFocusWords'),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowGrammarPrincipal',
      ),
      this.props.translate('reportAdventureAssessmentStatsTableRowAverageTime'),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowAverageScore',
      ),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowAverageStars',
      ),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowNumberClassesPlayed',
      ),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowNumberStudentsPlayed',
      ),
    ]
    const rows = []
    if (this.props.dataAdventures) {
      for (const row of this.props.dataAdventures) {
        // const averageQuestionsCorrect =  row.id/row.completed;
        rows.push([
          row.name,
          row.focus_words,
          row.grammar_principles,
          (Math.round(row.time * 100) / 100).toFixed(1),
          (Math.round(row.score * 100) / 100).toFixed(1),
          (Math.round(row.stars * 100) / 100).toFixed(1),
          parseInt(row.classPlayed),
          parseInt(row.studentPlayed),
        ])
      }
    }

    const rows1 = []
    if (this.props.dataAssessmentAdventures) {
      for (const row of this.props.dataAssessmentAdventures) {
        // const averageQuestionsCorrect =  row.id/row.completed;
        rows1.push([
          row.name,
          row.focus_words,
          row.grammar_principles,
          (Math.round(row.time * 100) / 100).toFixed(1),
          (Math.round(row.score * 100) / 100).toFixed(1),
          (Math.round(row.stars * 100) / 100).toFixed(1),
          parseInt(row.classPlayed),
          parseInt(row.studentPlayed),
        ])
      }
    }

    const columnsReport = [
      this.props.translate('reportDetailRowName'),
      this.props.translate('reportDetailRowSchool'),
      this.props.translate('reportDetailRowDescription'),
      this.props.translate('classDetailRowStartDate'),
      this.props.translate('classDetailRowEndDate'),
      this.props.translate('reportTableRowTags'),
    ]

    pdf.text(this.props.translate('breadCrumbReportView'), width, height)
    height += 10
    const format = config.dateFormat
    const currentHH = moment()
      .utc()
      .format('HH')
    const currentMM = moment()
      .utc()
      .format('mm')
    const currentSS = moment()
      .utc()
      .format('ss')
    const time = ` ${currentHH}:${currentMM}:${currentSS}`
    let stateDate = ''
    if (this.props.row.start_date) {
      const dbDate = moment(
        this.props.row.start_date + time,
        'YYYY-MM-DD HH:mm:ss',
      ).format('YYYY-MM-DD HH:mm:ss')
      const utcFormat = moment(dbDate)
        .utc()
        .format('YYYY-MM-DD HH:MM:SS')
      stateDate = moment
        .utc(utcFormat, 'YYYY-MM-DD HH:MM:SS')
        .local()
        .format(format)
    }
    let endDate = ''
    if (this.props.row.end_date) {
      const dbDate1 = moment(
        this.props.row.end_date + time,
        'YYYY-MM-DD HH:mm:ss',
      ).format('YYYY-MM-DD HH:mm:ss')
      const utcFormat1 = moment(dbDate1)
        .utc()
        .format('YYYY-MM-DD HH:MM:SS')
      endDate = moment
        .utc(utcFormat1, 'YYYY-MM-DD HH:MM:SS')
        .local()
        .format(format)
    }
    pdf.autoTable({
      startY: height,
      head: [columnsReport],
      body: [
        [
          this.props.row.report_name,
          this.props.row.school_name,
          this.props.row.description,
          stateDate,
          endDate,
          this.props.row.tags,
        ],
      ],
    })
    height += 40
    if (this.state.chartClassAttendanceWrapper !== null) {
      const classAttendanceImage = this.state.chartClassAttendanceWrapper
        .getChart()
        .getImageURI()
      pdf.text(this.props.translate('reportClassAttendance'), width, height)
      height += 1
      pdf.addImage(classAttendanceImage, 'JPEG', width, height)
    }
    if (this.state.chartClassPhonemeScoresWrapper !== null) {
      const classPhonemeScoresImage = this.state.chartClassPhonemeScoresWrapper
        .getChart()
        .getImageURI()
      height += 100
      pdf.text(this.props.translate('reportPhonemeScores'), width, height)
      height += 1
      pdf.addImage(classPhonemeScoresImage, 'JPEG', width, height)
    }
    height += 120
    pdf.text(this.props.translate('reportAdventureStats'), width, height)
    height += 5
    pdf.autoTable({
      columnStyles: {
        0: { columnWidth: 30 },
        3: { columnWidth: 20 },
        4: { columnWidth: 20 },
        5: { columnWidth: 20 },
      },
      startY: height,
      head: [columns],
      body: rows,
    })

    height += 120
    pdf.text(
      this.props.translate('reportAssessmentAdventureStats'),
      width,
      height,
    )
    height += 5
    pdf.autoTable({
      columnStyles: {
        0: { columnWidth: 30 },
        3: { columnWidth: 20 },
        4: { columnWidth: 20 },
        5: { columnWidth: 20 },
      },
      startY: height,
      head: [columns],
      body: rows1,
    })

    pdf.save(`${this.props.row.report_name}.pdf`)
  }

  handleChartClassAttendanceWrapper = chartWrapper => {
    this.setState({ chartClassAttendanceWrapper: chartWrapper })
  }

  handleChartClassPhonemeScoresWrapper = chartWrapper => {
    this.setState({ chartClassPhonemeScoresWrapper: chartWrapper })
  }

  render() {
    const {
      translate,
      row,
      dataAdventures,
      loading,
      dataAssessmentAdventures,
    } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              {translate('breadCrumbReportView')}
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/home`}>
                      {translate('breadCrumbHome')}
                    </Link>{' '}
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/reports`}>
                      {translate('breadCrumbReport')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {translate('breadCrumbReportView')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <Organization />
          <Detail row={row} handleDelete={this.handleDelete} />
          <ClassAttendance
            handleChartClassAttendanceWrapper={
              this.handleChartClassAttendanceWrapper
            }
            reportId={this.state.reportId}
          />
          <PhonemeScores
            handleChartClassPhonemeScoresWrapper={
              this.handleChartClassPhonemeScoresWrapper
            }
            reportId={this.state.reportId}
          />
          <AdventureStats
            dataAssessment={dataAssessmentAdventures}
            reportId={this.state.reportId}
            data={dataAdventures}
            loading={loading}
          />
          <AggregateDate reportId={this.state.reportId} print={this.print} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  row: state.organizationOrganizationReportDetail.data.row,
  user: state.authOrganization.user,
  dataAdventures: state.organizationAdventureStats.data.adventures,
  dataAssessmentAdventures:
    state.organizationAssessmentAdventureStats.data.adventures,

  loading: state.organizationAdventureStats.loading,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationReportDetails: (reportId, props) =>
    dispatch(getOrganizationReportDetails(reportId, props)),
  removeOrganizationReport: (reportId, props) =>
    dispatch(removeOrganizationReport(reportId, props)),
  getOrganizationAdventureStatsReportByReportId: (reportId, props) => {
    dispatch(getOrganizationAdventureStatsReportByReportId(reportId, props))
  },
  getOrganizationAssessmentAdventureStatsReportByReportId: (
    reportId,
    props,
  ) => {
    dispatch(
      getOrganizationAssessmentAdventureStatsReportByReportId(reportId, props),
    )
  },
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Details),
)
