import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import StarRatingComponent from 'react-star-rating-component'

export const StarRating = props => {
  const { number, count } = props
  return (
    <Fragment>
      <StarRatingComponent
        name="app6"
        starColor="#ffb400"
        emptyStarColor="#ffb400"
        value={number}
        starCount={count}
        renderStarIcon={(index, value) => {
          return (
            <span>
              <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
            </span>
          )
        }}
        renderStarIconHalf={() => {
          return (
            <span>
              <span style={{ position: 'absolute' }}>
                <i className="far fa-star" />{' '}
              </span>
              <span>
                {' '}
                <i className="fas fa-star-half" />
              </span>
            </span>
          )
        }}
      />
    </Fragment>
  )
}

StarRating.defaultProps = {
  count: 3,
}

StarRating.propTypes = {
  count: PropTypes.number,
  number: PropTypes.number.isRequired,
}

export default StarRating
