/* TODO: Fix eslint */
/* eslint-disable */

import axios from 'axios'
import { reset } from 'redux-form'
import config from '../../config'
import handleResponse from './handleResponse'

/**
 * Define actions
 *
 */
export const GET_ADMIN_USER_PROFILE = 'GET_ADMIN_USER_PROFILE'
export const GET_ADMIN_USER_LIST = 'GET_ADMIN_USER_LIST'
export const ADMIN_USER_STORE = 'ADMIN_USER_STORE'
export const ADMIN_USER_PROFILE_UPDATE = 'ADMIN_USER_PROFILE_UPDATE'
export const REMOVE_ADMIN_USER = 'REMOVE_ADMIN_USER'
export const ADMIN_CHANGE_PASSWORD = 'ADMIN_CHANGE_PASSWORD'

/**
 * To get admin users
 *
 * @return data
 */
export const getAdminUserList = () => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(`${config.apiUrl}`, headers)

    dispatch({
      type: GET_ADMIN_USER_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get admin user profile details
 *
 * @return data
 */
export const getAdminUserProfile = () => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(`${config.apiUrl}/profile`, headers)
    dispatch({
      type: GET_ADMIN_USER_PROFILE,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To store an admin user
 *
 * @param  object values
 */
export const storeAdminUser = values => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const adminUserValues = {
      name: values.name,
      email: values.email,
      verify_email: values.verify_email,
    }
    const response = await axios.post(
      `${config.apiUrl}`,
      adminUserValues,
      headers,
    )
    dispatch({
      type: ADMIN_USER_STORE,
      payload: response.data.result,
    })
    dispatch(reset('new-admin-user-form'))
    // return response.data.result.data.id;
  } catch (error) {
    await handleResponse(error, 1)
  }
}

/**
 * To update an admin user user profile
 *
 * @param  object values
 */
export const updateProfile = values => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const adminUserValues = {
      name: values.name,
      email: values.email,
    }
    const response = await axios.put(
      `${config.apiUrl}`,
      adminUserValues,
      headers,
    )
    dispatch({
      type: ADMIN_USER_PROFILE_UPDATE,
      payload: response.data.result.data,
    })
    // dispatch(reset('edit-admin-profile-form'));
    return response.data.result.data.id
  } catch (error) {
    await handleResponse(error, 1)
  }
}

/**
 * To remove an admin user
 *
 * @param  int adminUserId
 */
export const removeAdminUser = adminUserId => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.delete(
      `${config.apiUrl}/${adminUserId}`,
      headers,
    )

    dispatch({
      type: REMOVE_ADMIN_USER,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To update password of an admin user
 *
 * @param  object values
 */
export const updatePassword = values => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const adminUserValues = {
      current_password: values.current_password,
      password: values.password,
      confirm_password: values.confirm_password,
    }
    const response = await axios.put(
      `${config.apiUrl}/change-password`,
      adminUserValues,
      headers,
    )

    dispatch({
      type: ADMIN_CHANGE_PASSWORD,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    await handleResponse(error, 1)
  }
}
