/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css'
import { connect } from 'react-redux'
import { dispatchFormField } from 'store/actions/form'

import { getTranslate } from 'react-localize-redux'

class RenderMultipleSelectWithCreateField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.input.value || [],
      tags: this.props.data,
    }
  }

  handleCreate(input, name) {
    let { tags, value } = this.state
    let newOption = {
      newid: input.value.length + 1,
      name,
    }
    this.setState({
      value: [...value, newOption],
      tags: [...tags, newOption],
    })
    this.props.dispatchFormField(this.props.formName, this.props.fieldName, this.state.value)
  }
  handleChange(value) {
    if (value.length > 0) {
      this.setState({ value })
      this.props.dispatchFormField(this.props.formName, this.props.fieldName, this.state.value)
    } else {
      this.setState({ value: [] })
      this.props.dispatchFormField(this.props.formName, this.props.fieldName, [])
    }
  }
  handleBlur() {
    if (this.state.value.length > 0) {
      this.props.dispatchFormField(this.props.formName, this.props.fieldName, this.state.value)
    }
  }

  render() {
    let { value, tags } = this.state
    const {
      input,
      meta: { error, touched },
      data,
      valueField,
      textField,
      formName,
    } = this.props
    
    return (
      <Fragment>
        <Multiselect
          {...input}
          onBlur={this.handleBlur()}
          data={tags}
          value={value}
          allowCreate="onFilter"
          onCreate={name => this.handleCreate(input, name)}
          onChange={value => this.handleChange(value)}
          textField={textField}
          valueField={valueField}
        />
        {touched &&
          error &&
          error.map((e, index) => (
            <span key={index} className="invalid-feedback">
              <strong></strong>
            </span>
          ))}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenderMultipleSelectWithCreateField)
