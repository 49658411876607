/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import Loader from '../../../../../../components/Loader'
import RenderField from '../../../../../../components/RenderFieldTranlate'
import RenderSelectField from '../../../../../../components/RenderSelectFieldTranlate'
import validateForm from './helpers'
import { getOrganizationStates } from '../../../../../../store/actions/country'
import { dispatchFormField } from '../../../../../../store/actions/form'
/**
 * import translation related files
 *
 */

class EditForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      countryFlag: 0,
    }
  }

  handleShowStates = async e => {
    this.setState({ countryFlag: 1 })
    this.props.dispatchFormField('organization-profile-form', 'state', '')
    await this.props.getOrganizationStates(this.props, e.target.value)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      row,
      countries,
      countriesCode,
      states,
      handleCancel,
      handleRedirect,
      translate,
    } = this.props
    if (this.state.countryFlag === 0 && row && row.country_id) {
      //
      this.props.getOrganizationStates(this.props, row.country_id)
    }

    return (
      <div className="card-content collapse show">
        <div className="card-body">
          <form
            className="form edit_organization_form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="form-body">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="organization_name">
                    <span>*</span>
                    {translate('formOrgizationFieldOrganizationName')}
                  </label>
                  <Field
                    name="organization_name"
                    component={RenderField}
                    type="text"
                    placeholder={translate(
                      'formOrgizationFieldOrganizationName',
                    )}
                    value="Test1"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address_1">
                    <span>*</span>
                    {translate('formOrgizationFieldAddress1')}
                  </label>
                  <Field
                    name="address_1"
                    component={RenderField}
                    type="text"
                    placeholder={translate('formOrgizationFieldAddress1')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address_2">
                    {translate('formOrgizationFieldAddress2')}
                  </label>
                  <Field
                    className="form-control square"
                    name="address_2"
                    component={RenderField}
                    type="text"
                    placeholder={translate('formOrgizationFieldAddress2')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address_3">
                    {translate('formOrgizationFieldAddress3')}
                  </label>
                  <Field
                    className="form-control square"
                    name="address_3"
                    component={RenderField}
                    type="text"
                    placeholder={translate('formOrgizationFieldAddress3')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="country_id">
                    <span>*</span>
                    {translate('formOrgizationFieldCountry')}
                  </label>
                  <Field
                    name="country_id"
                    component={RenderSelectField}
                    onChange={this.handleShowStates.bind(this)}
                  >
                    <option disabled value="">
                      {translate('formOrgizationFieldCountry')}
                    </option>
                    {countries &&
                      countries.map(country => (
                        <option value={country.id}>{country.name}</option>
                      ))}
                  </Field>
                </div>
                <div className="form-group">
                  <label htmlFor="state">
                    <span>*</span>
                    {translate('formOrgizationFieldState')}
                  </label>
                  <Field name="state" component={RenderSelectField}>
                    <option value="">
                      {translate('formOrgizationFieldState')}
                    </option>
                    {states &&
                      states.map(state => (
                        <option value={state.id}>{state.name}</option>
                      ))}
                  </Field>
                </div>

                {/* }
                      <div className="form-group">
                        <label htmlFor="state"><span>*</span>{translate("formOrgizationFieldState")}</label>
                        <Field
                            name="state"
                            component={RenderField}
                            type="text"
                            placeholder={translate("formOrgizationFieldState")}
                          />
                      </div>
                    { */}
                <div className="form-group">
                  <label htmlFor="city">
                    <span>*</span>
                    {translate('formOrgizationFieldCity')}
                  </label>
                  <Field
                    name="city"
                    component={RenderField}
                    type="text"
                    placeholder={translate('formOrgizationFieldCity')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="zip_code">
                    <span>*</span>
                    {translate('formOrgizationFieldZip')}
                  </label>
                  <Field
                    name="zip_code"
                    component={RenderField}
                    type="text"
                    placeholder={translate('formOrgizationFieldZip')}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="name">
                    <span>*</span>
                    {translate('formOrgizationFieldName')}
                  </label>
                  <Field
                    name="name"
                    component={RenderField}
                    type="text"
                    placeholder={translate('formOrgizationFieldName')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    <span>*</span>
                    {translate('formOrgizationFieldEmail')}
                  </label>
                  <Field
                    name="email"
                    component={RenderField}
                    type="email"
                    disabled="disabled"
                    placeholder={translate('formOrgizationFieldEmail')}
                  />
                </div>
                <div className="form-group div_phone_organization">
                  <label htmlFor="phone">
                    <span>*</span>
                    {translate('formOrgizationFieldPhone')}
                  </label>
                  <Field
                    className="div_phone_organization"
                    name="phone_country_code"
                    component={RenderSelectField}
                  >
                    <option disabled value="">
                      {translate('formInstructorCountryCode')}
                    </option>
                    {countriesCode &&
                      countriesCode.map(country => (
                        <React.Fragment>
                          {country.dial_code !== null && (
                            <option value={country.dial_code}>
                              +{country.dial_code}
                            </option>
                          )}
                        </React.Fragment>
                      ))}
                  </Field>
                  <Field
                    name="phone"
                    component={RenderField}
                    type="tel"
                    placeholder={translate('formOrgizationFieldPhone')}
                  />
                  <div className="clearfix" />
                </div>
                <div className="form-group div_phone_organization">
                  <label htmlFor="faxinput3">
                    {translate('formOrgizationFieldFax')}
                  </label>
                  <Field
                    className="div_phone_organization"
                    name="fax_country_code"
                    component={RenderSelectField}
                  >
                    <option disabled value="">
                      {translate('formInstructorCountryCode')}
                    </option>
                    {countriesCode &&
                      countriesCode.map(country => (
                        <React.Fragment>
                          {country.dial_code !== null && (
                            <option value={country.dial_code}>
                              +{country.dial_code}
                            </option>
                          )}
                        </React.Fragment>
                      ))}
                  </Field>
                  <Field
                    name="fax"
                    component={RenderField}
                    type="tel"
                    placeholder={translate('formOrgizationFieldFax')}
                  />
                  <div className="clearfix" />
                </div>
                <div className="form-actions right org_btns">
                  <button
                    type="button"
                    className="btn btn-danger mr-1 org-pad"
                    onClick={
                      pristine || submitting ? handleRedirect : handleCancel
                    }
                  >
                    <i className="ft-x" />{' '}
                    {translate('formOrgizationBtnCancel')}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary org-pad"
                    disabled={pristine || submitting}
                  >
                    <i className="la la-check-square-o" />{' '}
                    {submitting ? (
                      <Loader />
                    ) : (
                      translate('formOrgizationBtnSave')
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

EditForm = reduxForm({
  form: 'organization-profile-form',
  validate: validateForm,
  enableReinitialize: true,
})(EditForm)

const mapStateToProps = state => ({
  states: state.organizationStateList.data.states,
  translate: getTranslate(state.localize),
  initialValues: state.organizationOrganizationDetail.data.row,
  row: state.organizationOrganizationDetail.data.row,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationStates: (props, country_id) =>
    dispatch(getOrganizationStates(props, country_id)),
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditForm)
