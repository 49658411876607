/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import base64 from 'file-base64'
import { confirmAlert } from 'react-confirm-alert' // Import
import { getCountries, getCountriesCode } from '../../../store/actions/country'
import { storeOrganization } from '../../../store/actions/organizations'
import OrganizationForm from './OrganizationForm'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import config from '../../../config'
import { getStates } from '../../../store/actions/country'

class NewOrganization extends React.Component {
  constructor() {
    super()
    this.state = {
      files: [],
    }
  }

  componentWillMount() {
    this.props.getCountries()
    this.props.getCountriesCode()
  }

  handleCancel = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'You want to cancel this form?',
      buttons: [
        {
          label: `${config.btnYesText}`,
          onClick: () => {
            this.props.history.push(`${config.adminUrl}/home`)
          },
        },
        {
          label: `${config.btnNoText}`,
        },
      ],
    })
  }

  handleRedirect = () => {
    this.props.history.push(`${config.adminUrl}/home`)
  }

  handleSubmit = async values => {
    const file = this.state.files
    // console.log(file);
    const organizationId = await this.props.storeOrganization(values, file)
    this.props.history.push(`${config.adminUrl}/organization/${organizationId}`)
  }

  handleShowStates = async e => {
    await this.props.getStates(e.target.value)
  }

  handleInputChange = e => {
    // console.log('step 3');
    // get the files
    const { files } = e.target
    // console.log(files);
    // Process each file
    const allFiles = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // Make new FileReader
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // console.log(reader.result);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        }
        this.setState({ files: fileInfo })
        // console.log(fileInfo);
      } // reader.onload
    } // for
  }

  render() {
    return (
      <OrganizationForm
        countries={this.props.countries}
        countriesCode={this.props.countriesCode}
        states={this.props.states}
        onSubmit={this.handleSubmit}
        handleInputChange={this.handleInputChange}
        handleCancel={this.handleCancel}
        handleRedirect={this.handleRedirect}
        handleShowStates={this.handleShowStates}
      />
    )
  }
}

const mapStateToProps = state => ({
  countries: state.countries.data.countries,
  countriesCode: state.adminCountryCode.data.countries,
  states: state.adminStateList.data.states,
  response: state.storeOrganization,
})

const mapDispatchToProps = dispatch => ({
  getCountries: () => {
    dispatch(getCountries())
  },
  getCountriesCode: () => {
    dispatch(getCountriesCode())
  },
  storeOrganization: (values, file) =>
    dispatch(storeOrganization(values, file)),
  getStates: country_id => dispatch(getStates(country_id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewOrganization)
