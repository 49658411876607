/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'

// BEGIN: Image
import { withLocalize } from 'react-localize-redux'
import innerBannerImg from '../../../assets/root/images/inner-banner.jpg'
// END: Image

import englishRootContactUsTranslations from '../../../lang/root/contactus/en.json'
import spanishRootContactUsTranslations from '../../../lang/root/contactus/es.json'
import chineseRootContactUsTranslations from '../../../lang/root/contactus/zh.json'

class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishRootContactUsTranslations, 'en')
    this.props.addTranslationForLanguage(spanishRootContactUsTranslations, 'es')
    this.props.addTranslationForLanguage(chineseRootContactUsTranslations, 'zh')
  }

  render() {
    const { translate } = this.props
    return (
      <React.Fragment>
        <div id="banner_section">
          <div className="yellowbox">
            <center>
              <h2 className="taglines">{translate('title')}</h2>
            </center>
          </div>
          <img
            src={innerBannerImg}
            className="img-responsive"
            alt={translate('title')}
          />
        </div>
        <div id="about" className="aboutus inner-abtus">
          <div className="container">
            <h3 className="wow animate fadeInDown delay-06s">
              {translate('title')}
            </h3>
            {translate('details')}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withLocalize(ContactUs)
