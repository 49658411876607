/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { getTranslate } from 'react-localize-redux'
import Loader from '../../../../../../components/Loader'
import RenderField from '../../../../../../components/RenderFieldTranlate'
import validateForm from './helpers'

/**
 * import translation related files
 *
 */

class NewUserForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { pristine, handleSubmit, submitting, translate } = this.props

    return (
      <form className="form" onSubmit={handleSubmit} autoComplete="off">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="name">
              <span>*</span>
              {translate('formOrgizationUserFieldName')}
            </label>
            <Field
              name="name"
              component={RenderField}
              type="text"
              placeholder={translate('formOrgizationUserFieldName')}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">
              <span>*</span>
              {translate('formOrgizationUserFieldEmail')}
            </label>
            <Field
              name="email"
              component={RenderField}
              type="text"
              placeholder={translate('formOrgizationUserFieldEmail')}
            />
          </div>
          <div className="form-group">
            <label htmlFor="verify_email">
              <span>*</span>
              {translate('formOrgizationUserFieldVerifyEmail')}
            </label>
            <Field
              name="verify_email"
              component={RenderField}
              type="text"
              placeholder={translate('formOrgizationUserFieldVerifyEmail')}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={pristine || submitting}
          >
            {submitting ? <Loader /> : translate('formOrgizationUserBtnName')}
          </button>
        </div>
      </form>
    )
  }
}

NewUserForm = reduxForm({
  form: 'organization-sub-admin-form',
  validate: validateForm,
})(NewUserForm)

NewUserForm = connect(state => ({
  translate: getTranslate(state.localize),
}))(NewUserForm)

export default NewUserForm
