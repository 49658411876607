import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import { getTranslate } from 'react-localize-redux'
import PronunciationBreakdown from 'components/Data/PronunciationBreakdown'
import Chart from 'components/Data/PhonemeScoreChart'

const CombinedPronunciationCharts = props => {
  const { translate, data: propData, loading: propLoading } = props
  let loadingTable = propLoading
  const width = '100%'

  let data = [['', '', { role: 'style', axis: 'vertical' }]]
  if (props && propData) {
    const formattedData = propData.map(row => {
      return [
        `${row.phoneme_IPA}\n${row.phoneme_REG}`,
        row.score,
        'linear-gradient(to right, #9f78ff, #32cafe)',
      ]
    })
    data = data.concat(formattedData)
    loadingTable = propLoading
  }
  return (
    <Fragment>
      {props && propData.length > 0 && (
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('phonemeScores')}
            </h5>
            <div className="table-responsive">
              <LoadingOverlay
                active={loadingTable}
                spinner
                text={translate('loadingOverlayText')}
              >
                {props && propData.length > 0 && (
                  <Chart width={width} data={data} />
                )}
              </LoadingOverlay>
            </div>
          </div>
        </div>
      )}
      <PronunciationBreakdown data={propData} loadingTable={loadingTable} />
    </Fragment>
  )
}

CombinedPronunciationCharts.defaultProps = {
  loading: false,
}

CombinedPronunciationCharts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  translate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default connect(mapStateToProps)(CombinedPronunciationCharts)
