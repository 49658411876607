/* TODO: Fix eslint */
/* eslint-disable */

import { change, reset, reduxForm } from 'redux-form'

import config from '../../config'

export const IS_HAMBURGER_STATUS = 'IS_HAMBURGER_STATUS'
export const REMOVE_TOASTER = 'REMOVE_TOASTER'

/**
 * To get classes of a organization
 *
 * @param  string formName
 * @param  string fieldName
 * @param  string values
 * @return data
 */
export const dispatchFormField = (formName, fieldName, values) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(change(formName, fieldName, values))
  } catch (error) {}
}

/**
 * To remove toster/notify/message popup
 *
 * @param  string formName
 * @param  string fieldName
 * @param  string values
 * @return data
 */
export const removeToster = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: REMOVE_TOASTER,
    })
  } catch (error) {}
}

/**
 * To store a school for organization
 *
 * @param  string formName
 */
export const resetForm = formName => async (dispatch, getState) => {
  try {
    dispatch(reset(formName))
  } catch (error) {}
}

export const hamburger = (status = false) => async (dispatch, getState) => {
  try {
    console.log(status)
    dispatch({
      type: IS_HAMBURGER_STATUS,
      payload: status,
    })
  } catch (error) {}
}
