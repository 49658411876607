/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { dispatchFormField } from './../../store/actions/form'
import moment from 'moment'

/**
 * import translation related files
 *
 */
import { getTranslate } from 'react-localize-redux'

class RenderField extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    if (this.props.edit === 'true') {
      // const end_date = moment(this.props.dbDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
      // const dateTimelocal  = moment.utc(end_date).toDate();
      // const dateTimelocal1  = moment(dateTimelocal).format('MM/DD/YYYY');
      const currentHH = moment()
        .utc()
        .format('HH')
      const currentMM = moment()
        .utc()
        .format('mm')
      const currentSS = moment()
        .utc()
        .format('ss')
      const time = ' ' + currentHH + ':' + currentMM + ':' + currentSS
      const dbDate = moment(this.props.dbDate + time, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss',
      )
      const utcFormat = moment(dbDate)
        .utc()
        .format('YYYY-MM-DD HH:MM:SS')
      const dateTimelocal1 = moment
        .utc(utcFormat, 'YYYY-MM-DD HH:MM:SS')
        .local()
        .format('MM/DD/YYYY')
      this.props.dispatchFormField(this.props.formName, this.props.fieldName, dateTimelocal1)
    }
  }

  render() {
    const {
      input,
      meta: { error, touched },
      placeholder,
      id,
      selected,
      selectsEnd,
      startDate,
      endDate,
      onChange,
      minDate,
      translate,
    } = this.props

    return (
      <Fragment>
        <DatePicker
          autoComplete="off"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          selected={selected}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          minDate={minDate}
          placeholder={placeholder}
          id={id}
          {...input}
          className={
            touched && error
              ? 'form-control square daterange-end is-invalid'
              : 'form-control square daterange-end'
          }
        />
        {touched &&
          error &&
          error.map((e, index) => (
            <span key={index} className="invalid-feedback end_feedback">
              <strong>{translate(e)}</strong>
            </span>
          ))}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenderField)
