/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../config'
import Principal from '../Common/Principal'
import ReportsTable from '../Tables/Reports'
import CreateReportByTags from './CreateReportByTags'

/**
 * import translation related files
 *
 */

class Reports extends React.Component {
  constructor(props) {
    super(props)
    this.state = { checkAll: '' }
  }

  componentDidMount() {
    if (this.props.user && this.props.user.is_admin_report_creation === 0) {
      this.props.history.push(`${config.principalUrl}/home`)
    }
  }

  handleCheck = () => {
    const currentStatus = this.state.checkAll
    const setStatus = currentStatus === '' ? 'checked' : ''
    this.setState({ checkAll: setStatus })
  }

  render() {
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row" />
        <div className="content-body">
          <Principal />
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <ReportsTable />
            </div>
            {/* }
              <div className="col-xl-4 col-lg-6 col-md-12 pull-right">
               <CreateReportByTags
                  handleCheck={this.handleCheck}
                  checked = {this.state.checkAll}
               />
              </div>
            { */}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  user: state.authPrincipal.user,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
