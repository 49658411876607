import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import LoadingOverlay from 'react-loading-overlay'
import {
  getOrganizationDetails,
  downloadOrganizationDOC,
} from 'store/actions/organizations'
import config from 'config'
import DisplayMobileFormat from 'components/DisplayMobileFormat'
import DateTimeWithTimezone from 'components/DateTimeWithTimezone'

class OrganizationCommonDetail extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    const { match, history, getOrganizationDetails } = this.props
    const organizationId = match.params.id
    if (organizationId === ' ' || organizationId === 'undefined') {
      history.push(`${config.adminUrl}/home`)
    }
    this.setState({ loading: true })
    getOrganizationDetails(organizationId)
  }

  handleDownload = async () => {
    const { organizationRow, downloadOrganizationDOC } = this.props
    const filePaths = JSON.parse(organizationRow.documents_path)
    const fileName = filePaths[filePaths.length - 1]
    await downloadOrganizationDOC(fileName)
  }

  render() {
    const { organizationRow, loading: loadingTable } = this.props
    return (
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <div className="card">
            <LoadingOverlay active={loadingTable} spinner text={`${config.loadingOverlayText}`}>
              <div className="card-header">
                <h4 className="org-id">
                  ID:
                  {organizationRow && organizationRow.id}
                </h4>
                <h4 className="org-id status_heading">
                  Status:{' '}
                  <span
                    className={
                      organizationRow && organizationRow.status === 'Active'
                        ? 'status_approved'
                        : 'status_pending'
                    }
                  >
                    {organizationRow && organizationRow.status}
                  </span>
                </h4>
                <h4 className="card-title">
                  Organization Name: {organizationRow && organizationRow.organization_name}
                </h4>
                <div className="heading-elements">
                  <ul className="list-inline mb-0">
                    <li>
                      <Link
                        to={`${config.adminUrl}/edit-organization/${organizationRow &&
                          organizationRow.id}`}
                        className="add_btn pull-right"
                      >
                        Edit
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-content collapse show">
                <div className="card-body pb-0 card_margin">
                  <h6 className="contact_heading">Address</h6>
                  <p className="address_Org">
                    {organizationRow && organizationRow.address_1}
                    {organizationRow &&
                    organizationRow.address_2 !== null &&
                    organizationRow.address_2 !== ''
                      ? ', '
                      : ''}
                    {organizationRow && organizationRow.address_2}
                    {organizationRow &&
                    organizationRow.address_3 !== null &&
                    organizationRow.address_3 !== ''
                      ? ', '
                      : ''}
                    {organizationRow && organizationRow.address_3}
                    <br /> {organizationRow && organizationRow.city},{' '}
                    {organizationRow && organizationRow.state_name}
                    <br />
                    {organizationRow && organizationRow.zip_code},{' '}
                    {organizationRow && organizationRow.country}
                  </p>
                  <h6 className="contact_heading">Contact</h6>
                  <ul className="contact_informations">
                    <li>{organizationRow && organizationRow.name}</li>
                    <li>
                      <h6 className="contact_heading status_heading">Ph</h6>
                      <DisplayMobileFormat
                        code={organizationRow && organizationRow.phone_country_code}
                        number={organizationRow && organizationRow.phone}
                      />
                    </li>
                    {organizationRow && organizationRow.fax && organizationRow.fax != null && (
                      <li>
                        <h6 className="contact_heading status_heading">Fx</h6>
                        <DisplayMobileFormat
                          code={organizationRow && organizationRow.fax_country_code}
                          number={organizationRow && organizationRow.fax}
                        />
                      </li>
                    )}
                    <li>{organizationRow && organizationRow.email}</li>
                  </ul>
                  <div className="org_detail_pdf">
                    <h6 className="contact_heading font-small-3">Contract</h6>
                    <Link to="#" onClick={this.handleDownload}>
                      <i className="fa fa-file-pdf pdf_files" />
                    </Link>
                    <Link to="#" onClick={this.handleDownload}>
                      <h6 className="text-muted font-small-3 view_c"> View Contract</h6>
                    </Link>
                  </div>
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </div>
        <div className="col-lg-4 col-md-12">
          <div className="row">
            <LoadingOverlay active={loadingTable} spinner text={`${config.loadingOverlayText}`}>
              <div className="col-12">
                <div className="card pull-up">
                  <div className="card-header">
                    <h6 className="card-title float-left">
                      Start Date
                      <span className="start_date_color">
                        <DateTimeWithTimezone
                          dateTime={organizationRow && organizationRow.contract_start_date}
                          isTime="false"
                          isRed="false"
                        />
                      </span>
                    </h6>
                    <h6 className="card-title float-right">
                      End Date
                      <span className="end_date_color">
                        <DateTimeWithTimezone
                          dateTime={organizationRow && organizationRow.contract_end_date}
                          isTime="false"
                          isRed="true"
                          start_date={organizationRow && organizationRow.contract_start_date}
                          end_date={organizationRow && organizationRow.contract_end_date}
                        />
                      </span>
                    </h6>
                  </div>
                  <div className="card-content collapse show">
                    <div className="card-body pt-0 pb-1">
                      <h6 className="text-muted font-small-3">
                        {' '}
                        <span className="l_acti">Licenses / Assigned :</span>{' '}
                        <NumberFormat
                          thousandSeparator
                          value={
                            organizationRow && organizationRow.totalActiveLicense
                              ? organizationRow.totalActiveLicense
                              : 0
                          }
                          displayType="text"
                        />{' '}
                        /{' '}
                        <NumberFormat
                          thousandSeparator
                          value={
                            organizationRow && organizationRow.totalLicense
                              ? organizationRow.totalLicense
                              : 0
                          }
                          displayType="text"
                        />
                      </h6>
                      <div className="progress progress-sm mt-1 mb-0 box-shadow-2">
                        <div
                          className="progress-bar bg-gradient-x-info"
                          role="progressbar"
                          style={{
                            width: `${organizationRow &&
                              parseInt(organizationRow.activeLicenseRation)}%`,
                            backgroundImage: `${
                              organizationRow && organizationRow.activeLicenseRation >= 90
                                ? 'linear-gradient(to right, #ec6b6b 0%, #ff4747 100%)'
                                : ''
                            }`,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    )
  }
}

OrganizationCommonDetail.defaultProps = {
  loading: false,
  organizationRow: {},
}

OrganizationCommonDetail.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getOrganizationDetails: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  organizationRow: PropTypes.shape({})
}

const mapStateToProps = state => ({
  response: state.downloadOrganizationDOC,
  organizationRow: state.organizationDetail.data.row,
  loading: state.organizationDetail.loading,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationDetails: organizationId => {
    dispatch(getOrganizationDetails(organizationId))
  },
  downloadOrganizationDOC: file => dispatch(downloadOrganizationDOC(file)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationCommonDetail))
