/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import CheckBox from '../CheckBox'
import { dispatchFormField } from '../../store/actions/form'

/**
 * import translation related files
 *
 */

class RenderMultipleSelectField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.tags,
      checkedAll: false,
      storeName: this.props.formName,
    }
    localStorage.removeItem(this.state.storeName)
  }

  handleAllChecked = event => {
    const { data } = this.state
    data.forEach(row => (row.isChecked = event.target.checked))
    this.setState({ data })
    if (event.target.checked) {
      this.setState({ checkedAll: true })
    } else {
      this.setState({ checkedAll: false })
    }
    const data1 = this.state.data
    const selected = []
    data1.forEach(row => {
      if (row.isChecked == false) {
      } else {
        selected.push(row.name)
        // this.props.dispatchFormField(this.props.formName, this.props.fieldName, row.id);
      }
    })
    // console.log(selected);
    localStorage.setItem(this.state.storeName, selected)
    const authUser = localStorage.getItem(this.state.storeName)
    // console.log(authUser);
  }

  handleCheckChieldElement = event => {
    const { data } = this.state
    let checkedAllStatus = 1
    data.forEach(row => {
      if (row.id == event.target.value) {
        row.isChecked = event.target.checked
      }
    })
    this.setState({ data })
    const data1 = this.state.data
    const selected = []
    data1.forEach(row => {
      if (row.isChecked == false) {
        checkedAllStatus = 0
      } else {
        selected.push(row.name)
        // this.props.dispatchFormField(this.props.formName, this.props.fieldName, row.id);
      }
    })
    // console.log(selected);
    this.setState({ checkedAll: checkedAllStatus })
    localStorage.setItem(this.state.storeName, selected)
    const authUser = localStorage.getItem(this.state.storeName)
    // console.log(authUser);
  }

  render() {
    const { input, meta, options, translate } = this.props
    return (
      <React.Fragment>
        <div className="select_all">
          <label className="selectAll">
            {translate('reportTableSelectAll')}
            <input
              type="checkbox"
              onClick={this.handleAllChecked}
              checked={this.state.checkedAll}
              value="checkedall"
            />
            <span className="checkmark" />
          </label>
        </div>
        <div className="tags_container">
          {this.state.data.map(row => {
            return (
              <CheckBox
                handleCheckChieldElement={this.handleCheckChieldElement}
                {...row}
              />
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenderMultipleSelectField)

// export default function adapter(key, props) {
//   console.log('checkedbox')

//   console.log(props.options);

//       return (
//         <div>
//           {props.options.map((option, index) => (
//             <Field
//               key={index}
//               name={`${props.name}[${option.id}]`}
//               label={option.name}
//               checked={!!props.value[option.id]}
//               component={CHECKBOX}
//             />
//           ))}
//         </div>
//       );
// }
