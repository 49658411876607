/* TODO: Fix eslint */
/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'

import config from 'config'
import { resetForm } from 'store/actions/form'
import {
  instructorStudentChangeStatusByStudentId,
  resetActivation,
} from 'store/actions/student'
import ChnagePasswordModelBox from './ChnagePasswordModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css'

class Detail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowChangePassword: false,
    }
  }

  handleChangePasswordModalShow = () => {
    const { resetForm } = this.props

    resetForm('teacher-student-change-password')
    this.setState({
      isShowChangePassword: true,
    })
  }

  handleChangePasswordModelClose = () => {
    const { resetForm } = this.props

    resetForm('teacher-student-change-password')
    this.setState({
      isShowChangePassword: false,
    })
  }

  handleSubmitChangePassword = async values => {
    const {
      instructorStudentChangeStatusByStudentId,
      row,
      resetForm,
    } = this.props

    await instructorStudentChangeStatusByStudentId(values, row.id)
    resetForm('teacher-student-change-password')
    this.setState({ isShowChangePassword: false })
  }

  handleCancel = () => {
    const { translate, resetForm } = this.props

    confirmAlert({
      title: translate('alertHeading'),
      message: translate('alertFormText'),
      buttons: [
        {
          label: translate('btnYes'),
          onClick: () => {
            resetForm('teacher-student-change-password')
            this.setState({ isShowChangePassword: false })
          },
        },
        {
          label: translate('btnNo'),
        },
      ],
    })
  }

  handleResetActivation = () => {
    const { translate, resetActivation, row } = this.props

    confirmAlert({
      title: translate('alertHeading'),
      message: translate('alertResetActivation'),
      buttons: [
        {
          label: translate('btnYes'),
          onClick: () => {
            resetActivation(row.id, this.props)
          },
        },
        {
          label: translate('btnNo'),
        },
      ],
    })
  }

  render() {
    const { isShowChangePassword } = this.state
    const { translate, row } = this.props

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="row">
              <div className="col-md-12 col-lg-12 pull-left">
                <div className="card class_card">
                  <div className="card-header">
                    <h4 className="org-id">
                      {translate('studentDetailRowLicense')}:{row && row.code}
                    </h4>
                    <h4 className="card-title">{row && row.alias}</h4>
                    <a className="heading-elements-toggle">
                      <i className="la la-ellipsis-v font-medium-3" />
                    </a>
                    <div className="heading-elements">
                      <ul className="list-inline mb-0">
                        <li>
                          <Link
                            className="add_btn pull-right mr-1"
                            to={`${config.teacherUrl}/edit-student/${row &&
                              row.id}`}
                          >
                            {translate('btnEdit')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="add_btn pull-right mr-1"
                            to="#"
                            onClick={this.handleChangePasswordModalShow}
                          >
                            {translate('btnChangePassword')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="add_btn pull-right"
                            to="#"
                            onClick={this.handleResetActivation}
                          >
                            {translate('resetActivations')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
        <ChnagePasswordModelBox
          isShowChangePassword={isShowChangePassword}
          handleChangePasswordModelClose={this.handleChangePasswordModelClose}
          onSubmit={this.handleSubmitChangePassword}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  resetForm: formName => dispatch(resetForm(formName)),
  instructorStudentChangeStatusByStudentId: (values, studentId) =>
    dispatch(instructorStudentChangeStatusByStudentId(values, studentId)),
  resetActivation: (studentId, props) =>
    dispatch(resetActivation(studentId, props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
