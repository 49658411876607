/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import Moment from 'react-moment'
import { Link, withRouter } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { confirmAlert } from 'react-confirm-alert' // Import
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { dispatchFormField, resetForm } from '../../../../../store/actions/form'
import {
  getOrganizationPrincipal,
  storePrincipal,
  storePrincipalMultiple,
  sampleFilePrincipal,
} from '../../../../../store/actions/principal'

import config from '../../../../../config'
import CreateNewFormModelBox from '../../Principal/CreateNewFormModelBox'
import CreateNewMultipleFormModelBox from '../../Principal/CreateNewMultipleFormModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
/**
 * import translation related files
 *
 */
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class Principals extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowNewPrincipalForm: false,
      isShowNewMultiplePrincipalForm: false,
      files: [],
      page: 0,
      counter: 0,
      orderBy: 'created_at',
      order: 'DESC',
      loading: false,
    }
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentWillMount() {
    this.setState({ loading: true })
    this.props.getOrganizationPrincipal(
      this.props,
      this.state.page,
      this.state.orderBy,
      this.state.order,
    )
    this.setState({ page: this.state.page + 1 })
  }

  handlePageChange = async e => {
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: this.state.page + 1 })
        if (this.props.data.totalPage > this.state.page) {
          this.setState({ counter: this.state.counter + 1 })
          this.setState({ loading: true })
          this.setState({ scrollTop: element.scrollTop })
          await this.props.getOrganizationPrincipal(
            this.props,
            this.state.page,
            this.state.orderBy,
            this.state.order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    let order = 'ASC'
    if (this.state.orderBy === field) {
      order = this.state.order === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    this.setState({ loading: true })
    await this.props.getOrganizationPrincipal(this.props, 0, field, order)
    this.setState({ page: 1 })
  }

  handleNewPrincipalModelShow = () => {
    this.setState({
      isShowNewPrincipalForm: true,
      isShowNewMultiplePrincipalForm: false,
      files: [],
    })
  }

  handleNewPrincipalModelClose = () => {
    this.props.resetForm('organization-create-principal')
    this.setState({
      isShowNewPrincipalForm: false,
      isShowNewMultiplePrincipalForm: false,
    })
  }

  handleSubmit = async values => {
    const principalId = await this.props.storePrincipal(values, this.props)
    this.setState({ isShowNewPrincipalForm: false })
    return this.props.history.push(
      `${config.organizationUrl}/principal/${principalId}`,
    )
  }

  handleCancel = async organizationUserId => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            this.props.resetForm('organization-create-principal')
            this.props.resetForm('organization-create-principal-multiple')
            this.setState({ isShowNewPrincipalForm: false })
            this.setState({ isShowNewMultiplePrincipalForm: false })
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  handleNewMultiplePrincipalModelShow = () => {
    this.setState({
      isShowNewPrincipalForm: false,
      isShowNewMultiplePrincipalForm: true,
      files: [],
    })
  }

  handleNewMultiplePrincipalModelClose = () => {
    this.props.resetForm('organization-create-principal-multiple')
    this.setState({
      isShowNewPrincipalForm: false,
      isShowNewMultiplePrincipalForm: false,
    })
  }

  handleMultipleSubmit = async () => {
    const file = this.state.files
    await this.props.storePrincipalMultiple(file, this.props)
    await this.props.getOrganizationPrincipal(
      this.props,
      0,
      this.state.orderBy,
      this.state.order,
    )
    this.setState({ isShowNewMultiplePrincipalForm: false })
    return this.props.history.push(`${config.organizationUrl}/principal`)
  }

  downloadSampelXLSX = async e => {
    e.preventDefault()
    await this.props.sampleFilePrincipal(`Principal_Sample.xlsx`, this.props)
  }

  downloadSampelCSV = async e => {
    e.preventDefault()
    await this.props.sampleFilePrincipal(`Principal_Sample.csv`, this.props)
  }

  handleInputChangeMultiple = e => {
    e.preventDefault()
    const { files } = e.target
    const allFiles = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // Make new FileReader
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        }
        this.setState({ files: fileInfo })
        this.props.dispatchFormField(
          'organization-create-principal-multiple',
          'file',
          fileInfo,
        )
      } // reader.onload
    } // for
  }

  render() {
    const { translate, view } = this.props
    let loadingTable = this.state.loading
    loadingTable = this.props.loading
    const sortClass = this.state.order === 'ASC' ? 'ascending' : 'desending'
    return (
      <React.Fragment>
        <div className="row match-height">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('principalTableTitle')}
              <Link
                className="add_btn pull-right"
                to="#"
                onClick={this.handleNewPrincipalModelShow}
              >
                {translate('btnAddPrincipal')}
              </Link>
            </h5>
            <div className="card" style={{}}>
              <div
                className={
                  this.props.data && this.props.data.totalPage > 0
                    ? 'card-content '
                    : 'card-content'
                }
              >
                <div
                  id="recent-projects"
                  className="media-list position-relative"
                  onScroll={this.handlePageChange.bind(this)}
                >
                  <LoadingOverlay
                    active={loadingTable}
                    spinner
                    text={translate('loadingOverlayText')}
                  >
                    <div
                      className={
                        this.props.principal.length === 0
                          ? 'table-responsive table-height'
                          : 'table-responsive'
                      }
                    >
                      {this.props.principal && this.props.principal.length > 0 && (
                        <div id="tableContainer" className="tableContainer">
                          <table
                            className="scrollTable principal-list"
                            width="100%"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                          >
                            <thead className="fixedHeader">
                              <tr>
                                <th
                                  align="left"
                                  width="25%"
                                  onClick={() =>
                                    this.handleSorting('principal_name')
                                  }
                                  className={
                                    this.state.orderBy === 'principal_name'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('principalTableRowName')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="left"
                                  width="25%"
                                  onClick={() => this.handleSorting('email')}
                                  className={
                                    this.state.orderBy === 'email'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('principalTableRowEmail')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="left"
                                  width="25%"
                                  onClick={() =>
                                    this.handleSorting('school_name')
                                  }
                                  className={
                                    this.state.orderBy === 'school_name'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('principalTableRowSchool')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() => this.handleSorting('tags')}
                                  className={
                                    this.state.orderBy === 'tags'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('principalTableRowTags')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                {view && view === '1' && (
                                  <th align="center" width="5%">
                                    {translate('principalTableRowView')}
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody className="scrollContent">
                              {this.props.principal &&
                                this.props.principal.length > 0 &&
                                this.props.principal.map(row => (
                                  <tr>
                                    <td align="left" width="25%">
                                      <Link
                                        to={`${config.organizationUrl}/principal/${row.id}`}
                                      >
                                        {row.principal_name}
                                      </Link>
                                    </td>
                                    <td align="left" width="25%">
                                      {row.email}
                                    </td>
                                    <td align="left" width="25%">
                                      <Link
                                        to={`${config.organizationUrl}/school/${row.school_id}`}
                                      >
                                        {row.school_name}
                                      </Link>
                                    </td>
                                    <td align="center" width="20%">
                                      {row.tags}
                                    </td>
                                    {view && view === '1' && (
                                      <td align="center" width="5%">
                                        <Link
                                          to={`${config.organizationUrl}/principal/${row.id}`}
                                        >
                                          <i className="fa fa-eye" />
                                        </Link>
                                      </td>
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {this.props.principal &&
                        this.props.principal.length === 0 &&
                        loadingTable === false && (
                          <div className="table-no-record">
                            {translate('tableNoRecord')}
                          </div>
                        )}
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateNewFormModelBox
          isShowChangePassword={this.state.isShowNewPrincipalForm}
          handleNewPrincipalModelClose={this.handleNewPrincipalModelClose}
          onSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
          handleNewMultiplePrincipalModelShow={
            this.handleNewMultiplePrincipalModelShow
          }
        />
        <CreateNewMultipleFormModelBox
          isShowNewMultiplePrincipalForm={
            this.state.isShowNewMultiplePrincipalForm
          }
          handleNewMultiplePrincipalModelClose={
            this.handleNewMultiplePrincipalModelClose
          }
          onSubmit={this.handleMultipleSubmit}
          handleCancel={this.handleCancel}
          handleInputChange={this.handleInputChangeMultiple}
          downloadSampelXLSX={this.downloadSampelXLSX}
          downloadSampelCSV={this.downloadSampelCSV}
          handleNewPrincipalModelShow={this.handleNewPrincipalModelShow}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.organizationOrganizationPrincipal.data,
  principal: state.organizationOrganizationPrincipal.principal,
  loading: state.organizationOrganizationPrincipal.loading,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationPrincipal: (props, page, orderBy, order) =>
    dispatch(getOrganizationPrincipal(props, page, orderBy, order)),
  storePrincipal: (values, props) => dispatch(storePrincipal(values, props)),
  storePrincipalMultiple: (file, props) =>
    dispatch(storePrincipalMultiple(file, props)),
  resetForm: formName => dispatch(resetForm(formName)),
  sampleFilePrincipal: (name, props) =>
    dispatch(sampleFilePrincipal(name, props)),
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default withRouter(
  withLocalize(connect(mapStateToProps, mapDispatchToProps)(Principals)),
)
