/* TODO: Fix eslint */
/* eslint-disable */

import moment from 'moment'

const validateForm = values => {
  const errors = {}

  if (!values.name) {
    errors.name = [`errorMsgReportServiceCreateNameMissing`]
  } else if (values.name.length < 2) {
    errors.name = [`errorMsgReportServiceCreateNameIsLength`]
  } else if (values.name.length > 50) {
    errors.name = [`errorMsgReportServiceCreateNameIsLength`]
  }

  // description
  if (values.description) {
    if (values.description.length > 500) {
      errors.description = [`errorMsgReportServiceCreateDescriptionMissing`]
    }
  }

  if (!values.school_ids) {
    errors.school_ids = [`errorMsgReportServiceCreateSchoolMissing`]
  }

  // date_ranger
  if (values.start_date && values.end_date) {
    const start_date = moment(values.start_date, 'MM/DD/YYYY').format(
      'YYYY-MM-DD',
    )
    const end_date = moment(values.end_date, 'MM/DD/YYYY').format('YYYY-MM-DD')
    if (moment(start_date).isAfter(end_date) === true) {
      errors.start_date = [`errorMsgReportServiceCreateStartDateCompare`]
    }
  }
  return errors
}

export default validateForm
