/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { withLocalize, getTranslate } from 'react-localize-redux'
import {
  getOrganizationCountries,
  getOrganizationCountriesCode,
} from '../../../../../store/actions/country'
import { getOrganizationUserProfile } from '../../../../../store/actions/organizationUser'
import {
  OrganizationupdateOrganization,
  getOrganizationOrganizationDetails,
} from '../../../../../store/actions/organizations'
import EditForm from './EditForm'
import config from '../../../../../config'

/**
 * import translation related files
 *
 */
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class EditOrganization extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentWillMount() {
    this.props.getOrganizationCountries(this.props)
    this.props.getOrganizationCountriesCode(this.props)
  }

  handleCancel = () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: () => {
            this.props.history.push(`${config.organizationUrl}/home`)
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  handleRedirect = () => {
    this.props.history.push(`${config.organizationUrl}/home`)
  }

  handleSubmit = async values => {
    // console.log('handleSubmit');
    await this.props.OrganizationupdateOrganization(values, this.props)
    this.props.getOrganizationOrganizationDetails(this.props)
    this.props.getOrganizationUserProfile(this.props)
    // this.props.history.push(`${config.organizationUrl}/home`);
  }

  render() {
    return (
      <div className="col-md-12 col-lg-8">
        <div className="row match-height">
          <div className="col-md-12">
            <div className="card">
              <EditForm
                countries={this.props.countries}
                countriesCode={this.props.countriesCode}
                onSubmit={this.handleSubmit}
                handleInputChange={this.handleInputChange}
                row={this.props.organizationDetailRow}
                handleCancel={this.handleCancel}
                handleRedirect={this.handleRedirect}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  countries: state.organizationCountry.data.countries,
  countriesCode: state.organizationCountryCode.data.countries,
  organizationDetailRow: state.organizationDetail.data.row,
  response: state.OrganizationupdateOrganization,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationOrganizationDetails: props => {
    dispatch(getOrganizationOrganizationDetails(props))
  },
  getOrganizationUserProfile: props =>
    dispatch(getOrganizationUserProfile(props)),
  getOrganizationCountries: props => {
    dispatch(getOrganizationCountries(props))
  },
  getOrganizationCountriesCode: props => {
    dispatch(getOrganizationCountriesCode(props))
  },
  OrganizationupdateOrganization: (values, props) =>
    dispatch(OrganizationupdateOrganization(values, props)),
})

export default withLocalize(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditOrganization)),
)
