/* TODO: Fix eslint */
/* eslint-disable */

import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'

const validateForm = (values, props) => {
  const errors = {}
  const numbers = /^[0-9]+$/
  // var phoneRegex = /^\(?\d{3}?\)?\-?\d{3}?\-?\d{4}?$/;
  const phoneRegex = /^\d{3}-\d{3}-\d{4}?$/

  // const dial =  ['ar-AE', 'ar-BH', 'ar-DZ', 'ar-EG', 'ar-IQ', 'ar-JO', 'ar-KW', 'ar-SA', 'ar-SY', 'ar-TN', 'be-BY', 'bg-BG', 'bn-BD', 'cs-CZ', 'de-DE', 'da-DK', 'el-GR', 'en-AU', 'en-CA', 'en-GB', 'en-GH', 'en-HK', 'en-IE', 'en-IN', 'en-KE', 'en-MT', 'en-MU', 'en-NG', 'en-NZ', 'en-RW', 'en-SG', 'en-UG', 'en-US', 'en-TZ', 'en-ZA', 'en-ZM', 'en-PK', 'es-ES', 'es-MX', 'es-PY', 'es-UY', 'et-EE', 'fa-IR', 'fi-FI', 'fj-FJ', 'fr-FR', 'he-IL', 'hu-HU', 'id-ID', 'it-IT', 'ja-JP', 'kk-KZ', 'ko-KR', 'lt-LT', 'ms-MY', 'nb-NO', 'nl-BE', 'nl-NL', 'nn-NO', 'pl-PL', 'pt-PT', 'pt-BR', 'ro-RO', 'ru-RU', 'sl-SI', 'sk-SK', 'sr-RS', 'sv-SE', 'th-TH', 'tr-TR', 'uk-UA', 'vi-VN', 'zh-CN', 'zh-HK', 'zh-TW'];
  // organization_name
  if (!values.organization_name) {
    errors.organization_name = [
      `errorMsgOrganizationServiceUpdateOrganizationNameMissing`,
    ]
  } else if (values.organization_name.length < 5) {
    errors.organization_name = [
      `errorMsgOrganizationServiceUpdateOrganizationNameIsLength`,
    ]
  } else if (values.organization_name.length > 100) {
    errors.organization_name = [
      `errorMsgOrganizationServiceUpdateOrganizationNameIsLength`,
    ]
  }

  // address_1
  if (!values.address_1) {
    errors.address_1 = [`errorMsgOrganizationServiceUpdateAddress1Missing`]
  } else if (values.address_1.length < 5) {
    errors.address_1 = [`errorMsgOrganizationServiceUpdateAddress1IsLength`]
  } else if (values.address_1.length > 100) {
    errors.address_1 = [`errorMsgOrganizationServiceUpdateAddress1IsLength`]
  }

  // address_2
  if (values.address_2) {
    if (values.address_2.length < 2) {
      errors.address_2 = [`errorMsgOrganizationServiceUpdateAddress2IsLength`]
    } else if (values.address_2.length > 100) {
      errors.address_2 = [`errorMsgOrganizationServiceUpdateAddress2IsLength`]
    }
  }
  // address_3
  if (values.address_3) {
    if (values.address_3.length < 2) {
      errors.address_3 = [`errorMsgOrganizationServiceUpdateAddress3IsLength`]
    } else if (values.address_3.length > 100) {
      errors.address_3 = [`errorMsgOrganizationServiceUpdateAddress3IsLength`]
    }
  }

  // country_id
  if (!values.country_id) {
    errors.country_id = [`errorMsgOrganizationServiceUpdateCountryIdMissing`]
  }

  // state
  if (!values.state) {
    errors.state = [`errorMsgOrganizationServiceUpdateStateMissing`]
  } else if (values.state.length < 2) {
    errors.state = [`errorMsgOrganizationServiceUpdateStateIsLength`]
  } else if (values.state.length > 50) {
    errors.state = [`errorMsgOrganizationServiceUpdateStateIsLength`]
  }

  // city
  if (!values.city) {
    errors.city = [`errorMsgOrganizationServiceUpdateCityMissing`]
  } else if (values.city.length < 2) {
    errors.city = [`errorMsgOrganizationServiceUpdateCityIsLength`]
  } else if (values.city.length > 50) {
    errors.city = [`errorMsgOrganizationServiceUpdateCityIsLength`]
  }

  // zip_code
  if (!values.zip_code) {
    errors.zip_code = [`errorMsgOrganizationServiceUpdateZipCodeMissing`]
  } else if (values.zip_code.length < 5) {
    errors.zip_code = [`errorMsgOrganizationServiceUpdateZipCodeIsLength`]
  } else if (values.zip_code.length > 10) {
    errors.zip_code = [`errorMsgOrganizationServiceUpdateZipCodeIsLength`]
  }

  // name
  if (!values.name) {
    errors.name = [`errorMsgOrganizationServiceUpdateNameMissing`]
  } else if (values.name.length < 2) {
    errors.name = [`errorMsgOrganizationServiceUpdateNameIsLength`]
  } else if (values.name.length > 50) {
    errors.name = [`errorMsgOrganizationServiceUpdateNameIsLength`]
  }

  // email
  if (!values.email) {
    errors.email = [`errorMsgOrganizationServiceUpdateEmailMissing`]
  } else if (!isEmail(values.email)) {
    errors.email = [`errorMsgOrganizationServiceUpdateEmailIsEmail`]
  } else if (values.email.length > 100) {
    errors.email = [`errorMsgOrganizationServiceUpdateEmailIsLength`]
  }

  // phone_country_code
  if (!values.phone_country_code || values.phone_country_code === null) {
    errors.phone = [`errorMsgInstructorServiceUpdateCountryCodeMissing`]
  }

  // phone
  if (!values.phone) {
    // phone_country_code
    if (!values.phone_country_code || values.phone_country_code === null) {
      errors.phone = [`errorMsgInstructorServiceUpdateCountryCodeMissing`]
    } else {
      errors.phone = [`errorMsgOrganizationServiceUpdatePhoneMissing`]
    }
  } else {
    // if(!isNumeric(values.phone)){
    //    errors.phone = [`Phone should be integer`]
    // }else if(!values.phone.match(numbers)){
    //    errors.phone = [`Phone should be integer, no symbols are allowed`]
    // }else if (values.phone.length <8) {
    //   errors.phone = [`Phone should be in between 8 to 15 digit`]
    // }else if (values.phone.length > 15) {
    //   errors.phone = [`Phone should be in between 8 to 15 digit`]
    // }
    // if (!phoneRegex.test(values.phone)) {
    //   errors.phone = [`Phone should be xxx-xxx-xxxx format only`];
    // }else{
    //    const  phone  = values.phone.split('-');
    //    if(values.phone==='000-000-0000')
    //    {
    //      errors.phone = [`Phone should be xxx-xxx-xxxx format only`];
    //    }
    //    if(phone[0]==='000'){
    //      errors.phone = [`Phone should be xxx-xxx-xxxx format only`];
    //    }
    // }
  }

  // fax
  if (values.fax) {
    // fax_country_code
    if (!values.fax_country_code || values.fax_country_code === null) {
      errors.fax = [`errorMsgInstructorServiceUpdateCountryCodeMissing`]
    }
  }

  return errors
}

export default validateForm
