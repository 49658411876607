import { GET_ORGANIZATION_PRINCIPAL_DETAILS_BY_PRINCIPAL_ID } from '../actions/principal'

const organizationOrganizationPrincipalDetailReducer = (
  state = { data: [] },
  action,
) => {
  switch (action.type) {
    case GET_ORGANIZATION_PRINCIPAL_DETAILS_BY_PRINCIPAL_ID:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationOrganizationPrincipalDetailReducer
