import {
  GET_STATS,
  GET_STATS_LOADING,
  GET_STATS_LOADED,
} from '../actions/organizations'

const statsReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_STATS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_STATS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_STATS_LOADED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default statsReducer
