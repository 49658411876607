import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'
import NumberFormat from 'react-number-format'
import { Link, withRouter } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { withLocalize, getTranslate } from 'react-localize-redux'

import config from 'config'
import AliasNameForm from 'pages/Teacher/Home/Students/AliasNameForm'
import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'
import { setIntructorStudentListCurrentPage } from 'store/actions/student'

const StudentsTable = ({
  addTranslationForLanguage,
  classId,
  getStudentData,
}) => {
  const [orderBy, setOrderBy] = useState('id')
  const [order, setOrder] = useState('DESC')

  const dispatch = useDispatch()

  const translate = useSelector(state => getTranslate(state.localize))
  const studentsData = useSelector(state => state.teacherStudents.students)
  const studentsPageData = useSelector(
    state => state.teacherStudents.studentsPageData,
  )
  const studentsDataLoading = useSelector(
    state => state.teacherStudents.loading,
  )
  const currentPage = useSelector(state => state.teacherStudents.currentPage)

  useEffect(() => {
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }, [addTranslationForLanguage])

  useEffect(() => {
    if (classId) {
      dispatch(getStudentData(classId, currentPage, orderBy, order))
    } else {
      dispatch(getStudentData(currentPage, orderBy, order))
    }
  }, [classId, dispatch, order, orderBy, currentPage, getStudentData])

  const handleSorting = async field => {
    let newOrder = 'ASC'
    if (orderBy === field) {
      newOrder = order === 'DESC' ? 'ASC' : 'DESC'
    }
    setOrderBy(field)
    setOrder(newOrder)

    if (classId) {
      await dispatch(getStudentData(classId, currentPage, field, order))
    } else {
      await dispatch(getStudentData(currentPage, field, order))
    }
  }

  const onPageChange = async page => {
    await dispatch(setIntructorStudentListCurrentPage(page.selected))

    if (classId) {
      await dispatch(getStudentData(classId, page.selected, orderBy, order))
    } else {
      await dispatch(getStudentData(page.selected, orderBy, order))
    }
  }

  const sortClass = order === 'ASC' ? 'ascending' : 'desending'

  return (
    <div className="row match-height">
      <div className="col-xl-12 col-lg-12 col-md-12">
        <h5 className="card-title text-bold-700 my-2">
          {translate('studentTableTitle')}
        </h5>
        <div className="card" style={{}}>
          <div
            className={
              studentsData && studentsData.totalPage > 0
                ? 'card-content '
                : 'card-content'
            }
          >
            <div
              id="recent-projects"
              className="media-list position-relative"
              onScroll={() => {}}
            >
              <LoadingOverlay
                active={studentsDataLoading}
                spinner
                text={translate('loadingOverlayText')}
              >
                <div
                  className={
                    studentsData.length === 0
                      ? 'table-responsive table-height'
                      : 'table-responsive'
                  }
                >
                  {studentsData && studentsData.length > 0 && (
                    <div id="tableContainer" className="tableContainer">
                      {classId ? (
                        <table
                          className="scrollTable class-student-list-teacher"
                          width="100%"
                          cellSpacing={0}
                          cellPadding={0}
                          border={0}
                        >
                          <thead className="fixedHeader">
                            <tr>
                              <th
                                align="left"
                                width="30%"
                                onClick={() => handleSorting('code')}
                                className={orderBy === 'code' ? sortClass : ''}
                              >
                                {translate('studentTableRowLicense')}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="left"
                                width="30%"
                                onClick={() => handleSorting('alias')}
                                className={orderBy === 'alias' ? sortClass : ''}
                              >
                                {translate('studentTableRowName')}{' '}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="15%"
                                onClick={() => handleSorting('status')}
                                className={
                                  orderBy === 'status' ? sortClass : ''
                                }
                              >
                                {translate('studentTableRowStatus')}{' '}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="15%"
                                onClick={() => handleSorting('sessions')}
                                className={
                                  orderBy === 'sessions' ? sortClass : ''
                                }
                              >
                                {translate('studentTableRowSession')}{' '}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th align="center" width="10%">
                                {translate('studentTableRowView')}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="scrollContent">
                            {studentsData &&
                              studentsData.length > 0 &&
                              studentsData.map(row => (
                                <tr key={`student-${row.id}`}>
                                  <td align="left" width="30%">
                                    <Link
                                      to={`${config.teacherUrl}/student/${row.id}`}
                                      style={{
                                        display: `block`,
                                      }}
                                    >
                                      {row.code}
                                    </Link>
                                  </td>
                                  <td align="left" width="30%">
                                    <AliasNameForm
                                      alias={row.alias}
                                      id={row.id}
                                      dynamicName={row.id}
                                    />
                                  </td>
                                  <td align="center" width="15%">
                                    <span
                                      className={
                                        row.status === 'Active'
                                          ? 'status_approved'
                                          : 'status_pending'
                                      }
                                    >
                                      {row.status}
                                    </span>
                                  </td>
                                  <td align="center" width="15%">
                                    <NumberFormat
                                      thousandSeparator
                                      value={row.sessions ? row.sessions : 0}
                                      displayType="text"
                                    />
                                  </td>
                                  <td align="center" width="10%">
                                    <Link
                                      to={`${config.teacherUrl}/student/${row.id}`}
                                      style={{
                                        display: `block`,
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <table
                          className="scrollTable student-list-teacher"
                          width="100%"
                          cellSpacing={0}
                          cellPadding={0}
                          border={0}
                        >
                          <thead className="fixedHeader">
                            <tr>
                              <th
                                align="left"
                                width="30%"
                                onClick={() => handleSorting('code')}
                                className={orderBy === 'code' ? sortClass : ''}
                              >
                                {translate('studentTableRowLicense')}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="left"
                                width="30%"
                                onClick={() => handleSorting('alias')}
                                className={orderBy === 'alias' ? sortClass : ''}
                              >
                                {translate('studentTableRowName')}{' '}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="left"
                                width="15%"
                                onClick={() => handleSorting('class_name')}
                                className={
                                  orderBy === 'class_name' ? sortClass : ''
                                }
                              >
                                {translate('studentTableRowClass')}{' '}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="10%"
                                onClick={() => handleSorting('status')}
                                className={
                                  orderBy === 'status' ? sortClass : ''
                                }
                              >
                                {translate('studentTableRowStatus')}{' '}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="10%"
                                onClick={() => handleSorting('sessions')}
                                className={
                                  orderBy === 'sessions' ? sortClass : ''
                                }
                              >
                                {translate('studentTableRowSession')}{' '}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th align="center" width="5%">
                                {translate('studentTableRowView')}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="scrollContent">
                            {studentsData &&
                              studentsData.length > 0 &&
                              studentsData.map(row => (
                                <tr key={`student-${row.id}`}>
                                  <td align="left" width="30%">
                                    <Link
                                      to={`${config.teacherUrl}/student/${row.id}`}
                                      style={{
                                        display: `block`,
                                      }}
                                    >
                                      {row.code}
                                    </Link>
                                  </td>
                                  <td align="left" width="30%">
                                    <AliasNameForm
                                      alias={row.alias}
                                      id={row.id}
                                      dynamicName={row.id}
                                    />
                                  </td>
                                  <td align="left" width="15%">
                                    {row.class_name}
                                  </td>
                                  <td align="center" width="10%">
                                    <span
                                      className={
                                        row.status === 'Active'
                                          ? 'status_approved'
                                          : 'status_pending'
                                      }
                                    >
                                      {row.status}
                                    </span>
                                  </td>
                                  <td align="center" width="10%">
                                    <NumberFormat
                                      thousandSeparator
                                      value={row.sessions ? row.sessions : 0}
                                      displayType="text"
                                    />
                                  </td>
                                  <td align="center" width="5%">
                                    <Link
                                      to={`${config.teacherUrl}/student/${row.id}`}
                                      style={{
                                        display: `block`,
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      <ReactPaginate
                        previousLabel="⬅️"
                        nextLabel="➡️"
                        pageCount={studentsPageData.totalPage}
                        onPageChange={page => onPageChange(page)}
                        activeClassName="activePage"
                        containerClassName="paginateContainer"
                      />
                    </div>
                  )}
                  {studentsData &&
                    studentsData.length === 0 &&
                    studentsDataLoading === false && (
                      <div className="table-no-record">
                        {translate('tableNoRecord')}
                      </div>
                    )}
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

StudentsTable.defaultProps = {
  classId: null,
}

StudentsTable.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  getStudentData: PropTypes.func.isRequired,
  classId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default withRouter(withLocalize(StudentsTable))
