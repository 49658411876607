import { ORGANIZATION_USER_PROFILE_UPDATE } from '../actions/organizationUser'

const organizationUserProfileUpdateReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case ORGANIZATION_USER_PROFILE_UPDATE:
      return {
        ...state,
        user: action.payload.user,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationUserProfileUpdateReducer
