import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { withLocalize, getTranslate } from 'react-localize-redux'

import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'
import englishFormTranslations from 'lang/organization/form/en.json'
import spanishFormTranslations from 'lang/organization/form/es.json'
import chineseFormTranslations from 'lang/organization/form/zh.json'
import {
  instructorStudentUpdateByStudentId,
  setHasPrivacyModalBoxBeenShown,
} from 'store/actions/student'
import PrivacyModalBox from './PrivacyModalBox'

const AliasNameForm = ({ addTranslationForLanguage, id, alias }) => {
  const translate = useSelector(state => getTranslate(state.localize))
  const hasPrivacyModalBoxBeenShown = useSelector(
    state => state.teacherStudents.hasPrivacyModalBoxBeenShown,
  )

  const [newAlias, setNewAlias] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [isAliasShown, setIsAliasShown] = useState(false)
  const [isPrivacyModalBoxShown, setIsPrivacyModalBoxShown] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
    addTranslationForLanguage(englishFormTranslations, 'en')
    addTranslationForLanguage(spanishFormTranslations, 'es')
    addTranslationForLanguage(chineseFormTranslations, 'zh')
  }, [addTranslationForLanguage])

  const handleInputChange = event => {
    if (hasPrivacyModalBoxBeenShown === false) {
      setIsPrivacyModalBoxShown(true)
      dispatch(setHasPrivacyModalBoxBeenShown())
    }

    setNewAlias(event.target.value)

    if (event.target.value !== '') {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      if (newAlias !== '') {
        const studentId = await dispatch(
          instructorStudentUpdateByStudentId({ alias: newAlias }, id),
        )

        if (studentId === id) {
          setIsAliasShown(true)
        } else {
          setIsAliasShown(false)
        }
      }
    } catch (errors) {
      // eslint-disable-next-line no-console
      console.error(errors)
    }
  }

  const handlePrivacyModalBoxClose = () => {
    setIsPrivacyModalBoxShown(false)
  }

  return (
    <React.Fragment>
      {alias === null && (
        <React.Fragment>
          {isAliasShown === false && (
            <form
              onSubmit={handleSubmit}
              className="form-horizontal form_table"
            >
              <input
                type="text"
                className="form-control square"
                placeholder={translate('formStudentName')}
                onChange={handleInputChange}
                onClick={handleInputChange}
                name="alias"
                value={alias}
                minLength="2"
                maxLength="25"
              />
              <button
                type="submit"
                className="btn btn-primary"
                disabled={disabled}
              >
                <i className="fa fa-check" />
              </button>
            </form>
          )}
          {isAliasShown === true && <span>{newAlias}</span>}
        </React.Fragment>
      )}
      {alias !== null && <span>{alias}</span>}
      <PrivacyModalBox
        isPrivacyModalBoxShown={isPrivacyModalBoxShown}
        handlePrivacyModalBoxClose={handlePrivacyModalBoxClose}
      />
    </React.Fragment>
  )
}

AliasNameForm.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  alias: PropTypes.string.isRequired,
}

export default withLocalize(AliasNameForm)
