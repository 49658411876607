/* TODO: Fix eslint */
/* eslint-disable */

const validateForm = (values, props) => {
  const errors = {}
  const numbers = /^[0-9]+$/
  // name
  if (!values.name) {
    errors.name = [`errorMsgSchoolServiceCreateNameMissing`]
  } else if (values.name.length < 2) {
    errors.name = [`errorMsgSchoolServiceCreateNameIsLength`]
  } else if (values.name.length > 50) {
    errors.name = [`errorMsgSchoolServiceCreateNameIsLength`]
  }

  // principal
  if (!values.principal) {
    errors.principal = [`errorMsgSchoolServiceCreatePrincipalMissing`]
  }

  // total_license
  if (!values.total_license) {
    errors.total_license = [`errorMsgSchoolServiceCreateTotalLicenseMissing`]
  } else if (isNaN(Number(values.total_license))) {
    errors.total_license = [`errorMsgSchoolServiceCreateTotalLicenseIsInt`]
  } else if (!numbers.test(values.total_license)) {
    errors.total_license = [`errorMsgSchoolServiceCreateTotalLicenseSymbol`]
  } else if (values.total_license <= 0) {
    errors.total_license = [
      `errorMsgSchoolServiceCreateTotalLicenseLessThanZero`,
    ]
  } else if (values.total_license.length < 0) {
    errors.total_license = [
      `errorMsgSchoolServiceCreateTotalLicenseLessThanZero`,
    ]
  }

  return errors
}

export default validateForm
