/* TODO: Fix eslint */
/* eslint-disable */

import isEquals from 'validator/lib/equals'

const validateForm = (values, props) => {
  const errors = {}

  // code
  if (!values.code) {
    errors.code = [`errorMsgStudentAuthServiceRegisterLicenseMissing`]
  }

  // password
  if (!values.password) {
    errors.password = [`errorMsgStudentAuthServiceRegisterPasswordMissing`]
  } else if (values.password.length < 5) {
    errors.password = [`errorMsgStudentAuthServiceRegisterPasswordIsLength`]
  } else if (values.password.length > 20) {
    errors.password = [`errorMsgStudentAuthServiceRegisterPasswordIsLength`]
  }

  // confirm_password
  if (values.password) {
    if (!values.confirm_password) {
      errors.confirm_password = [
        `errorMsgStudentAuthServiceRegisterConfirmPasswordMissing`,
      ]
    } else if (!isEquals(values.confirm_password, values.password)) {
      errors.confirm_password = [
        `errorMsgStudentAuthServiceRegisterConfirmPasswordNotMatched`,
      ]
    }
  }
  return errors
}

export default validateForm
