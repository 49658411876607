import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

import DownloadItem from 'components/Download/DownloadItem'
import AllDownloads from 'components/Download/AllDownloads'

const SUPPORTED_PLATFORMS = ['ios', 'android', 'mac', 'windows']

const PlatformDownload = ({ optionForAll }) => {
  const [showOtherDownloads, setShowOtherDownloads] = useState(false)
  const translate = useSelector(state => getTranslate(state.localize))

  const getOS = () => {
    const { userAgent } = window.navigator
    const { platform } = window.navigator
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'mac'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'ios'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'windows'
    } else if (/Android/.test(userAgent)) {
      os = 'android'
    } else if (!os && /Linux/.test(platform)) {
      os = 'linux'
    }

    return os
  }

  const toggleOtherDownloads = () => {
    setShowOtherDownloads(!showOtherDownloads)
  }

  const platform = getOS()

  return (
    <div className="row">
      <div
        className="card"
        style={{
          width: '97%',
          margin: 'auto',
        }}
      >
        <div
          className="card-header"
          style={{
            textAlign: 'center',
          }}
        >
          <h2>{translate('downloadTitle')}</h2>
        </div>
        <div
          className="card-body"
          style={{
            textAlign: 'center',
          }}
        >
          {SUPPORTED_PLATFORMS.includes(platform) ? (
            <DownloadItem
              platform={platform}
              url={translate(`downloadLink_${platform}`)}
            />
          ) : (
            <Fragment>{translate('downloadUnsupportedPlatform')}</Fragment>
          )}
          {optionForAll && (
            <Fragment>
              <div
                onClick={toggleOtherDownloads}
                onKeyPress={toggleOtherDownloads}
                tabIndex={0}
                role="button"
                style={{
                  width: '80%',
                  height: '20px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  margin: 'auto',
                }}
              >
                <span style={{ backgroundColor: '#FFF', padding: '0 10px' }}>
                  {showOtherDownloads ? '∧ ' : '∨ '}
                  {translate('downloadOthers')}
                  {showOtherDownloads ? ' ∧' : ' ∨'}
                </span>
              </div>
              <div style={{ display: showOtherDownloads ? '' : 'none' }}>
                <AllDownloads skipPlatforms={[platform]} />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

PlatformDownload.defaultProps = {
  optionForAll: true,
}

PlatformDownload.propTypes = {
  optionForAll: PropTypes.bool,
}

export default PlatformDownload
