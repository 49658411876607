/* TODO: Fix eslint */
/* eslint-disable */

import { RECOVER_PASSWORD } from '../actions/auth'

const authUser = JSON.parse(localStorage.getItem('authUser'))

const recoverPasswordReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case RECOVER_PASSWORD:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default recoverPasswordReducer
