import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

import DownloadItem from 'components/Download/DownloadItem'

const VALID_PLATFORMS = ['ios', 'android', 'windows', 'mac']

const AllDownloads = ({ skipPlatforms }) => {
  const translate = useSelector(state => getTranslate(state.localize))

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {!skipPlatforms.includes('android') && (
        <DownloadItem
          platform="android"
          url={translate(`downloadLink_android`)}
        />
      )}
      {!skipPlatforms.includes('ios') && (
        <DownloadItem platform="ios" url={translate(`downloadLink_ios`)} />
      )}
      {!skipPlatforms.includes('windows') && (
        <DownloadItem
          platform="windows"
          url={translate(`downloadLink_windows`)}
        />
      )}
      {!skipPlatforms.includes('mac') && (
        <DownloadItem platform="mac" url={translate(`downloadLink_mac`)} />
      )}
    </div>
  )
}

AllDownloads.defaultProps = {
  skipPlatforms: [],
}

AllDownloads.propTypes = {
  skipPlatforms: PropTypes.arrayOf(PropTypes.oneOf(VALID_PLATFORMS)),
}

export default AllDownloads
