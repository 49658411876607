/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import Moment from 'react-moment'
import { Link, withRouter } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { confirmAlert } from 'react-confirm-alert' // Import
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { withLocalize, getTranslate } from 'react-localize-redux'
import {
  getPrincipalClasses,
  storeClass,
} from '../../../../../store/actions/class'
import config from '../../../../../config'
import TableRowDateProgressBar from '../../../../../components/TableRowDateProgressBar'
import TableRowLicenseProgressBar from '../../../../../components/TableRowLicenseProgressBar'
import { resetForm } from '../../../../../store/actions/form'
import CreateNewFormModelBox from '../../Classes/CreateNewFormModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DateTimeWithTimezone from '../../../../../components/DateTimeWithTimezone'

/**
 * import translation related files
 *
 */
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class Classes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowNewClassForm: false,
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
      loading: false,
    }
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentWillMount() {
    this.setState({ loading: true })
    this.props.getPrincipalClasses(
      this.props,
      this.state.page,
      this.state.orderBy,
      this.state.order,
    )
    this.setState({ page: this.state.page + 1 })
  }

  handlePageChange = async e => {
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: this.state.page + 1 })
        if (this.props.data.totalPage > this.state.page) {
          this.setState({ counter: this.state.counter + 1 })
          this.setState({ loading: true })
          this.setState({ scrollTop: element.scrollTop })
          await this.props.getPrincipalClasses(
            this.props,
            this.state.page,
            this.state.orderBy,
            this.state.order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    let order = 'ASC'
    if (this.state.orderBy === field) {
      order = this.state.order === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    this.setState({ loading: true })
    await this.props.getPrincipalClasses(this.props, 0, field, order)
    this.setState({ page: 1 })
  }

  handleNewClassModelShow = () => {
    this.setState({
      isShowNewClassForm: true,
    })
  }

  handleNewClassModelClose = () => {
    this.props.resetForm('principal-create-class')
    this.setState({
      isShowNewClassForm: false,
    })
  }

  handleSubmit = async values => {
    const classId = await this.props.storeClass(values, this.props)
    this.setState({ isShowNewClassForm: false })
    return this.props.history.push(`${config.principalUrl}/class/${classId}`)
  }

  handleCancel = async organizationUserId => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            this.props.resetForm('principal-create-class')
            this.setState({ isShowNewClassForm: false })
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  render() {
    const { translate } = this.props
    let loadingTable = this.state.loading
    loadingTable = this.props.loading
    const sortClass = this.state.order === 'ASC' ? 'ascending' : 'desending'
    return (
      <React.Fragment>
        <div className="row match-height">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('classTableTitle')}
              <Link
                className="add_btn pull-right"
                to="#"
                onClick={this.handleNewClassModelShow}
              >
                {translate('btnAddClass')}
              </Link>
            </h5>
            <div className="card" style={{}}>
              <div
                className={
                  this.props.data && this.props.data.totalPage > 0
                    ? 'card-content '
                    : 'card-content'
                }
              >
                <div
                  id="recent-projects"
                  className="media-list position-relative"
                  onScroll={this.handlePageChange.bind(this)}
                >
                  <LoadingOverlay
                    active={loadingTable}
                    spinner
                    text={translate('loadingOverlayText')}
                  >
                    <div
                      className={
                        this.props.classes.length === 0
                          ? 'table-responsive table-height'
                          : 'table-responsive'
                      }
                    >
                      {this.props.classes && this.props.classes.length > 0 && (
                        <div id="tableContainer" className="tableContainer">
                          <table
                            className="scrollTable classes-list-principal"
                            width="100%"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                          >
                            <thead className="fixedHeader">
                              <tr>
                                <th
                                  align="left"
                                  width="15%"
                                  onClick={() => this.handleSorting('name')}
                                  className={
                                    this.state.orderBy === 'name'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('classTableRowName')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="left"
                                  width="15%"
                                  onClick={() =>
                                    this.handleSorting('instructor_name')
                                  }
                                  className={
                                    this.state.orderBy === 'instructor_name'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('classTableRowTeacher')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() =>
                                    this.handleSorting('total_active_license')
                                  }
                                  className={
                                    this.state.orderBy ===
                                    'total_active_license'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('classTableRowLicenses')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() =>
                                    this.handleSorting('start_date')
                                  }
                                  className={
                                    this.state.orderBy === 'start_date'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('classTableRowStart')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="10%"
                                  onClick={() => this.handleSorting('status')}
                                  className={
                                    this.state.orderBy === 'status'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('classTableRowStatus')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="15%"
                                  onClick={() => this.handleSorting('tags')}
                                  className={
                                    this.state.orderBy === 'tags'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('classTableRowTags')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th align="center" width="5%">
                                  {translate('classTableRowView')}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="scrollContent">
                              {this.props.classes &&
                                this.props.classes.length > 0 &&
                                this.props.classes.map(row => (
                                  <tr>
                                    <td align="left" width="15%">
                                      <Link
                                        to={`${config.principalUrl}/class/${row.id}`}
                                      >
                                        {row.name}
                                      </Link>
                                    </td>
                                    <td align="left" width="15%">
                                      <Link
                                        to={`${config.principalUrl}/instructor/${row.instructor_id}`}
                                      >
                                        {row.instructor_name}
                                      </Link>
                                    </td>
                                    <td align="center" width="20%">
                                      <span className="text-center">
                                        <NumberFormat
                                          thousandSeparator
                                          value={Number(
                                            row.total_active_license,
                                          )}
                                          displayType="text"
                                        />{' '}
                                        /{' '}
                                        <NumberFormat
                                          thousandSeparator
                                          value={Number(row.total_license)}
                                          displayType="text"
                                        />
                                      </span>
                                      <TableRowLicenseProgressBar
                                        activeLicense={
                                          row && row.total_active_license
                                        }
                                        totalLicense={row && row.total_license}
                                      />
                                    </td>
                                    <td align="center" width="20%">
                                      <span className="text-center">
                                        <DateTimeWithTimezone
                                          dateTime={row.start_date}
                                          isTime="false"
                                          isRed="false"
                                        />{' '}
                                        -{' '}
                                        <DateTimeWithTimezone
                                          dateTime={row.end_date}
                                          isTime="false"
                                          isRed="true"
                                          start_date={row.start_date}
                                          end_date={row.end_date}
                                        />
                                      </span>
                                      <TableRowDateProgressBar
                                        start_date={row.start_date}
                                        end_date={row.end_date}
                                      />
                                    </td>
                                    <td align="center" width="10%">
                                      <span
                                        className={
                                          row.status === 'Active'
                                            ? 'status_approved'
                                            : 'status_pending'
                                        }
                                      >
                                        {row.status}
                                      </span>
                                    </td>
                                    <td align="center" width="15%">
                                      {row.tags}
                                    </td>
                                    <td align="center" width="5%">
                                      <Link
                                        to={`${config.principalUrl}/class/${row.id}`}
                                      >
                                        <i className="fa fa-eye" />
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {this.props.classes &&
                        this.props.classes.length === 0 &&
                        loadingTable === false && (
                          <div className="table-no-record">
                            {translate('tableNoRecord')}
                          </div>
                        )}
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateNewFormModelBox
          isShowNewClassForm={this.state.isShowNewClassForm}
          handleNewClassModelClose={this.handleNewClassModelClose}
          onSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.principalClasses.data,
  classes: state.principalClasses.classes,
  loading: state.principalClasses.loading,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalClasses: (props, page, orderBy, order) =>
    dispatch(getPrincipalClasses(props, page, orderBy, order)),
  storeClass: (values, props) => dispatch(storeClass(values, props)),
  resetForm: formName => dispatch(resetForm(formName)),
})

export default withRouter(
  withLocalize(connect(mapStateToProps, mapDispatchToProps)(Classes)),
)
