import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DataTable from 'react-data-table-component'
import LoadingOverlay from 'react-loading-overlay'
import NumberFormat from 'react-number-format'

import { withLocalize, getTranslate } from 'react-localize-redux'
import config from 'config'
import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'

class PronunciationBreakdown extends React.Component {
  constructor(props) {
    super(props)

    const { addTranslationForLanguage } = this.props
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }

  render() {
    const { translate, loading: loadingTable, data } = this.props
    const columns = [
      {
        name: translate('pronunciationBreakdownTableRowIPA'),
        selector: 'phoneme_IPA',
        sortable: true,
      },
      {
        name: translate('pronunciationBreakdownTableRowREG'),
        selector: 'phoneme_REG',
        sortable: true,
      },
      {
        name: translate('pronunciationBreakdownTableRowWord'),
        selector: 'word',
        sortable: true,
      },
      {
        name: translate('pronunciationBreakdownTableRowScore'),
        selector: 'score',
        sortable: true,
        cell: row => (
          <NumberFormat
            thousandSeparator
            value={Number(row.score)}
            displayType="text"
          />
        ),
      },
    ]
    return (
      <div className="graph-section">
        <div className="row">
          <div className="col-md-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('pronunciationBreakdownTableTitle')}
            </h5>
            <div className="card" style={{}}>
              <div className="card-content">
                <div
                  id="recent-projects"
                  className="media-list position-relative"
                >
                  <LoadingOverlay
                    active={loadingTable}
                    spinner
                    text={`${config.loadingOverlayText}`}
                  >
                    <div
                      className={
                        loadingTable === true
                          ? 'table-responsive table-height'
                          : 'table-responsive'
                      }
                    >
                      <DataTable
                        columns={columns}
                        data={data}
                        className="aggregate-project-table pronunciation-breakdown-table"
                        progressPending={loadingTable}
                        progressComponent={translate('loadingOverlayTextTable')}
                        id="aggregate-project-table"
                      />
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PronunciationBreakdown.defaultProps = {
  loading: false,
}

PronunciationBreakdown.propTypes = {
  translate: PropTypes.func.isRequired,
  addTranslationForLanguage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default withLocalize(connect(mapStateToProps)(PronunciationBreakdown))
