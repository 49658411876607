import { GET_INSTRUCTOR_STUDENT_DETAILS_BY_STUDENT_ID } from '../../actions/student'

const teacherStudentDetailReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_INSTRUCTOR_STUDENT_DETAILS_BY_STUDENT_ID:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default teacherStudentDetailReducer
