import {
  GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID,
  GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADING,
  GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADED,
} from 'store/actions/instructors'

const initialState = {
  instructors: [],
}

const organizationInstructorReducer = (state = initialState, action = {}) => {
  const newState = state
  switch (action.type) {
    case GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID:
      if (action.payload.currentPage === 0) {
        newState.instructors = action.payload.instructors
        return {
          instructors: newState.instructors,
          ...newState,
          data: action.payload,
        }
      }
      newState.instructors = action.payload.instructors
      return {
        instructors: newState.instructors,
        ...newState,
        data: action.payload,
      }

    case GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADING:
      return {
        ...newState,
        loading: true,
      }
    case GET_INSTRUCTOR_LIST_BY_ORGANIZATION_ID_LOADED:
      return {
        ...newState,
        loading: false,
      }
    default:
      return newState
  }
}

export default organizationInstructorReducer
