/* TODO: Fix eslint */
/* eslint-disable */

/** images **/
import loadingGif from './../../app-assets/images/loading.gif'

import React from 'react'

const Loader = ({ width, height }) => (
  <div className="text-center">
    <img
      src={loadingGif}
      alt=""
      width={width ? width : '16px'}
      height={height ? height : '16px'}
      className="img"
    />
  </div>
)

export default Loader
