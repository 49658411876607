/* TODO: Fix eslint */
/* eslint-disable */

import axios from 'axios'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { reset } from 'redux-form'
import config from '../../config'
import handleResponse from './handleResponse'
import handleResponseTranslate from './handleResponseTranslate'

/**
 * Define actions
 *
 */
export const GET_STATS = 'GET_STATS'
export const GET_STATS_LOADING = 'GET_STATS_LOADING'
export const GET_STATS_LOADED = 'GET_STATS_LOADED'

export const GET_ORGANIZATION_STATS = 'GET_ORGANIZATION_STATS'
export const GET_ORGANIZATION_STATS_LOADING = 'GET_ORGANIZATION_STATS_LOADING'
export const GET_ORGANIZATION_STATS_LOADED = 'GET_ORGANIZATION_STATS_LOADED'

export const GET_ORGANIZATION_LIST = 'GET_ORGANIZATION_LIST'
export const GET_ORGANIZATION_LIST_LOADING = 'GET_ORGANIZATION_LIST_LOADING'
export const GET_ORGANIZATION_LIST_LOADED = 'GET_ORGANIZATION_LIST_LOADED'

export const ORGANIZATION_STORE = 'ORGANIZATION_STORE'
export const GET_ORGANIZATION_DETAILS = 'GET_ORGANIZATION_DETAILS'
export const GET_ORGANIZATION_DETAILS_LOADING =
  'GET_ORGANIZATION_DETAILS_LOADING'
export const GET_ORGANIZATION_DETAILS_LOADED = 'GET_ORGANIZATION_DETAILS_LOADED'

export const ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE'

export const GET_ORGANIZATION_ORGANIZATION_STATS =
  'GET_ORGANIZATION_ORGANIZATION_STATS'
export const GET_ORGANIZATION_ORGANIZATION_STATS_LOADING =
  'GET_ORGANIZATION_ORGANIZATION_STATS_LOADING'
export const GET_ORGANIZATION_ORGANIZATION_STATS_LOADED =
  'GET_ORGANIZATION_ORGANIZATION_STATS_LOADED'
export const GET_ORGANIZATION_ORGANIZATION_DETAILS =
  'GET_ORGANIZATION_ORGANIZATION_DETAILS'
export const GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADING =
  'GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADING'
export const GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADED =
  'GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADED'
export const ORGANIZATION_ORGANIZATION_UPDATE =
  'ORGANIZATION_ORGANIZATION_UPDATE'

/**
 * To get total stats of FluentWorlds
 *
 * @return data
 */
export const getStats = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_STATS_LOADING,
    })

    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/stats`,
      headers,
    )
    dispatch({
      type: GET_STATS,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_STATS_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get organization stats
 *
 * @param  int  organizationId
 * @return data
 */
export const getOrganizationStats = organizationId => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_STATS_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/stats`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_STATS,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_STATS_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get organization stats
 *
 * @param  int  organizationId
 * @return data
 */
export const getOrganizationList = (page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_LIST_LOADING,
    })

    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_LIST_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To store/save organization
 *
 * @param  object  values
 * @param  object  file
 * @return organizationId
 */
export const storeOrganization = (values, file) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }

    // const contract_start_dateUTC = moment.utc(values.contract_start_date);
    // const contract_end_dateUTC = moment.utc(values.contract_end_date);
    // const contract_start_date = moment(contract_start_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');
    // const contract_end_date = moment(contract_end_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');

    const currentHH = moment().format('HH')
    const currentMM = moment().format('mm')
    const currentSS = moment().format('ss')
    const time = ` ${currentHH}:${currentMM}:${currentSS}`
    const startFormat = moment(
      values.contract_start_date + time,
      'MM/DD/YYYY HH:mm:ss',
    ).format('YYYY-MM-DD HH:mm:ss')
    const contract_start_date = moment(startFormat)
      .utc()
      .format('YYYY-MM-DD')

    const endFormat1 = moment(
      values.contract_end_date,
      'MM/DD/YYYY HH:mm:ss',
    ).format('YYYY-MM-DD')
    const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
      'YYYY-MM-DD HH:mm:ss',
    )
    const contract_end_date = moment(endFormat)
      .utc()
      .format('YYYY-MM-DD')
    //  console.log(values);

    // console.log(`contract_end_date=${values.contract_end_date}`);
    // console.log(`endFormat=${endFormat}`);
    // console.log(`contract_end_date=${values.contract_end_date}`);

    const organizationValues = {
      organization_name: values.organization_name,
      address_1: values.address_1,
      address_2: values.address_2,
      address_3: values.address_3,
      country_id: Number(values.country_id),
      state: values.state,
      city: values.city,
      zip_code: values.zip_code,
      phone: values.phone,
      fax: values.fax,
      contract_start_date,
      contract_end_date,
      is_admin_report_creation: values.is_admin_report_creation,
      name: values.name,
      email: values.email,
      total_license: parseInt(Number(values.total_license)),
      document: values.document,
      file,
      phone_country_code: values.phone_country_code,
      fax_country_code: values.fax_country_code,
    }
    // console.log(organizationValues);
    const response = await axios.post(
      `${config.apiUrl}/organization`,
      organizationValues,
      headers,
    )
    dispatch({
      type: ORGANIZATION_STORE,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    await handleResponse(error, 1)
  }
}

/**
 * To update organization
 *
 * @param  int  organizationId
 * @param  object  values
 * @param  object  file
 * @return organizationId
 */
export const updateOrganization = (organizationId, values, file) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }

    // const contract_start_dateUTC = moment.utc(values.contract_start_date);
    // const contract_end_dateUTC = moment.utc(values.contract_end_date);
    // const contract_start_date = moment(contract_start_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');
    // const contract_end_date = moment(contract_end_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');

    const currentHH = moment().format('HH')
    const currentMM = moment().format('mm')
    const currentSS = moment().format('ss')
    const time = ` ${currentHH}:${currentMM}:${currentSS}`
    const startFormat = moment(
      values.contract_start_date + time,
      'MM/DD/YYYY HH:mm:ss',
    ).format('YYYY-MM-DD HH:mm:ss')
    const contract_start_date = moment(startFormat)
      .utc()
      .format('YYYY-MM-DD')

    const endFormat1 = moment(
      values.contract_end_date,
      'MM/DD/YYYY HH:mm:ss',
    ).format('YYYY-MM-DD')
    const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
      'YYYY-MM-DD HH:mm:ss',
    )
    const contract_end_date = moment(endFormat)
      .utc()
      .format('YYYY-MM-DD')

    const organizationValues = {
      organization_name: values.organization_name,
      address_1: values.address_1,
      address_2: values.address_2,
      address_3: values.address_3,
      country_id: Number(values.country_id),
      state: values.state,
      city: values.city,
      zip_code: values.zip_code,
      phone: values.phone,
      fax: values.fax,
      contract_start_date,
      contract_end_date,
      is_admin_report_creation: values.is_admin_report_creation,
      name: values.name,
      email: values.email,
      total_license: parseInt(Number(values.total_license)),
      document: values.document,
      file,
      status: values.status,
      phone_country_code: values.phone_country_code,
      fax_country_code: values.fax_country_code,
    }
    const response = await axios.put(
      `${config.apiUrl}/organization/${organizationId}`,
      organizationValues,
      headers,
    )
    dispatch({
      type: ORGANIZATION_UPDATE,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    await handleResponse(error, 1)
  }
}

/**
 * To get organization details
 *
 * @param  int  organizationId
 * @return data
 */
export const getOrganizationDetails = organizationId => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_DETAILS_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}`,
      headers,
    )

    dispatch({
      type: GET_ORGANIZATION_DETAILS,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_DETAILS_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To download organization PDF file
 *
 * @param  string  key
 * @return pdf file
 */
export const downloadOrganizationDOC = file => async (dispatch, getState) => {
  try {
    const headers = {
      access_token: `${getState().auth.accessToken}`,
      responseType: 'blob',
    }

    await axios
      .get(`${config.apiUrl}/download/${file.key}`, headers)
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
        saveAs(pdfBlob, file.name)
      })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get organization stats for organization
 *
 * @param  object  props
 * @return data
 */
export const getOrganizationOrganizationStats = props => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_ORGANIZATION_STATS_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/stats`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_ORGANIZATION_STATS,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_ORGANIZATION_STATS_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get organization details for organization
 *
 * @param  object  props
 * @return data
 */
export const getOrganizationOrganizationDetails = props => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(`${config.apiOrganizationUrl}`, headers)
    dispatch({
      type: GET_ORGANIZATION_ORGANIZATION_DETAILS,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To update organization for organization
 *
 * @param  object  values
 * @param  object  props
 * @return organizationId
 */
export const OrganizationupdateOrganization = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const organizationValues = {
      organization_name: values.organization_name,
      address_1: values.address_1,
      address_2: values.address_2,
      address_3: values.address_3,
      country_id: Number(values.country_id),
      state: values.state,
      city: values.city,
      zip_code: values.zip_code,
      phone: values.phone,
      fax: values.fax,
      name: values.name,
      email: values.email,
      phone_country_code: values.phone_country_code,
      fax_country_code: values.fax_country_code,
    }
    const response = await axios.put(
      `${config.apiOrganizationUrl}`,
      organizationValues,
      headers,
    )
    dispatch({
      type: ORGANIZATION_ORGANIZATION_UPDATE,
      payload: response.data.result,
    })
    dispatch(reset('organization-profile-form'))
    return response.data.result.data.id

    return response.data.result.data.id
  } catch (error) {
    await handleResponseTranslate(error, props, 1)
  }
}

/**
 * To download organization PDF file for organizatoin
 *
 * @param  string  key
 * @return pdf file
 */
export const OrganizationdownloadOrganizationDOC = (file, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      access_token: `${getState().authOrganization.accessToken}`,
      responseType: 'blob',
    }
    await axios
      .get(`${config.apiOrganizationUrl}/download/${file.key}`, headers)
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
        saveAs(pdfBlob, file.name)
      })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}
