/* TODO: Fix this eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import Modal from 'react-modal'
import { logoutUser } from 'store/actions/auth'
import { updatePassword, getAdminUserProfile } from 'store/actions/adminUser'
import config from 'config'

import ChangePassword from 'pages/Home/Common/ChangePassword'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false,
    }

    const { getAdminUserProfile } = this.props
    getAdminUserProfile()
  }

  handleChangePasswordOpenModel = () => {
    this.setState({ modalIsOpen: true })
  }

  handleCancel = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'You want to cancel this form?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({ modalIsOpen: false })
          },
        },
        {
          label: 'No',
        },
      ],
    })
  }

  handleChangePasswordCloseModel = () => {
    this.setState({ modalIsOpen: false })
  }

  handleSubmit = async values => {
    const { updatePassword } = this.props
    await updatePassword(values)
    this.setState({ modalIsOpen: false })
  }

  render() {
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '38%',
        backdrop: 'static',
        keyboard: false,
      },
    }

    const { user, logoutUser, handleHamburger } = this.props
    const { modalIsOpen } = this.state
    return (
      <React.Fragment>
        <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light">
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div className="collapse navbar-collapse show" id="navbar-mobile">
                <ul className="nav navbar-nav mr-auto float-left">
                  <li className="nav-item mobile-menu d-md-none mr-auto">
                    <Link className="nav-link nav-menu-main menu-toggle hidden-xs" to="#">
                      <i className="ft-menu font-large-1" />
                    </Link>
                  </li>
                  <li className="nav-item d-none d-md-block">
                    <Link
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      to="#"
                      onClick={handleHamburger}
                      style={{ transition: 'all 0s ease 0s' }}
                    >
                      <i className="ft-menu" />
                    </Link>
                  </li>
                </ul>
                <ul className="nav navbar-nav float-right">
                  <li className="dropdown dropdown-user nav-item">
                    <Link
                      className="dropdown-toggle nav-link dropdown-user-link"
                      to={`${config.adminUrl}/home`}
                      data-toggle="dropdown"
                    >
                      {' '}
                      {user &&
                        (user.name.length > 13 ? `${user.name.substring(0, 10)}...` : user.name)}
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <div className="arrow_box_right">
                        <Link className="dropdown-item" to={`${config.adminUrl}/profile`}>
                          <i className="ft-user" />
                          Edit Profile
                        </Link>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          onClick={this.handleChangePasswordOpenModel}
                          to='#'
                        >
                          <i className="ft-lock" />
                          Change Password
                        </Link>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          to={`${config.adminUrl}/login`}
                          onClick={logoutUser}
                        >
                          <i className="ft-power" />
                          Logout
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.handleChangePasswordCloseModel}
          style={customStyles}
          data-backdrop="static"
          data-keyboard="false"
        >
          <ChangePassword
            close={this.handleChangePasswordCloseModel}
            onSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
          />
        </Modal>
      </React.Fragment>
    )
  }
}

Header.defaultProps = {
  user: {
    name: ''
  }
}

Header.propTypes = {
  getAdminUserProfile: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  handleHamburger: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string
  })
}

const mapStateToProps = state => ({
  user: state.adminUserProfile.data.row,
})

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
  updatePassword: values => dispatch(updatePassword(values)),
  getAdminUserProfile: () => dispatch(getAdminUserProfile()),
})
export default connect(mapStateToProps, mapDispatchToProps)(Header)
