import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import LoadingOverlay from 'react-loading-overlay'
import config from 'config'
import TableRowLicenseProgressBar from 'components/TableRowLicenseProgressBar'
import { getClassesByschoolId } from 'store/actions/class'
import DateTimeWithTimezone from 'components/DateTimeWithTimezone'
import TableRowDateProgressBar from 'components/TableRowDateProgressBar'

class Classes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
      loading: true,
    }

    const { getClassesByschoolId, organizationId, schoolId } = this.props
    getClassesByschoolId(organizationId, schoolId, 0, 'id', 'DESC')
  }

  componentDidMount() {
    const { page } = this.state
    this.setState({ page: page + 1 })
  }

  handlePageChange = async e => {
    const { getClassesByschoolId, organizationId, schoolId, data } = this.props
    const { page, order, orderBy, counter } = this.state
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: page + 1 })
        if (data.totalPage > page) {
          this.setState({
            counter: counter + 1,
            loading: true,
            scrollTop: element.scrollTop,
          })
          await getClassesByschoolId(
            organizationId,
            schoolId,
            page,
            orderBy,
            order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    const { getClassesByschoolId, organizationId, schoolId } = this.props
    const { order: stateOrder, orderBy } = this.state
    let order = 'ASC'
    if (orderBy === field) {
      order = stateOrder === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({
      orderBy: field, order,
      loading: true,
    })
    await getClassesByschoolId(
      organizationId,
      schoolId,
      0,
      field,
      order,
    )
    this.setState({ page: 1 })
  }

  render() {
    const { loading: loadingTable, data, classes } = this.props
    const { order, orderBy } = this.state
    const sortClass = order === 'ASC' ? 'ascending' : 'desending'
    return (
      <div className="row match-height">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <h5 className="card-title text-bold-700 my-2">Classes</h5>
          <div className="card" style={{}}>
            <div
              className={
                data && data.totalPage > 0 ? 'card-content ' : 'card-content'
              }
            >
              <div
                id="recent-projects"
                className="media-list position-relative"
                onScroll={this.handlePageChange.bind(this)}
              >
                <LoadingOverlay active={loadingTable} spinner text={`${config.loadingOverlayText}`}>
                  <div
                    className={
                      classes.length === 0
                        ? 'table-responsive table-height'
                        : 'table-responsive'
                    }
                  >
                    {classes && classes.length > 0 && (
                      <div id="tableContainer" className="tableContainer">
                        <table
                          className="scrollTable class-list-school-details"
                          width="100%"
                          cellSpacing={0}
                          cellPadding={0}
                          border={0}
                        >
                          <thead className="fixedHeader">
                            <tr>
                              <th
                                align="left"
                                width="20%"
                                onClick={() => this.handleSorting('name')}
                                className={orderBy === 'name' ? sortClass : ''}
                              >
                                Name
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="left"
                                width="20%"
                                onClick={() => this.handleSorting('instructor_name')}
                                className={
                                  orderBy === 'instructor_name' ? sortClass : ''
                                }
                              >
                                Teacher
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="20%"
                                onClick={() => this.handleSorting('total_active_license')}
                                className={
                                  orderBy === 'total_active_license' ? sortClass : ''
                                }
                              >
                                Licenses / Assigned
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="20%"
                                onClick={() => this.handleSorting('start_date')}
                                className={orderBy === 'start_date' ? sortClass : ''}
                              >
                                Start - End Date
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="20%"
                                onClick={() => this.handleSorting('tags')}
                                className={orderBy === 'tags' ? sortClass : ''}
                              >
                                Tags
                                <i className="fas fa-sort-up" />
                              </th>
                            </tr>
                          </thead>
                          <tbody className="scrollContent">
                            {classes &&
                              classes.length > 0 &&
                              classes.map(row => (
                                <tr key={`class-${row.id}`}>
                                  <td align="left" width="20%">
                                    <Link
                                      to={`${config.adminUrl}/organization/${row.organization_id}/class/${row.id}`}
                                    >
                                      {row.name}
                                    </Link>
                                  </td>
                                  <td align="left" width="20%">
                                    <Link
                                      to={`${config.adminUrl}/organization/${row.organization_id}/instructor/${row.instructor_id}`}
                                    >
                                      {row.instructor_name}
                                    </Link>
                                  </td>
                                  <td align="center" width="20%">
                                    <span className="text-center">
                                      <NumberFormat
                                        thousandSeparator
                                        value={Number(row.total_active_license)}
                                        displayType="text"
                                      />{' '}
                                      /{' '}
                                      <NumberFormat
                                        thousandSeparator
                                        value={Number(row.total_license)}
                                        displayType="text"
                                      />
                                    </span>
                                    <TableRowLicenseProgressBar
                                      activeLicense={row && row.total_active_license}
                                      totalLicense={row && row.total_license}
                                    />
                                  </td>
                                  <td align="center" width="20%">
                                    <span className="text-center">
                                      <DateTimeWithTimezone
                                        dateTime={row.start_date}
                                        isTime="false"
                                        isRed="false"
                                      />{' '}
                                      -{' '}
                                      <DateTimeWithTimezone
                                        dateTime={row.end_date}
                                        isTime="false"
                                        isRed="true"
                                        start_date={row.start_date}
                                        end_date={row.end_date}
                                      />
                                    </span>
                                    <TableRowDateProgressBar
                                      start_date={row.start_date}
                                      end_date={row.end_date}
                                    />
                                  </td>
                                  <td align="center" width="20%">
                                    {row.tags}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {classes &&
                      classes.length === 0 &&
                      loadingTable === false && (
                        <div className="table-no-record">{`${config.tableNoRecord}`}</div>
                      )}
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Classes.defaultProps = {
  loading: false,
  data: {
    totalPage: 0
  },
  classes: []
}

Classes.propTypes = {
  getClassesByschoolId: PropTypes.func.isRequired,
  data: PropTypes.shape({
    totalPage: PropTypes.number
  }),
  loading: PropTypes.bool,
  classes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    organization_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    instructor_name: PropTypes.string,
    total_active_license: PropTypes.number,
    total_license: PropTypes.number,
    tags: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }))
}

const mapStateToProps = state => ({
  data: state.adminSchoolClasses.data,
  classes: state.adminSchoolClasses.classes,
  loading: state.adminSchoolClasses.loading,
})

const mapDispatchToProps = dispatch => ({
  getClassesByschoolId: (organizationId, schoolId, page, orderBy, order) => {
    dispatch(getClassesByschoolId(organizationId, schoolId, page, orderBy, order))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Classes)
