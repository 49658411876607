import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { withLocalize } from 'react-localize-redux'

import config from 'config'

import organizationImg from 'assets/root/images/network.svg'
import principalImg from 'assets/root/images/principal.svg'
import teacherImg from 'assets/root/images/university-lecture.svg'
import studentImg from 'assets/root/images/student.svg'

import englishRootLoginTranslations from 'lang/root/login/en.json'
import spanishRootLoginTranslations from 'lang/root/login/es.json'
import chineseRootLoginTranslations from 'lang/root/login/zh.json'

const LoginModalBox = ({
  addTranslationForLanguage,
  isShowLogin,
  setIsShowLogin,
  translate,
}) => {
  addTranslationForLanguage(englishRootLoginTranslations, 'en')
  addTranslationForLanguage(spanishRootLoginTranslations, 'es')
  addTranslationForLanguage(chineseRootLoginTranslations, 'zh')

  return (
    <Modal
      show={isShowLogin}
      onHide={setIsShowLogin}
      animation
      id="loginPopup"
      dialogClassName="all_modals"
      size="lg"
      aria-labelledby="loginPopup"
      data-testid="loginModal"
    >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={() => setIsShowLogin(false)}
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="user-vendor_login">
          <div className="row">
            <div className="col-md-6 user_login_div">
              <svg width={42} height={42} xmlns="#" xmlnsXlink="#">
                <image xlinkHref={organizationImg} height={42} width={42} />
              </svg>
              <Link
                className="user_login_heading"
                to={`${config.organizationUrl}`}
              >
                <h3>
                  {translate('organizationLogin')}
                  <i className="fa fa-arrow-right" />
                </h3>
              </Link>
              <p>{translate('organizationLoginText')}</p>
            </div>
            <div className="col-md-6 user_login_div v_login_div">
              <svg width={42} height={42} xmlns="#" xmlnsXlink="#">
                <image xlinkHref={principalImg} height={42} width={42} />
              </svg>
              <Link
                className="user_login_heading"
                to={`${config.principalUrl}`}
              >
                <h3>
                  {translate('principalLogin')}
                  <i className="fa fa-arrow-right" />
                </h3>
              </Link>
              <p>{translate('principalLoginText')}</p>
            </div>
            <div className="clear" />
            <div className="col-md-6 user_login_div">
              <svg width={42} height={42} xmlns="#" xmlnsXlink="#">
                <image xlinkHref={teacherImg} height={42} width={42} />
              </svg>
              <Link className="user_login_heading" to={`${config.teacherUrl}`}>
                <h3>
                  {translate('teacherLogin')}
                  <i className="fa fa-arrow-right" />
                </h3>
              </Link>
              <p>{translate('teacherLoginText')}</p>
            </div>
            <div className="col-md-6 user_login_div v_login_div">
              <svg width={42} height={42} xmlns="#" xmlnsXlink="#">
                <image xlinkHref={studentImg} height={42} width={42} />
              </svg>
              <Link className="user_login_heading" to={`${config.studentUrl}`}>
                <h3>
                  {translate('studentLogin')}
                  <i className="fa fa-arrow-right" />
                </h3>
              </Link>
              <p>{translate('studentLoginText')}</p>
            </div>
            <div className="clear" />
          </div>
        </div>
      </div>
    </Modal>
  )
}

LoginModalBox.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  isShowLogin: PropTypes.bool.isRequired,
  setIsShowLogin: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

export default withLocalize(LoginModalBox)
