import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { updateProfile, getAdminUserProfile } from 'store/actions/adminUser'
import EditFrom from 'pages/Home/Profile/EditProfile/EditFrom'
import config from 'config'

class EditProfile extends React.Component {
  constructor(props) {
    super(props)

    const { getAdminUserProfile } = props
    getAdminUserProfile()
  }

  handleCancel = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'You want to cancel this form?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            window.location.reload()
          },
        },
        {
          label: 'No',
        },
      ],
    })
  }

  handleRedirect = () => {
    const { history } = this.props
    history.push(`${config.adminUrl}/home`)
  }

  handleSubmit = async values => {
    const { updateProfile, getAdminUserProfile } = this.props
    await updateProfile(values)
    getAdminUserProfile()
  }

  render() {
    const { adminUserProfile } = this.props
    return (
      <EditFrom
        onSubmit={this.handleSubmit}
        row={adminUserProfile}
        handleCancel={this.handleCancel}
        handleRedirect={this.handleRedirect}
      />
    )
  }
}

EditProfile.defaultProps = {
  adminUserProfile: {},
}

EditProfile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  getAdminUserProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  adminUserProfile: PropTypes.shape({}),
}

const mapStateToProps = state => ({
  adminUserProfile: state.adminUserProfile.data.row,
})

const mapDispatchToProps = dispatch => ({
  updateProfile: values => dispatch(updateProfile(values)),
  getAdminUserProfile: () => dispatch(getAdminUserProfile()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProfile),
)
