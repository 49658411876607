/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import OrganizationCommonDetail from '../../Common/Organization'
import OrganizationStats from '../OrganizationStats'
import Reports from './Reports'
import { getClassesByOrganizationId } from '../../../../store/actions/class'
import config from '../../../../config'
import TableRowDateProgressBar from '../../../../components/TableRowDateProgressBar'
import TableRowLicenseProgressBar from '../../../../components/TableRowLicenseProgressBar'
import DateTimeWithTimezone from '../../../../components/DateTimeWithTimezone'

class Classes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationId: '',
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
      loading: false,
    }
  }

  componentWillMount() {
    const { match } = this.props
    const organizationId = match.params.id
    if (organizationId === ' ' || organizationId === 'undefined') {
      this.props.history.push(`${config.adminUrl}/home`)
    }
    this.setState({ organizationId })
    this.setState({ loading: true })
    this.props.getClassesByOrganizationId(
      organizationId,
      this.state.page,
      this.state.orderBy,
      this.state.order,
    )
    this.setState({ page: this.state.page + 1 })
  }

  handlePageChange = async e => {
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: this.state.page + 1 })
        if (this.props.data.totalPage > this.state.page) {
          this.setState({ counter: this.state.counter + 1 })
          this.setState({ loading: true })
          this.setState({ scrollTop: element.scrollTop })
          await this.props.getClassesByOrganizationId(
            this.state.organizationId,
            this.state.page,
            this.state.orderBy,
            this.state.order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    let order = 'ASC'
    if (this.state.orderBy === field) {
      order = this.state.order === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    this.setState({ loading: true })

    await this.props.getClassesByOrganizationId(
      this.state.organizationId,
      0,
      field,
      order,
    )
    this.setState({ page: 1 })
  }

  render() {
    let loadingTable = this.state.loading
    loadingTable = this.props.loading
    const sortClass = this.state.order === 'ASC' ? 'ascending' : 'desending'

    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">View Classes</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.adminUrl}/organization/${this.state.organizationId}`}
                    >
                      Organization
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">View Classes</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <OrganizationCommonDetail />
          <div className="row">
            <OrganizationStats />
            <Reports organizationId={this.state.organizationId} />
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <h5 className="card-title text-bold-700 my-2">Classes</h5>
              <div className="card" style={{}}>
                <div
                  className={
                    this.props.data && this.props.data.totalPage > 0
                      ? 'card-content '
                      : 'card-content'
                  }
                >
                  <div
                    id="recent-projects"
                    className="media-list position-relative"
                    onScroll={this.handlePageChange.bind(this)}
                  >
                    <LoadingOverlay
                      active={loadingTable}
                      spinner
                      text={`${config.loadingOverlayText}`}
                    >
                      <div
                        className={
                          this.props.classes.length === 0
                            ? 'table-responsive table-height'
                            : 'table-responsive'
                        }
                      >
                        {this.props.classes && this.props.classes.length > 0 && (
                          <div id="tableContainer" className="tableContainer">
                            <table
                              className="scrollTable class-list-admin-organization"
                              width="100%"
                              cellSpacing={0}
                              cellPadding={0}
                              border={0}
                            >
                              <thead className="fixedHeader">
                                <tr>
                                  <th
                                    align="left"
                                    width="15%"
                                    onClick={() => this.handleSorting('name')}
                                    className={
                                      this.state.orderBy === 'name'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    Name
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="left"
                                    width="15%"
                                    onClick={() =>
                                      this.handleSorting('instructor_name')
                                    }
                                    className={
                                      this.state.orderBy === 'instructor_name'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    Teacher
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="left"
                                    width="15%"
                                    onClick={() =>
                                      this.handleSorting('school_name')
                                    }
                                    className={
                                      this.state.orderBy === 'school_name'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    School/Dept
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="20%"
                                    onClick={() =>
                                      this.handleSorting('total_license')
                                    }
                                    className={
                                      this.state.orderBy === 'total_license'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    Licenses / Assigned
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="20%"
                                    onClick={() =>
                                      this.handleSorting('start_date')
                                    }
                                    className={
                                      this.state.orderBy === 'start_date'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    Start - End Date
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="15%"
                                    onClick={() => this.handleSorting('tags')}
                                    className={
                                      this.state.orderBy === 'tags'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    Tags
                                    <i className="fas fa-sort-up" />
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="scrollContent">
                                {this.props.classes &&
                                  this.props.classes.length > 0 &&
                                  this.props.classes.map(row => (
                                    <tr>
                                      <td align="left" width="15%">
                                        <Link
                                          to={`${config.adminUrl}/organization/${row.organization_id}/class/${row.id}`}
                                        >
                                          {row.name}
                                        </Link>
                                      </td>
                                      <td align="left" width="15%">
                                        <Link
                                          to={`${config.adminUrl}/organization/${row.organization_id}/instructor/${row.instructor_id}`}
                                        >
                                          {row.instructor_name}
                                        </Link>
                                      </td>
                                      <td align="left" width="15%">
                                        {row.school_name}
                                      </td>
                                      <td align="center" width="20%">
                                        <span className="text-center">
                                          <NumberFormat
                                            thousandSeparator
                                            value={Number(
                                              row.total_active_license,
                                            )}
                                            displayType="text"
                                          />{' '}
                                          /{' '}
                                          <NumberFormat
                                            thousandSeparator
                                            value={Number(row.total_license)}
                                            displayType="text"
                                          />
                                        </span>
                                        <TableRowLicenseProgressBar
                                          activeLicense={
                                            row && row.total_active_license
                                          }
                                          totalLicense={
                                            row && row.total_license
                                          }
                                        />
                                      </td>
                                      <td align="center" width="20%">
                                        <span className="text-center">
                                          <DateTimeWithTimezone
                                            dateTime={row.start_date}
                                            isTime="false"
                                            isRed="false"
                                          />{' '}
                                          -{' '}
                                          <DateTimeWithTimezone
                                            dateTime={row.end_date}
                                            isTime="false"
                                            isRed="true"
                                            start_date={row.start_date}
                                            end_date={row.end_date}
                                          />
                                        </span>
                                        <TableRowDateProgressBar
                                          start_date={row.start_date}
                                          end_date={row.end_date}
                                        />
                                      </td>
                                      <td align="center" width="15%">
                                        {row.tags}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {this.props.classes &&
                          this.props.classes.length === 0 &&
                          loadingTable === false && (
                            <div className="table-no-record">{`${config.tableNoRecord}`}</div>
                          )}
                      </div>
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  data: state.organizatoinClasses.data,
  classes: state.organizatoinClasses.classes,
  loading: state.organizatoinClasses.loading,
})

const mapDispatchToProps = dispatch => ({
  getClassesByOrganizationId: (organizationId, page, orderBy, order) => {
    dispatch(getClassesByOrganizationId(organizationId, page, orderBy, order))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Classes)
