import { GET_REPORT_DETAILS } from '../actions/report'

const organizationReportDetailReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_REPORT_DETAILS:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationReportDetailReducer
