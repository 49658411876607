import { GET_TEACHER_STATS } from '../../actions/instructors'

const teacherStatsReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_TEACHER_STATS:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default teacherStatsReducer
