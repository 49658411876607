import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import LoadingOverlay from 'react-loading-overlay'
import { getOrganizationList } from 'store/actions/organizations'
import config from 'config'
import DateTimeWithTimezone from 'components/DateTimeWithTimezone'
import OrganizationStats from './OrganizationStats'

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
    }

    const { getOrganizationList } = this.props
    const { page, orderBy, order } = this.state
    getOrganizationList(page, orderBy, order)
  }

  componentDidMount() {
    const { page } = this.state
    this.setState({ page: page + 1 })
  }

  handlePageChange = async e => {
    const { data, getOrganizationList } = this.props
    const { page, orderBy, order, counter } = this.state
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: page + 1 })
        if (data.totalPage > page) {
          this.setState({ counter: counter + 1 })
          await getOrganizationList(page, orderBy, order)
        }
      }
    }
  }

  handleSorting = async field => {
    const { getOrganizationList } = this.props
    const { orderBy, order: stateOrder } = this.state
    let order = 'ASC'
    if (orderBy === field) {
      order = stateOrder === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    await getOrganizationList(0, field, order)
    this.setState({ page: 1 })
  }

  render() {
    const { loading: loadingTable, stats, data, organizations } = this.props
    const { order, orderBy } = this.state
    const sortClass = order === 'ASC' ? 'ascending' : 'desending'
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row" />
        <div className="content-body">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <OrganizationStats stats={stats} />
              <div className="row match-height">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <h5 className="card-title text-bold-700 my-2">
                    Organizations
                    <Link
                      className="add_btn pull-right"
                      to={`${config.adminUrl}/add-new-organization`}
                    >
                      {' '}
                      Add Organization
                    </Link>
                  </h5>
                  <div className="card" style={{}}>
                    <div
                      className={
                        data && data.totalPage > 0
                          ? 'card-content '
                          : 'card-content'
                      }
                    >
                      <div
                        id="recent-projects"
                        className="media-list position-relative"
                        onScroll={this.handlePageChange.bind(this)}
                      >
                        <LoadingOverlay
                          active={loadingTable}
                          spinner
                          text={`${config.loadingOverlayText}`}
                        >
                          <div
                            className={
                              organizations.length === 0
                                ? 'table-responsive table-height'
                                : 'table-responsive'
                            }
                          >
                            {organizations && organizations.length > 0 && (
                              <div
                                id="tableContainer"
                                className="tableContainer"
                              >
                                <table
                                  className="scrollTable organization-list"
                                  width="100%"
                                  cellSpacing={0}
                                  cellPadding={0}
                                  border={0}
                                >
                                  <thead className="fixedHeader">
                                    <tr>
                                      <th
                                        align="left"
                                        width="15%"
                                        onClick={() =>
                                          this.handleSorting(
                                            'organization_name',
                                          )
                                        }
                                        className={
                                          orderBy === 'organization_name'
                                            ? sortClass
                                            : ''
                                        }
                                      >
                                        Name
                                        <i className="fas fa-sort-up" />
                                      </th>
                                      <th
                                        align="center"
                                        width="10%"
                                        onClick={() =>
                                          this.handleSorting('total_school')
                                        }
                                        className={
                                          orderBy === 'total_school'
                                            ? sortClass
                                            : ''
                                        }
                                      >
                                        Schools / Depts
                                        <i className="fas fa-sort-up" />
                                      </th>
                                      <th
                                        align="center"
                                        width="10%"
                                        onClick={() =>
                                          this.handleSorting('total_license')
                                        }
                                        className={
                                          orderBy === 'total_license'
                                            ? sortClass
                                            : ''
                                        }
                                      >
                                        Licenses
                                        <i className="fas fa-sort-up" />
                                      </th>
                                      <th
                                        align="center"
                                        width="15%"
                                        onClick={() =>
                                          this.handleSorting(
                                            'total_active_license',
                                          )
                                        }
                                        className={
                                          orderBy === 'total_active_license'
                                            ? sortClass
                                            : ''
                                        }
                                      >
                                        Assigned Licenses
                                        <i className="fas fa-sort-up" />
                                      </th>
                                      <th
                                        align="center"
                                        width="10%"
                                        onClick={() =>
                                          this.handleSorting('total_class')
                                        }
                                        className={
                                          orderBy === 'total_class'
                                            ? sortClass
                                            : ''
                                        }
                                      >
                                        Classes
                                        <i className="fas fa-sort-up" />
                                      </th>
                                      <th
                                        align="center"
                                        width="12%"
                                        onClick={() =>
                                          this.handleSorting('start_date')
                                        }
                                        className={
                                          orderBy === 'start_date'
                                            ? sortClass
                                            : ''
                                        }
                                      >
                                        Start Date
                                        <i className="fas fa-sort-up" />
                                      </th>
                                      <th
                                        align="center"
                                        width="12%"
                                        onClick={() =>
                                          this.handleSorting('end_date')
                                        }
                                        className={
                                          orderBy === 'end_date'
                                            ? sortClass
                                            : ''
                                        }
                                      >
                                        End Date
                                        <i className="fas fa-sort-up" />
                                      </th>
                                      <th
                                        align="center"
                                        width="10%"
                                        onClick={() =>
                                          this.handleSorting('status')
                                        }
                                        className={
                                          orderBy === 'status' ? sortClass : ''
                                        }
                                      >
                                        Status
                                        <i className="fas fa-sort-up" />
                                      </th>
                                      <th align="center" width="5%">
                                        View
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="scrollContent">
                                    {organizations &&
                                      organizations.length > 0 &&
                                      organizations.map(row => (
                                        <tr key={`organization-${row.id}`}>
                                          <td align="left" width="15%">
                                            <Link
                                              to={`${config.adminUrl}/organization/${row.id}`}
                                            >
                                              {row.organization_name}
                                            </Link>
                                          </td>
                                          <td align="center" width="10%">
                                            <NumberFormat
                                              thousandSeparator
                                              value={Number(row.total_school)}
                                              displayType="text"
                                            />
                                          </td>
                                          <td align="center" width="10%">
                                            <NumberFormat
                                              thousandSeparator
                                              value={Number(row.total_license)}
                                              displayType="text"
                                            />
                                          </td>
                                          <td align="center" width="15%">
                                            <NumberFormat
                                              thousandSeparator
                                              value={Number(
                                                row.total_active_license,
                                              )}
                                              displayType="text"
                                            />
                                          </td>
                                          <td align="center" width="10%">
                                            <NumberFormat
                                              thousandSeparator
                                              value={Number(row.total_class)}
                                              displayType="text"
                                            />
                                          </td>
                                          <td align="center" width="12%">
                                            <DateTimeWithTimezone
                                              dateTime={row.start_date}
                                              isTime="false"
                                              isRed="false"
                                            />
                                          </td>
                                          <td align="center" width="12%">
                                            <DateTimeWithTimezone
                                              dateTime={row.end_date}
                                              isTime="false"
                                              isRed="true"
                                              start_date={row.start_date}
                                              end_date={row.end_date}
                                            />
                                          </td>
                                          <td align="center" width="10%">
                                            <span
                                              className={
                                                row.status === 'Active'
                                                  ? 'status_approved'
                                                  : 'status_pending'
                                              }
                                            >
                                              {row.status}
                                            </span>
                                          </td>
                                          <td align="center" width="5%">
                                            <Link
                                              to={`${config.adminUrl}/organization/${row.id}`}
                                            >
                                              <i className="fa fa-eye" />
                                            </Link>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                            {organizations &&
                              organizations.length === 0 &&
                              loadingTable === false && (
                                <div className="table-no-record">{`${config.tableNoRecord}`}</div>
                              )}
                          </div>
                        </LoadingOverlay>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DashboardContainer.defaultProps = {
  loading: false,
  data: {},
  organizations: [],
  stats: {},
}

DashboardContainer.propTypes = {
  getOrganizationList: PropTypes.func.isRequired,
  data: PropTypes.shape({
    totalPage: PropTypes.number,
  }),
  loading: PropTypes.bool,
  organizations: PropTypes.arrayOf(PropTypes.object),
  stats: PropTypes.shape({}),
}

const mapStateToProps = state => ({
  data: state.organizationList.data,
  organizations: state.organizationList.organizations,
  loading: state.organizationList.loading,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationList: (page, orderBy, order) => {
    dispatch(getOrganizationList(page, orderBy, order))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
