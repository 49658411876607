/* TODO: Fix these eslint-disable */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Modal } from 'react-bootstrap'
import { getTranslate, withLocalize } from 'react-localize-redux'
import Loader from 'components/Loader'
import RenderField from 'components/RenderFieldTranlate'
import RenderTextField from 'components/RenderTextFieldTranlate'
import FileInput from 'components/RenderFileInputTranlate'
import { getTeacherCountries } from 'store/actions/country'

import validateForm from 'pages/Teacher/Home/Profile/EditProfileModelBox/helpers'

import englishFormTranslations from 'lang/organization/form/en.json'
import spanishFormTranslations from 'lang/organization/form/es.json'
import chineseFormTranslations from 'lang/organization/form/zh.json'

class EditProfileModelBox extends React.Component {
  constructor(props) {
    super(props)
    const { addTranslationForLanguage, getTeacherCountries } = this.props
    addTranslationForLanguage(englishFormTranslations, 'en')
    addTranslationForLanguage(spanishFormTranslations, 'es')
    addTranslationForLanguage(chineseFormTranslations, 'zh')
    getTeacherCountries(this.props)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      handleCancel,
      isShowEditProfile,
      handleEditProfileModelClose,
      handleInputChange,
      imagePreviewUrl,
      translate,
      row
    } = this.props

    return (
      <Modal
        show={isShowEditProfile}
        onHide={handleEditProfileModelClose}
        animation={false}
        id="changePassword"
        dialogClassName=""
        aria-labelledby="basicModalLabel1"
      >
        <div className="modal-content">
          <form className="form-horizontal" onSubmit={handleSubmit} autoComplete="off">
            <div className="modal-header">
              <h4 className="modal-title" id="basicModalLabel1">
                {translate('formInstructorProfileTitleEdit')}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={handleEditProfileModelClose}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="article_hidden" className="modal-single">
                <div className="banner_pic">
                  <div className="upload-wrap">
                    <div
                      className="uploadpreview 01"
                      style={{ backgroundImage: `url(${imagePreviewUrl})` }}
                    />
                    <Field
                      component={FileInput}
                      name="image"
                      type="file"
                      accept=".png,.jpeg,.jpg"
                      onChange={handleInputChange.bind(this)}
                    />
                    <label>{translate('formInstructorEditImage')}</label>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="form-group">
                <h4 className="org-id">
                  {translate('id')}
                  :
                  {row && row.id}
                </h4>
              </div>
              <div className="form-group">
                <label htmlFor="name">
                  <span>*</span>
                  {translate('formInstructorName')}
                </label>
                <Field
                  type="text"
                  className="form-control round"
                  placeholder={translate('formInstructorName')}
                  component={RenderField}
                  name="name"
                  id="name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  <span>*</span>
                  {translate('formInstructorEmail')}
                </label>
                <Field
                  type="text"
                  className="form-control round"
                  placeholder={translate('formInstructorEmail')}
                  component={RenderField}
                  name="email"
                  id="email"
                  disabled="disabled"
                />
              </div>
              <div className="form-group">
                <label htmlFor="bio">{translate('formInstructorBio')}</label>
                <Field
                  name="bio"
                  component={RenderTextField}
                  placeholder={translate('formInstructorBio')}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={pristine || submitting ? handleEditProfileModelClose : handleCancel}
              >
                {translate('formChangePassworBtnCancel')}
              </button>
              <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
                {submitting ? <Loader /> : translate('formChangePassworBtnSave')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

EditProfileModelBox.defaultProps = {
  pristine: false,
  submitting: false,
  isShowEditProfile: false,
  imagePreviewUrl: '',
  row: {},
}

EditProfileModelBox.propTypes = {
  translate: PropTypes.func.isRequired,
  addTranslationForLanguage: PropTypes.func.isRequired,
  getTeacherCountries: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleEditProfileModelClose: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  isShowEditProfile: PropTypes.bool,
  imagePreviewUrl: PropTypes.string,
  row: PropTypes.shape({
    id: PropTypes.number,
  }),
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  initialValues: state.teacherProfile.data.row,
  row: state.teacherProfile.data.row,
  countries: state.country.data.countries,
})

const mapDispatchToProps = dispatch => ({
  getTeacherCountries: props => dispatch(getTeacherCountries(props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(
  reduxForm({
    form: 'teacher-change-profile',
    validate: validateForm,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(EditProfileModelBox),
))