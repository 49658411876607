import { GET_SCHOOL_DETAILS_BY_SCHOOL_ID } from '../actions/school'

const organizationSchoolDetailReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_SCHOOL_DETAILS_BY_SCHOOL_ID:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationSchoolDetailReducer
