import isEmail from 'validator/lib/isEmail'

const validateForm = values => {
  const errors = {}

  // name
  if (!values.name) {
    errors.name = [`errorMsgInstructorServiceCreateNameMissing`]
  } else if (values.name.length < 2) {
    errors.name = [`errorMsgInstructorServiceCreateNameIsLength`]
  } else if (values.name.length > 50) {
    errors.name = [`errorMsgInstructorServiceCreateNameIsLength`]
  }

  // email
  if (!values.email) {
    errors.email = [`errorMsgInstructorServiceCreateEmailMissing`]
  } else if (!isEmail(values.email)) {
    errors.email = [`errorMsgInstructorServiceCreateEmailIsEmail`]
  } else if (values.email.length > 100) {
    errors.phone = [`errorMsgInstructorServiceCreateEmailIsLength`]
  }

  // email
  // if (!values.image) {
  //   errors.image = [`errorMsgInstructorServiceCreateImageMissing`];
  // }

  return errors
}

export default validateForm
