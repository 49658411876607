/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import moment from 'moment'
import MomentR from 'react-moment'
import Loader from '../../../../components/Loader'
import RenderField from '../../../../components/RenderField'
import RenderSelectField from '../../../../components/RenderSelectField'
import FileInput from '../../../../components/RenderFileInput'
import RenderDateStartPicker from '../../../../components/RenderDateRangePicker/start'
import RenderDateEndtPicker from '../../../../components/RenderDateRangePicker/end'
import { getOrganizationDetails } from '../../../../store/actions/organizations'
import DisplayMobileFormat from '../../../../components/DisplayMobileFormat'
import { getStates } from '../../../../store/actions/country'
import validateEditOrganizationForm from './helpers'
import config from '../../../../config'
import { dispatchFormField } from '../../../../store/actions/form'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    // let date =  new Date();
    const date = moment().format('YYYY-MM-DD')
    this.state = {
      startDate: new Date(date),
      endDate: new Date(date),
      today: new Date(date),
      countryFlag: 0,
    }
  }

  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate
    endDate = endDate || this.state.endDate
    this.setState({ startDate, endDate })
  }

  handleChangeStart = startDate => this.handleChange({ startDate })

  handleChangeEnd = endDate => this.handleChange({ endDate })

  handleShowStates = async e => {
    this.setState({ countryFlag: 1 })
    this.props.dispatchFormField('edit-organization-form', 'state', '')
    await this.props.getStates(e.target.value)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      row,
      countries,
      countriesCode,
      handleInputChange,
      handleCancel,
      handleRedirect,
      handleDownload,
      states,
    } = this.props
    const filePaths = row && row.documents_path != null ? JSON.parse(row.documents_path) : ''
    if (this.state.countryFlag === 0 && row && row.country_id) {
      this.props.getStates(row.country_id)
    }
    return (
      <div className="content-body">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="row match-height">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-content collapse show">
                    <div className="card-body">
                      <form
                        className="form edit_organization_form"
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                        autoComplete="off"
                      >
                        <div className="form-body">
                          <div className="col-4">
                            <h4>Company</h4>
                            <div className="form-group">
                              <label htmlFor="organization_name">
                                <span>*</span>
                                Organization Name
                              </label>
                              <Field
                                name="organization_name"
                                component={RenderField}
                                type="text"
                                placeholder="Organization Name"
                                value="Test1"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="address_1">
                                <span>*</span>
                                Address Line 1
                              </label>
                              <Field
                                name="address_1"
                                component={RenderField}
                                type="text"
                                placeholder="Address Line 1"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="address_2">Address Line 2</label>
                              <Field
                                className="form-control square"
                                name="address_2"
                                component={RenderField}
                                type="text"
                                placeholder="Address Line 2"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="address_3">Address Line 3</label>
                              <Field
                                className="form-control square"
                                name="address_3"
                                component={RenderField}
                                type="text"
                                placeholder="Address Line 3"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="country_id">
                                <span>*</span>
                                Country
                              </label>
                              <Field
                                name="country_id"
                                component={RenderSelectField}
                                onChange={this.handleShowStates.bind(this)}
                              >
                                <option disabled value="">
                                  Countries
                                </option>
                                {countries &&
                                  countries.map(country => (
                                    <option key={`country-${country.id}`} value={country.id}>{country.name}</option>
                                  ))}
                              </Field>
                            </div>
                            <div className="form-group">
                              <label htmlFor="state">
                                <span>*</span>
                                State
                              </label>
                              <Field name="state" component={RenderSelectField}>
                                <option value="">State</option>
                                {states &&
                                  states.map(state => (
                                    <option key={`region-${state.id}`} value={state.id}>{state.name}</option>
                                  ))}
                              </Field>
                            </div>
                            <div className="form-group">
                              <label htmlFor="city">
                                <span>*</span>
                                City
                              </label>
                              <Field
                                name="city"
                                component={RenderField}
                                type="text"
                                placeholder="City"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="zip_code">
                                <span>*</span>
                                ZIP/Postal Code
                              </label>
                              <Field
                                name="zip_code"
                                component={RenderField}
                                type="text"
                                placeholder="Zip/Postal Code"
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <h4>Contact</h4>
                            <div className="form-group">
                              <label htmlFor="name">
                                <span>*</span>
                                Primary Contact Name
                              </label>
                              <Field
                                name="name"
                                component={RenderField}
                                type="text"
                                placeholder="Primary Contact Name"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="email">
                                <span>*</span>
                                Email
                              </label>
                              <Field
                                name="email"
                                component={RenderField}
                                type="email"
                                placeholder="Email"
                              />
                            </div>
                            <div className="form-group div_phone_admin">
                              <label htmlFor="phone">
                                <span>*</span>
                                Phone
                              </label>
                              <Field
                                className="div_phone_admin"
                                name="phone_country_code"
                                component={RenderSelectField}
                              >
                                <option disabled value="">
                                  Country Code
                                </option>
                                {countriesCode &&
                                  countriesCode.map(country => (
                                    <React.Fragment key={`country-${country.dial_code}`}>
                                      {country.dial_code !== null && (
                                        <option value={country.dial_code}>
                                          +{country.dial_code}
                                        </option>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Field>
                              <Field
                                name="phone"
                                component={RenderField}
                                type="tel"
                                placeholder="Phone"
                              />
                              <div className="clearfix" />
                            </div>
                            <div className="form-group div_phone_admin">
                              <label htmlFor="fax">Fax</label>
                              <Field
                                className="div_phone_admin"
                                name="fax_country_code"
                                component={RenderSelectField}
                              >
                                <option disabled value="">
                                  Country Code
                                </option>
                                {countriesCode &&
                                  countriesCode.map(country => (
                                    <React.Fragment key={`fax-country-${country.dial_code}`}>
                                      {country.dial_code !== null && (
                                        <option value={country.dial_code}>
                                          +{country.dial_code}
                                        </option>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Field>
                              <Field
                                name="fax"
                                component={RenderField}
                                type="tel"
                                placeholder="Fax"
                              />
                              <div className="clearfix" />
                            </div>
                            <h4>Status</h4>
                            <div className="form-group">
                              <label htmlFor="status">
                                <span>*</span>
                                Status
                              </label>
                              <Field name="status" component={RenderSelectField}>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </Field>
                            </div>
                          </div>
                          <div className="col-4 edit-calendar">
                            <h4>Contract</h4>
                            <div className="form-group date_ranger">
                              <label>
                                <span>*</span>
                                Start Date
                              </label>
                              <div className="input-group">
                                <Field
                                  name="contract_start_date"
                                  component={RenderDateStartPicker}
                                  id="contract_start_date"
                                  placeholder="Start Date"
                                  required
                                  selected={this.state.startDate}
                                  selectsStart
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  onChange={this.handleChangeStart}
                                  required
                                  readonly
                                  edit="true"
                                  dbDate={
                                    this.props.orgRow && this.props.orgRow.contract_start_date
                                  }
                                  formName="edit-organization-form"
                                  fieldName="contract_start_date"
                                />
                              </div>
                            </div>

                            <div className="form-group date_ranger">
                              <label>
                                <span>*</span>
                                End Date
                              </label>
                              <div className="input-group">
                                <Field
                                  name="contract_end_date"
                                  component={RenderDateEndtPicker}
                                  id="contract_end_date"
                                  placeholder="End Date"
                                  selected={this.state.endDate}
                                  selectsEnd
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  onChange={this.handleChangeEnd}
                                  required
                                  readonly
                                  dbDate={this.props.orgRow && this.props.orgRow.contract_end_date}
                                  formName="edit-organization-form"
                                  fieldName="contract_end_date"
                                  edit="true"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="total_license">
                                <span>*</span>
                                License Count
                              </label>
                              <Field
                                name="total_license"
                                component={RenderField}
                                type="text"
                                placeholder="License Count(20000)"
                              />
                            </div>
                            <div className="pdf_container">
                              <ul>
                                {filePaths &&
                                  filePaths.map(file => (
                                    <li key={`file-${file.id}`}>
                                      <Link to="#" onClick={() => handleDownload(file)}>
                                        {' '}
                                        <i className="fa fa-file-pdf pdf_files_sm" />
                                        <MomentR format={`${config.dateFormat}`}>
                                          {file.start_date}
                                        </MomentR>
                                        {file.end_date && (
                                          <React.Fragment>
                                            <span> - </span>
                                            <MomentR format="MMM DD,YYYY">{file.end_date}</MomentR>
                                          </React.Fragment>
                                        )}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            <div className="form-group">
                              <label>PDF Contract Upload</label>
                              <Field
                                component={FileInput}
                                name="document"
                                type="file"
                                accept=".pdf"
                                onChange={handleInputChange.bind(this)}
                              />
                            </div>
                            <div className="form-actions right org_btns">
                              {/* {error &&  <p className="text-danger font-weight-bold mb-2">{error}</p>} */}
                              <button
                                type="button"
                                className="btn btn-danger mr-1"
                                onClick={pristine || submitting ? handleRedirect : handleCancel}
                              >
                                <i className="ft-x" /> Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={pristine || submitting}
                              >
                                <i className="la la-check-square-o" />{' '}
                                {submitting ? <Loader /> : 'Save'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditForm = reduxForm({
  form: 'edit-organization-form',
  validate: validateEditOrganizationForm,
})(EditForm)

const mapStateToProps = state => ({
  states: state.adminStateList.data.states,
  orgRow: state.organizationDetail.data.row,
  initialValues: state.organizationDetail.data.row,
})

const mapDispatchToProps = dispatch => ({
  getStates: country_id => dispatch(getStates(country_id)),
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditForm)

// EditForm = connect(
//   state => ({
//     states:state.adminStateList.data.states,
//     initialValues: state.organizationDetail.data.row // pull initial values from account reducer
//   })
// )(EditForm)

// //  getStates: (country_id) => dispatch(getStates(country_id)),
// export default EditForm;
