/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import Loader from '../../../../../components/Loader'
import RenderField from '../../../../../components/RenderField'
import validateForm from './helpers'

class EditFrom extends React.Component {
  render() {
    const { pristine, submitting, row, handleSubmit, handleRedirect, handleCancel } = this.props
    return (
      <div className="row">
        <div className="col-12">
          <h4 className="card-title">Admin Profile</h4>
          <form className="form" onSubmit={handleSubmit} autoComplete="off">
            <div className="card pull-up">
              <div className="card-header">
                <h6 className="card-title c_title_t">
                  Id:
                  {row && row.id}
                </h6>
                <br />
                <div className="form-group">
                  <label htmlFor="name">
                    <span>*</span>
                    Name
                  </label>
                  <Field name="name" component={RenderField} type="text" placeholder="Name" />
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    <span>*</span>
                    Email
                  </label>
                  <Field name="email" component={RenderField} type="text" placeholder="Name" />
                </div>
              </div>
              <div className="card-content text-center">
                <div className="card-body pt-0 pb-1">
                  <button
                    type="button"
                    className="btn btn-danger mr-1"
                    onClick={pristine || submitting ? handleRedirect : handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn grey btn-primary"
                    disabled={pristine || submitting}
                  >
                    {submitting ? <Loader /> : 'Save'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

EditFrom = reduxForm({
  form: 'edit-admin-profile-form',
  validate: validateForm,
  enableReinitialize: true,
})(EditFrom)

const mapStateToProps = state => ({
  // user: state.adminUserProfile.user,
  initialValues: state.adminUserProfile.data.row,
})

export default connect(mapStateToProps)(EditFrom)
