import React from 'react'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import TableRowLicenseProgressBar from 'components/TableRowLicenseProgressBar'

const Details = ({ row, organizationId }) => (
  <div className="row">
    <div className="col-md-12 col-lg-8">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <h5 className="card-title text-bold-700 my-2">School/Dept</h5>
          <div className="card">
            <div className="card-content">
              <div className="media-list position-relative">
                <div className="table-responsive">
                  <table className="table table-padded table-xl mb-0" id="recent-project-table">
                    <thead>
                      <tr>
                        <th className="border-top-0">School Name</th>
                        <th className="border-top-0">Principal</th>
                        <th className="border-top-0">Teachers</th>
                        <th className="border-top-0">Classes</th>
                        <th className="border-top-0">Licenses Activated</th>
                        <th className="border-top-0">Tags</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-truncate align-middle">{row && row.school_name}</td>
                        <td className="text-truncate">{row && row.principal_name}</td>
                        <td className="text-truncate">
                          <NumberFormat
                            thousandSeparator
                            value={row && row.total_instructors ? row.total_instructors : 0}
                            displayType="text"
                          />
                        </td>
                        <td className="text-truncate">
                          <NumberFormat
                            thousandSeparator
                            value={row && row.total_class ? row.total_class : 0}
                            displayType="text"
                          />
                        </td>
                        <td className="text-truncate">
                          <span className="text-center">
                            {' '}
                            <NumberFormat
                              thousandSeparator
                              value={row && row.total_active_license ? row.total_active_license : 0}
                              displayType="text"
                            />{' '}
                            /{' '}
                            <NumberFormat
                              thousandSeparator
                              value={row && row.total_license ? row.total_license : 0}
                              displayType="text"
                            />
                          </span>
                          <TableRowLicenseProgressBar
                            activeLicense={row && row.total_active_license}
                            totalLicense={row && row.total_license}
                          />
                        </td>
                        <td className="text-truncate pb-0">
                          <p className="font-small-2 text-muted"> {row && row.tags}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-12 col-lg-4">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Reports and Analytics</h4>
        </div>
        <div className="card-content collapse show">
          <div className="card-body p-0 pb-0">
            <div className="chartist">
              <Link
                className="add_btn pull-right generate_report"
                to={`/organization/${organizationId}/create-report`}
              >
                Generate Report
              </Link>
              <div id="project-stats" className="height-200 areaGradientShadow1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default Details
