import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'

import { getTranslate } from 'react-localize-redux'

const PrivacyModalBox = ({
  isPrivacyModalBoxShown,
  handlePrivacyModalBoxClose,
}) => {
  const translate = useSelector(state => getTranslate(state.localize))

  return (
    <Modal
      show={isPrivacyModalBoxShown}
      onHide={handlePrivacyModalBoxClose}
      animation={false}
      id="privacy-policy"
      dialogClassName=""
      aria-labelledby="basicModalLabel1"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="basicModalLabel1">
            {translate('formStudentPrivacyPolicyTitle')}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={handlePrivacyModalBoxClose}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          {translate('formStudentPrivacyPolicyContent')}
        </div>
      </div>
    </Modal>
  )
}

PrivacyModalBox.propTypes = {
  isPrivacyModalBoxShown: PropTypes.func.isRequired,
  handlePrivacyModalBoxClose: PropTypes.func.isRequired,
}

export default PrivacyModalBox
