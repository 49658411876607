/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import OrganizationCommonDetail from 'pages/Home/Common/Organization'
import InstructorList from './Instructors'
import ClassList from './Classes'
import Details from './Details'
import { getDetailByschoolId } from 'store/actions/school'
import config from 'config'

class SchoolDetail extends React.Component {
  constructor(props) {
    super(props)

    const { match, history, getDetailByschoolId } = this.props
    const organizationId = match.params.id
    const schoolId = match.params.sid
    if (organizationId === ' ' || organizationId === 'undefined') {
      history.push(`${config.adminUrl}/home`)
    }
    if (schoolId === ' ' || schoolId === 'undefined') {
      history.push(`${config.adminUrl}/home`)
    }
    this.state = {
      organizationId,
      schoolId,
    }
    getDetailByschoolId(organizationId, schoolId)
  }

  render() {
    const { organizationId, schoolId } = this.state
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">View School/Dept</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.adminUrl}/organization/${organizationId}`}
                    >
                      Organization
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">View School/Dept</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <OrganizationCommonDetail />
          <Details
            row={this.props.organizationSchool.row}
            organizationId={organizationId}
          />
          <InstructorList organizationId={organizationId} schoolId={schoolId} />
          <ClassList organizationId={organizationId} schoolId={schoolId} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  organizationSchool: state.organizationSchoolDetail.data,
})

const mapDispatchToProps = dispatch => ({
  getDetailByschoolId: (organizationId, schoolId) => {
    dispatch(getDetailByschoolId(organizationId, schoolId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDetail)
