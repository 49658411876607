import React from 'react'
import PropTypes from 'prop-types'
import Chart from 'react-google-charts'
import { connect } from 'react-redux'

import { getTranslate } from 'react-localize-redux'

const PhonemeScoresChart = props => {
  const { translate, data, width } = props
  return (
    <Chart
      className="chart_student"
      chartType="ColumnChart"
      width={`${width}px`}
      height="400px"
      position="relative"
      data={data}
      loader={<div>{translate('loadingOverlayText')}</div>}
      options={{
        intervals: { style: 'sticks' },
        legend: 'none',
        chartArea: { width: '88%' },
      }}
    />
  )
}

PhonemeScoresChart.propTypes = {
  translate: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default connect(mapStateToProps)(PhonemeScoresChart)
