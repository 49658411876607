/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { reduxForm, Field, reset } from 'redux-form'
import { Modal } from 'react-bootstrap'
import { getTranslate } from 'react-localize-redux'
import Loader from '../../../../../components/Loader'
import FileInput from '../../../../../components/RenderFileInputTranlate'

/**
 * import translation related files
 *
 */

class CreateNewMultipleFormModelBox extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      error,
      reset,
      handleCancel,
      isShowNewMultipleInstructorForm,
      handleNewMultipleInstructorModelClose,
      translate,
      tags,
      handleCreateTag,
      principalProfile,
      handleInputChange,
      handleSingleInstructor,
      downloadSampelXLSX,
      downloadSampelCSV,
    } = this.props

    return (
      <Modal
        show={isShowNewMultipleInstructorForm}
        onHide={handleNewMultipleInstructorModelClose}
        animation={false}
        dialogClassName=""
        aria-labelledby="basicModalLabel1"
      >
        <div className="modal-content">
          <form
            className="form-horizontal"
            onSubmit={handleSubmit}
            autoComplete="off"
            encType="multipart/form-data"
          >
            <div className="modal-header">
              <h4 className="modal-title" id="basicModalLabel1">
                {translate('formInstructorMuiltipleAdd')}
              </h4>
              <button
                type="button"
                className="close"
                onClick={handleNewMultipleInstructorModelClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="articleHiddendivshown" className="modal-multiple">
                {translate('formInstructorAddMuiltipleDescription')}
                <span className="exmp">
                  {translate('formInstructorAddMuiltipleExampleXLS')}{' '}
                  <Link to="#" onClick={downloadSampelXLSX}>
                    {translate('formInstructorAddMuiltipleTitleA')}
                  </Link>
                </span>
                <span className="exmp">
                  {translate('formInstructorAddMuiltipleExampleCSV')}{' '}
                  <Link to="#" onClick={downloadSampelCSV}>
                    {translate('formInstructorAddMuiltipleTitleA')}
                  </Link>
                </span>
                <div className="form-group">
                  <label>
                    <span>*</span>
                    {translate('formInstructorAddMuiltipleExt')}
                  </label>
                  <Field
                    component={FileInput}
                    name="file"
                    type="file"
                    accept=".csv,.xlsx"
                    required
                    onChange={handleInputChange.bind(this)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={
                  pristine || submitting
                    ? handleNewMultipleInstructorModelClose
                    : handleCancel
                }
              >
                {translate('formInstructorBtnCancel')}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {submitting ? <Loader /> : translate('formInstructorBtnCreate')}
              </button>
            </div>
            <div className="modal-texts text-center">
              <p>
                {translate('formInstructorAddSingleTitle')}{' '}
                <Link
                  className="multiple_i_show"
                  onClick={handleSingleInstructor}
                >
                  {translate('formInstructorAddMuiltipleTitleA')}
                </Link>
              </p>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

CreateNewMultipleFormModelBox = reduxForm({
  form: 'principal-create-instructor-multiple',
  enableReinitialize: true,
})(CreateNewMultipleFormModelBox)

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  principalProfile: state.principalProfile.data.row,
})

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset('principal-create-instructor-multiple')),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewMultipleFormModelBox)
