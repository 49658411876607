/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import moment from 'moment'
import { getTranslate } from 'react-localize-redux'
import TableRowDateProgressBar from '../../../../../components/TableRowDateProgressBar'
import config from '../../../../../config'
import DateTimeWithTimezone from '../../../../../components/DateTimeWithTimezone'

/**
 * import translation related files
 *
 */

class ClassDetail extends React.Component {
  render() {
    const { row, translate, sessions } = this.props
    return (
      <div className="row match-height">
        <div className="col-md-12 col-lg-12 pull-left">
          <h5 className="card-title text-bold-700 my-2">
            View Class
            <a
              className="add_btn pull-right"
              href={`${config.studentUrl}/pronunciation`}
            >
              {translate('studentDetailRowView')}
            </a>
          </h5>
          <div className="card">
            <div className="card-content collapse show">
              <div className="card-footer text-center p-1">
                <div className="row">
                  <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-left">
                    <p className="blue-grey lighten-2 mb-0">
                      {translate('classDetailRowName')}
                    </p>
                    <p className="font-medium-6 text-bold-400">
                      {row && row.name}
                    </p>
                  </div>
                  <div className="col-md-6 col-12 text-left">
                    <p className="blue-grey lighten-2 mb-0">
                      {translate('studentTableRowSession')}
                    </p>
                    <div className="scroll_sessions">
                      {sessions &&
                        sessions.map(session => (
                          <p className="font-medium-6 text-bold-400">
                            <DateTimeWithTimezone
                              dateTime={session.created_at}
                              isTime="false"
                              isRed="false"
                            />
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-left">
                    <p className="blue-grey lighten-2 mb-0">
                      {translate('classDetailRowDescription')}
                    </p>
                    <p className="font-medium-6 text-bold-400">
                      {row && row.description}
                    </p>
                  </div>
                  <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-left">
                    <div className="row">
                      <div className="col-12">
                        <div className="card pull-up">
                          <div className="card-header">
                            <h6 className="card-title float-left">
                              {translate('classDetailRowStartDate')}
                              <span className="start_date_color">
                                <DateTimeWithTimezone
                                  dateTime={row && row.start_date}
                                  isTime="false"
                                  isRed="false"
                                />
                              </span>
                            </h6>
                            <h6 className="card-title float-right">
                              {translate('classDetailRowEndDate')}
                              <span className="end_date_color">
                                <DateTimeWithTimezone
                                  dateTime={row && row.end_date}
                                  isTime="false"
                                  isRed="true"
                                  start_date={row && row.start_date}
                                  end_date={row && row.end_date}
                                />
                              </span>
                            </h6>
                          </div>
                          <div className="card-content collapse show">
                            <div className="card-body pt-0 pb-1">
                              <TableRowDateProgressBar
                                start_date={row && row.start_date}
                                end_date={row && row.end_date}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  row: state.studentProfile.data.classRow,
  sessions: state.studentProfile.data.sessions,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetail)
