import { PRINCIPAL_LOGIN_USER, PRINCIPAL_LOGOUT_USER } from '../../actions/auth'

const authPrincipal = JSON.parse(localStorage.getItem('authPrincipal'))

const authPrincipalReducer = (
  state = {
    user: authPrincipal ? authPrincipal.user : null,
    accessToken: authPrincipal ? authPrincipal.token : null,
  },
  action,
) => {
  switch (action.type) {
    case PRINCIPAL_LOGIN_USER:
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.token,
      }
    case PRINCIPAL_LOGOUT_USER:
      return {
        ...state,
        user: null,
        accessToken: null,
      }
    default:
      return state
  }
}

export default authPrincipalReducer
