import React from 'react'
import PropTypes from 'prop-types'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { connect } from 'react-redux'
import config from 'config'

import IndexLayoutRoute from 'layouts/Index'
import LoginLayoutRoute from 'layouts/Login'
import DashboardLayoutRoute from 'layouts/Dashboard'
import OrganizationDashboardRoute from 'layouts/OrganizationDashboard'
import PrincipalDashboardRoute from 'layouts/PrincipalDashboard'
import TeacherDashboardRoute from 'layouts/TeacherDashboard'
import StudentDashboardRoute from 'layouts/StudentDashboard'

import NotFound from 'pages/NotFound'

import RootHomePage from 'pages/Root/Home'
import RootAboutUsPage from 'pages/Root/AboutUs'
import RootContactUsPage from 'pages/Root/ContactUs'
import RootPrivacyPolicyPage from 'pages/Root/PrivacyPolicy'
import RootTermsConditionPage from 'pages/Root/TermsCondition'

import StudentLoginPage from 'pages/Student/Login'
import StudentRegisterPage from 'pages/Student/Register'
import StudentDashboardPage from 'pages/Student/Home/Dashboard'
import StudentAggregateDataPage from 'pages/Student/Home/Pronunciation'

import OrganizationLoginPage from 'pages/Organization/Login'
import OrganizationRecoverPasswordPage from 'pages/Organization/RecoverPassword'
import OrganizationDashboardPage from 'pages/Organization/Home/Dashboard'
import OrganizationSchoolsPage from 'pages/Organization/Home/Schools'
import OrganizationSchoolDetailsPage from 'pages/Organization/Home/Schools/Details'
import OrganizationSchoolEditPage from 'pages/Organization/Home/Schools/Edit'
import OrganizationPrincipalPage from 'pages/Organization/Home/Principal'
import OrganizationPrincipalDetailsPage from 'pages/Organization/Home/Principal/Details'
import OrganizationPrincipalEditPage from 'pages/Organization/Home/Principal/Edit'
import OrganizationProfilePage from 'pages/Organization/Home/Profile'
import OrganizationInstructorsPage from 'pages/Organization/Home/Instructors'
import OrganizationInstructorsDetailsPage from 'pages/Organization/Home/Instructors/Details'
import OrganizationClassesPage from 'pages/Organization/Home/Classes'
import OrganizationClassesDetailsPage from 'pages/Organization/Home/Classes/Details'
import OrganizationReportsPage from 'pages/Organization/Home/Reports'
import OrganizationCreateReportPage from 'pages/Organization/Home/Reports/Create'
import OrganizationReportsDetailsPage from 'pages/Organization/Home/Reports/Details'

import PrincipalLoginPage from 'pages/Principal/Login'
import PrincipalRecoverPasswordPage from 'pages/Principal/RecoverPassword'
import PrincipalDashboardPage from 'pages/Principal/Home/Dashboard'
import PrincipalInstructorsPage from 'pages/Principal/Home/Instructors'
import PrincipalInstructorsDetailsPage from 'pages/Principal/Home/Instructors/Details'
import PrincipalClassesPage from 'pages/Principal/Home/Classes'
import PrincipalClassesDetailsPage from 'pages/Principal/Home/Classes/Details'
import PrincipalReportsPage from 'pages/Principal/Home/Reports'
import PrincipalCreateReportPage from 'pages/Principal/Home/Reports/Create'
import PrincipalReportsDetailsPage from 'pages/Principal/Home/Reports/Details'

import TeacherLoginPage from 'pages/Teacher/Login'
import TeacherRecoverPasswordPage from 'pages/Teacher/RecoverPassword'
import TeacherDashboardPage from 'pages/Teacher/Home/Dashboard'
import TeacherClassesPage from 'pages/Teacher/Home/Classes'
import TeacherClassesDetailsPage from 'pages/Teacher/Home/Classes/ClassDetails'
import TeacherStudentsPage from 'pages/Teacher/Home/Students'
import TeacherStudentDetailPage from 'pages/Teacher/Home/Students/Details'
import TeacherStudentEditPage from 'pages/Teacher/Home/Students/Edit'

import TeacherReportsPage from 'pages/Teacher/Home/Reports'

import LoginPage from 'pages/Login'
import RecoverPasswordPage from 'pages/Login/RecoverPassword'
import DashboardPage from 'pages/Home/Dashboard'
import AddNewOrganizationPage from 'pages/Home/NewOrganization'
import OrganizationDetailPage from 'pages/Home/OrganizationDetail'
import EditOrganizationPage from 'pages/Home/EditOrganization'
import OrganizationSchoolDetailPage from 'pages/Home/OrganizationDetail/Schools/SchoolDetail'
import InstructorPage from 'pages/Home/Organization/Instructor'
import InstructorDetailPage from 'pages/Home/Organization/Instructor/Details'
import ClassesPage from 'pages/Home/Organization/Classes'
import ClassDetailPage from 'pages/Home/Organization/Classes/Details'
import ReportPage from 'pages/Home/Organization/Report'
import ReportDetailsPage from 'pages/Home/Organization/Report/Details'
import CreateReportPage from 'pages/Home/Organization/CreateReport'
import ProfilePage from 'pages/Home/Profile'

import { initializeLanguage } from 'store/actions/language'

class App extends React.Component {
  constructor(props) {
    super(props)

    const { initializeLanguage } = this.props
    initializeLanguage()
  }

  render() {
    return (
      <Router>
        <Switch>
          {/** * To index page or fist page * */}
          <IndexLayoutRoute path="/" exact component={RootHomePage} />
          <IndexLayoutRoute
            path={`${config.aboutUsUrl}`}
            exact
            component={RootAboutUsPage}
          />
          <IndexLayoutRoute
            path={`${config.contactUsUrl}`}
            exact
            component={RootContactUsPage}
          />
          <IndexLayoutRoute
            path={`${config.termofUseUrl}`}
            exact
            component={RootTermsConditionPage}
          />
          <IndexLayoutRoute
            path={`${config.privacyPolicyUrl}`}
            exact
            component={RootPrivacyPolicyPage}
          />
          {/** * To define organization routes * */}
          <Route exact path={`${config.organizationUrl}`}>
            <Redirect to={`${config.organizationUrl}/login`} />
          </Route>
          <LoginLayoutRoute
            path={`${config.organizationUrl}/login`}
            component={OrganizationLoginPage}
          />
          <LoginLayoutRoute
            path={`${config.organizationUrl}/recover-password`}
            exact
            component={OrganizationRecoverPasswordPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/home`}
            exact
            component={OrganizationDashboardPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/schools`}
            exact
            component={OrganizationSchoolsPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/school/:sid`}
            exact
            component={OrganizationSchoolDetailsPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/edit-school/:sid`}
            exact
            component={OrganizationSchoolEditPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/principal`}
            exact
            component={OrganizationPrincipalPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/principal/:pid`}
            exact
            component={OrganizationPrincipalDetailsPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/edit-principal/:pid`}
            exact
            component={OrganizationPrincipalEditPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/edit`}
            exact
            component={OrganizationProfilePage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/instructors`}
            exact
            component={OrganizationInstructorsPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/instructor/:iid`}
            exact
            component={OrganizationInstructorsDetailsPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/classes`}
            exact
            component={OrganizationClassesPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/class/:cid`}
            exact
            component={OrganizationClassesDetailsPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/reports`}
            exact
            component={OrganizationReportsPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/create-report`}
            exact
            component={OrganizationCreateReportPage}
          />
          <OrganizationDashboardRoute
            path={`${config.organizationUrl}/report/:rid`}
            exact
            component={OrganizationReportsDetailsPage}
          />
          {/** * To define principal routes * */}
          <Route exact path={`${config.principalUrl}`}>
            <Redirect to={`${config.principalUrl}/login`} />
          </Route>
          <LoginLayoutRoute
            path={`${config.principalUrl}/login`}
            component={PrincipalLoginPage}
          />
          <LoginLayoutRoute
            path={`${config.principalUrl}/recover-password`}
            exact
            component={PrincipalRecoverPasswordPage}
          />
          <PrincipalDashboardRoute
            path={`${config.principalUrl}/home`}
            exact
            component={PrincipalDashboardPage}
          />
          <PrincipalDashboardRoute
            path={`${config.principalUrl}/instructors`}
            exact
            component={PrincipalInstructorsPage}
          />
          <PrincipalDashboardRoute
            path={`${config.principalUrl}/instructor/:iid`}
            exact
            component={PrincipalInstructorsDetailsPage}
          />
          <PrincipalDashboardRoute
            path={`${config.principalUrl}/classes`}
            exact
            component={PrincipalClassesPage}
          />
          <PrincipalDashboardRoute
            path={`${config.principalUrl}/class/:cid`}
            exact
            component={PrincipalClassesDetailsPage}
          />
          <PrincipalDashboardRoute
            path={`${config.principalUrl}/reports`}
            exact
            component={PrincipalReportsPage}
          />
          <PrincipalDashboardRoute
            path={`${config.principalUrl}/create-report`}
            exact
            component={PrincipalCreateReportPage}
          />
          <PrincipalDashboardRoute
            path={`${config.principalUrl}/report/:rid`}
            exact
            component={PrincipalReportsDetailsPage}
          />
          {/** * To define teacher routes * */}
          <Route exact path={`${config.teacherUrl}`}>
            <Redirect to={`${config.teacherUrl}/login`} />
          </Route>
          <LoginLayoutRoute
            path={`${config.teacherUrl}/login`}
            component={TeacherLoginPage}
          />
          <LoginLayoutRoute
            path={`${config.teacherUrl}/recover-password`}
            exact
            component={TeacherRecoverPasswordPage}
          />
          <TeacherDashboardRoute
            path={`${config.teacherUrl}/home`}
            exact
            component={TeacherDashboardPage}
          />
          <TeacherDashboardRoute
            path={`${config.teacherUrl}/classes`}
            exact
            component={TeacherClassesPage}
          />
          <TeacherDashboardRoute
            path={`${config.teacherUrl}/class/:cid`}
            exact
            component={TeacherClassesDetailsPage}
          />
          <TeacherDashboardRoute
            path={`${config.teacherUrl}/students`}
            exact
            component={TeacherStudentsPage}
          />
          <TeacherDashboardRoute
            path={`${config.teacherUrl}/student/:sid`}
            exact
            component={TeacherStudentDetailPage}
          />
          <TeacherDashboardRoute
            path={`${config.teacherUrl}/edit-student/:sid`}
            exact
            component={TeacherStudentEditPage}
          />
          <TeacherDashboardRoute
            path={`${config.teacherUrl}/reports`}
            exact
            component={TeacherReportsPage}
          />
          {/** * To define student routes * */}
          <Route exact path={`${config.studentUrl}`}>
            <Redirect to={`${config.studentUrl}/login`} />
          </Route>
          <LoginLayoutRoute
            path={`${config.studentUrl}/login`}
            component={StudentLoginPage}
          />
          <LoginLayoutRoute
            path={`${config.studentUrl}/register`}
            component={StudentRegisterPage}
          />
          <StudentDashboardRoute
            path={`${config.studentUrl}/home`}
            exact
            component={StudentDashboardPage}
          />
          <StudentDashboardRoute
            path={`${config.studentUrl}/pronunciation`}
            exact
            component={StudentAggregateDataPage}
          />
          {/** * To define admin routes * */}
          <Route exact path={`${config.adminUrl}`}>
            <Redirect to={`${config.adminUrl}/login`} />
          </Route>
          <LoginLayoutRoute
            path={`${config.adminUrl}/login`}
            component={LoginPage}
          />
          <LoginLayoutRoute
            path={`${config.adminUrl}/recover-password`}
            exact
            component={RecoverPasswordPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/home`}
            exact
            component={DashboardPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/add-new-organization`}
            exact
            component={AddNewOrganizationPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/organization/:id`}
            exact
            component={OrganizationDetailPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/edit-organization/:id`}
            exact
            component={EditOrganizationPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/organization/:id/schools/:sid`}
            exact
            component={OrganizationSchoolDetailPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/organization/:id/instructors`}
            exact
            component={InstructorPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/organization/:id/instructor/:iid`}
            exact
            component={InstructorDetailPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/organization/:id/classes`}
            exact
            component={ClassesPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/organization/:id/class/:cid`}
            exact
            component={ClassDetailPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/organization/:id/reports`}
            exact
            component={ReportPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/organization/:id/report/:rid`}
            exact
            component={ReportDetailsPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/organization/:id/create-report`}
            exact
            component={CreateReportPage}
          />
          <DashboardLayoutRoute
            path={`${config.adminUrl}/profile`}
            exact
            component={ProfilePage}
          />
          {/* add 404 page */}
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    )
  }
}

App.propTypes = {
  initializeLanguage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
  initializeLanguage: () => dispatch(initializeLanguage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
