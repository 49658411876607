/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactFlagsSelect from 'react-flags-select'
import { confirmAlert } from 'react-confirm-alert' // Import
import { getTranslate, withLocalize } from 'react-localize-redux'
import config from '../../../../../config'
import { principalLogoutUser } from '../../../../../store/actions/auth'
import {
  getPrincipalProfile,
  updatePrincipalPassword,
  updatePrincipalProfile,
} from '../../../../../store/actions/principal'

import 'react-flags-select/css/react-flags-select.css'
import ChnagePasswordModelBox from '../../Profile/ChnagePasswordModelBox'
import EditProfileModelBox from '../../Profile/EditProfileModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { resetForm } from '../../../../../store/actions/form'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowChangePassword: false,
      isShowEditProfile: false,
    }

    const { getPrincipalProfile } = this.props
    getPrincipalProfile(this.props)
  }

  onSelectFlag = countryCode => {
    const { setActiveLanguage } = this.props
    let langCode = 'en'
    if (countryCode === 'US') {
      langCode = 'en'
    } else if (countryCode === 'ES') {
      langCode = 'es'
    } else if (countryCode === 'CN') {
      langCode = 'zh'
    }
    setActiveLanguage(langCode)
    const languages = {
      defaultLanguage: langCode,
      defaultCountry: countryCode,
    }
    localStorage.setItem('currentLanguage', JSON.stringify(languages))
  }

  handleChangePasswordModelShow = () => {
    const { resetForm } = this.props
    resetForm('principal-change-password')
    this.setState({
      isShowChangePassword: true,
    })
  }

  handleChangePasswordModelClose = () => {
    const { resetForm } = this.props
    resetForm('principal-change-password')
    this.setState({
      isShowChangePassword: false,
    })
  }

  handleSubmitChangePassword = async values => {
    const { resetForm, updatePrincipalPassword } = this.props
    await updatePrincipalPassword(values, this.props)
    resetForm('principal-change-password')
    this.setState({ isShowChangePassword: false })
  }

  handleCancel = () => {
    const { resetForm, translate } = this.props
    confirmAlert({
      title: translate('alertHeading'),
      message: translate('alertFormText'),
      buttons: [
        {
          label: translate('btnYes'),
          onClick: () => {
            resetForm('principal-change-password')
            resetForm('principal-change-profile')
            this.setState({ isShowChangePassword: false })
            this.setState({ isShowEditProfile: false })
          },
        },
        {
          label: translate('btnNo'),
        },
      ],
    })
  }

  handleEditProfileModelShow = () => {
    const { resetForm } = this.props
    resetForm('principal-change-profile')
    this.setState({
      isShowEditProfile: true,
    })
  }

  handleEditProfileModelClose = () => {
    const { resetForm } = this.props
    resetForm('principal-change-profile')
    this.setState({
      isShowEditProfile: false,
    })
  }

  handleSubmitEditProfile = async values => {
    const {
      resetForm,
      updatePrincipalProfile,
      getPrincipalProfile,
    } = this.props
    await updatePrincipalProfile(values, this.props)
    await getPrincipalProfile(this.props)
    resetForm('principal-change-profile')
    this.setState({ isShowEditProfile: false })
  }

  render() {
    const {
      translate,
      principalLogoutUser,
      principalProfile,
      handleHamburger,
    } = this.props
    const { isShowChangePassword, isShowEditProfile } = this.state
    const currentSetLanguage = JSON.parse(
      localStorage.getItem('currentLanguage'),
    )
    return (
      <React.Fragment>
        <nav
          className="header-navbar navbar-expand-md navbar navbar-with-menu 
          navbar-without-dd-arrow fixed-top navbar-semi-light"
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div className="collapse navbar-collapse show" id="navbar-mobile">
                <ul className="nav navbar-nav mr-auto float-left">
                  <li className="nav-item mobile-menu d-md-none mr-auto">
                    <Link
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      to="#"
                    >
                      <i className="ft-menu font-large-1" />
                    </Link>
                  </li>
                  <li className="nav-item d-none d-md-block">
                    <Link
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      to="#"
                      onClick={handleHamburger}
                      style={{ transition: 'all 0s ease 0s' }}
                    >
                      <i className="ft-menu" />
                    </Link>
                  </li>
                </ul>
                <ul className="nav navbar-nav float-right">
                  <ReactFlagsSelect
                    className="selectpicker"
                    data-width="fit"
                    countries={['US', 'ES', 'CN']}
                    customLabels={{
                      US: 'English',
                      ES: 'español',
                      CN: '中文(简体',
                    }}
                    defaultCountry={currentSetLanguage.defaultCountry}
                    showSelectedLabel
                    onSelect={this.onSelectFlag}
                  />
                  <li className="dropdown dropdown-user nav-item">
                    <Link
                      className="dropdown-toggle nav-link dropdown-user-link"
                      href="#"
                      data-toggle="dropdown"
                    >
                      {principalProfile &&
                        (principalProfile.name.length > 13
                          ? `${principalProfile.name.substring(0, 10)}...`
                          : principalProfile.name)}
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={this.handleEditProfileModelShow}
                      >
                        <i className="ft-user" />
                        {translate('topNavMenuEditProfile')}
                      </Link>
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={this.handleChangePasswordModelShow}
                      >
                        <i className="ft-lock" />
                        {translate('btnChangePassword')}
                      </Link>
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item"
                        to={`${config.principalUrl}/login`}
                        onClick={principalLogoutUser}
                      >
                        <i className="ft-power" />
                        {translate('topNavMenuLogout')}
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <ChnagePasswordModelBox
          isShowChangePassword={isShowChangePassword}
          handleChangePasswordModelClose={this.handleChangePasswordModelClose}
          onSubmit={this.handleSubmitChangePassword}
          handleCancel={this.handleCancel}
        />
        {principalProfile && (
          <EditProfileModelBox
            isShowEditProfile={isShowEditProfile}
            handleEditProfileModelClose={this.handleEditProfileModelClose}
            onSubmit={this.handleSubmitEditProfile}
            handleCancel={this.handleCancel}
          />
        )}
      </React.Fragment>
    )
  }
}

Header.propTypes = {
  translate: PropTypes.func.isRequired,
  principalLogoutUser: PropTypes.func.isRequired,
  handleHamburger: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  updatePrincipalProfile: PropTypes.func.isRequired,
  updatePrincipalPassword: PropTypes.func.isRequired,
  getPrincipalProfile: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
  principalProfile: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  principalProfile: state.principalProfile.data.row,
})

const mapDispatchToProps = dispatch => ({
  principalLogoutUser: () => dispatch(principalLogoutUser()),
  getPrincipalProfile: props => dispatch(getPrincipalProfile(props)),
  updatePrincipalPassword: (values, props) =>
    dispatch(updatePrincipalPassword(values, props)),
  updatePrincipalProfile: (values, props) =>
    dispatch(updatePrincipalProfile(values, props)),
  resetForm: formName => dispatch(resetForm(formName)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Header),
)
