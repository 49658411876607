/* TODO: Fix eslint */
/* eslint-disable */

/** images * */

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import Loader from '../../../../components/Loader'
import RenderField from '../../../../components/RenderFieldTranlate'
import validateForm from './helpers'
import logo from '../../../../app-assets/images/logo/fluent-world-logo.png'
import config from '../../../../config'

class LoginForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { pristine, handleSubmit, submitting, error, translate, onSelectFlag } = this.props
    const currentSetLanguage = JSON.parse(localStorage.getItem('currentLanguage'))
    let enCurrentLanguage = false
    let esCurrentLanguage = false
    let zhCurrentLanguage = false
    if (currentSetLanguage.defaultLanguage === 'en') {
      enCurrentLanguage = true
    } else if (currentSetLanguage.defaultLanguage === 'es') {
      esCurrentLanguage = true
    } else if (currentSetLanguage.defaultLanguage === 'zh') {
      zhCurrentLanguage = true
    } else {
      enCurrentLanguage = true
    }
    return (
      <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
        <div className="card-header border-0">
          <div className="text-center mb-1">
            <Link to="/">
              <img src={logo} alt={translate('companyName')} width="60%;" />
            </Link>
          </div>
          <div className="font-large-1  text-center">{translate('principalLogin')}</div>
        </div>
        <div className="card-content">
          <div className="card-body">
            <div className="form-group custom-controls-stacked mb-1">
              <label className="custom-control custom-radio mr-3">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="language"
                  defaultValue="en"
                  checked={enCurrentLanguage}
                  onClick={() => onSelectFlag('US')}
                />
                <span className="custom-control-label">English</span>
              </label>
              <label className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="language"
                  defaultValue="es"
                  checked={esCurrentLanguage}
                  onClick={() => onSelectFlag('ES')}
                />
                <span className="custom-control-label">español</span>
              </label>
              <label className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="language"
                  defaultValue="zh"
                  checked={zhCurrentLanguage}
                  onClick={() => onSelectFlag('CN')}
                />
                <span className="custom-control-label">中文(简体</span>
              </label>
            </div>
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <fieldset className="form-group position-relative has-icon-left">
                <Field
                  type="text"
                  className="form-control round"
                  placeholder={translate('fieldEmail')}
                  component={RenderField}
                  name="email"
                  id="email"
                  autoFocus
                />
                <div className="form-control-position">
                  <i className="ft-user" />
                </div>
              </fieldset>
              <fieldset className="form-group position-relative has-icon-left">
                <Field
                  type="password"
                  className="form-control round"
                  placeholder={translate('fieldPassword')}
                  component={RenderField}
                  name="password"
                  id="password"
                />
                <div className="form-control-position">
                  <i className="ft-lock" />
                </div>
              </fieldset>
              <div className="form-group row">
                <div className="col-md-6 col-12 text-center text-sm-left" />
                <div className="col-md-6 col-12 float-sm-left text-center text-sm-right">
                  <Link to={`${config.principalUrl}/recover-password`} className="card-link">
                    {translate('forgotPassword')}
                  </Link>
                </div>
              </div>
              <div className="form-group text-center">
                {error && (
                  <h4 className="text-danger login-danger font-weight-bold mb-1">
                    {translate(`${error}`)}
                  </h4>
                )}
                <button
                  type="submit"
                  className="btn round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1"
                  disabled={pristine || submitting}
                >
                  {submitting ? <Loader /> : translate('btnLogin')}
                </button>
              </div>
            </form>
          </div>
          <p className="card-subtitle text-muted text-center font-small-3 mx-2 my-1">
            <span>
              <Link to={`${config.termofUseUrl}`} className="card-link">
                {translate('footerTermsofUse')}
              </Link>
              <Link to={`${config.privacyPolicyUrl}`} className="card-link">
                {translate('footerPrivacyPolicy')}
              </Link>
            </span>
          </p>
        </div>
      </div>
    )
  }
}

LoginForm = reduxForm({
  form: 'principal-login-form',
  validate: validateForm,
})(LoginForm)

LoginForm = connect(state => ({
  translate: getTranslate(state.localize),
}))(LoginForm)

export default LoginForm
