/* TODO: Fix eslint */
/* eslint-disable */

import axios from 'axios'
import moment from 'moment'
import { saveAs } from 'file-saver'
import config from '../../config'
import handleResponse from './handleResponse'
import handleResponseTranslate from './handleResponseTranslate'
import handleResponsePrincipalTranslate from './handleResponsePrincipalTranslate'
import handleResponseTeacherTranslate from './handleResponseTeacherTranslate'

export const GET_REPORT_LIST = 'GET_REPORT_LIST'
export const GET_REPORT_LIST_LOADING = 'GET_REPORT_LIST_LOADING'
export const GET_REPORT_LIST_LOADED = 'GET_REPORT_LIST_LOADED'
export const REPORT_STORE = 'REPORT_STORE'
export const GET_REPORT_DETAILS = 'GET_REPORT_DETAILS'
export const REMOVE_REPORT = 'REMOVE_REPORT'
export const GET_ORGANIZATION_REPORT_LIST = 'GET_ORGANIZATION_REPORT_LIST'
export const GET_ORGANIZATION_REPORT_LIST_LOADING =
  'GET_ORGANIZATION_REPORT_LIST_LOADING'
export const GET_ORGANIZATION_REPORT_LIST_LOADED =
  'GET_ORGANIZATION_REPORT_LIST_LOADED'
export const REPORT_ORGANIZATION_STORE = 'REPORT_ORGANIZATION_STORE'
export const GET_ORGANIZATION_REPORT_DETAILS = 'GET_ORGANIZATION_REPORT_DETAILS'
export const REMOVE_ORGANIZATION_REPORT = 'REMOVE_ORGANIZATION_REPORT'
export const GET_PRINCIPAL_REPORT_LIST = 'GET_PRINCIPAL_REPORT_LIST'
export const GET_PRINCIPAL_REPORT_LIST_LOADING =
  'GET_PRINCIPAL_REPORT_LIST_LOADING'
export const GET_PRINCIPAL_REPORT_LIST_LOADED =
  'GET_PRINCIPAL_REPORT_LIST_LOADED'
export const REPORT_PRINCIPAL_STORE = 'REPORT_PRINCIPAL_STORE'
export const GET_PRINCIPAL_REPORT_DETAILS = 'GET_PRINCIPAL_REPORT_DETAILS'
export const REMOVE_PRINCIPAL_REPORT = 'REMOVE_PRINCIPAL_REPORT'
export const GET_REPORT_CLASS_ATTENDENCES_LIST_LOADING =
  'GET_REPORT_CLASS_ATTENDENCES_LIST_LOADING'
export const GET_REPORT_CLASS_ATTENDENCES_LIST =
  'GET_REPORT_CLASS_ATTENDENCES_LIST'
export const GET_REPORT_CLASS_ATTENDENCES_LIST_LOADED =
  'GET_REPORT_CLASS_ATTENDENCES_LIST_LOADED'
export const GET_REPORT_PHONEME_SCORES_LIST_LOADING =
  'GET_REPORT_PHONEME_SCORES_LIST_LOADING'
export const GET_REPORT_PHONEME_SCORES_LIST = 'GET_REPORT_PHONEME_SCORES_LIST'
export const GET_REPORT_PHONEME_SCORES_LIST_LOADED =
  'GET_REPORT_PHONEME_SCORES_LIST_LOADED'
export const GET_REPORT_ADVENTURE_STATES_LIST_LOADING =
  'GET_REPORT_ADVENTURE_STATES_LIST_LOADING'
export const GET_REPORT_ADVENTURE_STATES_LIST =
  'GET_REPORT_ADVENTURE_STATES_LIST'
export const GET_REPORT_ADVENTURE_STATES_LIST_LOADED =
  'GET_REPORT_ADVENTURE_STATES_LIST_LOADED'
export const GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADING =
  'GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADING'
export const GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST =
  'GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST'
export const GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADED =
  'GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADED'
export const GET_ORGANIZATION_REPORT_PHONEME_SCORES_LIST_LOADING =
  'GET_ORGANIZATION_REPORT_PHONEME_SCORES_LIST_LOADING'
export const GET_ORGANIZATION_REPORT_PHONEME_SCORES_LIST =
  'GET_ORGANIZATION_REPORT_PHONEME_SCORES_LIST'
export const GET_ORGANIZATION_REPORT_PHONEME_SCORES_LIST_LOADED =
  'GET_ORGANIZATION_REPORT_PHONEME_SCORES_LIST_LOADED'
export const GET_ORGANIZATION_REPORT_ADVENTURE_STATES_LIST_LOADING =
  'GET_ORGANIZATION_REPORT_ADVENTURE_STATES_LIST_LOADING'
export const GET_ORGANIZATION_REPORT_ADVENTURE_STATES_LIST =
  'GET_ORGANIZATION_REPORT_ADVENTURE_STATES_LIST'
export const GET_ORGANIZATION_REPORT_ADVENTURE_STATES_LIST_LOADED =
  'GET_ORGANIZATION_REPORT_ADVENTURE_STATES_LIST_LOADED'
export const GET_PRINCIPAL_REPORT_CLASS_ATTENDENCES_LIST_LOADING =
  'GET_PRINCIPAL_REPORT_CLASS_ATTENDENCES_LIST_LOADING'
export const GET_PRINCIPAL_REPORT_CLASS_ATTENDENCES_LIST =
  'GET_PRINCIPAL_REPORT_CLASS_ATTENDENCES_LIST'
export const GET_PRINCIPAL_REPORT_CLASS_ATTENDENCES_LIST_LOADED =
  'GET_PRINCIPAL_REPORT_CLASS_ATTENDENCES_LIST_LOADED'
export const GET_PRINCIPAL_REPORT_PHONEME_SCORES_LIST_LOADING =
  'GET_PRINCIPAL_REPORT_PHONEME_SCORES_LIST_LOADING'
export const GET_PRINCIPAL_REPORT_PHONEME_SCORES_LIST =
  'GET_PRINCIPAL_REPORT_PHONEME_SCORES_LIST'
export const GET_PRINCIPAL_REPORT_PHONEME_SCORES_LIST_LOADED =
  'GET_PRINCIPAL_REPORT_PHONEME_SCORES_LIST_LOADED'
export const GET_PRINCIPAL_REPORT_ADVENTURE_STATES_LIST_LOADING =
  'GET_PRINCIPAL_REPORT_ADVENTURE_STATES_LIST_LOADING'
export const GET_PRINCIPAL_REPORT_ADVENTURE_STATES_LIST =
  'GET_PRINCIPAL_REPORT_ADVENTURE_STATES_LIST'
export const GET_PRINCIPAL_REPORT_ADVENTURE_STATES_LIST_LOADED =
  'GET_PRINCIPAL_REPORT_ADVENTURE_STATES_LIST_LOADED'
export const GET_TEACHER_REPORT_CLASS_ATTENDENCES_LIST_LOADING =
  'GET_TEACHER_REPORT_CLASS_ATTENDENCES_LIST_LOADING'
export const GET_TEACHER_REPORT_CLASS_ATTENDENCES_LIST =
  'GET_TEACHER_REPORT_CLASS_ATTENDENCES_LIST'
export const GET_TEACHER_REPORT_CLASS_ATTENDENCES_LIST_LOADED =
  'GET_TEACHER_REPORT_CLASS_ATTENDENCES_LIST_LOADED'
export const GET_TEACHER_REPORT_PHONEME_SCORES_LIST_LOADING =
  'GET_TEACHER_REPORT_PHONEME_SCORES_LIST_LOADING'
export const GET_TEACHER_REPORT_PHONEME_SCORES_LIST =
  'GET_TEACHER_REPORT_PHONEME_SCORES_LIST'
export const GET_TEACHER_REPORT_PHONEME_SCORES_LIST_LOADED =
  'GET_TEACHER_REPORT_PHONEME_SCORES_LIST_LOADED'
export const GET_TEACHER_REPORT_ADVENTURE_STATES_LIST_LOADING =
  'GET_TEACHER_REPORT_ADVENTURE_STATES_LIST_LOADING'
export const GET_TEACHER_REPORT_ADVENTURE_STATES_LIST =
  'GET_TEACHER_REPORT_ADVENTURE_STATES_LIST'
export const GET_TEACHER_REPORT_ADVENTURE_STATES_LIST_LOADED =
  'GET_TEACHER_REPORT_ADVENTURE_STATES_LIST_LOADED'

export const GET_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING =
  'GET_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING'
export const GET_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST =
  'GET_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST'
export const GET_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED =
  'GET_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED'
export const GET_ORGANIZATION_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING =
  'GET_ORGANIZATION_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING'
export const GET_ORGANIZATION_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST =
  'GET_ORGANIZATION_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST'
export const GET_ORGANIZATION_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED =
  'GET_ORGANIZATION_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED'
export const GET_PRINCIPAL_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING =
  'GET_PRINCIPAL_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING'
export const GET_PRINCIPAL_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST =
  'GET_PRINCIPAL_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST'
export const GET_PRINCIPAL_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED =
  'GET_PRINCIPAL_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED'
export const GET_TEACHER_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING =
  'GET_TEACHER_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING'
export const GET_TEACHER_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST =
  'GET_TEACHER_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST'
export const GET_TEACHER_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED =
  'GET_TEACHER_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED'

/**
 * To get reports of a organization for admin
 *
 * @param  int organizationId
 * @return data
 */
export const getReportByOrganizationId = (
  organizationId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_REPORT_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/reports/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_REPORT_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_REPORT_LIST_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To store/save a report for admin
 *
 * @param  int organizationId
 * @param  object values
 * @return int id
 */
export const storeReport = (organizationId, values) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const school_ids = []
    if (values.school_ids) {
      values.school_ids.forEach(function(item) {
        school_ids.push(item.id)
      })
    }
    const tag_ids = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        tag_ids.push(item.id)
      })
    }
    let start_date = null
    let end_date = null
    const currentHH = moment().format('HH')
    const currentMM = moment().format('mm')
    const currentSS = moment().format('ss')
    const time = ` ${currentHH}:${currentMM}:${currentSS}`
    if (values.start_date) {
      const endFormat1 = moment(
        values.start_date,
        'MM/DD/YYYY HH:mm:ss',
      ).format('YYYY-MM-DD')
      const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss',
      )
      start_date = moment(endFormat)
        .utc()
        .format('YYYY-MM-DD')
    }
    if (values.end_date) {
      const endFormat1 = moment(values.end_date, 'MM/DD/YYYY HH:mm:ss').format(
        'YYYY-MM-DD',
      )
      const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss',
      )
      end_date = moment(endFormat)
        .utc()
        .format('YYYY-MM-DD')
    }
    const reportValues = {
      name: values.name,
      description: values.description,
      start_date,
      end_date,
      school_ids: school_ids.length > 0 ? school_ids.toString() : '',
      tag_ids: tag_ids.length > 0 ? tag_ids.toString() : null,
    }

    const response = await axios.post(
      `${config.apiUrl}/organization/${organizationId}/report`,
      reportValues,
      headers,
    )
    dispatch({
      type: REPORT_STORE,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    await handleResponse(error, 1)
  }
}

/**
 * To get report details for admin
 *
 * @param  int organizationId
 * @param  object values
 * @return int id
 */
export const getReportDetails = (organizationId, reportId) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/report/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_REPORT_DETAILS,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To remove a report for admin
 *
 * @param  int organizationId
 * @param  int reportId
 */
export const removeReport = (organizationId, reportId) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.delete(
      `${config.apiUrl}/organization/${organizationId}/report/${reportId}`,
      headers,
    )

    dispatch({
      type: REMOVE_REPORT,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}
/**
 * To export a report as pdf for admin
 *
 * @param  int organizationId
 * @param  int reportId
 */
export const exportReport = (organizationId, reportId) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      access_token: `${getState().auth.accessToken}`,
      responseType: 'blob',
    }
    await axios
      .get(
        `${config.apiUrl}/${organizationId}/export-report/${reportId}`,
        headers,
      )
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
        saveAs(pdfBlob, 'school-details')
      })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get class attendences of a report of an organization for admin
 *
 * @param  int organizationId
 * @param  int reportId
 * @return data
 */
export const getAdminClassAttendencesReportByReportId = (
  organizationId,
  reportId,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_REPORT_CLASS_ATTENDENCES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/reports/class-attendance/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_REPORT_CLASS_ATTENDENCES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_REPORT_CLASS_ATTENDENCES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}
/**
 * To get phoneme scores of a report of an organization for admin
 *
 * @param  int organizationId
 * @param  int reportId
 * @return data
 */
export const getAdminPhonemeScoresReportByReportId = (
  organizationId,
  reportId,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_REPORT_PHONEME_SCORES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/reports/phoneme-scores/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_REPORT_PHONEME_SCORES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_REPORT_PHONEME_SCORES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}
/**
 * To get adventure stats of a report of an organization for admin
 *
 * @param  int organizationId
 * @param  int reportId
 * @return data
 */
export const getAdminAdventureStatsReportByReportId = (
  organizationId,
  reportId,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_REPORT_ADVENTURE_STATES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/reports/adventure-stats/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_REPORT_ADVENTURE_STATES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_REPORT_ADVENTURE_STATES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}
/**
 * To get assessment adventure stats of a report of an organization for admin
 *
 * @param  int organizationId
 * @param  int reportId
 * @return data
 */
export const getAdminAssessmentAdventureStatsReportByReportId = (
  organizationId,
  reportId,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/reports/assessment-adventure-stats/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}
/**
 * To get reports of a organization for organization
 *
 * @param  object props
 * @return data
 */
export const getOrganizationReport = (props, page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_REPORT_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/reports/${page}/${orderBy}/${order}`,
      headers,
    )

    dispatch({
      type: GET_ORGANIZATION_REPORT_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_REPORT_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To store/save a report for organization
 *
 * @param  object props
 * @param  object values
 * @return int id
 */
export const storeOrganizationReport = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const school_ids = []
    if (values.school_ids) {
      values.school_ids.forEach(function(item) {
        school_ids.push(item.id)
      })
    }
    const tag_ids = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        tag_ids.push(item.id)
      })
    }
    let start_date = null
    let end_date = null
    const currentHH = moment().format('HH')
    const currentMM = moment().format('mm')
    const currentSS = moment().format('ss')
    const time = ` ${currentHH}:${currentMM}:${currentSS}`
    if (values.start_date) {
      const endFormat1 = moment(
        values.start_date,
        'MM/DD/YYYY HH:mm:ss',
      ).format('YYYY-MM-DD')
      const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss',
      )
      start_date = moment(endFormat)
        .utc()
        .format('YYYY-MM-DD')
    }
    if (values.end_date) {
      const endFormat1 = moment(values.end_date, 'MM/DD/YYYY HH:mm:ss').format(
        'YYYY-MM-DD',
      )
      const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss',
      )
      end_date = moment(endFormat)
        .utc()
        .format('YYYY-MM-DD')
    }
    const reportValues = {
      name: values.name,
      description: values.description,
      start_date,
      end_date,
      school_ids: school_ids.length > 0 ? school_ids.toString() : '',
      tag_ids: tag_ids.length > 0 ? tag_ids.toString() : null,
    }
    // console.log(reportValues);
    const response = await axios.post(
      `${config.apiOrganizationUrl}/report`,
      reportValues,
      headers,
    )
    // console.log(response);
    // console.log(response.data.result);
    dispatch({
      type: REPORT_ORGANIZATION_STORE,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    await handleResponseTranslate(error, props, 1)
  }
}

/**
 * To get report details for organization
 *
 * @param  object props
 * @param  object values
 * @return int id
 */
export const getOrganizationReportDetails = (reportId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/report/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_REPORT_DETAILS,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To remove a report for organization
 *
 * @param  object props
 * @param  int reportId
 */
export const removeOrganizationReport = (reportId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.delete(
      `${config.apiOrganizationUrl}/report/${reportId}`,
      headers,
    )

    dispatch({
      type: REMOVE_ORGANIZATION_REPORT,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To export a report as pdf for organization
 *
 * @param  int reportId
 */
export const exportOrganizationReport = (reportId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      access_token: `${getState().authOrganization.accessToken}`,
      responseType: 'blob',
    }
    await axios
      .get(`${config.apiOrganizationUrl}/export-report/${reportId}`, headers)
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
        saveAs(pdfBlob, 'school-details')
      })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get class attendences of a report of an organization for organization
 *
 * @param  int reportId
 * @return data
 */
export const getOrganizationClassAttendencesReportByReportId = (
  reportId,
  props,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/reports/class-attendance/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_REPORT_CLASS_ATTENDENCES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, 3, props)
  }
}
/**
 * To get phoneme scores of a report of an organization for organization
 *
 * @param  int reportId
 * @return data
 */
export const getOrganizationPhonemeScoresReportByReportId = (
  reportId,
  props,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_REPORT_PHONEME_SCORES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/reports/phoneme-scores/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_REPORT_PHONEME_SCORES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_REPORT_PHONEME_SCORES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, 3, props)
  }
}
/**
 * To get adventure stats of a report of an organization for organization
 *
 * @param  int reportId
 * @return data
 */
export const getOrganizationAdventureStatsReportByReportId = (
  reportId,
  props,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_REPORT_ADVENTURE_STATES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/reports/adventure-stats/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_REPORT_ADVENTURE_STATES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_REPORT_ADVENTURE_STATES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, 3, props)
  }
}

/**
 * To get assessment adventure stats of a report of an organization for organization
 *
 * @param  int reportId
 * @return data
 */
export const getOrganizationAssessmentAdventureStatsReportByReportId = (
  reportId,
  props,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/reports/assessment-adventure-stats/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, 3, props)
  }
}

/**
 * To get reports of a principal for principal
 *
 * @param  object props
 * @return data
 */
export const getPrincipalReport = (props, page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_PRINCIPAL_REPORT_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/reports/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_REPORT_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_PRINCIPAL_REPORT_LIST_LOADED,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}

/**
 * To store/save a report for principal
 *
 * @param  object props
 * @param  object values
 * @return int id
 */
export const storePrincipalReport = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const school_ids = []
    if (values.school_ids) {
      values.school_ids.forEach(function(item) {
        school_ids.push(item.id)
      })
    }
    const tag_ids = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        tag_ids.push(item.id)
      })
    }
    let start_date = null
    let end_date = null
    const currentHH = moment().format('HH')
    const currentMM = moment().format('mm')
    const currentSS = moment().format('ss')
    const time = ` ${currentHH}:${currentMM}:${currentSS}`
    if (values.start_date) {
      const endFormat1 = moment(
        values.start_date,
        'MM/DD/YYYY HH:mm:ss',
      ).format('YYYY-MM-DD')
      const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss',
      )
      start_date = moment(endFormat)
        .utc()
        .format('YYYY-MM-DD')
    }
    if (values.end_date) {
      const endFormat1 = moment(values.end_date, 'MM/DD/YYYY HH:mm:ss').format(
        'YYYY-MM-DD',
      )
      const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss',
      )
      end_date = moment(endFormat)
        .utc()
        .format('YYYY-MM-DD')
    }
    const reportValues = {
      name: values.name,
      description: values.description,
      start_date,
      end_date,
      school_ids: school_ids.length > 0 ? school_ids.toString() : '',
      tag_ids: tag_ids.length > 0 ? tag_ids.toString() : null,
    }

    const response = await axios.post(
      `${config.apiPrincipalUrl}/report`,
      reportValues,
      headers,
    )

    dispatch({
      type: REPORT_PRINCIPAL_STORE,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}

/**
 * To get report details for principal
 *
 * @param  object props
 * @param  object values
 * @return int id
 */
export const getPrincipalReportDetails = (reportId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/report/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_REPORT_DETAILS,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}

/**
 * To remove a report for principal
 *
 * @param  object props
 * @param  int reportId
 */
export const removePrincipalReport = (reportId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.delete(
      `${config.apiPrincipalUrl}/report/${reportId}`,
      headers,
    )

    dispatch({
      type: REMOVE_PRINCIPAL_REPORT,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}
/**
 * To export a report as pdf for principal
 *
 * @param  int reportId
 */
export const exportPrincipalReport = (reportId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      access_token: `${getState().authPrincipal.accessToken}`,
      responseType: 'blob',
    }
    await axios
      .get(`${config.apiPrincipalUrl}/export-report/${reportId}`, headers)
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
        saveAs(pdfBlob, 'school-details')
      })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}
/**
 * To get class attendences of a report for principal
 *
 * @param  int reportId
 * @return data
 */
export const getPrincipalClassAttendencesReportByReportId = (
  reportId,
  props,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PRINCIPAL_REPORT_CLASS_ATTENDENCES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/reports/class-attendance/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_REPORT_CLASS_ATTENDENCES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_PRINCIPAL_REPORT_CLASS_ATTENDENCES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, 3, props)
  }
}
/**
 * To get phoneme scores of a report for principal
 *
 * @param  int reportId
 * @return data
 */
export const getPrincipalPhonemeScoresReportByReportId = (
  reportId,
  props,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PRINCIPAL_REPORT_PHONEME_SCORES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/reports/phoneme-scores/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_REPORT_PHONEME_SCORES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_PRINCIPAL_REPORT_PHONEME_SCORES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, 3, props)
  }
}
/**
 * To get adventure stats of a report for principal
 *
 * @param  int reportId
 * @return data
 */
export const getPrincipalAdventureStatsReportByReportId = (
  reportId,
  props,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PRINCIPAL_REPORT_ADVENTURE_STATES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/reports/adventure-stats/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_REPORT_ADVENTURE_STATES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_PRINCIPAL_REPORT_ADVENTURE_STATES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, 3, props)
  }
}
/**
 * To get assessment adventure stats of a report for principal
 *
 * @param  int reportId
 * @return data
 */
export const getPrincipalAssessmentAdventureStatsReportByReportId = (
  reportId,
  props,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PRINCIPAL_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/reports/assessment-adventure-stats/${reportId}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_PRINCIPAL_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, 3, props)
  }
}
/**
 * To get class attendences of a report for teacher
 *
 * @param  int reportId
 * @return data
 */
export const getTeacherClassAttendencesReportByReportId = props => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_TEACHER_REPORT_CLASS_ATTENDENCES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiTeacherUrl}/reports/class-attendance`,
      headers,
    )
    dispatch({
      type: GET_TEACHER_REPORT_CLASS_ATTENDENCES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_TEACHER_REPORT_CLASS_ATTENDENCES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, 3, props)
  }
}
/**
 * To get phoneme scores of a report for teacher
 *
 * @param  int reportId
 * @return data
 */
export const getTeacherPhonemeScoresReportByReportId = props => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_TEACHER_REPORT_PHONEME_SCORES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiTeacherUrl}/reports/phoneme-scores`,
      headers,
    )
    dispatch({
      type: GET_TEACHER_REPORT_PHONEME_SCORES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_TEACHER_REPORT_PHONEME_SCORES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, 3, props)
  }
}
/**
 * To get adventure stats of a report for teacher
 *
 * @param  int reportId
 * @return data
 */
export const getTeacherAdventureStatsReportByReportId = props => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_TEACHER_REPORT_ADVENTURE_STATES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiTeacherUrl}/reports/adventure-stats`,
      headers,
    )
    dispatch({
      type: GET_TEACHER_REPORT_ADVENTURE_STATES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_TEACHER_REPORT_ADVENTURE_STATES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, 3, props)
  }
}

/**
 * To get assessment adventure stats of a report for teacher
 *
 * @param  int reportId
 * @return data
 */
export const getTeacherAssessmentAdventureStatsReportByReportId = props => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_TEACHER_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiTeacherUrl}/reports/assessment-adventure-stats`,
      headers,
    )
    dispatch({
      type: GET_TEACHER_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_TEACHER_REPORT_ASSESSMENT_ADVENTURE_STATES_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, 3, props)
  }
}

/**
 * To export a report as pdf for teacher
 *
 */
export const exportTeacherReport = props => async (dispatch, getState) => {
  try {
    const headers = {
      access_token: `${getState().authTeacher.accessToken}`,
      responseType: 'blob',
    }
    await axios
      .get(
        `${config.apiTeacherUrl}/export-report/${
          getState().authTeacher.user.id
        }`,
        headers,
      )
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
        saveAs(pdfBlob, 'school-details')
      })
  } catch (error) {
    await handleResponseTeacherTranslate(error, props, 3)
  }
}
