/* TODO: Fix eslint */
/* eslint-disable */

/** Import CSS File * */
// BEGIN: Page CSS
// import './../assets/root/css/bootstrap.min.css';
import '../assets/root/css/style.css'
import '../assets/root/css/responsive.css'
import '../assets/root/css/font-awesome.min.css'
import '../assets/root/css/animate.css'
// END: Page CSS

import React from 'react'
import { Route } from 'react-router-dom'
import Noty from '../components/Noty'

import Header from '../pages/Root/Common/Header'
import Footer from '../pages/Root/Common/Footer'

const Index = props => (
  <React.Fragment>
    <Header />
    {props.children}
    <Footer />
  </React.Fragment>
)

const IndexRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Index>
          <Component {...matchProps} />
        </Index>
      )}
    />
  )
}

export default IndexRoute
