/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'
import { exportOrganizationReport } from '../../../../../../store/actions/report'

/**
 * import translation related files
 *
 */

class AggregateDate extends React.Component {
  constructor(props) {
    super(props)
  }

  handleDownloadPDF = async () => {
    // await this.props.exportOrganizationReport(this.props.reportId,this.props);
  }

  render() {
    const { translate, print } = this.props
    return (
      <div className="row">
        <div className="col-md-12 col-lg-8 pull-left">
          <h5 className="card-title text-bold-700 my-2 pull-right">
            <Link className="add_btn btn-floating" to="#" onClick={print}>
              {translate('exportPDF')}
            </Link>
          </h5>
        </div>
        <div className="clearfix" />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  exportOrganizationReport: (reportId, props) => {
    dispatch(exportOrganizationReport(reportId, props))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AggregateDate)
