/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { withLocalize, getTranslate } from 'react-localize-redux'
import Loader from '../../../../../components/Loader'
import RenderField from '../../../../../components/RenderFieldTranlate'
import config from '../../../../../config'
import validateForm from './helpers'
import { getPrincipalProfile } from '../../../../../store/actions/principal'
/**
 * import translation related files
 *
 */
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class EditProfileModelBox extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentDidMount() {
    this.props.getPrincipalProfile(this.props)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      error,
      reset,
      handleCancel,
      isShowEditProfile,
      handleEditProfileModelClose,
      translate,
      row,
    } = this.props

    return (
      <React.Fragment>
        {row && (
          <Modal
            show={isShowEditProfile}
            onHide={handleEditProfileModelClose}
            animation={false}
            id="changePassword"
            dialogClassName=""
            aria-labelledby="basicModalLabel1"
          >
            <div className="modal-content">
              <form className="form-horizontal" onSubmit={handleSubmit} autoComplete="off">
                <div className="modal-header">
                  <h4 className="modal-title" id="basicModalLabel1">
                    {translate('formPrincipalProfileTitleEdit')}
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={handleEditProfileModelClose}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <h4 className="org-id">
                      {translate('id')}:{row && row.id}
                    </h4>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">
                      <span>*</span>
                      {translate('formPrincipalProfileName')}
                    </label>
                    <Field
                      type="text"
                      className="form-control round"
                      placeholder={translate('formPrincipalProfileName')}
                      component={RenderField}
                      name="name"
                      id="name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      <span>*</span>
                      {translate('formPrincipalProfileEmail')}
                    </label>
                    <Field
                      type="text"
                      className="form-control round"
                      placeholder={translate('formPrincipalProfileEmail')}
                      component={RenderField}
                      name="email"
                      id="email"
                      disabled="disabled"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={pristine || submitting ? handleEditProfileModelClose : handleCancel}
                  >
                    {translate('formChangePassworBtnCancel')}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={pristine || submitting}
                  >
                    {submitting ? <Loader /> : translate('formChangePassworBtnSave')}
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

EditProfileModelBox = withLocalize(
  reduxForm({
    form: 'principal-change-profile',
    validate: validateForm,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(EditProfileModelBox),
)

// EditProfileModelBox = (connect(
//   state => ({
//    translate: getTranslate(state.localize),
//    initialValues: {name:state.principalProfile.data.row && state.principalProfile.data.row.name,email:state.principalProfile.data.row && state.principalProfile.data.row.email},
//    row: state.principalProfile.data.row
//   })
// ))(EditProfileModelBox)

// export default (EditProfileModelBox);

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  initialValues: state.principalProfile.data.row,
  row: state.principalProfile.data.row,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalProfile: props => dispatch(getPrincipalProfile(props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModelBox)
