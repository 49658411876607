import { TEACHER_LOGIN_USER, TEACHER_LOGOUT_USER } from '../../actions/auth'

const authTeacher = JSON.parse(localStorage.getItem('authTeacher'))

const authTeacherReducer = (
  state = {
    user: authTeacher ? authTeacher.user : null,
    accessToken: authTeacher ? authTeacher.token : null,
  },
  action,
) => {
  switch (action.type) {
    case TEACHER_LOGIN_USER:
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.token,
      }
    case TEACHER_LOGOUT_USER:
      return {
        ...state,
        user: null,
        accessToken: null,
      }
    default:
      return state
  }
}

export default authTeacherReducer
