/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert' // Import
import config from '../../../../../config'
import EditForm from './EditForm'

import {
  getOrganizationPrincipalDetailByprincipalId,
  updatePrincipal,
  deletePrincipal,
} from '../../../../../store/actions/principal'
import { getOrganizationTags } from '../../../../../store/actions/tag'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import { getTranslate, withLocalize } from 'react-localize-redux'
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
    this.state = {
      principalId: '',
    }
  }

  componentDidMount() {
    this.props.getOrganizationTags(this.props)
  }

  componentWillMount() {
    const { match } = this.props
    const principalId = match.params.pid
    if (principalId === ' ' || principalId === 'undefined') {
      this.props.history.push(`${config.organizationUrl}/home`)
    }
    this.setState({ principalId })
    this.props.getOrganizationPrincipalDetailByprincipalId(
      this.props,
      principalId,
    )
  }

  handleRedirect = () => {
    this.props.history.push(
      `${config.organizationUrl}/principal/${this.state.principalId}`,
    )
  }

  handleCancel = async () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            this.props.history.push(
              `${config.organizationUrl}/principal/${this.state.principalId}`,
            )
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  handleSubmit = async values => {
    const principalId = await this.props.updatePrincipal(
      this.state.principalId,
      values,
      this.props,
    )
    return this.props.history.push(
      `${config.organizationUrl}/principal/${this.state.principalId}`,
    )
  }

  handleDelete = async () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertDeleteText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            await this.props.deletePrincipal(this.state.principalId, this.props)
            this.props.history.push(`${config.organizationUrl}/principal`)
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  render() {
    const { translate, row, tags } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              {translate('breadCrumbPrincipalEdit')}
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/home`}>
                      {translate('breadCrumbHome')}
                    </Link>{' '}
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/principal`}>
                      {translate('breadCrumbPrincipal')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.organizationUrl}/principal/${row &&
                        row.id}`}
                    >
                      {translate('breadCrumbPrincipalView')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {translate('breadCrumbPrincipalEdit')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <EditForm
                  row={row}
                  tags={tags}
                  onSubmit={this.handleSubmit}
                  handleCancel={this.handleCancel}
                  handleRedirect={this.handleRedirect}
                  handleDelete={this.handleDelete}
                />
                <div className="clearfix" />
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  row: state.organizationOrganizationPrincipalDetail.data.row,
  tags: state.organizationOrganizationTags.data.tags,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationPrincipalDetailByprincipalId: (props, principalId) =>
    dispatch(getOrganizationPrincipalDetailByprincipalId(props, principalId)),
  getOrganizationTags: props => dispatch(getOrganizationTags(props)),
  updatePrincipal: (principalId, values, props) =>
    dispatch(updatePrincipal(principalId, values, props)),
  deletePrincipal: (principalId, props) =>
    dispatch(deletePrincipal(principalId, props)),
})

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Edit))
