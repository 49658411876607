const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://dashboard.fluentworlds.com/api/'
    : 'http://localhost:8080/'

export default {
  apiUrl: `${API_URL}admin`,
  apiOrganizationUrl: `${API_URL}organization`,
  apiPrincipalUrl: `${API_URL}principal`,
  apiTeacherUrl: `${API_URL}teacher`,
  apiStudentUrl: `${API_URL}student`,

  adminUrl: '/admin',
  organizationUrl: '/organization',
  principalUrl: '/principal',
  teacherUrl: '/teacher',
  studentUrl: '/student',
  termofUseUrl: '/terms-of-use',
  privacyPolicyUrl: '/privacy-policy',
  aboutUsUrl: '/about-us',
  contactUsUrl: '/contact-us',
  dateFormat: 'MMM DD, YYYY',
  dateTimeFormat: 'MMM DD, YYYY HH:mm',
  loadingOverlayText: 'Loading your content...',
  loadingOverlayTextTable: '',
  tableNoRecord: 'There are no records to display',
  btnYesText: 'Yes',
  btnNoText: 'NO',
}
