import {
  GET_INSTRUCTOR_STUDENT_LIST,
  SET_INSTRUCTOR_STUDENT_LIST_LOADING,
  SET_INSTRUCTOR_STUDENT_LIST_CURRENT_PAGE,
  SET_HAS_PRIVACY_MODAL_BEEN_SHOWN,
} from 'store/actions/student'

const initialState = {
  students: [],
  studentsPageData: null,
  loading: true,
  currentPage: 0,
  hasPrivacyModalBoxBeenShown: false,
}

const teacherClassStudentReducer = (state = initialState, action = {}) => {
  const newState = state
  switch (action.type) {
    case GET_INSTRUCTOR_STUDENT_LIST:
      if (action.payload.currentPage === 0) {
        newState.students = action.payload.students
        return {
          students: newState.students,
          ...newState,
          studentsPageData: action.payload,
        }
      }
      newState.students = action.payload.students
      return {
        students: newState.students,
        ...newState,
        studentsPageData: action.payload,
      }

    case SET_INSTRUCTOR_STUDENT_LIST_LOADING:
      return {
        ...newState,
        loading: action.payload,
      }
    case SET_INSTRUCTOR_STUDENT_LIST_CURRENT_PAGE:
      return {
        ...newState,
        currentPage: action.payload,
      }
    case SET_HAS_PRIVACY_MODAL_BEEN_SHOWN:
      return {
        ...newState,
        hasPrivacyModalBoxBeenShown: true,
      }
    default:
      return newState
  }
}

export default teacherClassStudentReducer
