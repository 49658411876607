/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm, Field } from 'redux-form'
import { getTranslate } from 'react-localize-redux'
import Loader from '../../../../../../components/Loader'
import RenderField from '../../../../../../components/RenderFieldTranlate'
import RenderMultipleSelectWithCreateField from '../../../../../../components/RenderMultipleSelectWithCreateFieldTranslate'
import RenderSelectField from '../../../../../../components/RenderSelectFieldTranlate'
import validateForm from '../../CreateNewFormModelBox/helpers'

/**
 * import translation related files
 *
 */

class EditForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      error,
      reset,
      handleCancel,
      handleRedirect,
      handleChangeStatus,
      translate,
      tags,
      principal,
      row,
    } = this.props

    return (
      <div className="col-md-12 col-lg-12 pull-left">
        <h5 className="card-title text-bold-700 my-2">
          {translate('formSchoolTitleEdit')}
          <Link
            className="add_btn pull-right btn_delete"
            to="#"
            onClick={handleChangeStatus}
          >
            {row && row.status === 'Active'
              ? translate('formSchoolBTNStatusDeactivated')
              : translate('formSchoolBTNStatusActive')}
          </Link>
        </h5>
        <div className="card">
          <div className="card-content collapse show">
            <div className="card-footer text-left p-1">
              <form
                className="form-horizontal"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="edit_school_form">
                  <div className="form-group">
                    <label htmlFor="name">
                      <span>*</span>
                      {translate('formSchoolFieldName')}
                    </label>
                    <Field
                      name="name"
                      component={RenderField}
                      type="text"
                      placeholder={translate('formSchoolFieldName')}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="reportName">
                      <span>*</span>
                      {translate('formSchoolFieldPrincipal')}
                    </label>
                    <Field name="principal" component={RenderSelectField}>
                      <option value="">
                        {translate('formSchoolFieldPrincipalSelect')}
                      </option>
                      {principal &&
                        principal.map(row => (
                          <option value={row.id}>{row.name}</option>
                        ))}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label htmlFor="status">
                      <span>*</span>
                      {translate('formSchoolFieldStatus')}
                    </label>
                    <Field
                      name="status"
                      component={RenderField}
                      type="text"
                      disabled="disabled"
                      placeholder={translate('formSchoolFieldStatus')}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="total_license">
                      <span>*</span>
                      {translate('formSchoolFieldLicenses')}
                    </label>
                    <Field
                      name="total_license"
                      component={RenderField}
                      type="text"
                      placeholder={translate('formSchoolFieldLicenses')}
                    />
                  </div>
                  <div className="form-group select_p_tags">
                    <label htmlFor="tag_ids">
                      {translate('formSchoolFieldTags')}
                    </label>
                    {tags && (
                      <Field
                        name="tag_ids"
                        component={RenderMultipleSelectWithCreateField}
                        data={tags}
                        textField="name"
                        valueField="id"
                        type="text"
                        formName="organization-edit-school"
                        fieldName="tag_ids"
                      />
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger mr-1"
                    onClick={
                      pristine || submitting ? handleRedirect : handleCancel
                    }
                  >
                    {translate('formSchoolBTNCancel')}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={pristine || submitting}
                  >
                    {submitting ? <Loader /> : translate('formSchoolBTNSave')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditForm = reduxForm({
  form: 'organization-edit-school',
  validate: validateForm,
  enableReinitialize: true,
})(EditForm)

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  initialValues: state.organizationOrganizationSchoolDetail.data.row,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(EditForm)
