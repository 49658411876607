/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
/** componets * */

import {
  withLocalize,
  getTranslate,
  getActiveLanguage,
} from 'react-localize-redux'
import RecoverPasswordForm from './RecoverPasswordForm'
import { principalRecoverPassword } from '../../../store/actions/auth'
import config from '../../../config'

/**
 * import translation related files
 *
 */
import { initializeLanguage } from '../../../store/actions/language'
import englishGlobalTranslations from '../../../lang/en.global.json'
import spanishGlobalTranslations from '../../../lang/es.global.json'
import chineseGlobalTranslations from '../../../lang/zh.global.json'
import englishOrganizationLoginTranslations from '../../../lang/organization/login/en.json'
import spanishOrganizationLoginTranslations from '../../../lang/organization/login/es.json'
import chineseOrganizationLoginTranslations from '../../../lang/organization/login/zh.json'

class RecoverPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emailField: true,
      successMessage: '',
    }
    this.props.initializeLanguage()
    this.props.addTranslationForLanguage(
      englishOrganizationLoginTranslations,
      'en',
    )
    this.props.addTranslationForLanguage(
      spanishOrganizationLoginTranslations,
      'es',
    )
    this.props.addTranslationForLanguage(
      chineseOrganizationLoginTranslations,
      'zh',
    )
  }

  componentWillMount() {
    if (this.props.user) {
      return this.props.history.push(`${config.principalUrl}/home`)
    }
  }

  handleSubmit = async values => {
    const response = await this.props.principalRecoverPassword(
      values,
      this.props,
    )
    console.log(response)
    if (response) {
      if (response.status === 'success') {
        this.setState({ emailField: false, successMessage: response.message })
      }
    }
  }

  render() {
    return (
      <RecoverPasswordForm
        onSubmit={this.handleSubmit}
        emailField={this.state.emailField}
        successMessage={this.state.successMessage}
      />
    )
  }
}

const mapStateToProps = state => ({
  user: state.authPrincipal.user,
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  principalRecoverPassword: (values, props) =>
    dispatch(principalRecoverPassword(values, props)),
  initializeLanguage: () => dispatch(initializeLanguage()),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(RecoverPassword),
)
