/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import Moment from 'react-moment'
import moment from 'moment'
import config from '../../config'

class DateTimeWithTimezone extends React.Component {
  render() {
    const { dateTime, isTime, isRed, start_date, end_date } = this.props
    let format = config.dateFormat
    let color = ''
    const dateTimeUTC = ''
    let dateTimelocal = ''
    if (isTime === 'true') {
      format = config.dateTimeFormat
      // dateTimelocal  = moment.utc(dateTime).toDate();
      const dbDate = moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss',
      )
      // const utcFormat = moment(dbDate).utc().format('YYYY-MM-DD HH:MM:SS');
      dateTimelocal = moment.utc(dbDate, 'YYYY-MM-DD HH:MM:SS').local()
    } else {
      const currentHH = moment()
        .utc()
        .format('HH')
      const currentMM = moment()
        .utc()
        .format('mm')
      const currentSS = moment()
        .utc()
        .format('ss')
      const time = ` ${currentHH}:${currentMM}:${currentSS}`
      const dbDate = moment(dateTime + time, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss',
      )
      const utcFormat = moment(dbDate)
        .utc()
        .format('YYYY-MM-DD HH:MM:SS')
      dateTimelocal = moment.utc(utcFormat, 'YYYY-MM-DD HH:MM:SS').local()
      // dateTimelocal = moment(dateTime).utc().format('YYYY-MM-DD HH:MM:SS');

      // dateTimelocal  = moment.utc(dateTime).toDate();
      // console.log(dbDate);
      // console.log(utcFormat);
      // console.log(dateTimelocal);
    }
    if (isRed === 'true') {
      let datePercentage = 0
      let totalDay = 0
      let leftDay = 0
      const startDate = moment(start_date)
      const currentDate = moment()
      const endDate = moment(end_date)
      totalDay = endDate.diff(startDate, 'days')
      leftDay = currentDate.diff(startDate, 'days')
      if (totalDay === 0) {
        datePercentage = 100
      } else if (leftDay === 0 && totalDay == 0) {
        datePercentage = 100
      } else if (leftDay > totalDay) {
        datePercentage = 100
      } else {
        datePercentage = parseInt(Number((leftDay * 100) / totalDay))
      }
      color = datePercentage >= 90 ? '#FF0000' : ''
    }
    return (
      <React.Fragment>
        <Moment format={`${format}`} style={{ color: `${color}` }}>
          {dateTimelocal}
        </Moment>
      </React.Fragment>
    )
  }
}

export default DateTimeWithTimezone
