/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
/**
 * import translation related files
 *
 */
// import { withLocalize } from "react-localize-redux";
// import { initializeLanguage } from './../../../../../store/actions/language';
import { getTranslate } from 'react-localize-redux'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    // this.props.initializeLanguage();
  }

  render() {
    const { translate } = this.props
    return (
      <footer className="footer footer-static footer-light navbar-border navbar-shadow">
        <div className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
          <span className="float-md-left d-block d-md-inline-block" />
        </div>
      </footer>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  // initializeLanguage: () => dispatch(initializeLanguage())
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
