/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { getTranslate } from 'react-localize-redux'

class RenderField extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      input,
      meta: { error, touched },
      type,
      placeholder,
      id,
      minlength,
      maxlength,
      disabled,
      translate,
    } = this.props

    return (
      <Fragment>
        <input
          minLength={minlength}
          maxLength={maxlength}
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          type={type}
          {...input}
          className={touched && error ? 'form-control square is-invalid' : 'form-control square'}
        />
        {touched &&
          error &&
          error.map((e, index) => (
            <span key={index} className="invalid-feedback">
              <strong>
                {e.split('_')[0] === 'errorMsgSchoolServiceCreateTotalLicenseLessthan' ||
                e.split('_')[0] === 'errorMsgSchoolServiceCreateTotalLicenseGreaterthan' ||
                e.split('_')[0] === 'errorMsgSchoolServiceCreateTotalLicenseLessZero' ||
                e.split('_')[0] === 'errorMsgClassServiceCreateTotalLicenseLessthan' ||
                e.split('_')[0] === 'errorMsgClassServiceCreateTotalLicenseGreaterthan' ||
                e.split('_')[0] === 'errorMsgClassServiceCreateTotalLicenseLessZero'
                  ? `${translate(e.split('_')[0])}` +
                    (e.split('_')[1] == 0 ? '' : `${e.split('_')[1]}`)
                  : translate(e.split('_')[0])}
              </strong>
            </span>
          ))}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default connect(mapStateToProps)(RenderField)
