import axios from 'axios'
import config from '../../config'
import handleResponseTeacherTranslate from './handleResponseTeacherTranslate'
import handleResponseStudentTranslate from './handleResponseStudentTranslate'

/**
 * Define actions
 *
 */
export const SET_HAS_PRIVACY_MODAL_BEEN_SHOWN =
  'SET_HAS_PRIVACY_MODAL_BEEN_SHOWN'
export const GET_INSTRUCTOR_STUDENT_LIST = 'GET_INSTRUCTOR_STUDENT_LIST'
export const SET_INSTRUCTOR_STUDENT_LIST_LOADING =
  'SET_INSTRUCTOR_STUDENT_LIST_LOADING'
export const SET_INSTRUCTOR_STUDENT_LIST_CURRENT_PAGE =
  'SET_INSTRUCTOR_STUDENT_LIST_CURRENT_PAGE'
export const GET_INSTRUCTOR_STUDENT_DETAILS_BY_STUDENT_ID =
  'GET_INSTRUCTOR_STUDENT_DETAILS_BY_STUDENT_ID'
export const INSTRUCTOR_STUDENT_CHANGE_PASSWORD_BY_STUDENT_ID =
  'INSTRUCTOR_STUDENT_CHANGE_PASSWORD_BY_STUDENT_ID'
export const INSTRUCTOR_STUDENT_UPDATE_BY_STUDENT_ID =
  'INSTRUCTOR_STUDENT_UPDATE_BY_STUDENT_ID'
export const INSTRUCTOR_STUDENT_CHANGE_STATUS_BY_STUDENT_ID =
  'INSTRUCTOR_STUDENT_CHANGE_STATUS_BY_STUDENT_ID'
export const TEACHER_RESET_ACTIVATION = 'TEACHER_RESET_ACTIVATION'
export const STUDENT_PROFILE = 'STUDENT_PROFILE'
export const STUDENT_CHANGE_PASSWORD = 'STUDENT_CHANGE_PASSWORD'
export const STUDENT_PHONEME_SCRORES_LIST = 'STUDENT_PHONEME_SCRORES_LIST'
export const STUDENT_PHONEME_SCRORES_LIST_LOADED =
  'STUDENT_PHONEME_SCRORES_LIST_LOADED'
export const STUDENT_PHONEME_SCRORES_LIST_LOADING =
  'STUDENT_PHONEME_SCRORES_LIST_LOADING'
export const STUDENT_ADVENTURE_ASSESMENT_LIST =
  'STUDENT_ADVENTURE_ASSESMENT_LIST'
export const STUDENT_ADVENTURE_ASSESMENT_LIST_LOADED =
  'STUDENT_ADVENTURE_ASSESMENT_LIST_LOADED'
export const STUDENT_ADVENTURE_ASSESMENT_LIST_LOADING =
  'STUDENT_ADVENTURE_ASSESMENT_LIST_LOADING'
export const STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADING =
  'STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADING'
export const STUDENT_ADVENTURE_ASSESMENT_DT_LIST =
  'STUDENT_ADVENTURE_ASSESMENT_DT_LIST'
export const STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADED =
  'STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADED'

/**
 * Set whether the privacy modal has been displayed yet for editing student's names
 */
export const setHasPrivacyModalBoxBeenShown = () => async dispatch => {
  return dispatch({
    type: SET_HAS_PRIVACY_MODAL_BEEN_SHOWN,
  })
}

/**
 * Set instructor students table current page
 *
 * @return updated value of current page
 */
export const setIntructorStudentListCurrentPage = page => async dispatch => {
  return dispatch({
    type: SET_INSTRUCTOR_STUDENT_LIST_CURRENT_PAGE,
    payload: page,
  })
}

/**
 * To get students of a teacher for teacher
 *
 * @return data
 */
export const getInstructorStudents = (page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }

    dispatch({
      type: SET_INSTRUCTOR_STUDENT_LIST_LOADING,
      payload: true,
    })
    const response = await axios.get(
      `${config.apiTeacherUrl}/student/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_INSTRUCTOR_STUDENT_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: SET_INSTRUCTOR_STUDENT_LIST_LOADING,
      payload: false,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, {}, 3)
  }
}

/**
 * To get students of a class for teacher
 *
 * @return data
 */
export const getInstructorStudentsByClassId = (
  classId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    dispatch({
      type: SET_INSTRUCTOR_STUDENT_LIST_LOADING,
      payload: true,
    })
    const response = await axios.get(
      `${config.apiTeacherUrl}/student/${classId}/class/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_INSTRUCTOR_STUDENT_LIST,
      payload: response.data.result.data,
    })
    return dispatch({
      type: SET_INSTRUCTOR_STUDENT_LIST_LOADING,
      payload: false,
    })
  } catch (error) {
    return handleResponseTeacherTranslate(error, {}, 3)
  }
}

/**
 * To get student detail of a student for teacher
 *
 * @param  int studentId
 * @return data
 */
export const getInstructorStudentDetailByStudentId = studentId => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiTeacherUrl}/student/${studentId}`,
      headers,
    )
    dispatch({
      type: GET_INSTRUCTOR_STUDENT_DETAILS_BY_STUDENT_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, {}, 3)
  }
}

/**
 * To generate password of a student for teacher
 *
 * @param  values object
 * @param  int studentId
 * @return data
 */
export const instructorStudentChangeStatusByStudentId = (
  values,
  studentId,
) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }

    const userValues = {
      password: values.password,
      confirm_password: values.confirm_password,
    }

    const response = await axios.put(
      `${config.apiTeacherUrl}/student/change-password/${studentId}`,
      userValues,
      headers,
    )
    dispatch({
      type: INSTRUCTOR_STUDENT_CHANGE_PASSWORD_BY_STUDENT_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, {}, 3)
  }
}

/**
 * To update a student for teacher
 *
 * @param  values object
 * @param  int studentId
 * @return data
 */
export const instructorStudentUpdateByStudentId = (values, studentId) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }

    const userValues = {
      name: values.alias,
    }

    const response = await axios.put(
      `${config.apiTeacherUrl}/student/${studentId}`,
      userValues,
      headers,
    )
    dispatch({
      type: INSTRUCTOR_STUDENT_UPDATE_BY_STUDENT_ID,
      payload: response.data.result.data,
    })
    return response.data.result.data.id
  } catch (error) {
    return handleResponseTeacherTranslate(error, {}, 3)
  }
}

/**
 * To change status of a student for teacher
 *
 * @param  int studentId
 * @return data
 */
export const instructorStudentChangeStatusBystudentId = (
  studentId,
  props,
) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiTeacherUrl}/student/change-status/${studentId}`,
      headers,
    )
    dispatch({
      type: INSTRUCTOR_STUDENT_CHANGE_STATUS_BY_STUDENT_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, props, 3)
  }
}

/**
 * To reset activation of a student for teacher
 *
 * @param  studentId number
 * @return data
 */
export const resetActivation = (studentId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiTeacherUrl}/student/reset-activation/${studentId}`,
      headers,
    )
    dispatch({
      type: TEACHER_RESET_ACTIVATION,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, props, 1)
  }
}

/**
 * To get student profile for student
 *
 * @return data
 */
export const getStudentProfile = props => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authStudent.accessToken}`,
      },
    }

    const response = await axios.get(`${config.apiStudentUrl}/profile`, headers)
    dispatch({
      type: STUDENT_PROFILE,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseStudentTranslate(error, props, 3)
  }
}

/**
 * To change password of a student for student
 *
 * @param  values object
 * @return data
 */
export const studentChangePassword = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authStudent.accessToken}`,
      },
    }

    const userValues = {
      current_password: values.current_password,
      password: values.password,
      confirm_password: values.confirm_password,
    }

    const response = await axios.put(
      `${config.apiStudentUrl}/change-password`,
      userValues,
      headers,
    )
    dispatch({
      type: STUDENT_CHANGE_PASSWORD,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseStudentTranslate(error, props, 1)
  }
}
/**
 * To get phonemes scores for student
 *
 * @param  values object
 * @return data
 */
export const studentGetPhonemeScores = (props, studentId) => async (
  dispatch,
  getState,
) => {
  try {
    if (typeof studentId === 'undefined' || !studentId) {
      dispatch({
        type: STUDENT_PHONEME_SCRORES_LIST_LOADING,
      })
      const headers = {
        headers: {
          access_token: `${getState().authStudent.accessToken}`,
        },
      }

      const response = await axios.get(
        `${config.apiStudentUrl}/phoneme-scores`,
        headers,
      )
      dispatch({
        type: STUDENT_PHONEME_SCRORES_LIST,
        payload: response.data.result.data,
      })
      dispatch({
        type: STUDENT_PHONEME_SCRORES_LIST_LOADED,
      })
      return response.data.result.data.phonemeScores
    }
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiTeacherUrl}/student/${studentId}/stats-pronunciation`,
      headers,
    )
    return response.data.result.data.phonemeScores
  } catch (error) {
    return handleResponseStudentTranslate(error, props, 1)
  }
}

/**
 * To get adventure assessment stats for student
 *
 * @param  values object
 * @return data
 */
export const studentGetAdventureAssessmentStats = (props, student) => async (
  dispatch,
  getState,
) => {
  try {
    if (typeof student === 'undefined' || !student) {
      dispatch({
        type: STUDENT_ADVENTURE_ASSESMENT_LIST_LOADING,
      })
      const headers = {
        headers: {
          access_token: `${getState().authStudent.accessToken}`,
        },
      }

      const response = await axios.get(
        `${config.apiStudentUrl}/adventure-assessment-stats`,
        headers,
      )

      dispatch({
        type: STUDENT_ADVENTURE_ASSESMENT_LIST,
        payload: response.data.result.data,
      })
      dispatch({
        type: STUDENT_ADVENTURE_ASSESMENT_LIST_LOADED,
      })
      return response.data.result.data.adventures
    }
    dispatch({
      type: STUDENT_ADVENTURE_ASSESMENT_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiTeacherUrl}/student/${student}/stats-adventure`,
      headers,
    )
    return response.data.result.data.adventures
  } catch (error) {
    return handleResponseStudentTranslate(error, props, 1)
  }
}

/**
 * To get adventure assessment stats details for student
 *
 * @param  values object
 * @return data
 */
export const studentGetAdventureAssessmentStatsDetails = (
  props,
  advId,
  studentId,
) => async (dispatch, getState) => {
  try {
    if (typeof studentId === 'undefined' || !studentId) {
      dispatch({
        type: STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADING,
      })
      const headers = {
        headers: {
          access_token: `${getState().authStudent.accessToken}`,
        },
      }

      const response = await axios.get(
        `${config.apiStudentUrl}/adventure-assessment-stats-detail/${advId}`,
        headers,
      )
      dispatch({
        type: STUDENT_ADVENTURE_ASSESMENT_DT_LIST,
        payload: response.data.result.data,
      })
      dispatch({
        type: STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADED,
      })
      return response.data.result.data.adventures
    }
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiTeacherUrl}/student/${studentId}/${advId}`,
      headers,
    )
    return response.data.result.data.adventures
  } catch (error) {
    return handleResponseStudentTranslate(error, props, 1)
  }
}
