import { GET_ADMIN_USER_LIST } from '../actions/adminUser'

const adminUserListReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_ADMIN_USER_LIST:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default adminUserListReducer
