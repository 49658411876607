/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm, Field, reset } from 'redux-form'
import { Modal } from 'react-bootstrap'
import { getTranslate } from 'react-localize-redux'
import Loader from '../../../../../components/Loader'
import RenderField from '../../../../../components/RenderFieldTranlate'
import RenderMultipleSelectWithCreateField from '../../../../../components/RenderMultipleSelectWithCreateFieldTranslate'
import validateForm from './helpers'
import { getOrganizationTags } from '../../../../../store/actions/tag'

/**
 * import translation related files
 *
 */

class CreateNewFormModelBox extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.props.getOrganizationTags(this.props)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      error,
      reset,
      handleCancel,
      isShowChangePassword,
      handleNewPrincipalModelClose,
      translate,
      tags,
      handleCreateTag,
      handleNewMultiplePrincipalModelShow,
    } = this.props
    return (
      <Modal
        show={isShowChangePassword}
        onHide={handleNewPrincipalModelClose}
        animation={false}
        id="changePassword"
        dialogClassName=""
        aria-labelledby="basicModalLabel1"
      >
        <div className="modal-content">
          <form
            className="form-horizontal"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="modal-header">
              <h4 className="modal-title" id="basicModalLabel1">
                {translate('formPrincipalTitleAdd')}
              </h4>
              <button
                type="button"
                className="close"
                onClick={handleNewPrincipalModelClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="name">
                  <span>*</span>
                  {translate('formPrincipalFieldName')}
                </label>
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={translate('formPrincipalFieldName')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  <span>*</span>
                  {translate('formPrincipalFieldEmail')}
                </label>
                <Field
                  name="email"
                  component={RenderField}
                  type="text"
                  placeholder={translate('formPrincipalFieldEmail')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="verify_email">
                  <span>*</span>
                  {translate('formPrincipalFieldVerifyEmail')}
                </label>
                <Field
                  name="verify_email"
                  component={RenderField}
                  type="text"
                  placeholder={translate('formPrincipalFieldVerifyEmail')}
                />
              </div>
              <div className="form-group select_p_tags">
                <label htmlFor="reportName">
                  {translate('formPrincipalFieldTags')}
                </label>
                <Field
                  name="tag_ids"
                  component={RenderMultipleSelectWithCreateField}
                  data={tags}
                  textField="name"
                  valueField="id"
                  type="text"
                  formName="organization-create-principal"
                  fieldName="tag_ids"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={
                  pristine || submitting
                    ? handleNewPrincipalModelClose
                    : handleCancel
                }
              >
                {translate('formPrincipalBTNCancel')}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {submitting ? <Loader /> : translate('formPrincipalBTNCreate')}
              </button>
            </div>
            <div className="modal-texts text-center">
              <p>
                {translate('formPrincipalAddMuiltipleTitle')}{' '}
                <Link
                  className="multiple_i_show"
                  onClick={handleNewMultiplePrincipalModelShow}
                >
                  {translate('formInstructorAddMuiltipleTitleA')}
                </Link>
              </p>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

CreateNewFormModelBox = reduxForm({
  form: 'organization-create-principal',
  validate: validateForm,
  enableReinitialize: true,
})(CreateNewFormModelBox)

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  tags: state.organizationOrganizationTags.data.tags,
  initialValues: { name: '', email: '', verify_email: '', tag_ids: '' },
})

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset('organization-create-principal')),
  getOrganizationTags: props => dispatch(getOrganizationTags(props)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewFormModelBox)
