import {
  GET_ORGANIZATION_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADING,
  GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADED,
} from '../actions/organizations'

const organizationOrganizationDetailReducer = (
  state = { data: [] },
  action,
) => {
  switch (action.type) {
    case GET_ORGANIZATION_ORGANIZATION_DETAILS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_ORGANIZATION_ORGANIZATION_DETAILS_LOADED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default organizationOrganizationDetailReducer
