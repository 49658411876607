/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'
import CommonClassAttendance from '../../../../../Common/Report/ClassAttendance'
import { getAdminClassAttendencesReportByReportId } from '../../../../../../store/actions/report'
/**
 * import translation related files
 *
 */

class ClassAttendance extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  componentWillMount() {
    const loadingTable = this.state.loading
    this.props.getAdminClassAttendencesReportByReportId(
      this.props.organizationId,
      this.props.reportId,
    )
  }

  render() {
    const {
      translate,
      data,
      loading,
      handleChartClassAttendanceWrapper,
    } = this.props
    return (
      <CommonClassAttendance
        data={data}
        loading={loading}
        handleChartClassAttendanceWrapper={handleChartClassAttendanceWrapper}
      />
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.adminClassAttendance.data.attendances,
  loading: state.adminClassAttendance.loading,
})

const mapDispatchToProps = dispatch => ({
  getAdminClassAttendencesReportByReportId: (organizationId, reportId) => {
    dispatch(getAdminClassAttendencesReportByReportId(organizationId, reportId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassAttendance)
