/* TODO: Fix eslint */
/* eslint-disable */

import isEmail from 'validator/lib/isEmail'
import isEquals from 'validator/lib/equals'

const validateForm = (values, props) => {
  const errors = {}

  // name
  if (!values.name) {
    errors.name = [`errorMsggPrincipalServiceCreateNameMissing`]
  } else if (values.name.length < 2) {
    errors.name = [`errorMsgPrincipalServiceCreateNameIsLength`]
  } else if (values.name.length > 50) {
    errors.name = [`errorMsgPrincipalServiceCreateNameIsLength`]
  }

  // email
  if (!values.email) {
    errors.email = [`errorMsgPrincipalServiceCreateEmailMissing`]
  } else if (!isEmail(values.email)) {
    errors.email = [`errorMsgPrincipalServiceCreateEmailIsEmail`]
  } else if (values.email.length > 100) {
    errors.phone = [`errorMsgPrincipalServiceCreateEmailIsLength`]
  }

  // email
  if (values.email) {
    if (!values.verify_email) {
      errors.verify_email = [`errorMsgPrincipalServiceCreateVerifyEmailMissing`]
    } else if (!isEquals(values.verify_email, values.email)) {
      errors.verify_email = [`errorMsgPrincipalServiceCreateVerifyEmailEquals`]
    }
  }

  return errors
}

export default validateForm
