import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { withLocalize, getTranslate } from 'react-localize-redux'

import config from 'config'
import Teacher from 'pages/Teacher/Home/Common/Teacher'
import ClassDetail from 'pages/Teacher/Home/Students/Details/ClassDetail'
import {
  getInstructorStudentDetailByStudentId,
  instructorStudentUpdateByStudentId,
  instructorStudentChangeStatusBystudentId,
} from 'store/actions/student'
import 'react-confirm-alert/src/react-confirm-alert.css'
import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'
import englishFormTranslations from 'lang/organization/form/en.json'
import spanishFormTranslations from 'lang/organization/form/es.json'
import chineseFormTranslations from 'lang/organization/form/zh.json'
import EditForm from './EditForm'

class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = { studentId: '' }

    const { addTranslationForLanguage } = this.props
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
    addTranslationForLanguage(englishFormTranslations, 'en')
    addTranslationForLanguage(spanishFormTranslations, 'es')
    addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentWillMount() {
    const { match, history, getInstructorStudentDetailByStudentId } = this.props
    const studentId = match.params.sid
    if (studentId === ' ' || studentId === 'undefined') {
      history.push(`${config.teacherUrl}/home`)
    }
    this.setState({ studentId })
    getInstructorStudentDetailByStudentId(studentId)
  }

  handleCancel = () => {
    const { studentId } = this.state
    const { translate, history } = this.props

    confirmAlert({
      title: translate('alertHeading'),
      message: translate('alertFormText'),
      buttons: [
        {
          label: translate('btnYes'),
          onClick: () => {
            history.push(`${config.teacherUrl}/student/${studentId}`)
          },
        },
        {
          label: translate('btnNo'),
        },
      ],
    })
  }

  handleRedirect = () => {
    const { studentId } = this.state
    const { history } = this.props
    history.push(`${config.teacherUrl}/student/${studentId}`)
  }

  handleSubmit = async values => {
    const { studentId } = this.state
    const { history, instructorStudentUpdateByStudentId } = this.props
    await instructorStudentUpdateByStudentId(values, studentId)
    history.push(`${config.teacherUrl}/student/${studentId}`)
  }

  handeChangeStatus = async status => {
    const { studentId } = this.state
    const {
      translate,
      history,
      instructorStudentChangeStatusBystudentId,
      getInstructorStudentDetailByStudentId,
    } = this.props

    confirmAlert({
      title: translate('alertHeading'),
      message:
        status === 'Active'
          ? translate('alertChangeStatusStudentDeactivate')
          : translate('alertChangeStatusStudentActivate'),
      buttons: [
        {
          label: translate('btnYes'),
          onClick: async () => {
            await instructorStudentChangeStatusBystudentId(
              studentId,
              this.props,
            )
            await getInstructorStudentDetailByStudentId(studentId)
            history.push(`${config.teacherUrl}/student/${studentId}`)
          },
        },
        {
          label: translate('btnNo'),
        },
      ],
    })
  }

  render() {
    const { translate, studentRow } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              {translate('breadCrumbStudentEdit')}
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.teacherUrl}/home`}>
                      {translate('breadCrumbHome')}
                    </Link>{' '}
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.teacherUrl}/student/${studentRow.row &&
                        studentRow.row.id}`}
                    >
                      {translate('breadCrumbStudent')}
                    </Link>{' '}
                  </li>
                  <li className="breadcrumb-item active">
                    {translate('breadCrumbStudentEdit')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <Teacher />
          <EditForm
            row={studentRow.row}
            onSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
            handleRedirect={this.handleRedirect}
            handeChangeStatus={this.handeChangeStatus}
          />
          <ClassDetail row={studentRow.classRow} />
        </div>
      </div>
    )
  }
}

Details.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  studentRow: PropTypes.oneOfType([
    PropTypes.shape({
      row: PropTypes.object,
      classRow: PropTypes.object,
      sessions: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
      ]),
    }),
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  getInstructorStudentDetailByStudentId: PropTypes.func.isRequired,
  instructorStudentUpdateByStudentId: PropTypes.func.isRequired,
  instructorStudentChangeStatusBystudentId: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  studentRow: state.teacherStudentDetail.data,
})

const mapDispatchToProps = dispatch => ({
  getInstructorStudentDetailByStudentId: studentId =>
    dispatch(getInstructorStudentDetailByStudentId(studentId)),
  instructorStudentUpdateByStudentId: (values, studentId) =>
    dispatch(instructorStudentUpdateByStudentId(values, studentId)),
  instructorStudentChangeStatusBystudentId: (studentId, props) =>
    dispatch(instructorStudentChangeStatusBystudentId(studentId, props)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Details),
)
