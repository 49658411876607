import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withLocalize, getTranslate } from 'react-localize-redux'
import AdventureTable from 'components/Data/AdventureTable'
import CombinedPronunciationCharts from 'components/Data/CombinedPronunciationCharts'
import config from 'config'
import Teacher from 'pages/Teacher/Home/Common/Teacher'
import Detail from 'pages/Teacher/Home/Students/Details/Detail'
import ClassDetail from 'pages/Teacher/Home/Students/Details/ClassDetail'
import {
  getInstructorStudentDetailByStudentId,
  studentGetAdventureAssessmentStats,
  studentGetAdventureAssessmentStatsDetails,
  studentGetPhonemeScores,
} from 'store/actions/student'

import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'

class Details extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      adventureData: [],
      phonemeData: [],
    }

    const { addTranslationForLanguage } = this.props
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }

  async componentDidMount() {
    const {
      match,
      history,
      getInstructorStudentDetailByStudentId,
      studentGetAdventureAssessmentStats,
      studentGetAdventureAssessmentStatsDetails,
      studentGetPhonemeScores,
    } = this.props
    const studentId = match.params.sid
    if (studentId === ' ' || studentId === 'undefined') {
      history.push(`${config.teacherUrl}/home`)
    }
    getInstructorStudentDetailByStudentId(studentId)
    const advData = await studentGetAdventureAssessmentStats(
      this.props,
      studentId,
    )
    advData.forEach(async (adv, idx) => {
      advData[idx].all = await studentGetAdventureAssessmentStatsDetails(
        this.props,
        adv.id,
        studentId,
      )
    })
    this.setState({
      adventureData: advData,
    })
    const phonemes = await studentGetPhonemeScores(this.props, studentId)
    this.setState({ phonemeData: phonemes })
  }

  render() {
    const { translate, studentRow } = this.props
    const { adventureData, phonemeData } = this.state
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              {translate('breadCrumbStudentDetail')}
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.teacherUrl}/home`}>
                      {translate('breadCrumbHome')}
                    </Link>{' '}
                  </li>
                  <li className="breadcrumb-item active">
                    {translate('breadCrumbStudentDetail')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <Teacher />
          <Detail row={studentRow.row} />
          <ClassDetail
            row={studentRow.classRow}
            sessions={studentRow.sessions}
          />
          <div className="student-stats">
            {/* TODO: Add attendance/session graph */}
            <CombinedPronunciationCharts data={phonemeData} />
            <AdventureTable data={adventureData} />
          </div>
        </div>
      </div>
    )
  }
}

Details.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  getInstructorStudentDetailByStudentId: PropTypes.func.isRequired,
  studentGetAdventureAssessmentStats: PropTypes.func.isRequired,
  studentGetAdventureAssessmentStatsDetails: PropTypes.func.isRequired,
  studentGetPhonemeScores: PropTypes.func.isRequired,
  studentRow: PropTypes.oneOfType([
    PropTypes.shape({
      row: PropTypes.object,
      classRow: PropTypes.object,
      sessions: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
      ]),
    }),
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  studentRow: state.teacherStudentDetail.data,
})

const mapDispatchToProps = dispatch => ({
  getInstructorStudentDetailByStudentId: studentId =>
    dispatch(getInstructorStudentDetailByStudentId(studentId)),
  studentGetAdventureAssessmentStats: (props, studentId) =>
    dispatch(studentGetAdventureAssessmentStats(props, studentId)),
  studentGetAdventureAssessmentStatsDetails: (props, adventureId, studentId) =>
    dispatch(
      studentGetAdventureAssessmentStatsDetails(props, adventureId, studentId),
    ),
  studentGetPhonemeScores: (props, studentId) =>
    dispatch(studentGetPhonemeScores(props, studentId)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Details),
)
