/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import Moment from 'react-moment'
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { getTranslate } from 'react-localize-redux'
import { getOrganizationOrganizationDetails } from '../../../../../store/actions/organizations'
import config from '../../../../../config'
import DisplayMobileFormat from '../../../../../components/DisplayMobileFormat'
import DateTimeWithTimezone from '../../../../../components/DateTimeWithTimezone'
/**
 * import translation related files
 *
 */

class Organization extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  componentWillMount() {
    this.setState({ loading: true })
    this.props.getOrganizationOrganizationDetails(this.props)
  }

  render() {
    const { organizationRow, translate } = this.props
    let loadingTable = this.state.loading
    loadingTable = this.props.loading
    return (
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <div className="card">
            <LoadingOverlay
              active={loadingTable}
              spinner
              text={translate('loadingOverlayText')}
            >
              <div className="card-header">
                <h4 className="org-id">
                  {translate('id')}:{organizationRow && organizationRow.id}
                </h4>
                <h4 className="card-title">
                  {translate('organizationName')}:{' '}
                  {organizationRow && organizationRow.organization_name}
                </h4>
                <a className="heading-elements-toggle">
                  <i className="la la-ellipsis-v font-medium-3" />
                </a>
                <div className="heading-elements">
                  <ul className="list-inline mb-0">
                    <li>
                      <Link
                        className="add_btn pull-right"
                        to={`${config.organizationUrl}/edit`}
                      >
                        {translate('btnEdit')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-content collapse show">
                <div className="card-body pb-0 card_margin">
                  <h6 className="contact_heading">{translate('address')}</h6>
                  <p className="address_Org">
                    {organizationRow && organizationRow.address_1}
                    {organizationRow &&
                    organizationRow.address_2 !== null &&
                    organizationRow.address_2 !== ''
                      ? ', '
                      : ''}
                    {organizationRow && organizationRow.address_2}
                    {organizationRow &&
                    organizationRow.address_3 !== null &&
                    organizationRow.address_3 !== ''
                      ? ', '
                      : ''}
                    {organizationRow && organizationRow.address_3}
                    <br /> {organizationRow && organizationRow.city},{' '}
                    {organizationRow && organizationRow.state_name}
                    <br />
                    {organizationRow && organizationRow.zip_code},{' '}
                    {organizationRow && organizationRow.country}
                  </p>
                  <h6 className="contact_heading">{translate('contact')}</h6>
                  <ul className="contact_informations">
                    <li>{organizationRow && organizationRow.name}</li>
                    <li>
                      <h6 className="contact_heading status_heading">
                        {translate('Ph')}
                      </h6>
                      <DisplayMobileFormat
                        code={
                          organizationRow && organizationRow.phone_country_code
                        }
                        number={organizationRow && organizationRow.phone}
                      />
                    </li>
                    {organizationRow &&
                      organizationRow.fax &&
                      organizationRow.fax != null && (
                        <li>
                          <h6 className="contact_heading status_heading">
                            {translate('Fx')}
                          </h6>
                          <DisplayMobileFormat
                            code={
                              organizationRow &&
                              organizationRow.fax_country_code
                            }
                            number={organizationRow && organizationRow.fax}
                          />
                        </li>
                      )}
                    <li>{organizationRow && organizationRow.email}</li>
                  </ul>
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </div>
        <div className="col-lg-4 col-md-12">
          <div className="row">
            <div className="col-12">
              <LoadingOverlay
                active={loadingTable}
                spinner
                text={translate('loadingOverlayText')}
              >
                <div className="card pull-up">
                  <div className="card-header">
                    <h6 className="card-title float-left">
                      {translate('startDate')}
                      <span className="start_date_color">
                        <DateTimeWithTimezone
                          dateTime={
                            organizationRow &&
                            organizationRow.contract_start_date
                          }
                          isTime="false"
                          isRed="false"
                        />
                      </span>
                    </h6>
                    <h6 className="card-title float-right">
                      {translate('endDate')}
                      <span className="end_date_color">
                        <DateTimeWithTimezone
                          dateTime={
                            organizationRow && organizationRow.contract_end_date
                          }
                          isTime="false"
                          isRed="true"
                          start_date={
                            organizationRow &&
                            organizationRow.contract_start_date
                          }
                          end_date={
                            organizationRow && organizationRow.contract_end_date
                          }
                        />
                      </span>
                    </h6>
                  </div>
                  <div className="card-content collapse show">
                    <div className="card-body pt-0 pb-1">
                      <h6 className="text-muted font-small-3">
                        <span className="l_acti">
                          {translate('licenses')} /{translate('activated')} :
                        </span>{' '}
                        <NumberFormat
                          thousandSeparator
                          value={
                            organizationRow &&
                            organizationRow.totalActiveLicense
                              ? organizationRow.totalActiveLicense
                              : 0
                          }
                          displayType="text"
                        />{' '}
                        /{' '}
                        <NumberFormat
                          thousandSeparator
                          value={
                            organizationRow && organizationRow.totalLicense
                              ? organizationRow.totalLicense
                              : 0
                          }
                          displayType="text"
                        />
                      </h6>
                      <div className="progress progress-sm mt-1 mb-0 box-shadow-2">
                        <div
                          className="progress-bar bg-gradient-x-info"
                          role="progressbar"
                          style={{
                            width: `${organizationRow &&
                              parseInt(organizationRow.activeLicenseRation)}%`,
                            backgroundImage: `${
                              organizationRow &&
                              organizationRow.activeLicenseRation >= 90
                                ? 'linear-gradient(to right, #ec6b6b 0%, #ff4747 100%)'
                                : ''
                            }`,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  organizationRow: state.organizationOrganizationDetail.data.row,
  loading: state.organizationOrganizationDetail.loading,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationOrganizationDetails: props =>
    dispatch(getOrganizationOrganizationDetails(props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Organization)
