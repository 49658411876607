/* TODO: Fix eslint */
/* eslint-disable */

/** Import CSS File * */
// BEGIN: Vendor CSS
import '../app-assets/vendors/css/charts/chartist.css'
import '../app-assets/vendors/css/charts/chartist-plugin-tooltip.css'
// END: Vendor CSS

// BEGIN: Theme CSS
import '../app-assets/vendors/css/forms/selects/select2.min.css'
// END: Theme CSS

// BEGIN: Page CSS
import '../app-assets/css/core/menu/menu-types/vertical-menu.css'
import '../app-assets/css/core/colors/palette-gradient.css'
import '../app-assets/css/pages/chat-application.css'
import '../app-assets/css/pages/dashboard-analytics.css'
// END: Page CSS-

// BEGIN: Custom CSS-
import '../assets/css/style.css'
import '../assets/css/organization-style.css'
// END: Custom CSS-

import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import StudentDashboard from '../pages/Student/Home'

class DashboardRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={matchProps => (
          <StudentDashboard>
            <Component {...matchProps} />
          </StudentDashboard>
        )}
      />
    )
  }
}

const mapStateToProps = state => ({
  user: state.authStudent.user,
})

export default connect(mapStateToProps)(DashboardRoute)
