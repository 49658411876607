/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'
import TableRowLicenseProgressBar from '../../../../../../components/TableRowLicenseProgressBar'

class Detail extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { translate, row } = this.props
    const tagArr = row && row.tags ? row.tags.split(',') : []
    return (
      <div className="col-md-12 col-lg-8 pull-left">
        <h5 className="card-title text-bold-700 my-2">
          {translate('schoolDetailTitle')}
          <Link
            className="add_btn pull-right"
            to={`${config.organizationUrl}/edit-school/${row && row.id}`}
          >
            {translate('btnEditSchool')}
          </Link>
        </h5>
        <div className="card">
          <div className="card-content collapse show">
            <div className="card-footer text-center p-1">
              <div className="row">
                <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('schoolDetailRowName')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.name}
                  </p>
                </div>
                <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('schoolDetailRowPrincipalName')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    <Link
                      to={`${config.organizationUrl}/principal/${row &&
                        row.principal}`}
                    >
                      {row && row.principal_name}
                    </Link>
                  </p>
                </div>
                <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('schoolDetailRowLicenses')}
                  </p>
                  <span className="text-center">
                    <NumberFormat
                      thousandSeparator
                      value={
                        row && row.total_active_license
                          ? row.total_active_license
                          : 0
                      }
                      displayType="text"
                    />{' '}
                    /{' '}
                    <NumberFormat
                      thousandSeparator
                      value={row && row.total_license ? row.total_license : 0}
                      displayType="text"
                    />
                  </span>
                  <TableRowLicenseProgressBar
                    activeLicense={row && row.total_active_license}
                    totalLicense={row && row.total_license}
                  />
                </div>
                <div className="col-md-3 col-12 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('schoolDetailRowPrincipalStatus')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    <span
                      className={
                        row && row.status === 'Active'
                          ? 'status_approved'
                          : 'status_pending'
                      }
                    >
                      {row && row.status}
                    </span>
                  </p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-12">
                  <p className="blue-grey lighten-2 mb-0 text-left">
                    {translate('schoolTableRowTags')}
                  </p>
                  <div className="tags_div">
                    {tagArr && tagArr.map(tag => <p>{tag}</p>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
