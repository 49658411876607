import { SubmissionError } from 'redux-form'

const handleResponseTeacherTranslate = async (error, props, type) => {
  if (error.response) {
    const { status } = error.response
    const { data } = error.response
    if (status === 401 && data === 'Unauthorized') {
      localStorage.removeItem('authTeacher')
      window.location.reload(true)
    } else if (status === 422 || status === 400) {
      const errors = {}
      let failed = ''
      if (error.response.data.result.errors) {
        error.response.data.result.errors.forEach(obj => {
          if (obj) {
            Object.keys(obj).forEach(o => {
              if (o) {
                const message = obj[o]
                if (o === 'failed') {
                  errors.error = [`${message}`]
                } else {
                  errors[o] = [`${message}`]
                }
                failed = [`${message}`]
              }
            })
          }
        })

        if (type === 1) {
          throw new SubmissionError({
            ...errors,
            _error: 'Something went wrong. Please check for validation errors.',
          })
        } else if (type === 2) {
          throw new SubmissionError({
            _error: failed,
          })
        }
      }
    }
  }
}

export default handleResponseTeacherTranslate
