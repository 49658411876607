import { GET_CLASS_DETAILS_BY_CLASS_ID } from '../actions/class'

const organizatoinClassDetailReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_CLASS_DETAILS_BY_CLASS_ID:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizatoinClassDetailReducer
