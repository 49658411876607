import {
  STUDENT_PHONEME_SCRORES_LIST,
  STUDENT_PHONEME_SCRORES_LIST_LOADED,
  STUDENT_PHONEME_SCRORES_LIST_LOADING,
} from 'store/actions/student'

const initialState = {
  phonemeScoresData: [],
}

const phonemeScoresReducer = (state = initialState, action = {}) => {
  const newState = state
  switch (action.type) {
    case STUDENT_PHONEME_SCRORES_LIST:
      if (action.payload.currentPage === 0) {
        newState.phonemeScoresData = action.payload.phonemeScores
        return {
          phonemeScoresData: newState.phonemeScoresData,
          ...newState,
          data: action.payload,
        }
      }
      newState.phonemeScoresData = action.payload.phonemeScores
      return {
        phonemeScoresData: newState.phonemeScoresData,
        ...newState,
        data: action.payload,
      }

    case STUDENT_PHONEME_SCRORES_LIST_LOADED:
      return {
        ...newState,
        loading: false,
      }
    case STUDENT_PHONEME_SCRORES_LIST_LOADING:
      return {
        ...newState,
        loading: true,
      }
    default:
      return newState
  }
}

export default phonemeScoresReducer
