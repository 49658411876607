/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import Moment from 'react-moment'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { getOrganizationOrganizationStats } from '../../../../../../store/actions/organizations'

/**
 * import translation related files
 *
 */
import englishStatsTranslations from '../../../../../../lang/organization/stats/en.json'
import spanishStatsTranslations from '../../../../../../lang/organization/stats/es.json'
import chineseStatsTranslations from '../../../../../../lang/organization/stats/zh.json'

class Stats extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishStatsTranslations, 'en')
    this.props.addTranslationForLanguage(spanishStatsTranslations, 'es')
    this.props.addTranslationForLanguage(chineseStatsTranslations, 'zh')
  }

  componentWillMount() {
    // this.props.getOrganizationOrganizationStats(this.props);
  }

  render() {
    const { row, translate } = this.props
    return (
      <div className="card">
        <div className="card-header p-1">
          <h4 className="card-title float-left">
            School Stats
            {/* } - <span className="blue-grey lighten-2 font-small-3 mb-0">Accumulated 20 May 2019</span>{ */}
          </h4>
        </div>
        <div className="card-content collapse show">
          <div className="card-footer text-center p-1">
            <div className="row">
              <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                <p className="blue-grey lighten-2 mb-0">Lorem Ipsum</p>
                <p className="font-medium-5 text-bold-400">5</p>
              </div>
              <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                <p className="blue-grey lighten-2 mb-0">Lorem Ipsum</p>
                <p className="font-medium-5 text-bold-400">120</p>
              </div>
              <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                <p className="blue-grey lighten-2 mb-0">Lorem Ipsum</p>
                <p className="font-medium-5 text-bold-400">210</p>
              </div>
              <div className="col-md-6 col-12 text-center">
                <p className="blue-grey lighten-2 mb-0">Lorem Ipsum</p>
                <p className="font-medium-5 text-bold-400">1598</p>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  // row:state.organizationOrganizationStats.data
})

const mapDispatchToProps = dispatch => ({
  // getOrganizationOrganizationStats: (props) => dispatch(getOrganizationOrganizationStats(props))
})

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Stats))
