/* TODO: Fix eslint */
/* eslint-disable */

import isEmail from 'validator/lib/isEmail'

const validateForm = (values, props) => {
  const errors = {}

  // email
  if (!values.email) {
    errors.email = [`errorMsgEmailRequired`]
  } else if (!isEmail(values.email)) {
    errors.email = [`errorMsgEmailValid`]
  }

  // password
  if (!values.password) {
    errors.password = [`errorMsgPasswordRequired`]
  }

  return errors
}

export default validateForm
