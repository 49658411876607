import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import DataTable from 'react-data-table-component'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { StarRating } from 'components/StarRating'
import DateTimeWithTimezone from 'components/DateTimeWithTimezone'

import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'

class ExpandableComponent extends React.Component {
  constructor(props) {
    super(props)
    const { addTranslationForLanguage } = this.props
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }

  render() {
    const { translate, data } = this.props
    const columns = [
      {
        name: translate('adventureAssessmentStatsTableRowSession'),
        selector: 'created_at',
        sortable: true,
        cell: row => (
          <Fragment>
            <DateTimeWithTimezone
              dateTime={row.created_at}
              isTime="false"
              isRed="false"
            />
          </Fragment>
        ),
      },
      {
        name: translate('adventureAssessmentStatsTableRowCompleted'),
        selector: 'completed',
        sortable: true,
        right: true,
        cell: row => (
          <Fragment>
            {row.completed === 1 ? translate('btnYes') : translate('btnYes')}
          </Fragment>
        ),
      },
      {
        name: translate('adventureAssessmentStatsTableRowTime'),
        selector: 'time',
        sortable: true,
        right: true,
      },

      {
        name: translate('adventureAssessmentStatsTableRowScore'),
        selector: 'score',
        sortable: true,
        right: true,
        cell: row => (
          <NumberFormat
            thousandSeparator
            value={Number(row.score)}
            displayType="text"
          />
        ),
      },

      {
        name: translate('adventureAssessmentStatsTableRowStars'),
        selector: 'stars',
        sortable: true,
        right: true,
        cell: row => (
          <StarRating number={Math.round(row.stars * 100) / 100} count={3} />
        ),
      },
    ]
    return (
      <React.Fragment>
        <DataTable
          columns={columns}
          data={data.all}
          className="aggregate_school_table "
        />
      </React.Fragment>
    )
  }
}

ExpandableComponent.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  data: PropTypes.shape({
    all: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default withLocalize(connect(mapStateToProps)(ExpandableComponent))
