/* TODO: Fix eslint */
/* eslint-disable */

const validateForm = (values, props) => {
  const errors = {}

  // code
  if (!values.code) {
    errors.code = [`errorMsgStudentAuthServiceLoginLicenseMissing`]
  }

  // password
  if (!values.password) {
    errors.password = [`errorMsgStudentAuthServiceLoginPasswordMissing`]
  }

  return errors
}

export default validateForm
