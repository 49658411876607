import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DataTable from 'react-data-table-component'
import NumberFormat from 'react-number-format'
import LoadingOverlay from 'react-loading-overlay'
import { getTranslate } from 'react-localize-redux'
import { StarRating } from '../../../../components/StarRating'

class AdventureStats extends React.Component {
  render() {
    const { translate, data, dataAssessment } = this.props
    const loadingTable = false
    const columns = [
      {
        name: translate('reportAdventureAssessmentStatsTableRowAdventure'),
        selector: 'name',
        sortable: true,
      },
      {
        name: translate('reportAdventureAssessmentStatsTableRowFocusWords'),
        selector: 'focus_words',
        sortable: true,
      },
      {
        name: translate(
          'reportAdventureAssessmentStatsTableRowGrammarPrincipal',
        ),
        selector: 'grammar_principles',
        sortable: true,
      },
      {
        name: translate('reportAdventureAssessmentStatsTableRowAverageTime'),
        selector: 'time',
        sortable: true,
        cell: row => (
          <React.Fragment>
            {(Math.round(row.time * 100) / 100).toFixed(1)}
          </React.Fragment>
        ),
      },
      {
        name: translate('reportAdventureAssessmentStatsTableRowAverageScore'),
        selector: 'score',
        sortable: true,
        cell: row => (
          <NumberFormat
            thousandSeparator
            value={(Math.round(row.score * 100) / 100).toFixed(1)}
            displayType="text"
          />
        ),
      },
      {
        name: translate('reportAdventureAssessmentStatsTableRowAverageStars'),
        selector: 'stars',
        sortable: true,
        cell: row => (
          <StarRating
            number={(Math.round(row.stars * 100) / 100).toFixed(1)}
            count={3}
          />
        ),
      },
      {
        name: translate(
          'reportAdventureAssessmentStatsTableRowNumberClassesPlayed',
        ),
        selector: 'classPlayed',
        sortable: true,
        cell: row => (
          <NumberFormat
            thousandSeparator
            value={Number(row.classPlayed)}
            displayType="text"
          />
        ),
      },
      {
        name: translate(
          'reportAdventureAssessmentStatsTableRowNumberStudentsPlayed',
        ),
        selector: 'studentPlayed',
        sortable: true,
        cell: row => (
          <NumberFormat
            thousandSeparator
            value={Number(row.studentPlayed)}
            displayType="text"
          />
        ),
      },
    ]
    return (
      <React.Fragment>
        <div className="row match-height">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('reportAdventureStats')}
            </h5>
            <div className="card" style={{}}>
              <div
                className={
                  data && data.totalPage > 0
                    ? 'card-content classes-list-teacher-height'
                    : 'card-content'
                }
              >
                <div
                  id="recent-projects"
                  className="media-list position-relative"
                >
                  <LoadingOverlay
                    active={loadingTable}
                    spinner
                    text={translate('loadingOverlayText')}
                  >
                    <div
                      className={
                        loadingTable === true
                          ? 'table-responsive table-height'
                          : 'table-responsive'
                      }
                    >
                      <DataTable
                        columns={columns}
                        data={data}
                        className="classes-adventure-stats"
                        sortServer
                        onSort={this.handleSorting}
                        progressPending={loadingTable}
                        progressComponent={translate('loadingOverlayTextTable')}
                      />
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row match-height">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('reportAssessmentAdventureStats')}
            </h5>
            <div className="card" style={{}}>
              <div
                className={
                  data && data.totalPage > 0
                    ? 'card-content classes-list-teacher-height'
                    : 'card-content'
                }
              >
                <div
                  id="recent-projects"
                  className="media-list position-relative"
                >
                  <LoadingOverlay
                    active={loadingTable}
                    spinner
                    text={translate('loadingOverlayText')}
                  >
                    <div
                      className={
                        loadingTable === true
                          ? 'table-responsive table-height'
                          : 'table-responsive'
                      }
                    >
                      <DataTable
                        columns={columns}
                        data={dataAssessment}
                        className="classes-adventure-stats"
                        sortServer
                        onSort={this.handleSorting}
                        progressPending={loadingTable}
                        progressComponent={translate('loadingOverlayTextTable')}
                      />
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

AdventureStats.defaultProps = {
  data: {},
  dataAssessment: {},
}

AdventureStats.propTypes = {
  translate: PropTypes.func.isRequired,
  data: PropTypes.shape({
    totalPage: PropTypes.number,
  }),
  dataAssessment: PropTypes.objectOf(PropTypes.any),
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default connect(mapStateToProps)(AdventureStats)
