import {
  GET_SCHOOL_LIST_BY_ORGANIZATION_ID,
  GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADING,
  GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADED,
} from 'store/actions/school'

const initialState = {
  schools: [],
}

const organizationSchoolsReducer = (state = initialState, action = {}) => {
  const newState = state
  switch (action.type) {
    case GET_SCHOOL_LIST_BY_ORGANIZATION_ID:
      if (action.payload.currentPage === 0) {
        newState.schools = action.payload.schools
        return {
          schools: newState.schools,
          ...newState,
          data: action.payload,
        }
      }
      newState.schools = action.payload.schools
      return {
        schools: newState.schools,
        ...newState,
        data: action.payload,
      }

    case GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADING:
      return {
        ...newState,
        loading: true,
      }
    case GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADED:
      return {
        ...newState,
        loading: false,
      }
    default:
      return newState
  }
}

export default organizationSchoolsReducer
