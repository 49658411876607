/* TODO: Fix eslint */
/* eslint-disable */

import moment from 'moment'

const validateForm = values => {
  const errors = {}
  const numbers = /^[0-9]+$/
  // name
  if (!values.name) {
    errors.name = [`errorMsgClassServiceCreateNameMissing`]
  } else if (values.name.length < 2) {
    errors.name = [`errorMsgClassServiceCreateNameIsLength`]
  } else if (values.name.length > 50) {
    errors.name = [`errorMsgClassServiceCreateNameIsLength`]
  }

  // instructor
  if (!values.instructor) {
    errors.instructor = [`errorMsgClassServiceCreateInstructorMissing`]
  }

  // description
  if (values.description) {
    if (values.description.length > 500) {
      errors.description = [`errorMsgClassServiceCreateDescriptionIsLength`]
    }
  }

  // date_ranger
  if (!values.start_date) {
    errors.start_date = [`errorMsgClassServiceCreateStartDateMissing`]
  }
  if (!values.end_date) {
    errors.end_date = [`errorMsgClassServiceCreateEndDateMissing`]
  }
  if (values.start_date && values.end_date) {
    const start_date = moment(values.start_date, 'MM/DD/YYYY').format(
      'YYYY-MM-DD',
    )
    const end_date = moment(values.end_date, 'MM/DD/YYYY').format('YYYY-MM-DD')
    if (start_date === end_date) {
      errors.end_date = [`errorMsgClassServiceCreateDateCompare`]
    } else if (moment(start_date).isAfter(end_date) === true) {
      errors.start_date = [`errorMsgClassServiceCreateDateCompare`]
    }
  }

  // total_license
  if (!values.total_license) {
    errors.total_license = [`errorMsgClassServiceCreateTotalLicenseMissing`]
  } else if (isNaN(Number(values.total_license))) {
    errors.total_license = [`errorMsgClassServiceCreateTotalLicenseIsInt`]
  } else if (!numbers.test(values.total_license)) {
    errors.total_license = [`errorMsgClassServiceCreateTotalLicenseSymbol`]
  } else if (values.total_license <= 0) {
    errors.total_license = [
      `errorMsgClassServiceCreateTotalLicenseLessThanZero`,
    ]
  } else if (values.total_license.length < 0) {
    errors.total_license = [
      `errorMsgClassServiceCreateTotalLicenseLessThanZero`,
    ]
  }

  return errors
}

export default validateForm
