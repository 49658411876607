/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'

/**
 * import translation related files
 *
 */
import { withLocalize, getTranslate } from 'react-localize-redux'
import englishStatsTranslations from '../../../../../../lang/organization/stats/en.json'
import spanishStatsTranslations from '../../../../../../lang/organization/stats/es.json'
import chineseStatsTranslations from '../../../../../../lang/organization/stats/zh.json'

class Stats extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishStatsTranslations, 'en')
    this.props.addTranslationForLanguage(spanishStatsTranslations, 'es')
    this.props.addTranslationForLanguage(chineseStatsTranslations, 'zh')
  }

  render() {
    const { row, translate } = this.props
    return (
      <div className="col-md-12 col-lg-4 school_ststas pull-right">
        <div className="card">
          <div className="card-header p-1">
            <h4 className="card-title float-left">
              {translate('totalSchoolStats')}
            </h4>
          </div>
          <div className="card-content collapse show">
            <div className="card-footer text-center p-1">
              <div className="row">
                <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('totalClasses')}
                  </p>
                  <p className="font-medium-5 text-bold-400">
                    <NumberFormat
                      thousandSeparator
                      value={row && row.total_classes ? row.total_classes : 0}
                      displayType="text"
                    />
                  </p>
                </div>
                <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('totalSchoolLicenses')}
                  </p>
                  <p className="font-medium-5 text-bold-400">
                    <NumberFormat
                      thousandSeparator
                      value={
                        row && row.total_active_license
                          ? row.total_active_license
                          : 0
                      }
                      displayType="text"
                    />{' '}
                    /{' '}
                    <NumberFormat
                      thousandSeparator
                      value={row && row.total_license ? row.total_license : 0}
                      displayType="text"
                    />
                  </p>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Stats))
