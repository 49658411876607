import {
  STUDENT_ADVENTURE_ASSESMENT_LIST,
  STUDENT_ADVENTURE_ASSESMENT_LIST_LOADING,
  STUDENT_ADVENTURE_ASSESMENT_LIST_LOADED,
} from 'store/actions/student'

const initialState = {
  adventures: [],
}

const adventureAssessmentStatsReducer = (state = initialState, action = {}) => {
  const newState = state
  switch (action.type) {
    case STUDENT_ADVENTURE_ASSESMENT_LIST:
      if (action.payload.currentPage === 0) {
        newState.adventures = action.payload.adventures
        return {
          adventures: newState.adventures,
          ...newState,
          data: action.payload,
        }
      }
      newState.adventures = action.payload.adventures
      return {
        adventures: newState.adventures,
        ...newState,
        data: action.payload,
      }

    case STUDENT_ADVENTURE_ASSESMENT_LIST_LOADED:
      return {
        ...newState,
        loading: false,
      }
    case STUDENT_ADVENTURE_ASSESMENT_LIST_LOADING:
      return {
        ...newState,
        loading: true,
      }
    default:
      return newState
  }
}

export default adventureAssessmentStatsReducer
