import { GET_PRINCIPAL_TAG_LIST } from '../../actions/tag'

const principalTagsReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_PRINCIPAL_TAG_LIST:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default principalTagsReducer
