import { GET_PRINCIPAL_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID } from '../../actions/instructors'

const principalInstructorDetailReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_PRINCIPAL_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default principalInstructorDetailReducer
