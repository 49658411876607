/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../config'
import Organization from '../Common/Organization'
import PrincipalTable from '../Tables/Principal'
import Stats from './Stats'

/**
 * import translation related files
 *
 */

class Principal extends React.Component {
  render() {
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row" />
        <div className="content-body">
          <Organization />
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <Stats />
              <PrincipalTable view="1" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Principal)
