import { GET_ORGANIZATION_PRINCIPAL_DROPDOWN } from '../actions/principal'

const organizationOrganizationPrincipalDropdownReducer = (
  state = { data: [] },
  action,
) => {
  switch (action.type) {
    case GET_ORGANIZATION_PRINCIPAL_DROPDOWN:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationOrganizationPrincipalDropdownReducer
