import axios from 'axios'
import config from '../../config'
import handleResponse from './handleResponse'
import handleResponseTranslate from './handleResponseTranslate'
import handleResponseTeacherTranslate from './handleResponseTeacherTranslate'

export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST'
export const GET_COUNTRY_CODE_LIST = 'GET_COUNTRY_CODE_LIST'
export const GET_ORGANIZATION_COUNTRY_LIST = 'GET_ORGANIZATION_COUNTRY_LIST'
export const GET_ORGANIZATION_COUNTRY_CODE_LIST =
  'GET_ORGANIZATION_COUNTRY_CODE_LIST'
export const GET_TEACHER_COUNTRY_LIST = 'GET_TEACHER_COUNTRY_LIST'
export const GET_STATES_LIST = 'GET_STATES_LIST'
export const GET_ORGANIZATION_STATES_LIST = 'GET_ORGANIZATION_STATES_LIST'

/**
 * To get all country
 *
 * @return data
 */
export const getCountries = () => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(`${config.apiUrl}/countries`, headers)
    dispatch({
      type: GET_COUNTRY_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get all country codes
 *
 * @return data
 */
export const getCountriesCode = () => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/countries/dial-code`,
      headers,
    )
    dispatch({
      type: GET_COUNTRY_CODE_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get all states by country id
 *
 * @return data
 */
export const getStates = countryID => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/states/${countryID}`,
      headers,
    )
    dispatch({
      type: GET_STATES_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get all country for organization
 *
 * @return data
 */
export const getOrganizationCountries = props => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/countries`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_COUNTRY_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get all country codes for organization
 *
 * @return data
 */
export const getOrganizationCountriesCode = props => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/countries/dial-code`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_COUNTRY_CODE_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

//
/**
 * To get all state by country id
 *
 * @return data
 */
export const getOrganizationStates = (props, countryID) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/states/${countryID}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_STATES_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get all country
 *
 * @return data
 */
export const getTeacherCountries = props => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiTeacherUrl}/countries`,
      headers,
    )
    dispatch({
      type: GET_TEACHER_COUNTRY_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, props, 3)
  }
}
