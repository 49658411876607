/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import config from 'config'
import {
  studentGetAdventureAssessmentStats,
  studentGetAdventureAssessmentStatsDetails,
} from 'store/actions/student'
import PhonemeScores from './PhonemeScores'
import AdventureTable from 'components/Data/AdventureTable'

class AggregateData extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      adventureData: [],
    }
  }

  async componentDidMount() {
    const {
      studentGetAdventureAssessmentStats,
      studentGetAdventureAssessmentStatsDetails,
    } = this.props
    const advData = await studentGetAdventureAssessmentStats(this.props)
    advData.forEach(async (adv, idx) => {
      advData[idx].all = await studentGetAdventureAssessmentStatsDetails(
        this.props,
        adv.id,
      )
    })
    this.setState({
      adventureData: advData,
    })
  }

  render() {
    const { translate } = this.props
    const { adventureData } = this.state
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              {translate('breadCrumbStudentDetailPronunciation')}
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.studentUrl}/home`}>
                      {translate('breadCrumbHome')}
                    </Link>{' '}
                  </li>
                  <li className="breadcrumb-item active">
                    {translate('breadCrumbStudentDetailPronunciation')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <PhonemeScores />
          <AdventureTable data={adventureData} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  studentGetAdventureAssessmentStats: props =>
    dispatch(studentGetAdventureAssessmentStats(props)),
  studentGetAdventureAssessmentStatsDetails: (props, advId) =>
    dispatch(studentGetAdventureAssessmentStatsDetails(props, advId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AggregateData)
