/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css'

const RenderMultipleSelectField = ({
  input,
  meta: { error, touched },
  data,
  valueField,
  textField,
}) => (
  <Fragment>
    <Multiselect
      {...input}
      onBlur={() => input.onBlur()}
      value={input.value || []}
      data={data}
      valueField={valueField}
      textField={textField}
      className={touched && error ? 'is-invalid' : ''}
    />
    {touched &&
      error &&
      error.map((e, index) => (
        <span key={index} className="invalid-feedback">
          <strong>{e}</strong>
        </span>
      ))}
  </Fragment>
)

export default RenderMultipleSelectField
