/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { storeReport } from '../../../../store/actions/report'
import { getSchoolsListByOrganizationId } from '../../../../store/actions/school'
import { getTagsByOrganizationId } from '../../../../store/actions/tag'
import ReportForm from './ReportForm'
import config from '../../../../config'

class CreateReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationId: '',
    }
  }

  componentWillMount() {
    const { match } = this.props
    const organizationId = match.params.id
    this.setState({ organizationId })
    this.props.getSchoolsListByOrganizationId(organizationId)
    this.props.getTagsByOrganizationId(organizationId)
  }

  handleCancel = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'You want to cancel this form?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.history.push(
              `${config.adminUrl}/organization/${this.state.organizationId}/reports`,
            )
          },
        },
        {
          label: 'No',
        },
      ],
    })
  }

  handleRedirect = () => {
    this.props.history.push(
      `${config.adminUrl}/organization/${this.state.organizationId}/reports`,
    )
  }

  handleSubmit = async values => {
    const reportId = await this.props.storeReport(
      this.state.organizationId,
      values,
    )
    this.props.history.push(
      `${config.adminUrl}/organization/${this.state.organizationId}/report/${reportId}`,
    )
  }

  render() {
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">Create Report</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Create Report</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <ReportForm
            tags={this.props.tags}
            schools={this.props.schools}
            onSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
            handleRedirect={this.handleRedirect}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  schools: state.organizationSchoolDropdown.data.schools,
  tags: state.organizationTags.data.tags,
})

const mapDispatchToProps = dispatch => ({
  getSchoolsListByOrganizationId: organizationId => {
    dispatch(getSchoolsListByOrganizationId(organizationId))
  },
  getTagsByOrganizationId: organizationId => {
    dispatch(getTagsByOrganizationId(organizationId))
  },
  storeReport: (organizationId, values) =>
    dispatch(storeReport(organizationId, values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport)
