/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'
import CommonPhonemeScores from '../../../../../Common/Report/PhonemeScores'
import { getPrincipalPhonemeScoresReportByReportId } from '../../../../../../store/actions/report'

/**
 * import translation related files
 *
 */

class PhonemeScores extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  componentWillMount() {
    const loadingTable = this.state.loading
    this.props.getPrincipalPhonemeScoresReportByReportId(
      this.props.reportId,
      this.props,
    )
  }

  render() {
    const {
      translate,
      interval,
      data,
      loading,
      handleChartClassPhonemeScoresWrapper,
    } = this.props
    return (
      <CommonPhonemeScores
        interval={interval}
        data={data}
        loading={loading}
        handleChartClassPhonemeScoresWrapper={
          handleChartClassPhonemeScoresWrapper
        }
      />
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.principalPhonemeScores.data.phoneme,
  interval: state.principalPhonemeScores.data.interval,
  loading: state.principalPhonemeScores.loading,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalPhonemeScoresReportByReportId: (reportId, props) => {
    dispatch(getPrincipalPhonemeScoresReportByReportId(reportId, props))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PhonemeScores)
