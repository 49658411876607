/* TODO: Fix eslint */
/* eslint-disable */

import { initialize } from 'react-localize-redux'
import { renderToStaticMarkup } from 'react-dom/server'
import englishGlobalTranslations from '../../lang/en.global.json'
import spanishGlobalTranslations from '../../lang/es.global.json'
import chineseGlobalTranslations from '../../lang/zh.global.json'

export const initializeLanguage = () => (dispatch, getState) => {
  try {
    const languages = ['en', 'fr', 'es']
    const currentLanguage = JSON.parse(localStorage.getItem('currentLanguage'))
    if (!currentLanguage) {
      const languages = {
        defaultLanguage: 'en',
        defaultCountry: 'US',
      }
      localStorage.setItem('currentLanguage', JSON.stringify(languages))
    }
    const currentSetLanguage = JSON.parse(
      localStorage.getItem('currentLanguage'),
    )
    // this.props.initialize({
    //   languages: [
    //     { name: "English", code: "en", },
    //     { name: "Spanish", code: "es" },
    //     { name: "Chinese", code: "zh" }
    //   ],
    //   translation: englishGlobalTranslations,
    //   options: {
    //     renderToStaticMarkup,
    //     renderInnerHtml: true,
    //     defaultLanguage: currentSetLanguage.defaultLanguage
    //   }
    // });

    // this.props.addTranslationForLanguage(englishGlobalTranslations, "en");
    // this.props.addTranslationForLanguage(spanishGlobalTranslations, "es");
    // this.props.addTranslationForLanguage(chineseGlobalTranslations, "zh");
    // console.log('lang')
    // console.log(currentSetLanguage.defaultLanguage);

    dispatch(
      initialize({
        languages: [
          { name: 'English', code: 'en' },
          { name: 'Spanish', code: 'es' },
          { name: 'Chinese', code: 'zh' },
        ],
        translation: englishGlobalTranslations,
        options: {
          renderToStaticMarkup,
          renderInnerHtml: true,
          defaultLanguage: currentSetLanguage.defaultLanguage,
        },
      }),
    )
    dispatch.addTranslationForLanguage(englishGlobalTranslations, 'en')
    dispatch.addTranslationForLanguage(spanishGlobalTranslations, 'es')
    dispatch.addTranslationForLanguage(chineseGlobalTranslations, 'zh')
  } catch (error) {}
}
