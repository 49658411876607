/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'

/** componets * */

import LoginForm from './LoginForm'
import { loginUser } from '../../store/actions/auth'
import config from '../../config'
/** LoginContainer * */

class LoginContainer extends React.Component {
  componentWillMount() {
    if (this.props.user) {
      return this.props.history.push(`${config.adminUrl}/home`)
    }
  }

  handleSubmit = async values => {
    await this.props.loginUser(values)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      return this.props.history.push(`${config.adminUrl}/home`)
    }
  }

  render() {
    return <LoginForm onSubmit={this.handleSubmit} />
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
  loginUser: values => dispatch(loginUser(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
