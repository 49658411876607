/* TODO: Fix this eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import config from 'config'
import Copyright from 'components/shared/Footer/Copyright'

import logo from 'app-assets/images/logo/logo-icon1.png'
import img2 from 'app-assets/images/backgrounds/02.jpg'
import logo2 from 'app-assets/images/logo/logo-icon2.png'

export const LeftSideNavigation = props => {
  const { match, translate, isHamburger, is_admin_report_creation: isAdminReportCreation } = props
  return (
    <div
      className="main-menu menu-fixed menu-light menu-accordion    menu-shadow "
      data-scroll-to-active="true"
      data-img={img2}
    >
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <a className="navbar-brand" href={`${config.principalUrl}/home`}>
              <img
                className="brand-logo"
                alt={translate('companyName')}
                src={logo}
                width="100%;"
              />
              <h3 className="brand-text">
                {' '}
                <img
                  className="brand-logo1"
                  alt={translate('companyName')}
                  src={logo2}
                  width="100%;"
                />
              </h3>
            </a>
          </li>
          <li className="nav-item mobile-menu d-md-none mr-auto">
            <a className="nav-link close-navbar">
              <i className="ft-x" />
            </a>
          </li>
        </ul>
      </div>
      <div className="navigation-background" />
      <div className="main-menu-content">
        <ul
          className="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          <li
            className={
              match.path === `${config.principalUrl}/home` ? 'nav-item active' : 'nav-item'
            }
          >
            <a href={`${config.principalUrl}/home`}>
              <i className="ft-home" />
              <span className="menu-title" data-i18n>
                {translate('leftNavMenuDashboard')}
              </span>
            </a>
          </li>
          <li
            className={
              match.path === `${config.principalUrl}/instructors` ||
              match.path === `${config.principalUrl}/instructor/:iid`
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <a href={`${config.principalUrl}/instructors`}>
              <i className="fas fa-chalkboard-teacher" />
              <span className="menu-title" data-i18n>
                {translate('leftNavMenuInstructors')}
              </span>
            </a>
          </li>
          <li
            className={
              match.path === `${config.principalUrl}/classes` ||
              match.path === `${config.principalUrl}/class/:cid`
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <a href={`${config.principalUrl}/classes`}>
              <i className="fas fa-graduation-cap" />
              <span className="menu-title" data-i18n>
                {translate('leftNavMenuClasses')}
              </span>
            </a>
          </li>
          {isAdminReportCreation === 1 && (
            <li
              className={
                match.path === `${config.principalUrl}/reports` ||
                match.path === `${config.principalUrl}/report/:rid` ||
                match.path === `${config.principalUrl}/create-report`
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <a href={`${config.principalUrl}/reports`}>
                <i className="fas fa-poll" />
                <span className="menu-title" data-i18n>
                  {translate('leftNavMenuReports')}
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
      {isHamburger === false && (
        <Copyright path={`${config.principalUrl}/home`} />
      )}
    </div>
  )
}

LeftSideNavigation.propTypes = {
  translate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired,
  isHamburger: PropTypes.bool.isRequired,
  is_admin_report_creation: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default withRouter(connect(mapStateToProps)(LeftSideNavigation))
