import { STUDENT_PROFILE } from '../../actions/student'

const studentProfileReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case STUDENT_PROFILE:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default studentProfileReducer
