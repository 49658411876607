/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import moment from 'moment'
import { dispatchFormField } from '../../store/actions/form'

class RenderField extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    if (this.props.edit === 'true') {
      // const start_date = moment(this.props.dbDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
      // const dateTimelocal  = moment.utc(start_date).toDate();
      // const dateTimelocal1  = moment(dateTimelocal).format('MM/DD/YYYY');

      const currentHH = moment()
        .utc()
        .format('HH')
      const currentMM = moment()
        .utc()
        .format('mm')
      const currentSS = moment()
        .utc()
        .format('ss')
      const time = ` ${currentHH}:${currentMM}:${currentSS}`
      const dbDate = moment(
        this.props.dbDate + time,
        'YYYY-MM-DD HH:mm:ss',
      ).format('YYYY-MM-DD HH:mm:ss')
      const utcFormat = moment(dbDate)
        .utc()
        .format('YYYY-MM-DD HH:MM:SS')
      const dateTimelocal1 = moment
        .utc(utcFormat, 'YYYY-MM-DD HH:MM:SS')
        .local()
        .format('MM/DD/YYYY')
      this.props.dispatchFormField(
        this.props.formName,
        this.props.fieldName,
        dateTimelocal1,
      )
    }
  }

  render() {
    const {
      input,
      meta: { error, touched },
      type,
      placeholder,
      id,
      selected,
      selectsStart,
      startDate,
      endDate,
      handleChangeStart,
      minDate,
    } = this.props
    return (
      <Fragment>
        <DatePicker
          autoComplete="off"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          selected={selected}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          onChange={handleChangeStart}
          minDate={minDate}
          placeholder={placeholder}
          id={id}
          {...input}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          className={
            touched && error
              ? 'form-control square daterange-start is-invalid'
              : 'form-control square daterange-start'
          }
        />
        {touched &&
          error &&
          error.map((e, index) => (
            <span key={index} className="invalid-feedback start_feedback">
              <strong>{e}</strong>
            </span>
          ))}
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenderField)
