/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert' // Import
import config from '../../../../../config'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DataTable from 'react-data-table-component'
import MyAccountForm from './MyAccountForm'
import {
  getOrganizationUserList,
  storeOrganizationUser,
  removeOrganizationUser,
} from '../../../../../store/actions/organizationUser'

/**
 * import translation related files
 *
 */
import { withLocalize, getTranslate } from 'react-localize-redux'
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'

class MyAccounts extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }

  componentWillMount() {
    this.props.getOrganizationUserList(this.props)
  }

  handleCancel = async (organizationUserId, name) => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: `${this.props.translate(
        'alertRemoveText1',
      )} ${name} ${this.props.translate('alertRemoveText2')}`,
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            await this.props.removeOrganizationUser(
              organizationUserId,
              this.props,
            )
            this.props.getOrganizationUserList(this.props)
            // window.location.reload();
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  handleSubmit = async values => {
    await this.props.storeOrganizationUser(values, this.props)
    // setTimeout(function(){ window.location.reload(); },800);
    this.props.getOrganizationUserList()
    // /this.props.history.push(`/profile`);
  }

  render() {
    const { translate } = this.props
    const columns = [
      {
        name: translate('organizatoinUserTableRowName'),
        selector: 'name',
        sortable: true,
      },
      {
        name: translate('organizatoinUserTableRowEmail'),
        selector: 'email',
        sortable: true,
      },
      {
        name: translate('organizatoinUserTableRowAction'),
        cell: row => (
          <button
            type="buttom"
            onClick={() => this.handleCancel(row.id, row.name)}
            className="btn btn-danger btn-sm"
          >
            Remove
          </button>
        ),
      },
    ]
    return (
      <div className="col-xl-4 col-lg-6 col-md-12 pull-right">
        <h4 className="card-title">
          {translate('organizatoinUserTableTitle')}
        </h4>
        <div className="card" style={{}}>
          <div className="card-content my_accounts">
            <div className="col-12">
              <MyAccountForm onSubmit={this.handleSubmit} />
            </div>
            <div id="recent-projects" className="media-list position-relative">
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={this.props.users}
                  noDataComponent={translate('tableNoRecord')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  users: state.organizationUserList.data.users,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationUserList: props => dispatch(getOrganizationUserList(props)),
  storeOrganizationUser: (values, props) =>
    dispatch(storeOrganizationUser(values, props)),
  removeOrganizationUser: (organizationUserId, props) => {
    dispatch(removeOrganizationUser(organizationUserId, props))
  },
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(MyAccounts),
)
