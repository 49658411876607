/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import moment from 'moment'

class TableRowDateProgressBar extends React.Component {
  render() {
    const { totalLicense, activeLicense } = this.props
    let licensePercentage = 0
    if (totalLicense && activeLicense) {
      licensePercentage = parseInt(Number((activeLicense / totalLicense) * 100))
    }
    return (
      <Fragment>
        <div className="progress progress-sm mb-0 box-shadow-2">
          <div
            className="progress-bar bg-gradient-x-success"
            role="progressbar"
            style={{
              width: `${parseInt(licensePercentage)}%`,
              backgroundImage: `${
                licensePercentage >= 90
                  ? 'linear-gradient(to right, #ec6b6b 0%, #ff4747 100%)'
                  : 'linear-gradient(to right, #8f84ff 0%, #8f84ff 100%)'
              }`,
            }}
            aria-valuenow={75}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
      </Fragment>
    )
  }
}

export default TableRowDateProgressBar
