import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import LoadingOverlay from 'react-loading-overlay'
import config from 'config'
import RenderImage from 'components/RenderImage'
import { getInstructorsByschoolId } from 'store/actions/instructors'
import TableRowLicenseProgressBar from 'components/TableRowLicenseProgressBar'
import TextTruncate from 'components/TextTruncate'

class Instructors extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
      loading: true,
    }

    const { getInstructorsByschoolId, organizationId, schoolId } = props
    getInstructorsByschoolId(organizationId, schoolId, 0, 'id', 'DESC')
  }

  componentDidMount() {
    const { page } = this.state
    this.setState({ page: page + 1 })
  }

  handlePageChange = async e => {
    const { getInstructorsByschoolId, organizationId, schoolId, data } = this.props
    const { order, orderBy, page, counter } = this.state
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: page + 1 })
        if (data.totalPage > page) {
          this.setState({ 
            counter: counter + 1,
            loading: true,
            scrollTop: element.scrollTop,
          })
          await getInstructorsByschoolId(
            organizationId,
            schoolId,
            page,
            orderBy,
            order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    const { getInstructorsByschoolId, organizationId, schoolId } = this.props
    const { orderBy, order: stateOrder } = this.state
    let order = 'ASC'
    if (orderBy === field) {
      order = stateOrder === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ 
      orderBy: field, 
      order,
      loading: true
    })
    await getInstructorsByschoolId(
      organizationId,
      schoolId,
      0,
      field,
      order,
    )
    this.setState({ page: 1 })
  }

  render() {
    const { loading: loadingTable, data, instructors } = this.props
    const { orderBy, order } = this.state
    const sortClass = order === 'ASC' ? 'ascending' : 'desending'
    return (
      <div className="row match-height">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <h5 className="card-title text-bold-700 my-2">Instructors</h5>
          <div className="card" style={{}}>
            <div
              className={
                data && data.totalPage > 0 ? 'card-content ' : 'card-content'
              }
            >
              <div
                id="recent-projects"
                className="media-list position-relative"
                onScroll={this.handlePageChange.bind(this)}
              >
                <LoadingOverlay active={loadingTable} spinner text={`${config.loadingOverlayText}`}>
                  <div
                    className={
                      instructors.length === 0
                        ? 'table-responsive table-height'
                        : 'table-responsive'
                    }
                  >
                    {instructors && instructors.length > 0 && (
                      <div id="tableContainer" className="tableContainer">
                        <table
                          className="scrollTable instructor-list-school-details"
                          width="100%"
                          cellSpacing={0}
                          cellPadding={0}
                          border={0}
                        >
                          <thead className="fixedHeader">
                            <tr>
                              <th align="left" width="15%">
                                Image
                              </th>
                              <th
                                align="left"
                                width="15%"
                                onClick={() => this.handleSorting('name')}
                                className={orderBy === 'name' ? sortClass : ''}
                              >
                                Name
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="left"
                                width="20%"
                                onClick={() => this.handleSorting('email')}
                                className={orderBy === 'email' ? sortClass : ''}
                              >
                                Email
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="15%"
                                onClick={() => this.handleSorting('total_class')}
                                className={orderBy === 'total_class' ? sortClass : ''}
                              >
                                Classes
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="20%"
                                onClick={() => this.handleSorting('total_active_license')}
                                className={
                                  orderBy === 'total_active_license' ? sortClass : ''
                                }
                              >
                                Licenses / Assigned
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="15%"
                                onClick={() => this.handleSorting('tags')}
                                className={orderBy === 'tags' ? sortClass : ''}
                              >
                                Tags
                                <i className="fas fa-sort-up" />
                              </th>
                            </tr>
                          </thead>
                          <tbody className="scrollContent">
                            {instructors &&
                              instructors.length > 0 &&
                              instructors.map(row => (
                                <tr key={`instructor-${row.id}`}>
                                  <td align="left" width="15%">
                                    <RenderImage
                                      className="instructor_img"
                                      image={row.photo_path}
                                      apiUrl={config.apiUrl}
                                      source="1"
                                      name={row.name}
                                    />
                                  </td>
                                  <td align="left" width="15%">
                                    <Link
                                      to={`${config.adminUrl}/organization/${row.organization_id}/instructor/${row.id}`}
                                    >
                                      {row.name}
                                    </Link>
                                  </td>
                                  <td align="left" width="20%">
                                    <TextTruncate str={row.email} length={null} ending={null} />
                                  </td>
                                  <td align="center" width="15%">
                                    <NumberFormat
                                      thousandSeparator
                                      value={Number(row.total_class)}
                                      displayType="text"
                                    />
                                  </td>
                                  <td align="center" width="20%">
                                    <span className="text-center">
                                      <NumberFormat
                                        thousandSeparator
                                        value={
                                          row.total_active_license ? row.total_active_license : 0
                                        }
                                        displayType="text"
                                      />{' '}
                                      /{' '}
                                      <NumberFormat
                                        thousandSeparator
                                        value={row.total_license ? row.total_license : 0}
                                        displayType="text"
                                      />
                                    </span>
                                    <TableRowLicenseProgressBar
                                      activeLicense={row && row.total_active_license}
                                      totalLicense={row && row.total_license}
                                    />
                                  </td>
                                  <td align="center" width="15%">
                                    {row.tags}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {instructors &&
                      instructors.length === 0 &&
                      loadingTable === false && (
                        <div className="table-no-record">{`${config.tableNoRecord}`}</div>
                      )}
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Instructors.defaultProps = {
  loading: false,
  data: {
    totalPage: 0
  },
  instructors: []
}

Instructors.propTypes = {
  getInstructorsByschoolId: PropTypes.func.isRequired,
  data: PropTypes.shape({
    totalPage: PropTypes.number
  }),
  loading: PropTypes.bool,
  instructors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    email: PropTypes.string,
    organization_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    photo_path: PropTypes.string,
    total_active_license: PropTypes.number,
    total_license: PropTypes.number,
    total_class: PropTypes.number,
    tags: PropTypes.string,
  }))
}

const mapStateToProps = state => ({
  data: state.adminSchoolInstructor.data,
  instructors: state.adminSchoolInstructor.instructors,
  loading: state.adminSchoolInstructor.loading,
})

const mapDispatchToProps = dispatch => ({
  getInstructorsByschoolId: (organizationId, schoolId, page, orderBy, order) => {
    dispatch(getInstructorsByschoolId(organizationId, schoolId, page, orderBy, order))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Instructors)
