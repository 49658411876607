/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'

// BEGIN: Image
import { withLocalize } from 'react-localize-redux'
import innerBannerImg from '../../../assets/root/images/inner-banner.jpg'
// END: Image

import englishRootAboutUsTranslations from '../../../lang/root/aboutus/en.json'
import spanishRootAboutUsTranslations from '../../../lang/root/aboutus/es.json'
import chineseRootAboutUsTranslations from '../../../lang/root/aboutus/zh.json'

class AboutUs extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishRootAboutUsTranslations, 'en')
    this.props.addTranslationForLanguage(spanishRootAboutUsTranslations, 'es')
    this.props.addTranslationForLanguage(chineseRootAboutUsTranslations, 'zh')
  }

  render() {
    const { translate } = this.props
    return (
      <React.Fragment>
        <div id="banner_section">
          <div className="yellowbox">
            <center>
              <h2 className="taglines">{translate('title')}</h2>
            </center>
          </div>
          <img src={innerBannerImg} className="img-responsive" alt={translate('title')} />
        </div>
        <div id="about" className="aboutus inner-abtus">
          <div className="container">
            <h3 className="wow animate fadeInDown delay-06s">{translate('title')}</h3>
            {translate('details')}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withLocalize(AboutUs)
