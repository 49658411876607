/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import RenderImage from '../../../../../../components/RenderImage'
import config from '../../../../../../config'

/**
 * import translation related files
 *
 */

class Detail extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { translate, row } = this.props
    const tagArr = row && row.tags ? row.tags.split(',') : []
    return (
      <div className="card class_card">
        {row && (
          <RenderImage
            className="profile_img"
            image={row && row.photo_path}
            apiUrl={config.apiOrganizationUrl}
            source="2"
            name={row && row.name}
          />
        )}
        <div className="class_description">
          <div className="card-header card-header_name">
            <h4 className="card-title">{row && row.name}</h4>
            <h4 className="org-id student_name">{row && row.email}</h4>
          </div>
          <div className="card-content collapse show">
            <div className="card-body pb-0 card_margin student_s_name">
              <p className="address_Org">
                {translate('instructorTableRowSchool')}
              </p>
              <h6 className="contact_heading">{row && row.school_name}</h6>
            </div>
          </div>
          <div className="row">
            <div className>
              <p className="lighten-2 mb-0 text-left tag_headings">
                {translate('instructorTableRowTags')}
              </p>
              <div className="tags_div class_p_tags">
                {tagArr && tagArr.map(tag => <p>{tag}</p>)}
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
