import { GET_ORGANIZATION_REPORT_DETAILS } from '../actions/report'

const organizationOrganizationReportDetailReducer = (
  state = { data: [] },
  action,
) => {
  switch (action.type) {
    case GET_ORGANIZATION_REPORT_DETAILS:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationOrganizationReportDetailReducer
