import { GET_ADMIN_USER_PROFILE } from '../actions/adminUser'

const adminUserProfileReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_ADMIN_USER_PROFILE:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default adminUserProfileReducer
