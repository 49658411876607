/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import instructorImg from '../../app-assets/images/instructor.png'

class RenderImage extends React.Component {
  render() {
    const { className, name, image, apiUrl, source } = this.props
    let newImaage = null
    if (image !== null) {
      const imageObj = JSON.parse(image)
      if (imageObj) {
        if (imageObj.key && imageObj.type) {
          newImaage = `${apiUrl}/image/${imageObj.key}`
        } else {
          newImaage = instructorImg
        }
      } else {
        newImaage = instructorImg
      }
    } else {
      newImaage = instructorImg
    }
    return <img className={className} src={newImaage} alt={name} />
  }
}

export default RenderImage
