/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import OrganizationCommonDetail from 'pages/Home/Common/Organization'
import { getSchoolsByOrganizationId } from 'store/actions/school'
import OrganizationStats from 'pages/Home/Organization/OrganizationStats'
import Reports from 'pages/Home/OrganizationDetail/Reports'
import config from 'config'
import TableRowLicenseProgressBar from 'components/TableRowLicenseProgressBar'

class OrganizationDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationId: '',
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
      loading: false,
    }
  }

  componentDidMount() {
    const { match, history, getSchoolsByOrganizationId } = this.props
    const { page, orderBy, order } = this.state
    const organizationId = match.params.id
    if (organizationId === ' ' || organizationId === 'undefined') {
      history.push(`${config.adminUrl}/home`)
    }
    this.setState({ organizationId })
    this.setState({ loading: true })
    getSchoolsByOrganizationId(organizationId, page, orderBy, order)
    this.setState({ page: page + 1 })
  }

  handlePageChange = async e => {
    const { data, getSchoolsByOrganizationId } = this.props
    const { page, orderBy, order, organizationId, counter } = this.state
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: page + 1 })
        if (data.totalPage > page) {
          this.setState({ counter: counter + 1 })
          this.setState({ loading: true })
          this.setState({ scrollTop: element.scrollTop })
          await getSchoolsByOrganizationId(organizationId, page, orderBy, order)
        }
      }
    }
  }

  handleSorting = async field => {
    const { getSchoolsByOrganizationId } = this.props
    const { organizationId, orderBy } = this.state
    let order = 'ASC'
    if (orderBy === field) {
      order = this.state.order === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    this.setState({ loading: true })
    await getSchoolsByOrganizationId(organizationId, 0, field, order)
    this.setState({ page: 1 })
  }

  render() {
    const { loading: loadingTable, schools, data } = this.props
    const { order, organizationId, orderBy } = this.state
    const sortClass = order === 'ASC' ? 'ascending' : 'desending'
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">View Organization</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">View Organization</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <OrganizationCommonDetail />
          <div className="row">
            <OrganizationStats />
            <Reports organizationId={organizationId} />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h5 className="card-title text-bold-700 my-2">Schools</h5>
              <div className="card" style={{}}>
                <div
                  className={
                    data && data.totalPage > 0
                      ? 'card-content '
                      : 'card-content'
                  }
                >
                  <div
                    id="recent-projects"
                    className="media-list position-relative"
                    onScroll={this.handlePageChange.bind(this)}
                  >
                    <LoadingOverlay
                      active={loadingTable}
                      spinner
                      text={`${config.loadingOverlayText}`}
                    >
                      <div
                        className={
                          schools.length === 0
                            ? 'table-responsive table-height'
                            : 'table-responsive'
                        }
                      >
                        {schools && schools.length > 0 && (
                          <div id="tableContainer" className="tableContainer">
                            <table
                              className="scrollTable school-list-organization-details"
                              width="100%"
                              cellSpacing={0}
                              cellPadding={0}
                              border={0}
                            >
                              <thead className="fixedHeader">
                                <tr>
                                  <th
                                    align="left"
                                    width="20%"
                                    onClick={() =>
                                      this.handleSorting('school_name')
                                    }
                                    className={
                                      orderBy === 'school_name' ? sortClass : ''
                                    }
                                  >
                                    Name
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="left"
                                    width="20%"
                                    onClick={() =>
                                      this.handleSorting('principal_name')
                                    }
                                    className={
                                      orderBy === 'principal_name'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    Principal
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="10%"
                                    onClick={() =>
                                      this.handleSorting('total_instructors')
                                    }
                                    className={
                                      orderBy === 'total_instructors'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    Teachers
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="10%"
                                    onClick={() =>
                                      this.handleSorting('total_class')
                                    }
                                    className={
                                      orderBy === 'total_class' ? sortClass : ''
                                    }
                                  >
                                    Classes
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="20%"
                                    onClick={() =>
                                      this.handleSorting('total_active_license')
                                    }
                                    className={
                                      orderBy === 'total_active_license'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    Licenses / Assigned
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="20%"
                                    onClick={() => this.handleSorting('tags')}
                                    className={
                                      orderBy === 'tags' ? sortClass : ''
                                    }
                                  >
                                    Tags
                                    <i className="fas fa-sort-up" />
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="scrollContent">
                                {schools &&
                                  schools.length > 0 &&
                                  schools.map(row => (
                                    <tr key={`school-${row.id}`}>
                                      <td align="left" width="20%">
                                        <Link
                                          to={`${config.adminUrl}/organization/${row.organization_id}/schools/${row.id}`}
                                        >
                                          {row.school_name}
                                        </Link>
                                      </td>
                                      <td align="left" width="20%">
                                        {row.principal_name}
                                      </td>
                                      <td align="center" width="10%">
                                        <NumberFormat
                                          thousandSeparator
                                          value={
                                            row.total_instructors
                                              ? row.total_instructors
                                              : 0
                                          }
                                          displayType="text"
                                        />
                                      </td>
                                      <td align="center" width="10%">
                                        <NumberFormat
                                          thousandSeparator
                                          value={
                                            row.total_class
                                              ? row.total_class
                                              : 0
                                          }
                                          displayType="text"
                                        />
                                      </td>
                                      <td align="center" width="20%">
                                        <span className="text-center">
                                          <NumberFormat
                                            thousandSeparator
                                            value={
                                              row.total_active_license
                                                ? row.total_active_license
                                                : 0
                                            }
                                            displayType="text"
                                          />{' '}
                                          /{' '}
                                          <NumberFormat
                                            thousandSeparator
                                            value={
                                              row.total_license
                                                ? row.total_license
                                                : 0
                                            }
                                            displayType="text"
                                          />
                                        </span>
                                        <TableRowLicenseProgressBar
                                          activeLicense={
                                            row && row.total_active_license
                                          }
                                          totalLicense={
                                            row && row.total_license
                                          }
                                        />
                                      </td>
                                      <td align="center" width="20%">
                                        {row.tags}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {schools &&
                          schools.length === 0 &&
                          loadingTable === false && (
                            <div className="table-no-record">{`${config.tableNoRecord}`}</div>
                          )}
                      </div>
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  data: state.organizationSchools.data,
  schools: state.organizationSchools.schools,
  loading: state.organizationSchools.loading,
})

const mapDispatchToProps = dispatch => ({
  getSchoolsByOrganizationId: (organizationId, page, orderBy, order) => {
    dispatch(getSchoolsByOrganizationId(organizationId, page, orderBy, order))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetail)
