import { ADMIN_USER_PROFILE_UPDATE } from 'store/actions/adminUser'

const adminUserProfileUpdateReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case ADMIN_USER_PROFILE_UPDATE:
      return {
        ...state,
        user: action.payload.user,
        data: action.payload,
      }
    default:
      return state
  }
}

export default adminUserProfileUpdateReducer
