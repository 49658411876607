import { IS_HAMBURGER_STATUS } from '../actions/form'

const isHamburgerReducer = (state = { data: false }, action) => {
  switch (action.type) {
    case IS_HAMBURGER_STATUS:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default isHamburgerReducer
