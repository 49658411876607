/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import OrganizationCommonDetail from '../../../Common/Organization'
import Detail from './Detail'
import Stats from './Stats'
import { getOrganizationDetails } from 'store/actions/organizations'
import { getDetailByclassId } from 'store/actions/class'
import config from 'config'

class ClassDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationId: '',
    }
  }

  componentWillMount() {
    const { match } = this.props
    const organizationId = match.params.id
    const classId = match.params.cid
    if (organizationId === ' ' || organizationId === 'undefined') {
      this.props.history.push(`${config.adminUrl}/home`)
    }
    if (classId === ' ' || classId === 'undefined') {
      this.props.history.push(`${config.adminUrl}/home`)
    }
    this.setState({
      organizationId,
    })
    this.props.getOrganizationDetails(organizationId)
    this.props.getDetailByclassId(organizationId, classId)
  }

  render() {
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">View Class Details</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.adminUrl}/organization/${this.state.organizationId}`}
                    >
                      Organization
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Class Details</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <OrganizationCommonDetail
            organizationRow={this.props.organizationDetailRow}
          />
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <Detail
                  row={this.props.organizatoinClassRow}
                  organizationId={this.state.organizationId}
                />
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  organizationDetailRow: state.organizationDetail.data.row,
  organizatoinClassRow: state.organizatoinClassDetail.data.row,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationDetails: organizationId => {
    dispatch(getOrganizationDetails(organizationId))
  },
  getDetailByclassId: (organizationId, classId) => {
    dispatch(getDetailByclassId(organizationId, classId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetail)
