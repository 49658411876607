/* TODO: Fix this eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import config from 'config'
import Copyright from 'components/shared/Footer/Copyright'

import logo from 'app-assets/images/logo/logo-icon1.png'
import img2 from 'app-assets/images/backgrounds/02.jpg'
import logo2 from 'app-assets/images/logo/logo-icon2.png'

export const LeftSideNavigation = props => {
  const { match, translate, isHamburger } = props
  return (
    <div
      className="main-menu menu-fixed menu-light menu-accordion    menu-shadow "
      data-scroll-to-active="true"
      data-img={img2}
    >
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <a className="navbar-brand" href={`${config.studentUrl}/home`}>
              <img
                className="brand-logo"
                alt={translate('companyName')}
                src={logo}
                width="100%;"
              />
              <h3 className="brand-text">
                {' '}
                <img
                  className="brand-logo1"
                  alt={translate('companyName')}
                  src={logo2}
                  width="100%;"
                />
              </h3>
            </a>
          </li>
          <li className="nav-item d-md-none">
            <a className="nav-link close-navbar">
              <i className="ft-x" />
            </a>
          </li>
        </ul>
      </div>
      <div className="navigation-background" />
      <div className="main-menu-content">
        <ul
          className="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          <li
            className={
              match.path === `${config.studentUrl}/home` ? 'nav-item active' : 'nav-item'
            }
          >
            <a href={`${config.studentUrl}/home`}>
              <i className="ft-home" />
              <span className="menu-title" data-i18n>
                {translate('leftNavMenuDashboard')}
              </span>
            </a>
          </li>
        </ul>
      </div>
      {isHamburger === false && (
        <Copyright path={`${config.studentUrl}/home`} />
      )}
    </div>
  )
}

LeftSideNavigation.propTypes = {
  translate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired,
  isHamburger: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default withRouter(connect(mapStateToProps)(LeftSideNavigation))
