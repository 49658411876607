/* TODO: Fix eslint */
/* eslint-disable */

import axios from 'axios'
import { toast } from 'react-toastify'
import config from '../../config'
import handleResponse from './handleResponse'
import handleResponseTranslate from './handleResponseTranslate'
import handleResponsePrincipalTranslate from './handleResponsePrincipalTranslate'
import handleResponseStudentTranslate from './handleResponseStudentTranslate'

/**
 * Define actions
 *
 */
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const ORGANIZATION_LOGIN_USER = 'ORGANIZATION_LOGIN_USER'
export const ORGANIZATION_LOGOUT_USER = 'ORGANIZATION_LOGOUT_USER'
export const ORGANIZATION_RECOVER_PASSWORD = 'ORGANIZATION_RECOVER_PASSWORD'
export const PRINCIPAL_LOGIN_USER = 'PRINCIPAL_LOGIN_USER'
export const PRINCIPAL_LOGOUT_USER = 'PRINCIPAL_LOGOUT_USER'
export const PRINCIPAL_RECOVER_PASSWORD = 'PRINCIPAL_RECOVER_PASSWORD'
export const TEACHER_LOGIN_USER = 'TEACHER_LOGIN_USER'
export const TEACHER_LOGOUT_USER = 'TEACHER_LOGOUT_USER'
export const TEACHER_RECOVER_PASSWORD = 'TEACHER_RECOVER_PASSWORD'
export const STUDENT_LOGIN_USER = 'STUDENT_LOGIN_USER'
export const STUDENT_LOGOUT_USER = 'STUDENT_LOGOUT_USER'

/**
 * To login as admin
 *
 * @param  object  values
 * @return data
 */
export const loginUser = values => async dispatch => {
  try {
    const response = await axios.post(`${config.apiUrl}/auth/login`, {
      email: values.email,
      password: values.password,
    })
    localStorage.setItem('authUser', JSON.stringify(response.data.result.data))
    dispatch({
      type: LOGIN_USER,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 2)
  }
}

/**
 * To recover the password admin user through email
 *
 * @param  object  values
 */
export const recoverPassword = values => async dispatch => {
  try {
    const response = await axios.post(
      `${config.apiUrl}/auth/recover-password`,
      {
        email: values.email,
      },
    )
    // console.log(response);
    dispatch({
      type: RECOVER_PASSWORD,
      payload: response.data.result,
    })
  } catch (error) {
    await handleResponse(error, 2)
  }
}

/**
 * To logout from admin dashboard
 *
 */
export const logoutUser = () => dispatch => {
  localStorage.setItem('authUser', '')
  localStorage.removeItem('authUser')
  dispatch({
    type: LOGOUT_USER,
  })
}

/**
 * To login as organization
 *
 * @param  object  values
 * @return data
 */
export const organizationLoginUser = (values, props) => async dispatch => {
  try {
    const response = await axios.post(
      `${config.apiOrganizationUrl}/auth/login`,
      {
        email: values.email,
        password: values.password,
      },
    )
    localStorage.setItem(
      'authOrganizationUser',
      JSON.stringify(response.data.result.data),
    )
    dispatch({
      type: ORGANIZATION_LOGIN_USER,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 2)
  }
}

/**
 * To recover the password organization user through email
 *
 * @param  object  values
 */
export const organizationRecoverPassword = (
  values,
  props,
) => async dispatch => {
  try {
    const response = await axios.post(
      `${config.apiOrganizationUrl}/auth/recover-password`,
      {
        email: values.email,
      },
    )
    // console.log(response);
    dispatch({
      type: ORGANIZATION_RECOVER_PASSWORD,
      payload: response.data.result,
    })
    return response.data.result
  } catch (error) {
    await handleResponseTranslate(error, props, 2)
  }
}

/**
 * To logout from organization dashboard
 *
 */
export const organizationLogoutUser = () => dispatch => {
  localStorage.setItem('authOrganizationUser', '')
  localStorage.removeItem('authOrganizationUser')
  dispatch({
    type: ORGANIZATION_LOGOUT_USER,
  })
}

/**
 * To login as principal
 *
 * @param  object  values
 * @return data
 */
export const principalLoginUser = (values, props) => async dispatch => {
  try {
    const response = await axios.post(`${config.apiPrincipalUrl}/auth/login`, {
      email: values.email,
      password: values.password,
    })
    localStorage.setItem(
      'authPrincipal',
      JSON.stringify(response.data.result.data),
    )
    dispatch({
      type: PRINCIPAL_LOGIN_USER,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 2)
  }
}

/**
 * To recover the password principal through email
 *
 * @param  object  values
 */
export const principalRecoverPassword = (values, props) => async dispatch => {
  try {
    const response = await axios.post(
      `${config.apiPrincipalUrl}/auth/recover-password`,
      {
        email: values.email,
      },
    )
    // console.log(response);
    dispatch({
      type: PRINCIPAL_RECOVER_PASSWORD,
      payload: response.data.result,
    })
    return response.data.result
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 2)
  }
}

/**
 * To logout from principal dashboard
 *
 */
export const principalLogoutUser = () => dispatch => {
  localStorage.setItem('authPrincipal', '')
  localStorage.removeItem('authPrincipal')
  dispatch({
    type: PRINCIPAL_LOGOUT_USER,
  })
}

/**
 * To login as teacher/instructor
 *
 * @param  object  values
 * @return data
 */
export const teacherLoginUser = (values, props) => async dispatch => {
  try {
    const response = await axios.post(`${config.apiTeacherUrl}/auth/login`, {
      email: values.email,
      password: values.password,
    })
    localStorage.setItem(
      'authTeacher',
      JSON.stringify(response.data.result.data),
    )
    dispatch({
      type: TEACHER_LOGIN_USER,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 2)
  }
}

/**
 * To recover the password teacher/instructor through email
 *
 * @param  object  values
 */
export const teacherRecoverPassword = (values, props) => async dispatch => {
  try {
    const response = await axios.post(
      `${config.apiTeacherUrl}/auth/recover-password`,
      {
        email: values.email,
      },
    )
    // console.log(response);
    dispatch({
      type: TEACHER_RECOVER_PASSWORD,
      payload: response.data.result,
    })
    return response.data.result
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 2)
  }
}

/**
 * To logout from teacher/instructor dashboard
 *
 */
export const teacherLogoutUser = () => dispatch => {
  localStorage.setItem('authTeacher', '')
  localStorage.removeItem('authTeacher')
  dispatch({
    type: TEACHER_LOGOUT_USER,
  })
}

/**
 * To login as a student
 *
 * @param  object  values
 * @return data
 */
export const studentLoginUser = (values, props) => async dispatch => {
  try {
    const response = await axios.post(`${config.apiStudentUrl}/auth/login`, {
      code: values.code,
      password: values.password,
    })
    localStorage.setItem(
      'authStudent',
      JSON.stringify(response.data.result.data),
    )
    dispatch({
      type: STUDENT_LOGIN_USER,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseStudentTranslate(error, props, 2)
  }
}

/**
 * To register as a student
 *
 * @param  object  values
 * @return data
 */
export const studentRegisterUser = (values, props) => async dispatch => {
  try {
    const response = await axios.post(`${config.apiStudentUrl}/auth/register`, {
      code: values.code,
      password: values.password,
      confirm_password: values.confirm_password,
    })
    localStorage.setItem(
      'authStudent',
      JSON.stringify(response.data.result.data),
    )
    dispatch({
      type: STUDENT_LOGIN_USER,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseStudentTranslate(error, props, 2)
  }
}

/**
 * To logout from a student dashboard
 *
 */
export const studentLogoutUser = () => dispatch => {
  localStorage.setItem('authStudent', '')
  localStorage.removeItem('authStudent')
  toast.dismiss()
  dispatch({
    type: STUDENT_LOGOUT_USER,
  })
}
