/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MomentR from 'react-moment'
import moment from 'moment'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'
import DateTimeWithTimezone from '../../../../../../components/DateTimeWithTimezone'
import TableRowDateProgressBar from '../../../../../../components/TableRowDateProgressBar'

/**
 * import translation related files
 *
 */

class Detail extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { translate, row, handleDelete } = this.props
    const tagArr = row && row.tags ? row.tags.split(',') : []
    return (
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <h5 className="card-title text-bold-700 my-2">
                {translate('reportDetailTitle')}
                <Link
                  className="add_btn pull-right btn_delete"
                  to="#"
                  onClick={handleDelete}
                >
                  {translate('btnDeleteReport')}
                </Link>
              </h5>
              <div className="card">
                <div className="card-content collapse show">
                  <div className="card-footer text-center p-1">
                    <div className="row">
                      <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-left">
                        <p className="blue-grey lighten-2 mb-0">
                          {translate('reportDetailRowName')}
                        </p>
                        <p className="font-medium-6 text-bold-400">
                          {row && row.report_name}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 text-left">
                        <p className="blue-grey lighten-2 mb-0">
                          {translate('reportDetailRowSchool')}
                        </p>
                        <p className="font-medium-6 text-bold-400">
                          {row && row.school_name}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-left">
                        <p className="blue-grey lighten-2 mb-0">
                          {translate('reportDetailRowDescription')}
                        </p>
                        <p className="font-medium-6 text-bold-400">
                          {row && row.description}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-left">
                        <div className="row">
                          <div className="col-12">
                            <div className="card pull-up">
                              <div className="card-header">
                                <h6 className="card-title float-left">
                                  Start Date{' '}
                                  {row && row.start_date != null && (
                                    <span className="start_date_color">
                                      <DateTimeWithTimezone
                                        dateTime={row && row.start_date}
                                        isTime="false"
                                        isRed="false"
                                      />
                                    </span>
                                  )}
                                </h6>
                                <h6 className="card-title float-right">
                                  End Date{' '}
                                  {row && row.end_date != null && (
                                    <span className="end_date_color">
                                      <DateTimeWithTimezone
                                        dateTime={row && row.end_date}
                                        isTime="false"
                                        isRed="true"
                                        start_date={row && row.start_date}
                                        end_date={row && row.end_date}
                                      />
                                    </span>
                                  )}
                                </h6>
                              </div>
                              {row &&
                                row.start_date != null &&
                                row.end_date != null && (
                                  <div className="card-content collapse show">
                                    <div className="card-body pt-0 pb-1">
                                      <TableRowDateProgressBar
                                        start_date={row.start_date}
                                        end_date={row.end_date}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <p className="blue-grey lighten-2 mb-0 text-left">
                          {translate('reportTableRowTags')}
                        </p>
                        <div className="tags_div">
                          {tagArr && tagArr.map(tag => <p>{tag}</p>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* }
                  <div className="col-md-12 col-lg-4 school_ststas pull-right">
                    <div className="card pull-up border-top-info border-top-3 rounded-0">
                      <div className="card-header">
                        <h4 className="card-title">New Students <span className="badge badge-pill badge-info float-right m-0">5+</span></h4>
                      </div>
                      <div className="card-content collapse show">
                        <div className="card-body p-1">
                          <h4 className="font-large-1 text-bold-400">18.5% <i className="ft-users float-right" /></h4>
                        </div>
                        <div className="card-footer p-1">
                          <span className="text-muted"><i className="la la-arrow-circle-o-up info" /> 23.67% increase</span>
                        </div>
                      </div>
                    </div>
                  </div>
                { */}
            <div className="clearfix" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
