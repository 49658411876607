/* TODO: Fix eslint */
/* eslint-disable */

import axios from 'axios'
import moment from 'moment'
import config from '../../config'
import handleResponse from './handleResponse'
import handleResponseTranslate from './handleResponseTranslate'
import handleResponsePrincipalTranslate from './handleResponsePrincipalTranslate'
import handleResponseTeacherTranslate from './handleResponseTeacherTranslate'

/**
 * Define actions
 *
 */
export const GET_CLASS_LIST_BY_ORGANIZATION_ID =
  'GET_CLASS_LIST_BY_ORGANIZATION_ID'
export const GET_CLASS_LIST_BY_ORGANIZATION_ID_LOADING =
  'GET_CLASS_LIST_BY_ORGANIZATION_ID_LOADING'
export const GET_CLASS_LIST_BY_ORGANIZATION_ID_LOADED =
  'GET_CLASS_LIST_BY_ORGANIZATION_ID_LOADED'
export const GET_CLASS_DETAILS_BY_CLASS_ID = 'GET_CLASS_DETAILS_BY_CLASS_ID'
export const GET_CLASS_LIST_BY_SCHOOL_ID = 'GET_CLASS_LIST_BY_SCHOOL_ID'
export const GET_CLASS_LIST_BY_SCHOOL_ID_LOADING =
  'GET_CLASS_LIST_BY_SCHOOL_ID_LOADING'
export const GET_CLASS_LIST_BY_SCHOOL_ID_LOADED =
  'GET_CLASS_LIST_BY_SCHOOL_ID_LOADED'
export const GET_CLASS_LIST_BY_INSTRUCTOR_ID = 'GET_CLASS_LIST_BY_INSTRUCTOR_ID'
export const GET_CLASS_LIST_BY_INSTRUCTOR_ID_LOADING =
  'GET_CLASS_LIST_BY_INSTRUCTOR_ID_LOADING'
export const GET_CLASS_LIST_BY_INSTRUCTOR_ID_LOADED =
  'GET_CLASS_LIST_BY_INSTRUCTOR_ID_LOADED'
export const GET_ORGANIZATION_CLASS_LIST = 'GET_ORGANIZATION_CLASS_LIST'
export const GET_ORGANIZATION_CLASS_LIST_LOADING =
  'GET_ORGANIZATION_CLASS_LIST_LOADING'
export const GET_ORGANIZATION_CLASS_LIST_LOADED =
  'GET_ORGANIZATION_CLASS_LIST_LOADED'
export const GET_ORGANIZATION_CLASS_DETAILS_BY_CLASS_ID =
  'GET_ORGANIZATION_CLASS_DETAILS_BY_CLASS_ID'
export const GET_ORGANIZATION_CLASS_LIST_BY_INSTRUCTOR_ID =
  'GET_ORGANIZATION_CLASS_LIST_BY_INSTRUCTOR_ID'
export const GET_ORGANIZATION_CLASS_LIST_BY_INSTRUCTOR_ID_LOADING =
  'GET_ORGANIZATION_CLASS_LIST_BY_INSTRUCTOR_ID_LOADING'
export const GET_ORGANIZATION_CLASS_LIST_BY_INSTRUCTOR_ID_LOADED =
  'GET_ORGANIZATION_CLASS_LIST_BY_INSTRUCTOR_ID_LOADED'
export const GET_PRINCIPAL_CLASS_LIST = 'GET_PRINCIPAL_CLASS_LIST'
export const GET_PRINCIPAL_CLASS_LIST_LOADING =
  'GET_PRINCIPAL_CLASS_LIST_LOADING'
export const GET_PRINCIPAL_CLASS_LIST_LOADED = 'GET_PRINCIPAL_CLASS_LIST_LOADED'
export const GET_PRINCIPAL_CLASS_DETAILS_BY_CLASS_ID =
  'GET_PRINCIPAL_CLASS_DETAILS_BY_CLASS_ID'
export const GET_PRINCIPAL_CLASS_LIST_BY_INSTRUCTOR_ID =
  'GET_PRINCIPAL_CLASS_LIST_BY_INSTRUCTOR_ID'
export const GET_PRINCIPAL_CLASS_LIST_BY_INSTRUCTOR_ID_LOADING =
  'GET_PRINCIPAL_CLASS_LIST_BY_INSTRUCTOR_ID_LOADING'
export const GET_PRINCIPAL_CLASS_LIST_BY_INSTRUCTOR_ID_LOADED =
  'GET_PRINCIPAL_CLASS_LIST_BY_INSTRUCTOR_ID_LOADED'
export const STORE_CLASS = 'STORE_CLASS'
export const UPDATE_CLASS = 'UPDATE_CLASS'
export const GET_TEACHER_CLASS_LIST = 'GET_TEACHER_CLASS_LIST'
export const GET_TEACHER_CLASS_LIST_LOADING = 'GET_TEACHER_CLASS_LIST_LOADING'
export const GET_TEACHER_CLASS_LIST_LOADED = 'GET_TEACHER_CLASS_LIST_LOADED'
export const GET_TEACHER_CLASS_DETAILS_BY_CLASS_ID =
  'GET_TEACHER_CLASS_DETAILS_BY_CLASS_ID'

/**
 * To get classes of an organization for admin
 *
 * @param  int organizationId
 * @return data
 */
export const getClassesByOrganizationId = (
  organizationId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_CLASS_LIST_BY_ORGANIZATION_ID_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/classes/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_CLASS_LIST_BY_ORGANIZATION_ID,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_CLASS_LIST_BY_ORGANIZATION_ID_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get class detail of a class for admin
 *
 * @param  int organizationId
 * @param  int classId
 * @return data
 */
export const getDetailByclassId = (organizationId, classId) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/class/${classId}`,
      headers,
    )
    dispatch({
      type: GET_CLASS_DETAILS_BY_CLASS_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get classes of a school for admin
 *
 * @param  int organizationId
 * @param  int schoolId
 * @return data
 */
export const getClassesByschoolId = (
  organizationId,
  schoolId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_CLASS_LIST_BY_SCHOOL_ID_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/schoolclasses/${schoolId}/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_CLASS_LIST_BY_SCHOOL_ID,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_CLASS_LIST_BY_SCHOOL_ID_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get classes of an instructor for admin
 *
 * @param  int organizationId
 * @param  int instructorId
 * @return data
 */
export const getClassesByinstructorId = (
  organizationId,
  instructorId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_CLASS_LIST_BY_INSTRUCTOR_ID_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/instructorclasses/${instructorId}/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_CLASS_LIST_BY_INSTRUCTOR_ID,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_CLASS_LIST_BY_INSTRUCTOR_ID_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get classes of an organization for organization
 *
 * @param  object props
 * @return data
 */
export const getOrganizationClasses = (props, page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_CLASS_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/classes/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_CLASS_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_CLASS_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
} // end getOrganizationClasses

/**
 * To get class detail of a class for organization
 *
 * @param  object props
 * @param  int classId
 * @return data
 */
export const getOrganizationClassDetailByclassId = (classId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiOrganizationUrl}/class/${classId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_CLASS_DETAILS_BY_CLASS_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get classes of an instructor for organization
 *
 * @param  int organizationId
 * @param  int schoolId
 * @return data
 */
export const getOrganizationClassesByinstructorId = (
  props,
  instructorId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_CLASS_LIST_BY_INSTRUCTOR_ID_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/instructorclasses/${instructorId}/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_CLASS_LIST_BY_INSTRUCTOR_ID,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_CLASS_LIST_BY_INSTRUCTOR_ID_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, 3)
  }
}

/**
 * To get classes of a principal for principal
 *
 * @param  object props
 * @return data
 */
export const getPrincipalClasses = (props, page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_PRINCIPAL_CLASS_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/class/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_CLASS_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_PRINCIPAL_CLASS_LIST_LOADED,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}

/**
 * To get class detail of a class for principal
 *
 * @param  object props
 * @param  int classId
 * @return data
 */
export const getPrincipalClassDetailByclassId = (classId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiPrincipalUrl}/class/${classId}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_CLASS_DETAILS_BY_CLASS_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}

/**
 * To get classes of a principal for organization
 *
 * @param  int organizationId
 * @param  int schoolId
 * @return data
 */
export const getPrincipalClassesByinstructorId = (
  props,
  instructorId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PRINCIPAL_CLASS_LIST_BY_INSTRUCTOR_ID_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/class/instructor/${instructorId}/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_CLASS_LIST_BY_INSTRUCTOR_ID,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_PRINCIPAL_CLASS_LIST_BY_INSTRUCTOR_ID_LOADED,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, 3)
  }
}

/**
 * To store/save class
 *
 * @param  object  values
 * @param  object  props
 * @return classId
 */
export const storeClass = (values, props) => async (dispatch, getState) => {
  try {
    const tag_ids = []
    const new_tags = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        if (item.id) {
          tag_ids.push(item.id)
        }
        if (item.newid) {
          new_tags.push(item.name)
        }
      })
    }
    // console.log(`start_date=${values.start_date}`);

    // const start_dateUTC = moment.utc(values.start_date);
    // console.log(`start_dateUTC=${start_dateUTC}`);
    // const end_dateUTC = moment.utc(values.end_date);
    // const start_date = moment(start_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');
    // const end_date = moment(end_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');

    // const start_dateFormat =  moment(values.start_date, 'MM/DD/YYYY').format('YYYY-MM-DD');
    // const start_dateUTC = moment.utc(start_dateFormat);
    // console.log(`start_dateUTC=${start_dateUTC}`);
    // const end_dateUTC = moment.utc(values.end_date);
    // const start_date = moment(start_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');
    // const end_date = moment(end_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');

    const currentHH = moment().format('HH')
    const currentMM = moment().format('mm')
    const currentSS = moment().format('ss')
    const time = ` ${currentHH}:${currentMM}:${currentSS}`
    // const startFormat =  moment(values.start_date +time, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    // const start_date = moment(startFormat).utc().format('YYYY-MM-DD');

    // const endFormat =  moment(values.end_date +time, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(endFormat).utc().format('YYYY-MM-DD');

    // console.log(`values start_date=${values.start_date}`);
    // console.log(`startFormat=${startFormat}`);
    // console.log(`start_date=${start_date}`);

    const startFormat = moment(
      values.start_date + time,
      'MM/DD/YYYY HH:mm:ss',
    ).format('YYYY-MM-DD HH:mm:ss')
    const start_date = moment(startFormat)
      .utc()
      .format('YYYY-MM-DD')

    const endFormat1 = moment(values.end_date, 'MM/DD/YYYY HH:mm:ss').format(
      'YYYY-MM-DD',
    )
    const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
      'YYYY-MM-DD HH:mm:ss',
    )
    const end_date = moment(endFormat)
      .utc()
      .format('YYYY-MM-DD')

    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }

    const classsValues = {
      name: values.name,
      instructor: values.instructor,
      description: values.description,
      start_date,
      end_date,
      total_license: parseInt(Number(values.total_license)),
      tags: tag_ids.length > 0 ? tag_ids.toString() : null,
      new_tags: new_tags.length > 0 ? new_tags.toString() : null,
    }
    // console.log(classsValues);
    const response = await axios.post(
      `${config.apiPrincipalUrl}/class`,
      classsValues,
      headers,
    )
    dispatch({
      type: STORE_CLASS,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}

/**
 * To update class
 *
 * @param  int  classId
 * @param  object  values
 * @param  object  props
 * @return classId
 */
export const updateClass = (classId, values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const tag_ids = []
    const new_tags = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        if (item.id) {
          tag_ids.push(item.id)
        }
        if (item.newid) {
          new_tags.push(item.name)
        }
      })
    }
    // const start_dateUTC = moment.utc(values.start_date);
    // const end_dateUTC = moment.utc(values.end_date);
    // const start_date = moment(start_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');
    // const end_date = moment(end_dateUTC, 'MM/DD/YYYY').format('YYYY-MM-DD');

    const currentHH = moment().format('HH')
    const currentMM = moment().format('mm')
    const currentSS = moment().format('ss')
    const time = ` ${currentHH}:${currentMM}:${currentSS}`

    const startFormat = moment(
      values.start_date + time,
      'MM/DD/YYYY HH:mm:ss',
    ).format('YYYY-MM-DD HH:mm:ss')
    const start_date = moment(startFormat)
      .utc()
      .format('YYYY-MM-DD')

    const endFormat1 = moment(values.end_date, 'MM/DD/YYYY HH:mm:ss').format(
      'YYYY-MM-DD',
    )
    const endFormat = moment(endFormat1 + time, 'YYYY-MM-DD HH:mm:ss').format(
      'YYYY-MM-DD HH:mm:ss',
    )
    const end_date = moment(endFormat)
      .utc()
      .format('YYYY-MM-DD')

    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }

    const classsValues = {
      name: values.name,
      instructor: values.instructor,
      description: values.description,
      status: values.status,
      start_date,
      end_date,
      total_license: parseInt(Number(values.total_license)),
      tags: tag_ids.length > 0 ? tag_ids.toString() : null,
      new_tags: new_tags.length > 0 ? new_tags.toString() : null,
    }
    // console.log(classsValues);
    const response = await axios.put(
      `${config.apiPrincipalUrl}/class/${classId}`,
      classsValues,
      headers,
    )
    dispatch({
      type: UPDATE_CLASS,
      payload: response.data.result,
    })
    return response.data.result.data.id
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}

/**
 * To get classes of a teacher for teacher
 *
 * @param  object props
 * @return data
 */
export const getTeacherClasses = (props, page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_TEACHER_CLASS_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiTeacherUrl}/class/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_TEACHER_CLASS_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_TEACHER_CLASS_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, props, 3)
  }
}

/**
 * To get class detail of a class for teacher
 *
 * @param  object props
 * @param  int classId
 * @return data
 */
export const getTeacherClassDetailByClassId = classId => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authTeacher.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiTeacherUrl}/class/${classId}`,
      headers,
    )
    dispatch({
      type: GET_TEACHER_CLASS_DETAILS_BY_CLASS_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTeacherTranslate(error, {}, 3)
  }
}
