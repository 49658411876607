import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import config from 'config'
import Copyright from 'components/shared/Footer/Copyright'

import logo from 'app-assets/images/logo/logo-icon1.png'
import img2 from 'app-assets/images/backgrounds/02.jpg'
import logo2 from 'app-assets/images/logo/logo-icon2.png'


export const LeftSideNavigation = props => {
  const { match, translate, isHamburger, is_admin_report_creation: isAdminReportCreation } = props
  return (
    <div
      className="main-menu menu-fixed menu-light menu-accordion    menu-shadow "
      data-scroll-to-active="true"
      data-img={img2}
    >
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <a className="navbar-brand" href={`${config.teacherUrl}/home`}>
              <img
                className="brand-logo"
                alt={translate('companyName')}
                src={logo}
                width="100%;"
              />
              <h3 className="brand-text">
                {' '}
                <img
                  className="brand-logo1"
                  alt={translate('companyName')}
                  src={logo2}
                  width="100%;"
                />
              </h3>
            </a>
          </li>
          <li className="nav-item d-md-none">
            <button className="btn btn-link nav-link close-navbar" type="button">
              <i className="ft-x" />
            </button>
          </li>
        </ul>
      </div>
      <div className="navigation-background" />
      <div className="main-menu-content">
        <ul
          className="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          <li
            className={
              match.path === `${config.teacherUrl}/home` ? 'nav-item active' : 'nav-item'
            }
          >
            <a href={`${config.teacherUrl}/home`}>
              <i className="ft-home" />
              <span className="menu-title" data-i18n>
                {translate('leftNavMenuDashboard')}
              </span>
            </a>
          </li>
          <li
            className={
              match.path === `${config.teacherUrl}/classes` ||
              match.path === `${config.teacherUrl}/class/:cid`
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <a href={`${config.teacherUrl}/classes`}>
              <i className="fas fa-graduation-cap" />
              <span className="menu-title" data-i18n>
                {translate('leftNavMenuClasses')}
              </span>
            </a>
          </li>
          <li
            className={
              match.path === `${config.teacherUrl}/students` ||
              match.path === `${config.teacherUrl}/student/:sid` ||
              match.path === `${config.teacherUrl}/edit-student/:sid`
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <a href={`${config.teacherUrl}/students`}>
              <i className="fas fa-poll" />
              <span className="menu-title" data-i18n>
                {translate('leftNavMenuStudents')}
              </span>
            </a>
          </li>
          {isAdminReportCreation === 1 && (
            <li
              className={
                match.path === `${config.teacherUrl}/reports` ? 'nav-item active' : 'nav-item'
              }
            >
              <a href={`${config.teacherUrl}/reports`}>
                <i className="fas fa-poll" />
                <span className="menu-title" data-i18n>
                  {translate('leftNavMenuReports')}
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
      {isHamburger === false && (
        <Copyright path={`${config.teacherUrl}/home`} />
      )}
    </div>
  )
}

LeftSideNavigation.propTypes = {
  translate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired,
  isHamburger: PropTypes.bool.isRequired,
  is_admin_report_creation: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default withRouter(connect(mapStateToProps)(LeftSideNavigation))
