import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'

import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'

import Student from 'pages/Student/Home/Common/Student'
import PlatformDownload from 'components/Download/PlatformDownload'
import ClassDetail from './ClassDetail'

const Dashboard = ({ addTranslationForLanguage }) => {
  useEffect(() => {
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }, [addTranslationForLanguage])

  return (
    <div className="content-wrapper">
      <div className="content-wrapper-before" />
      <div className="content-header row" />
      <div className="content-body">
        <Student />
        <PlatformDownload optionForAll />
        <ClassDetail />
      </div>
    </div>
  )
}

Dashboard.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
}

export default withLocalize(Dashboard)
