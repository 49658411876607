import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import logo from 'app-assets/images/logo/logo-icon1.png'
import image2 from 'app-assets/images/backgrounds/02.jpg'
import logo2 from 'app-assets/images/logo/logo-icon2.png'

import config from 'config'

import Copyright from 'components/shared/Footer/Copyright'

class LeftSideNavigation extends React.Component {
  render() {
    const { match, location, isHamburger } = this.props
    const organizationId = match.params.id
    return (
      <div
        className="main-menu menu-fixed menu-light menu-accordion    menu-shadow "
        data-scroll-to-active="true"
        data-img={image2}
      >
        <div className="navbar-header">
          <ul className="nav navbar-nav flex-row">
            <li className="nav-item mr-auto">
              <Link className="navbar-brand" to={`${config.adminUrl}/home`}>
                <img className="brand-logo" alt="FluentWorlds" src={logo} width="100%;" />
                <h3 className="brand-text">
                  {' '}
                  <img className="brand-logo1" alt="FluentWorlds" src={logo2} width="100%;" />
                </h3>
              </Link>
            </li>
            <li className="nav-item d-md-none">
              <Link className="nav-link close-navbar" to="">
                <i className="ft-x" />
              </Link>
            </li>
          </ul>
        </div>

        <div className="navigation-background" />
        <div className="main-menu-content">
          <ul
            className="navigation navigation-main"
            id="main-menu-navigation"
            data-menu="menu-navigation"
          >
            <li
              className={
                location.pathname === `${config.adminUrl}/home` ? 'nav-item active' : 'nav-item'
              }
            >
              <Link className="menu-item" to={`${config.adminUrl}/home`}>
                <i className="ft-home" />
                <span className="menu-title" data-i18n>
                  Dashboard
                </span>
              </Link>
            </li>
            <li
              className={
                match.path === `${config.adminUrl}/organization/:id` ||
                match.path === `${config.adminUrl}/organization/:id/instructors` ||
                match.path === `${config.adminUrl}/organization/:id/classes` ||
                match.path === `${config.adminUrl}/organization/:id/reports` ||
                match.path === `${config.adminUrl}/edit-organization/:id` ||
                match.path === `${config.adminUrl}/organization/:id/schools/:sid` ||
                match.path === `${config.adminUrl}/organization/:id/instructor/:iid` ||
                match.path === `${config.adminUrl}/organization/:id/class/:cid` ||
                match.path === `${config.adminUrl}/organization/:id/create-report` ||
                match.path === `${config.adminUrl}/organization/:id/report/:rid`
                  ? 'nav-item has-sub open'
                  : 'hide'
              }
            >
              <label
                className={match.path === `${config.adminUrl}/organization/:id` ? 'active' : ''}
                to="/#"
              >
                <i className="ft-grid" />
                <span className="menu-title" data-i18n>
                  Organization Home
                </span>
              </label>
              <ul className="menu-content">
                <li
                  className={
                    match.path === `${config.adminUrl}/organization/:id/instructors` ||
                    match.path === `${config.adminUrl}/organization/:id/instructor/:iid`
                      ? 'active'
                      : ''
                  }
                >
                  <Link
                    className="menu-item"
                    to={`${config.adminUrl}/organization/${organizationId}/instructors`}
                  >
                    View Instructors
                  </Link>
                </li>
                <li
                  className={
                    match.path === `${config.adminUrl}/organization/:id/classes` ||
                    match.path === `${config.adminUrl}/organization/:id/class/:cid`
                      ? 'active'
                      : ''
                  }
                >
                  <Link
                    className="menu-item"
                    to={`${config.adminUrl}/organization/${organizationId}/classes`}
                  >
                    View Classes{' '}
                  </Link>
                </li>
                <li
                  className={
                    match.path === `${config.adminUrl}/organization/:id/reports` ||
                    match.path === `${config.adminUrl}/organization/:id/report/:rid` ||
                    match.path === `${config.adminUrl}/organization/:id/create-report`
                      ? 'active'
                      : ''
                  }
                >
                  {' '}
                  <Link
                    className="menu-item"
                    to={`${config.adminUrl}/organization/${organizationId}/reports`}
                  >
                    View Reports{' '}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {isHamburger === false && (
          <Copyright path={`${config.adminUrl}/home`} />
        )}
      </div>
    )
  }
}

export default withRouter(LeftSideNavigation)
