import {
  GET_ORGANIZATION_SCHOOL_LIST,
  GET_ORGANIZATION_SCHOOL_LIST_LOADING,
  GET_ORGANIZATION_SCHOOL_LIST_LOADED,
} from 'store/actions/school'

const initialState = {
  schools: [],
}

const organizationOrganizationSchoolsReducer = (
  state = initialState,
  action = {},
) => {
  const newState = state
  switch (action.type) {
    case GET_ORGANIZATION_SCHOOL_LIST:
      if (action.payload.currentPage === 0) {
        newState.schools = action.payload.schools
        return {
          schools: newState.schools,
          ...newState,
          data: action.payload,
        }
      }
      newState.schools = action.payload.schools
      return {
        schools: newState.schools,
        ...newState,
        data: action.payload,
      }

    case GET_ORGANIZATION_SCHOOL_LIST_LOADING:
      return {
        ...newState,
        loading: true,
      }
    case GET_ORGANIZATION_SCHOOL_LIST_LOADED:
      return {
        ...newState,
        loading: false,
      }
    default:
      return newState
  }
}

export default organizationOrganizationSchoolsReducer
