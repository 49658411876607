/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'

class DisplayMobileFormat extends React.Component {
  render() {
    const { number, code } = this.props
    let newNumber = ''
    if (number) {
      newNumber = number
        .replace(/\D+/g, '')
        .replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, '$1-$2-$3') //mask numbers xxx-xxx-xxxx
      if (code != '' && code != null) {
        newNumber = `+${code} ${newNumber}`
      }
    } else {
      newNumber = ''
    }

    return <Fragment>{newNumber}</Fragment>
  }
}

export default DisplayMobileFormat
