import { STUDENT_LOGIN_USER, STUDENT_LOGOUT_USER } from '../../actions/auth'

const authStudent = JSON.parse(localStorage.getItem('authStudent'))

const authStudentReducer = (
  state = {
    user: authStudent ? authStudent.user : null,
    accessToken: authStudent ? authStudent.token : null,
  },
  action,
) => {
  switch (action.type) {
    case STUDENT_LOGIN_USER:
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.token,
      }
    case STUDENT_LOGOUT_USER:
      return {
        ...state,
        user: null,
        accessToken: null,
      }
    default:
      return state
  }
}

export default authStudentReducer
