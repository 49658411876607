/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Chart from 'react-google-charts'
/**
 * import translation related files
 *
 */
import { getTranslate } from 'react-localize-redux'
import config from '../../../../config'

class ClassAttendance extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { translate, data, handleChartClassAttendanceWrapper } = this.props
    return (
      <div className="row">
        <div className="col-12">
          <div className="card card-transparent">
            <div className="card-header bg-transparent pl-0">
              <h5 className="card-title text-bold-700">
                {translate('reportClassAttendance')}
              </h5>
              <a className="heading-elements-toggle">
                <i className="la la-ellipsis-v font-medium-3" />
              </a>
            </div>
            <div className="card-content">
              <div className="table-responsive">
                {data && data.length > 0 && (
                  <Chart
                    width="100%"
                    height={300}
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={data}
                    options={{
                      chartArea: { width: '80%' },
                      hAxis: {
                        minValue: 0,
                      },
                    }}
                    legendToggle
                    getChartWrapper={chartWrapper => {
                      handleChartClassAttendanceWrapper(chartWrapper)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ClassAttendance)
