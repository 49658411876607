import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ReactFlagsSelect from 'react-flags-select'
import 'react-flags-select/css/react-flags-select.css'
import { renderToStaticMarkup } from 'react-dom/server'
import { Translate, withLocalize } from 'react-localize-redux'

import LoginModalBox from 'pages/Root/Common/LoginModalBox'
import logoImg from 'assets/root/images/logo.png'
import logoImg1 from 'assets/root/images/logo-icon.png'

import englishGlobalTranslations from 'lang/en.global.json'
import spanishGlobalTranslations from 'lang/es.global.json'
import chineseGlobalTranslations from 'lang/zh.global.json'

const Header = ({
  initialize,
  addTranslationForLanguage,
  setActiveLanguage,
  translate,
}) => {
  const [isShowLogin, setIsShowLogin] = useState(false)

  useEffect(() => {
    const currentLanguage = JSON.parse(localStorage.getItem('currentLanguage'))
    if (!currentLanguage) {
      const languages = {
        defaultLanguage: 'en',
        defaultCountry: 'US',
      }
      localStorage.setItem('currentLanguage', JSON.stringify(languages))
    }

    const currentSetLanguage = JSON.parse(
      localStorage.getItem('currentLanguage'),
    )
    initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Spanish', code: 'es' },
        { name: 'Chinese', code: 'zh' },
      ],
      translation: englishGlobalTranslations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: currentSetLanguage.defaultLanguage,
      },
    })

    addTranslationForLanguage(englishGlobalTranslations, 'en')
    addTranslationForLanguage(spanishGlobalTranslations, 'es')
    addTranslationForLanguage(chineseGlobalTranslations, 'zh')
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const onSelectFlag = countryCode => {
    let langCode = 'en'
    if (countryCode === 'US') {
      langCode = 'en'
    } else if (countryCode === 'ES') {
      langCode = 'es'
    } else if (countryCode === 'CN') {
      langCode = 'zh'
    }
    setActiveLanguage(langCode)
    const languages = {
      defaultLanguage: langCode,
      defaultCountry: countryCode,
    }
    localStorage.setItem('currentLanguage', JSON.stringify(languages))
  }

  const currentSetLanguage = JSON.parse(localStorage.getItem('currentLanguage'))

  return (
    <React.Fragment data-testid="header">
      <header>
        <div id="home" className="container-fluid header">
          <div className="container-logo">
            <div className="row">
              <div className="col-sm-3 col-xs-10">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={logoImg}
                      className="img-responsive hidden-xs-dsk"
                      style={{ width: 'auto' }}
                      alt="img"
                    />{' '}
                  </Link>
                  <Link to="/">
                    <img
                      src={logoImg1}
                      className="img-responsive hidden-xs-mobile"
                      style={{ width: 'auto' }}
                      alt="img"
                    />{' '}
                  </Link>
                </div>
              </div>
              <div className="col-sm-9 col-xs-2">
                <div
                  className="right_navs"
                  style={{ paddingTop: '20px', float: 'right' }}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    title={translate('loginLink')}
                    className="login btn_top"
                    onClick={() => setIsShowLogin(true)}
                  >
                    <i className="fa fa-user" />
                    <Translate id="loginLink" />
                  </Link>
                  <ReactFlagsSelect
                    countries={['US', 'ES', 'CN']}
                    customLabels={{
                      US: 'English',
                      ES: 'español',
                      CN: '中文(简体',
                    }}
                    defaultCountry={currentSetLanguage.defaultCountry}
                    showSelectedLabel
                    onSelect={onSelectFlag}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <LoginModalBox
        isShowLogin={isShowLogin}
        setIsShowLogin={setIsShowLogin}
      />
    </React.Fragment>
  )
}

Header.propTypes = {
  initialize: PropTypes.func.isRequired,
  addTranslationForLanguage: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

export default withLocalize(Header)
