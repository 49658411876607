import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { withLocalize, getTranslate } from 'react-localize-redux'
import config from 'config'
import Principal from 'pages/Principal/Home/Common/Principal'
import Detail from 'pages/Principal/Home/Classes/Details/Detail'
import {
  getPrincipalClassDetailByclassId,
  updateClass,
} from 'store/actions/class'
import { getPrincipalProfile, getStats } from 'store/actions/principal'
import { resetForm } from 'store/actions/form'
import EditFormModelBox from 'pages/Principal/Home/Classes/EditFormModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'
import englishFormTranslations from 'lang/organization/form/en.json'
import spanishFormTranslations from 'lang/organization/form/es.json'
import chineseFormTranslations from 'lang/organization/form/zh.json'

class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowNewClassForm: false,
      classId: '',
    }
    const { addTranslationForLanguage } = this.props
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
    addTranslationForLanguage(englishFormTranslations, 'en')
    addTranslationForLanguage(spanishFormTranslations, 'es')
    addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentWillMount() {
    const { match, history, getPrincipalClassDetailByclassId } = this.props
    const classId = match.params.cid
    if (classId === ' ' || classId === 'undefined') {
      history.push(`${config.principalUrl}/home`)
    }
    this.setState({ classId })
    getPrincipalClassDetailByclassId(classId, this.props)
  }

  handleModelShow = () => {
    this.setState({
      isShowNewClassForm: true,
    })
  }

  handleNewClassModelClose = () => {
    const { resetForm } = this.props
    resetForm('principal-edit-class')
    this.setState({
      isShowNewClassForm: false,
    })
  }

  handleSubmit = async values => {
    const {
      updateClass,
      getPrincipalClassDetailByclassId,
      getPrincipalProfile,
      getStats,
      history,
    } = this.props
    const { classId } = this.state
    const classIDUpdate = await updateClass(classId, values, this.props)
    getPrincipalClassDetailByclassId(classIDUpdate, this.props)
    getPrincipalProfile(this.props)
    getStats(this.props)
    this.setState({ isShowNewClassForm: false })
    return history.push(`${config.principalUrl}/class/${classId}`)
  }

  handleCancel = async () => {
    const { translate, resetForm } = this.props
    confirmAlert({
      title: translate('alertHeading'),
      message: translate('alertFormText'),
      buttons: [
        {
          label: translate('btnYes'),
          onClick: async () => {
            resetForm('principal-edit-class')
            this.setState({ isShowNewClassForm: false })
          },
        },
        {
          label: translate('btnNo'),
        },
      ],
    })
  }

  render() {
    const { translate, row } = this.props
    const { isShowNewClassForm } = this.state
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-4 col-12 mb-2">
              <h3 className="content-header-title">
                {translate('breadCrumbClassDetail')}
              </h3>
            </div>
            <div className="content-header-right col-md-8 col-12">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`${config.principalUrl}/home`}>
                        {translate('breadCrumbHome')}
                      </Link>{' '}
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`${config.principalUrl}/classes`}>
                        {translate('breadCrumbClass')}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {translate('breadCrumbClassDetail')}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <Principal />
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-md-12 col-lg-12 pull-left">
                    <Detail row={row} handleModelShow={this.handleModelShow} />
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditFormModelBox
          isShowNewClassForm={isShowNewClassForm}
          handleNewClassModelClose={this.handleNewClassModelClose}
          onSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  row: state.principalClassDetail.data.row,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalClassDetailByclassId: (classId, props) =>
    dispatch(getPrincipalClassDetailByclassId(classId, props)),
  updateClass: (classId, values, props) =>
    dispatch(updateClass(classId, values, props)),
  resetForm: formName => dispatch(resetForm(formName)),
  getPrincipalProfile: props => dispatch(getPrincipalProfile(props)),
  getStats: props => dispatch(getStats(props)),
})

Details.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  getPrincipalClassDetailByclassId: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  updateClass: PropTypes.func.isRequired,
  getStats: PropTypes.func.isRequired,
  getPrincipalProfile: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cid: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  row: PropTypes.shape({}).isRequired,
}

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Details),
)
