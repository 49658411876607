import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import OrganizationCommonDetail from 'pages/Home/Common/Organization'
import OrganizationStats from 'pages/Home/Organization/OrganizationStats'
import Reports from 'pages/Home/Organization/Instructor/Reports'
import { getInstructorsByOrganizationId } from 'store/actions/instructors'
import config from 'config'
import RenderImage from 'components/RenderImage'
import TableRowLicenseProgressBar from 'components/TableRowLicenseProgressBar'
import TextTruncate from 'components/TextTruncate'

class Instructor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationId: '',
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
    }

    const { match, history, getInstructorsByOrganizationId } = this.props
    const organizationId = match.params.id
    if (organizationId === ' ' || organizationId === 'undefined') {
      history.push(`${config.adminUrl}/home`)
    }
    getInstructorsByOrganizationId(organizationId, 0, 'id', 'DESC')
  }

  componentDidMount() {
    const { page } = this.state
    this.setState({ page: page + 1 })
  }

  handlePageChange = async e => {
    const { data, getInstructorsByOrganizationId } = this.props
    const { counter, page, order, orderBy, organizationId } = this.state
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: page + 1 })
        if (data.totalPage > page) {
          this.setState({
            counter: counter + 1,
          })
          await getInstructorsByOrganizationId(
            organizationId,
            page,
            orderBy,
            order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    const { getInstructorsByOrganizationId } = this.props
    const { order: stateOrder, orderBy, organizationId } = this.state
    let order = 'ASC'
    if (orderBy === field) {
      order = stateOrder === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({
      orderBy: field,
      order,
    })
    await getInstructorsByOrganizationId(organizationId, 0, field, order)
    this.setState({ page: 1 })
  }

  render() {
    const { loading: loadingTable, data, instructors } = this.props
    const { order, orderBy, organizationId } = this.state
    const sortClass = order === 'ASC' ? 'ascending' : 'desending'
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">View Instructors</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.adminUrl}/organization/${organizationId}`}
                    >
                      Organization
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">View Instructors</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <OrganizationCommonDetail />
          <div className="row">
            <OrganizationStats />
            <Reports organizationId={organizationId} />
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <h5 className="card-title text-bold-700 my-2">Instructors</h5>

              <div className="card" style={{}}>
                <div
                  className={
                    data && data.totalPage > 0
                      ? 'card-content '
                      : 'card-content'
                  }
                >
                  <div
                    id="recent-projects"
                    className="media-list position-relative"
                    onScroll={this.handlePageChange.bind(this)}
                  >
                    <LoadingOverlay
                      active={loadingTable}
                      spinner
                      text={`${config.loadingOverlayText}`}
                    >
                      <div
                        className={
                          instructors.length === 0
                            ? 'table-responsive table-height'
                            : 'table-responsive'
                        }
                      >
                        {instructors && instructors.length > 0 && (
                          <div id="tableContainer" className="tableContainer">
                            <table
                              className="scrollTable instructor-list-admin-organization"
                              width="100%"
                              cellSpacing={0}
                              cellPadding={0}
                              border={0}
                            >
                              <thead className="fixedHeader">
                                <tr>
                                  <th align="left" width="10%">
                                    Image
                                  </th>
                                  <th
                                    align="left"
                                    width="15%"
                                    onClick={() => this.handleSorting('name')}
                                    className={
                                      orderBy === 'name' ? sortClass : ''
                                    }
                                  >
                                    Name
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="left"
                                    width="15%"
                                    onClick={() => this.handleSorting('email')}
                                    className={
                                      orderBy === 'email' ? sortClass : ''
                                    }
                                  >
                                    Email
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="10%"
                                    onClick={() =>
                                      this.handleSorting('total_class')
                                    }
                                    className={
                                      orderBy === 'total_class' ? sortClass : ''
                                    }
                                  >
                                    Classes
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="20%"
                                    onClick={() =>
                                      this.handleSorting('total_license')
                                    }
                                    className={
                                      orderBy === 'total_license'
                                        ? sortClass
                                        : ''
                                    }
                                  >
                                    Licenses / Assigned
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="left"
                                    width="15%"
                                    onClick={() =>
                                      this.handleSorting('school_name')
                                    }
                                    className={
                                      orderBy === 'school_name' ? sortClass : ''
                                    }
                                  >
                                    School/Dept
                                    <i className="fas fa-sort-up" />
                                  </th>
                                  <th
                                    align="center"
                                    width="15%"
                                    onClick={() => this.handleSorting('tags')}
                                    className={
                                      orderBy === 'tags' ? sortClass : ''
                                    }
                                  >
                                    Tags
                                    <i className="fas fa-sort-up" />
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="scrollContent">
                                {instructors &&
                                  instructors.length > 0 &&
                                  instructors.map(row => (
                                    <tr key={`instructor-${row.id}`}>
                                      <td align="left" width="10%">
                                        <RenderImage
                                          className="instructor_img"
                                          image={row.photo_path}
                                          apiUrl={config.apiUrl}
                                          source="1"
                                          name={row.name}
                                        />
                                      </td>
                                      <td align="left" width="15%">
                                        <Link
                                          // eslint-disable-next-line max-len
                                          to={`${config.adminUrl}/organization/${row.organization_id}/instructor/${row.id}`}
                                        >
                                          {row.name}
                                        </Link>
                                      </td>
                                      <td align="left" width="15%">
                                        <TextTruncate
                                          str={row.email}
                                          length={null}
                                          ending={null}
                                        />
                                      </td>
                                      <td align="center" width="10%">
                                        <NumberFormat
                                          thousandSeparator
                                          value={
                                            row.total_class
                                              ? row.total_class
                                              : 0
                                          }
                                          displayType="text"
                                        />
                                      </td>
                                      <td align="center" width="20%">
                                        <span className="text-center">
                                          <NumberFormat
                                            thousandSeparator
                                            value={
                                              row.total_active_license
                                                ? row.total_active_license
                                                : 0
                                            }
                                            displayType="text"
                                          />{' '}
                                          /{' '}
                                          <NumberFormat
                                            thousandSeparator
                                            value={
                                              row.total_license
                                                ? row.total_license
                                                : 0
                                            }
                                            displayType="text"
                                          />
                                        </span>
                                        <TableRowLicenseProgressBar
                                          activeLicense={
                                            row && row.total_active_license
                                          }
                                          totalLicense={
                                            row && row.total_license
                                          }
                                        />
                                      </td>
                                      <td align="left" width="15%">
                                        {row.school_name}
                                      </td>
                                      <td align="center" width="15%">
                                        {row.tags}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {instructors &&
                          instructors.length === 0 &&
                          loadingTable === false && (
                            <div className="table-no-record">{`${config.tableNoRecord}`}</div>
                          )}
                      </div>
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Instructor.defaultProps = {
  loading: false,
  data: {
    totalPage: 0,
  },
  instructors: [],
}

Instructor.propTypes = {
  getInstructorsByOrganizationId: PropTypes.func.isRequired,
  data: PropTypes.shape({
    totalPage: PropTypes.number,
  }),
  loading: PropTypes.bool,
  instructors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      email: PropTypes.string,
      organization_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      photo_path: PropTypes.string,
      total_active_license: PropTypes.number,
      total_license: PropTypes.number,
      total_class: PropTypes.number,
    }),
  ),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

const mapStateToProps = state => ({
  data: state.organizatoinInstructors.data,
  instructors: state.organizatoinInstructors.instructors,
  loading: state.organizatoinInstructors.loading,
})

const mapDispatchToProps = dispatch => ({
  getInstructorsByOrganizationId: (organizationId, page, orderBy, order) => {
    dispatch(
      getInstructorsByOrganizationId(organizationId, page, orderBy, order),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Instructor)
