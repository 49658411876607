import {
  GET_CLASS_LIST_BY_SCHOOL_ID,
  GET_CLASS_LIST_BY_SCHOOL_ID_LOADING,
  GET_CLASS_LIST_BY_SCHOOL_ID_LOADED,
} from 'store/actions/class'

const initialState = {
  classes: [],
}

const schoolClassesReducer = (state = initialState, action = {}) => {
  const newState = state
  switch (action.type) {
    case GET_CLASS_LIST_BY_SCHOOL_ID:
      if (action.payload.currentPage === 0) {
        newState.classes = action.payload.classes
        return {
          classes: newState.classes,
          ...newState,
          data: action.payload,
        }
      }
      newState.classes = action.payload.classes
      return {
        classes: newState.classes,
        ...newState,
        data: action.payload,
      }

    case GET_CLASS_LIST_BY_SCHOOL_ID_LOADING:
      return {
        ...newState,
        loading: true,
      }
    case GET_CLASS_LIST_BY_SCHOOL_ID_LOADED:
      return {
        ...newState,
        loading: false,
      }
    default:
      return newState
  }
}

export default schoolClassesReducer
