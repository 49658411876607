/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate, withLocalize } from 'react-localize-redux'
import config from '../../../../../config'
import Organization from '../../Common/Organization'
import Detail from './Detail'
import Stats from './Stats'
import {
  getOrganizationSchoolDetailBySchoolId,
  downloadPDFOrganization,
} from '../../../../../store/actions/school'

import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'

class Details extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
    this.state = { schoolId: '' }
  }

  componentWillMount() {
    const { match } = this.props
    const schoolId = match.params.sid
    if (schoolId === ' ' || schoolId === 'undefined') {
      this.props.history.push(`${config.organizationUrl}/home`)
    }
    this.setState({ schoolId })
    this.props.getOrganizationSchoolDetailBySchoolId(this.props, schoolId)
  }

  handleDownloadPDF = async () => {}

  render() {
    const { translate, row } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              {translate('breadCrumbSchoolDetail')}
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/home`}>
                      {translate('breadCrumbHome')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/schools`}>
                      {translate('breadCrumbSchool')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {translate('breadCrumbSchoolDetail')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <Organization />

          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <Detail row={row} />
                <Stats row={row} />
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  row: state.organizationOrganizationSchoolDetail.data.row,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationSchoolDetailBySchoolId: (props, schoolId) =>
    dispatch(getOrganizationSchoolDetailBySchoolId(props, schoolId)),
  downloadPDFOrganization: (schoolId, props) =>
    dispatch(downloadPDFOrganization(schoolId, props)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Details),
)
