/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { reduxForm, Field, reset } from 'redux-form'
import { Modal } from 'react-bootstrap'
import { getTranslate } from 'react-localize-redux'
import Loader from '../../../../../components/Loader'
import RenderField from '../../../../../components/RenderFieldTranlate'
import RenderSelectField from '../../../../../components/RenderSelectFieldTranlate'
import RenderTextField from '../../../../../components/RenderTextFieldTranlate'
import RenderDateStartPicker from '../../../../../components/RenderDateRangePickerTranlate/start'
import RenderDateEndtPicker from '../../../../../components/RenderDateRangePickerTranlate/end'
import FileInput from '../../../../../components/RenderFileInputTranlate'
import RenderMultipleSelectWithCreateField from '../../../../../components/RenderMultipleSelectWithCreateFieldTranslate'
// import validateForm from './helpers';
import { getPrincipalTags } from '../../../../../store/actions/tag'

/**
 * import translation related files
 *
 */

class CreateNewFormModelBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = { file: '', imagePreviewUrl: '' }
  }

  componentWillMount() {
    this.props.getPrincipalTags(this.props)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      error,
      reset,
      handleCancel,
      isShowNewInstructorForm,
      handleNewInstructorModelClose,
      translate,
      tags,
      handleCreateTag,
      principalProfile,
      handleInputChange,
      imagePreviewUrl,
    } = this.props

    return (
      <Modal
        show={isShowNewInstructorForm}
        onHide={handleNewInstructorModelClose}
        animation={false}
        id="changePassword"
        dialogClassName=""
        aria-labelledby="basicModalLabel1"
      >
        <div className="modal-content">
          <form
            className="form-horizontal"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="modal-header">
              <h4 className="modal-title" id="basicModalLabel1">
                {translate('formInstructorTitleEdit')}
              </h4>
              <button
                type="button"
                className="close"
                onClick={handleNewInstructorModelClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="article_hidden" className="modal-single">
                <div className="banner_pic">
                  <div className="upload-wrap">
                    <div
                      className="uploadpreview 01"
                      style={{ backgroundImage: `url(${imagePreviewUrl})` }}
                    />
                    <Field
                      component={FileInput}
                      name="image"
                      type="file"
                      accept=".png,.jpeg,.jpg"
                      onChange={handleInputChange.bind(this)}
                    />
                    <label>{translate('formInstructorEditImage')}</label>
                  </div>
                </div>
                <div className="clearfix" />
              </div>

              <div className="form-group">
                <label htmlFor="name">
                  <span>*</span>
                  {translate('formInstructorName')}
                </label>
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={translate('formInstructorName')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  <span>*</span>
                  {translate('formInstructorEmail')}
                </label>
                <Field
                  name="email"
                  component={RenderField}
                  type="text"
                  placeholder={translate('formInstructorEmail')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="school_name">
                  <span>*</span>
                  {translate('formInstructorSchool')}
                </label>
                <Field
                  name="school_name"
                  component={RenderSelectField}
                  disabled="disabled"
                >
                  {principalProfile && (
                    <option value={principalProfile.id}>
                      {principalProfile.school_name}
                    </option>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <label htmlFor="status">
                  <span>*</span>
                  {translate('formInstructorStatus')}
                </label>
                <Field name="status" component={RenderSelectField}>
                  <option value="Active">Active</option>
                  <option value="Deactivated">Deactivate</option>
                </Field>
              </div>
              <div className="form-group select_p_tags">
                <label htmlFor="reportName">
                  {translate('formInstructorTag')}
                </label>
                <Field
                  name="tag_ids"
                  component={RenderMultipleSelectWithCreateField}
                  data={tags}
                  textField="name"
                  valueField="id"
                  type="text"
                  formName="principal-edit-instructor"
                  fieldName="tag_ids"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={
                  pristine || submitting
                    ? handleNewInstructorModelClose
                    : handleCancel
                }
              >
                {translate('formInstructorBtnCancel')}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {submitting ? <Loader /> : translate('formInstructorBtnSave')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

CreateNewFormModelBox = reduxForm({
  form: 'principal-edit-instructor',
  // validate: validateForm,
  enableReinitialize: true,
})(CreateNewFormModelBox)

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  tags: state.principalTags.data.tags,
  principalProfile: state.principalProfile.data.row,
  initialValues: state.principalInstructorDetail.data.row,
})

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset('principal-edit-instructor')),
  getPrincipalTags: props => dispatch(getPrincipalTags(props)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewFormModelBox)
