import {
  STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADING,
  STUDENT_ADVENTURE_ASSESMENT_DT_LIST,
  STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADED,
} from '../../actions/student'

const adventureAssessmentStatsDetailReducer = (
  state = { data: [] },
  action,
) => {
  switch (action.type) {
    case STUDENT_ADVENTURE_ASSESMENT_DT_LIST:
      return {
        ...state,
        data: action.payload,
      }
    case STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADING:
      return {
        ...state,
        loading: false,
      }
    case STUDENT_ADVENTURE_ASSESMENT_DT_LIST_LOADED:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}

export default adventureAssessmentStatsDetailReducer
