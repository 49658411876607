import isEquals from 'validator/lib/equals'

const validateForm = values => {
  const errors = {}

  // current_password
  if (!values.current_password) {
    errors.current_password = [`Current password is required`]
  }

  // password
  if (!values.password) {
    errors.password = [`New Password is required`]
  } else if (values.password.length < 5) {
    errors.password = [`Password should be 5 to 20 characters`]
  } else if (values.password.length > 20) {
    errors.password = [`Password should be 5 to 20 characters`]
  }

  if (values.current_password && values.password) {
    if (isEquals(values.current_password, values.password)) {
      errors.password = [`Password should not same as current password`]
    }
  }
  // confirm_password
  if (values.password) {
    if (!values.confirm_password) {
      errors.confirm_password = [`Confirm password is required `]
    } else if (!isEquals(values.confirm_password, values.password)) {
      errors.confirm_password = [
        `Confirm password doesn't match with new password`,
      ]
    }
  }

  return errors
}

export default validateForm
