/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../../../config'

const Reports = ({ organizationId }) => (
  <div className="col-md-12 col-lg-4">
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Reports and Analytics</h4>
      </div>
      <div className="card-content collapse show">
        <div className="card-body p-0 pb-0">
          <div className="chartist">
            <Link
              className="add_btn pull-right generate_report"
              to={`${config.adminUrl}/organization/${organizationId}/create-report`}
            >
              Generate Report
            </Link>
            <div id="project-stats" className="height-200 areaGradientShadow1" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Reports
