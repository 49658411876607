/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import Loader from '../../../../components/Loader'
import RenderField from '../../../../components/RenderField'

import validateForm from './helpers'

const ChangePasswordForm = ({
  pristine,
  handleSubmit,
  submitting,
  error,
  reset,
  close,
  handleCancel,
}) => {
  return (
    <React.Fragment>
      <form className="form-horizontal" onSubmit={handleSubmit} autoComplete="off">
        <div className="modal-header">
          <h4 className="modal-title">Change Password</h4>
          <button
            type="button"
            className="close"
            onClick={pristine || submitting ? close : handleCancel}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label htmlFor="current_password">
              <span>*</span>
              Current Password
            </label>
            <Field
              type="password"
              className="form-control round"
              placeholder="Current Password"
              component={RenderField}
              name="current_password"
              id="current_password"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">
              <span>*</span>
              New Password
            </label>
            <Field
              type="password"
              className="form-control round"
              placeholder="New Password"
              component={RenderField}
              name="password"
              id="password"
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirm_password">
              <span>*</span>
              Confirm Password
            </label>
            <Field
              type="password"
              className="form-control round"
              placeholder="Confirm Password"
              component={RenderField}
              name="confirm_password"
              id="confirm_password"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger"
            onClick={pristine || submitting ? close : handleCancel}
          >
            Close
          </button>
          <button type="submit" className="btn grey btn-primary " disabled={pristine || submitting}>
            {' '}
            {submitting ? <Loader /> : 'Save'}
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}
export default reduxForm({
  form: 'admin-change-password',
  validate: validateForm,
})(ChangePasswordForm)
