/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { reduxForm, Field } from 'redux-form'
import moment from 'moment'
import Loader from '../../../../../components/Loader'
import RenderField from '../../../../../components/RenderField'
import RenderTextField from '../../../../../components/RenderTextField'
import RenderMultipleSelectField from '../../../../../components/RenderMultipleSelectField'
import RenderDateStartPicker from '../../../../../components/RenderDateRangePicker/start'
import RenderDateEndtPicker from '../../../../../components/RenderDateRangePicker/end'
import validateForm from './helpers'

class ReportForm extends React.Component {
  constructor(props) {
    super(props)
    const date = moment().format('YYYY-MM-DD')
    this.state = {
      startDate: new Date(date),
      endDate: new Date(date),
      today: new Date(date),
    }
  }

  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate
    endDate = endDate || this.state.endDate
    this.setState({ startDate, endDate })
  }

  handleChangeStart = startDate => this.handleChange({ startDate })

  handleChangeEnd = endDate => this.handleChange({ endDate })

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      tags,
      schools,
      handleInputChange,
      handleCancel,
      handleRedirect,
    } = this.props

    return (
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <div className="card">
            <div className="card-content collapse show">
              <div className="card-body">
                <form className="form create-report" onSubmit={handleSubmit} autoComplete="off">
                  <div className="form-body">
                    <div className="form-group">
                      <label htmlFor="name">
                        <span>*</span>
                        Report Name
                      </label>
                      <Field
                        name="name"
                        component={RenderField}
                        type="text"
                        placeholder="Report Name"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="description">Report Description</label>
                      <Field
                        name="description"
                        component={RenderTextField}
                        placeholder="Report Description"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="reportName">
                        <span>*</span>
                        Schools / Departments
                      </label>
                      <Field
                        name="school_ids"
                        component={RenderMultipleSelectField}
                        data={schools}
                        textField="name"
                        valueField="id"
                      />
                    </div>
                    <div className="form-group date_ranger">
                      <label>Start Date - End Date</label>
                      <div className="input-group">
                        <Field
                          name="start_date"
                          component={RenderDateStartPicker}
                          id="start_date"
                          placeholder="Start Date"
                          required
                          selected={this.state.startDate}
                          selectsStart
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onChange={this.handleChangeStart}
                          readonly
                        />
                        <Field
                          name="end_date"
                          component={RenderDateEndtPicker}
                          id="end_date"
                          placeholder="End Date"
                          selected={this.state.endDate}
                          selectsEnd
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onChange={this.handleChangeEnd}
                          minDate={this.state.startDate}
                          required
                          readonly
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="reportName">Tags</label>
                      <Field
                        name="tag_ids"
                        component={RenderMultipleSelectField}
                        data={tags}
                        textField="name"
                        valueField="id"
                      />
                    </div>
                  </div>
                  <div className="form-actions right">
                    <button
                      type="button"
                      className="btn btn-danger mr-1"
                      onClick={pristine || submitting ? handleRedirect : handleCancel}
                    >
                      <i className="ft-x" /> Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={pristine || submitting}
                    >
                      <i className="la la-check-square-o" /> {submitting ? <Loader /> : 'Create'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'create-new=report-form',
  validate: validateForm,
})(ReportForm)
