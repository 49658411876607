import {
  GET_REPORT_LIST,
  GET_REPORT_LIST_LOADING,
  GET_REPORT_LIST_LOADED,
} from 'store/actions/report'

const initialState = {
  reports: [],
}

const organizationReportReducer = (state = initialState, action = {}) => {
  const newState = state
  switch (action.type) {
    case GET_REPORT_LIST:
      if (action.payload.currentPage === 0) {
        newState.reports = action.payload.reports
        return {
          reports: newState.reports,
          ...newState,
          data: action.payload,
        }
      }
      newState.reports = action.payload.reports
      return {
        reports: newState.reports,
        ...newState,
        data: action.payload,
      }

    case GET_REPORT_LIST_LOADING:
      return {
        ...newState,
        loading: true,
      }
    case GET_REPORT_LIST_LOADED:
      return {
        ...newState,
        loading: false,
      }
    default:
      return newState
  }
}

export default organizationReportReducer
