/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withLocalize, getTranslate } from 'react-localize-redux'
import config from '../../../config'
import englishGlobalTranslations from '../../../lang/en.global.json'
import spanishGlobalTranslations from '../../../lang/es.global.json'
import chineseGlobalTranslations from '../../../lang/zh.global.json'

import Noty from '../../../components/Noty'
import Header from './Common/Header'
import LeftSideNavigation from './Common/LeftSideNavigation'
import Footer from './Common/Footer'
import { hamburger, removeToster } from '../../../store/actions/form'

class TeacherDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHamburger: false,
    }

    this.props.addTranslationForLanguage(englishGlobalTranslations, 'en')
    this.props.addTranslationForLanguage(spanishGlobalTranslations, 'es')
    this.props.addTranslationForLanguage(chineseGlobalTranslations, 'zh')
  }

  handleHamburger = () => {
    const { isHamburger } = this.state
    const currentStatus = isHamburger !== true
    this.setState({ isHamburger: currentStatus })
  }

  componentDidMount() {
    const { user, history } = this.props
    if (!user) {
      return history.push(`${config.teacherUrl}/login`)
    }
  }

  handleCloseNoty = () => {
    const { removeToster } = this.props
    removeToster()
  }

  render() {
    const { user, children } = this.props
    const { isHamburger } = this.state
    return (
      <div
        onClick={this.handleCloseNoty}
        className={
          isHamburger === true
            ? 'vertical-layout vertical-menu 2-columns fixed-navbar menu-collapsed'
            : 'vertical-layout vertical-menu 2-columns fixed-navbar menu-expanded'
        }
        id="main-div"
        data-open="click"
        data-menu="vertical-menu"
        data-color="bg-gradient-x-purple-blue"
        data-col="2-columns"
      >
        <Noty />
        <Header handleHamburger={this.handleHamburger} />
        <LeftSideNavigation
          isHamburger={isHamburger}
          is_admin_report_creation={user && user.is_admin_report_creation}
        />
        <div className="app-content content">{children}</div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.authTeacher.user,
  translate: getTranslate(state.localize),
  isHamburger: state.isHamburger.data,
})

const mapDispatchToProps = dispatch => ({
  hamburger: status => dispatch(hamburger(status)),
  removeToster: () => {
    dispatch(removeToster())
  },
})

export default withLocalize(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TeacherDashboard)),
)
