/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Loader from 'components/Loader'
import RenderField from 'components/RenderField'
import RenderSelectField from 'components/RenderSelectField'
import FileInput from 'components/RenderFileInput'
import RenderDateStartPicker from 'components/RenderDateRangePicker/start'
import RenderDateEndtPicker from 'components/RenderDateRangePicker/end'
import validateOrganizationForm from 'pages/Home/NewOrganization/OrganizationForm/helpers'
import config from 'config'

class OrganizationForm extends React.Component {
  constructor(props) {
    super(props)
    const date = moment().format('YYYY-MM-DD')
    this.state = {
      startDate: new Date(date),
      endDate: new Date(date),
      today: new Date(date),
    }
  }

  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate
    endDate = endDate || this.state.endDate
    this.setState({ startDate, endDate })
  }

  handleChangeStart = startDate => this.handleChange({ startDate })

  handleChangeEnd = endDate => this.handleChange({ endDate })

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      countries,
      countriesCode,
      states,
      handleInputChange,
      handleCancel,
      handleRedirect,
      handleShowStates,
    } = this.props

    const { startDate, endDate } = this.state

    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">Add Organization</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Organization</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-content collapse show">
                      <div className="card-body">
                        <form
                          className="form edit_organization_form"
                          onSubmit={handleSubmit}
                          encType="multipart/form-data"
                          autoComplete="off"
                        >
                          <div className="form-body">
                            <div className="col-6">
                              <h4>Company</h4>

                              <div className="form-group">
                                <label htmlFor="organization_name">
                                  <span>*</span>
                                  Organization Name
                                </label>
                                <Field
                                  name="organization_name"
                                  component={RenderField}
                                  type="text"
                                  placeholder="Organization Name"
                                />
                              </div>

                              <div className="form-group">
                                <label htmlFor="address_1">
                                  <span>*</span>
                                  Address Line 1
                                </label>
                                <Field
                                  name="address_1"
                                  component={RenderField}
                                  type="text"
                                  placeholder="Address Line 1"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="address_2">Address Line 2</label>
                                <Field
                                  className="form-control square"
                                  name="address_2"
                                  component={RenderField}
                                  type="text"
                                  placeholder="Address Line 2"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="address_3">Address Line 3</label>
                                <Field
                                  className="form-control square"
                                  name="address_3"
                                  component={RenderField}
                                  type="text"
                                  placeholder="Address Line 3"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="country_id">
                                  <span>*</span>
                                  Country
                                </label>
                                <Field
                                  name="country_id"
                                  component={RenderSelectField}
                                  onChange={handleShowStates.bind(this)}
                                >
                                  <option disabled value="">
                                    Countries
                                  </option>
                                  {countries &&
                                    countries.map(country => (
                                      <option value={country.id}>{country.name}</option>
                                    ))}
                                </Field>
                              </div>
                              <div className="form-group">
                                <label htmlFor="state">
                                  <span>*</span>
                                  State
                                </label>
                                <Field name="state" component={RenderSelectField}>
                                  <option value="">State</option>
                                  {states &&
                                    states.map(state => (
                                      <option value={state.id}>{state.name}</option>
                                    ))}
                                </Field>
                              </div>
                              <div className="form-group">
                                <label htmlFor="city">
                                  <span>*</span>
                                  City
                                </label>
                                <Field
                                  name="city"
                                  component={RenderField}
                                  type="text"
                                  placeholder="City"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="zip_code">
                                  <span>*</span>
                                  ZIP/Postal Code
                                </label>
                                <Field
                                  name="zip_code"
                                  component={RenderField}
                                  type="text"
                                  placeholder="Zip/Postal Code"
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <h4>Contact</h4>
                              <div className="form-group">
                                <label htmlFor="name">
                                  <span>*</span>
                                  Primary Contact Name
                                </label>
                                <Field
                                  name="name"
                                  component={RenderField}
                                  type="text"
                                  placeholder="Primary Contact Name"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="email">
                                  <span>*</span>
                                  Email
                                </label>
                                <Field
                                  name="email"
                                  component={RenderField}
                                  type="email"
                                  placeholder="Email"
                                />
                              </div>
                              <div className="form-group div_phone_admin">
                                <label htmlFor="phone">
                                  <span>*</span>
                                  Phone
                                </label>
                                <Field
                                  className="div_phone_admin"
                                  name="phone_country_code"
                                  component={RenderSelectField}
                                >
                                  <option disabled value="">
                                    Country
                                  </option>
                                  {countriesCode &&
                                    countriesCode.map(country => (
                                      <React.Fragment>
                                        {country.dial_code !== null && (
                                          <option value={country.dial_code}>
                                            +{country.dial_code}
                                          </option>
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Field>
                                <Field
                                  name="phone"
                                  component={RenderField}
                                  type="tel"
                                  placeholder="Phone"
                                />
                                <div className="clearfix" />
                              </div>
                              <div className="form-group div_phone_admin">
                                <label htmlFor="fax">Fax</label>
                                <Field
                                  className="div_phone_admin"
                                  name="fax_country_code"
                                  component={RenderSelectField}
                                >
                                  <option disabled value="">
                                    Country
                                  </option>
                                  {countriesCode &&
                                    countriesCode.map(country => (
                                      <React.Fragment>
                                        {country.dial_code !== null && (
                                          <option value={country.dial_code}>
                                            +{country.dial_code}
                                          </option>
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Field>
                                <Field
                                  name="fax"
                                  component={RenderField}
                                  type="tel"
                                  placeholder="Fax"
                                />
                                <div className="clearfix" />
                              </div>
                              <h4>Contract</h4>
                              <div className="form-group date_ranger">
                                <label>
                                  <span>*</span>
                                  Start Date - End Date
                                </label>
                                <div className="input-group">
                                  <Field
                                    name="contract_start_date"
                                    component={RenderDateStartPicker}
                                    id="contract_start_date"
                                    placeholder="Start Date"
                                    required
                                    selected={startDate}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={this.handleChangeStart}
                                    readonly
                                  />
                                  <Field
                                    name="contract_end_date"
                                    component={RenderDateEndtPicker}
                                    id="contract_end_date"
                                    placeholder="End Date"
                                    selected={endDate}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={this.handleChangeEnd}
                                    minDate={startDate}
                                    readonly
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="total_license">
                                  <span>*</span>
                                  License Count
                                </label>
                                <Field
                                  name="total_license"
                                  component={RenderField}
                                  type="text"
                                  placeholder="License Count(20000)"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  <span>*</span>
                                  PDF Contract Upload
                                </label>
                                <Field
                                  component={FileInput}
                                  name="document"
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleInputChange.bind(this)}
                                />
                              </div>
                              <div className="custom-control custom-checkbox">
                                <Field
                                  className="custom-control-input"
                                  name="is_admin_report_creation"
                                  id="is_admin_report_creation"
                                  component="input"
                                  type="checkbox"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="is_admin_report_creation"
                                >
                                  Contract allows for Admin report creation
                                </label>
                              </div>

                              <div className="form-actions right org_btns">
                                <button
                                  type="button"
                                  className="btn btn-danger mr-1"
                                  onClick={pristine || submitting ? handleRedirect : handleCancel}
                                >
                                  <i className="ft-x" /> Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={pristine || submitting}
                                >
                                  <i className="la la-check-square-o" />{' '}
                                  {submitting ? <Loader /> : 'Create'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default reduxForm({
  form: 'new-organization-form',
  validate: validateOrganizationForm,
})(OrganizationForm)
