import { GET_ORGANIZATION_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID } from '../actions/instructors'

const organizatoinOrganizatoinInstructorDetailReducer = (
  state = { data: [] },
  action,
) => {
  switch (action.type) {
    case GET_ORGANIZATION_INSTRUCTOR_DETAILS_BY_INSTRUCTOR_ID:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizatoinOrganizatoinInstructorDetailReducer
