/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate, withLocalize } from 'react-localize-redux'
import config from '../../../../../config'
import Organization from '../../Common/Organization'
import Detail from './Detail'
import Stats from './Stats'
import InstructorsClasses from '../../Tables/InstructorsClasses'
//
import { getOrganizationInstructorDetailByinstructorId } from '../../../../../store/actions/instructors'

/**
 * import translation related files
 *
 */
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'

class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      instructorId: '',
    }
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }

  componentWillMount() {
    const { match } = this.props
    const instructorId = match.params.iid
    if (instructorId === ' ' || instructorId === 'undefined') {
      this.props.history.push(`${config.organizationUrl}/home`)
    }
    this.setState({ instructorId })
    this.props.getOrganizationInstructorDetailByinstructorId(
      instructorId,
      this.props,
    )
  }

  render() {
    const { translate, row } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              {translate('breadCrumbInstructorDetail')}
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/home`}>
                      {translate('breadCrumbHome')}
                    </Link>{' '}
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/instructors`}>
                      {translate('breadCrumbInstructor')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {translate('breadCrumbInstructorDetail')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <Organization />
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-12 col-lg-8 pull-left">
                  <Detail row={row.row} />
                </div>
                <div className="col-md-12 col-lg-4 pull-right">
                  <Stats row={row.row} />
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
          <InstructorsClasses instructorId={this.state.instructorId} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  row: state.organizatoinOrganizatoinInstructorDetail.data,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationInstructorDetailByinstructorId: (instructorId, props) =>
    dispatch(
      getOrganizationInstructorDetailByinstructorId(instructorId, props),
    ),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Details),
)
