/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css'

/**
 * import translation related files
 *
 */
import { getTranslate } from 'react-localize-redux'

class RenderMultipleSelectField extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      input,
      meta: { error, touched },
      data,
      valueField,
      textField,
      translate,
    } = this.props

    return (
      <Fragment>
        <Multiselect
          {...input}
          onBlur={() => input.onBlur()}
          value={input.value || []}
          data={data}
          valueField={valueField}
          textField={textField}
          className={touched && error ? 'is-invalid' : ''}
        />
        {touched &&
          error &&
          error.map((e, index) => (
            <span key={index} className="invalid-feedback">
              <strong>{translate(e)}</strong>
            </span>
          ))}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(RenderMultipleSelectField)
