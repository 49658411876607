import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

// Magic numbers for background size because of the Google Play margin in the image itself
// We could host the image ourselves and crop it
const MATCH_ANDROID_BADGE_BACKGROUND_SIZE = '67% 69%'
const VALID_PLATFORMS = ['ios', 'android', 'windows', 'mac']

const DownloadItem = ({
  platform,
  height,
  width,
  url,
  linkStyle,
  itemStyle,
}) => {
  const translate = useSelector(state => getTranslate(state.localize))

  const mergedStyle = {
    background: `url(${translate(`downloadBtnImage_${platform}`)}) no-repeat`,
    backgroundSize:
      platform === 'android' ? 'contain' : MATCH_ANDROID_BADGE_BACKGROUND_SIZE,
    backgroundPosition: 'center center',
    display: 'inline-block',
    overflow: 'hidden',
    textDecoration: 'none',
    height: '100%',
    width: '100%',
    padding: '5px',
    ...linkStyle,
  }

  return (
    <div style={{ height, width, display: 'inline-block', ...itemStyle }}>
      <a
        href={url}
        style={mergedStyle}
        target="_blank"
        rel="noopener noreferrer"
        alt={translate(`downloadLinkAlt_${platform}`)}
      >
        {' '}
      </a>
    </div>
  )
}

DownloadItem.defaultProps = {
  height: 75,
  width: 255,
  linkStyle: {},
  itemStyle: {},
}

DownloadItem.propTypes = {
  platform: PropTypes.oneOf(VALID_PLATFORMS).isRequired,
  url: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  linkStyle: PropTypes.shape({}),
  itemStyle: PropTypes.shape({}),
}

export default DownloadItem
