/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'
import CommonAdventureStats from '../../../../../Common/Report/AdventureStats'
import { getPrincipalAdventureStatsReportByReportId } from '../../../../../../store/actions/report'
/**
 * import translation related files
 *
 */

class AdventureStats extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  componentWillMount() {
    //    let loadingTable =this.state.loading;
    // this.props.getPrincipalAdventureStatsReportByReportId(this.props.reportId,this.props);
  }

  render() {
    const { translate, data, loading, dataAssessment } = this.props
    return (
      <CommonAdventureStats
        dataAssessment={dataAssessment}
        data={data}
        loading={loading}
      />
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  // data:state.principalAdventureStats.data.adventures,
  // loading: state.principalAdventureStats.loading
})

const mapDispatchToProps = dispatch => ({
  // getPrincipalAdventureStatsReportByReportId: (reportId,props) => {
  // dispatch(getPrincipalAdventureStatsReportByReportId(reportId,props))
  // }
})

export default connect(mapStateToProps, mapDispatchToProps)(AdventureStats)
