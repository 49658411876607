import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import config from 'config'
import { getOrganizationStats } from 'store/actions/organizations'

class OrganizationStats extends React.Component {
  componentDidMount() {
    const { match, history, getOrganizationStats } = this.props
    const organizationId = match.params.id
    if (organizationId === ' ' || organizationId === 'undefined') {
      history.push(`${config.adminUrl}/home`)
    }
    this.setState({ loading: true })
    getOrganizationStats(organizationId)
  }

  render() {
    const { row, loading: loadingTable } = this.props
    return (
      <div className="col-md-12 col-lg-8">
        <div className="school_ststas">
          <div className="card">
            <LoadingOverlay active={loadingTable} spinner text={`${config.loadingOverlayText}`}>
              <div className="card-header p-1">
                <h4 className="card-title">
                  Organization Stats
                </h4>
              </div>
              <div className="card-content collapse show">
                <div className="card-footer text-center p-1">
                  <div className="row">
                    <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                      <p className="blue-grey lighten-2 mb-0">Total Schools/Depts.</p>
                      <p className="font-medium-5 text-bold-400">
                        <NumberFormat
                          thousandSeparator
                          value={row && row.total_schools}
                          displayType="text"
                        />
                      </p>
                    </div>
                    <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                      <p className="blue-grey lighten-2 mb-0">Total Teachers</p>
                      <p className="font-medium-5 text-bold-400">
                        <NumberFormat
                          thousandSeparator
                          value={row && row.total_teachers}
                          displayType="text"
                        />
                      </p>
                    </div>
                    <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                      <p className="blue-grey lighten-2 mb-0">Total Classes</p>
                      <p className="font-medium-5 text-bold-400">
                        <NumberFormat
                          thousandSeparator
                          value={row && row.total_classes}
                          displayType="text"
                        />
                      </p>
                    </div>
                    <div className="col-md-3 col-12 text-center">
                      <p className="blue-grey lighten-2 mb-0">Total Students</p>
                      <p className="font-medium-5 text-bold-400">
                        <NumberFormat
                          thousandSeparator
                          value={row && row.total_licenses}
                          displayType="text"
                        />
                      </p>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    )
  }
}

OrganizationStats.defaultProps = {
  loading: false,
  row: {
    total_classes: 0,
    total_licenses: 0,
    total_schools: 0,
    total_teachers: 0
  }
}

OrganizationStats.propTypes = {
  loading: PropTypes.bool,
  getOrganizationStats: PropTypes.func.isRequired,
  row: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        total_classes: PropTypes.number,
        total_licenses: PropTypes.number,
        total_schools: PropTypes.number,
        total_teachers: PropTypes.number
      })
    ),
    PropTypes.shape({
      total_classes: PropTypes.number,
      total_licenses: PropTypes.number,
      total_schools: PropTypes.number,
      total_teachers: PropTypes.number
    })
  ])
}

const mapStateToProps = state => ({
  row: state.organizationStats.data,
  loading: state.organizationStats.loading,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationStats: organizationId => {
    dispatch(getOrganizationStats(organizationId))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationStats))
