import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

export const Copyright = props => {
  const { path, translate } = props
  return (
    <span className="footer_text">
      © {new Date().getFullYear()} {translate('copyrightPhrase')}{' '}
      <Link className="text-bold-800 grey darken-2" to={path}>
        {translate('fluentWorlds')}
      </Link>
    </span>
  )
}

Copyright.defaultProps = {
  path: '/',
}

Copyright.propTypes = {
  translate: PropTypes.func.isRequired,
  path: PropTypes.string,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default withLocalize(connect(mapStateToProps)(Copyright))
