/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import Loader from '../../../../../../components/Loader'
import RenderField from '../../../../../../components/RenderFieldTranlate'
import RenderSelectField from '../../../../../../components/RenderSelectFieldTranlate'
import validateForm from './helpers'

/**
 * import translation related files
 *
 */

class EditForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      row,
      handleCancel,
      handleRedirect,
      handeChangeStatus,
      translate,
    } = this.props

    return (
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-md-12 col-lg-12 pull-left">
              <div className="card class_card ">
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="card-header">
                    <h4 className="org-id">
                      {translate('studentDetailRowLicense')}:{row && row.code}
                    </h4>
                    <div className="form-group name_input_card">
                      <Field
                        name="alias"
                        component={RenderField}
                        type="text"
                        placeholder={translate('formStudentName')}
                      />
                    </div>
                    <a className="heading-elements-toggle">
                      <i className="la la-ellipsis-v font-medium-3" />
                    </a>
                    <div className="heading-elements">
                      <ul className="list-inline mb-0">
                        <li>
                          <Link
                            className="add_btn pull-right btn_delete"
                            to="#"
                            onClick={() => handeChangeStatus(row && row.status)}
                          >
                            {row &&
                              (row.status === 'Active'
                                ? translate('formStudentBTNStatusDeactivated')
                                : translate('formStudentBTNStatusActive'))}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <button
                      type="button"
                      className="btn grey btn-danger mr-1"
                      onClick={
                        pristine || submitting ? handleRedirect : handleCancel
                      }
                    >
                      {translate('formStudentBtnCancel')}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={pristine || submitting}
                    >
                      {submitting ? (
                        <Loader />
                      ) : (
                        translate('formStudentBtnSave')
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    )
  }
}

EditForm = reduxForm({
  form: 'organization-profile-form',
  validate: validateForm,
  enableReinitialize: true,
})(EditForm)

EditForm = connect(state => ({
  translate: getTranslate(state.localize),
  initialValues: state.teacherStudentDetail.data.row,
}))(EditForm)

export default EditForm
