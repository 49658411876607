/* TODO: Fix eslint */
/* eslint-disable */

/** Import CSS File * */
// BEGIN: Page CSS
import '../app-assets/css/core/colors/palette-gradient.css'
import '../app-assets/css/pages/login-register.css'
// END: Page CSS

import React from 'react'
import { Route } from 'react-router-dom'
import Noty from '../components/Noty'

const Login = props => (
  <div
    className="vertical-layout vertical-menu 1-column  bg-full-screen-image blank-page blank-page"
    ata-open="click"
    data-menu="vertical-menu"
    data-color="bg-gradient-x-purple-blue"
    data-col="1-column"
  >
    <div className="app-content">
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row" />
        <div className="content-body">
          <section className="flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-lg-4 col-md-6 col-10 box-shadow-2 p-0">
                {props.children}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
)

const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Login>
          <Component {...matchProps} />
        </Login>
      )}
    />
  )
}

export default LoginRoute
