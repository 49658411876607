/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'

import { Translate, getTranslate } from 'react-localize-redux'
import {
  LOGOUT_USER,
  ORGANIZATION_LOGOUT_USER,
  PRINCIPAL_LOGOUT_USER,
  TEACHER_LOGOUT_USER,
  STUDENT_LOGOUT_USER,
} from '../actions/auth'

import {
  ORGANIZATION_STORE,
  ORGANIZATION_UPDATE,
  ORGANIZATION_ORGANIZATION_UPDATE,
} from '../actions/organizations'
import {
  ADMIN_USER_PROFILE_UPDATE,
  ADMIN_USER_STORE,
  REMOVE_ADMIN_USER,
  ADMIN_CHANGE_PASSWORD,
} from '../actions/adminUser'
import {
  REPORT_STORE,
  REMOVE_REPORT,
  REMOVE_ORGANIZATION_REPORT,
  REPORT_ORGANIZATION_STORE,
} from '../actions/report'

import {
  ORGANIZATION_USER_STORE,
  REMOVE_ORGANIZATION_USER,
  ORGANIZATION_CHANGE_PASSWORD,
} from '../actions/organizationUser'
import {
  STORE_ORGANIZATION_PRINCIPAL,
  UPDATE_ORGANIZATION_PRINCIPAL,
  DELETE_ORGANIZATION_PRINCIPAL,
  PRINCIPAL_CHANGE_PASSWORD,
  PRINCIPAL_UPDATE_PROFILE,
  STORE_PRINCIPAL_MULTIPLE_ALL,
  STORE_PRINCIPAL_MULTIPLE_EXIST,
} from '../actions/principal'
import {
  STORE_ORGANIZATION_SCHOOL,
  UPDATE_ORGANIZATION_SCHOOL,
  CHANGE_STATUS_ORGANIZATION_SCHOOL,
} from '../actions/school'

import { STORE_CLASS, UPDATE_CLASS } from '../actions/class'
import {
  STORE_INSTRUCTOR,
  UPDATE_INSTRUCTOR,
  STORE_INSTRUCTOR_MULTIPLE_ALL,
  STORE_INSTRUCTOR_MULTIPLE_EXIST,
  TEACHER_CHANGE_PASSWORD,
  TEACHER_UPDATE_PROFILE,
} from '../actions/instructors'
import {
  INSTRUCTOR_STUDENT_CHANGE_PASSWORD_BY_STUDENT_ID,
  INSTRUCTOR_STUDENT_UPDATE_BY_STUDENT_ID,
  INSTRUCTOR_STUDENT_CHANGE_STATUS_BY_STUDENT_ID,
  STUDENT_CHANGE_PASSWORD,
  TEACHER_RESET_ACTIVATION,
} from '../actions/student'
import { REMOVE_TOASTER } from '../actions/form'

const notyReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_TOASTER:
      return {
        type: 'success',
        message: ``,
      }

    case ORGANIZATION_STORE:
      return {
        type: 'success',
        message: `Organization has been created successfully!`,
      }
    case ORGANIZATION_UPDATE:
      return {
        type: 'success',
        message: `Organization has been updated successfully!`,
      }
    case ADMIN_USER_PROFILE_UPDATE:
      return {
        type: 'success',
        message: `Profile has been updated successfully!`,
      }
    case ADMIN_USER_STORE:
      return {
        type: 'success',
        message: `Sub-Admin has been created successfully!`,
      }
    case REMOVE_ADMIN_USER:
      return {
        type: 'success',
        message: `Sub-Admin user has been removed successfully!`,
      }
    case ADMIN_CHANGE_PASSWORD:
      return {
        type: 'success',
        message: `Password has been changed successfully!`,
      }

    case REPORT_STORE:
      return {
        type: 'success',
        message: `Report has been created successfully!`,
      }
    case REMOVE_REPORT:
      return {
        type: 'success',
        message: `Report has been deleted successfully!`,
      }

    case ORGANIZATION_CHANGE_PASSWORD:
      return {
        type: 'success',
        message: <Translate id="successMsgUserServiceChangePassword" />,
      }

    case REMOVE_ORGANIZATION_USER:
      return {
        type: 'success',
        message: <Translate id="successMsgUserServiceDelete" />,
      }

    case ORGANIZATION_USER_STORE:
      return {
        type: 'success',
        message: <Translate id="successMsgUserServiceCreate" />,
      }

    case ORGANIZATION_ORGANIZATION_UPDATE:
      return {
        type: 'success',
        message: <Translate id="successMsgOrganizationServiceUpdate" />,
      }

    case STORE_ORGANIZATION_PRINCIPAL:
      return {
        type: 'success',
        message: <Translate id="successMsgPrincipalServiceCreate" />,
      }

    case UPDATE_ORGANIZATION_PRINCIPAL:
      return {
        type: 'success',
        message: <Translate id="successMsgPrincipalServiceUpdate" />,
      }

    case DELETE_ORGANIZATION_PRINCIPAL:
      return {
        type: 'success',
        message: <Translate id="successMsgPrincipalServiceDelete" />,
      }

    case STORE_PRINCIPAL_MULTIPLE_ALL:
      return {
        type: 'success',
        message: <Translate id="successMsgPrincipalServiceCreateAll" />,
      }

    case STORE_PRINCIPAL_MULTIPLE_EXIST:
      return {
        type: 'success',
        message: <Translate id="successMsgPrincipalServiceCreateExist" />,
      }

    case STORE_ORGANIZATION_SCHOOL:
      return {
        type: 'success',
        message: <Translate id="successMsgSchoolServiceCreate" />,
      }

    case UPDATE_ORGANIZATION_SCHOOL:
      return {
        type: 'success',
        message: <Translate id="successMsgSchoolServiceUpdate" />,
      }

    case CHANGE_STATUS_ORGANIZATION_SCHOOL:
      return {
        type: 'success',
        message: <Translate id="successMsgSchoolServiceChangeStatus" />,
      }

    case REMOVE_ORGANIZATION_REPORT:
      return {
        type: 'success',
        message: <Translate id="successMsgReportServiceDelete" />,
      }

    case REPORT_ORGANIZATION_STORE:
      return {
        type: 'success',
        message: <Translate id="successMsgReportServiceCreate" />,
      }

    case PRINCIPAL_CHANGE_PASSWORD:
      return {
        type: 'success',
        message: <Translate id="successMsgUserServiceChangePassword" />,
      }

    case PRINCIPAL_UPDATE_PROFILE:
      return {
        type: 'success',
        message: <Translate id="successMsgPrincipalServiceProfileUpdate" />,
      }

    case STORE_CLASS:
      return {
        type: 'success',
        message: <Translate id="successMsgClassServiceCreate" />,
      }

    case UPDATE_CLASS:
      return {
        type: 'success',
        message: <Translate id="successMsgClassServiceUpdate" />,
      }

    case STORE_INSTRUCTOR:
      return {
        type: 'success',
        message: <Translate id="successMsgInstructorServiceCreate" />,
      }

    case UPDATE_INSTRUCTOR:
      return {
        type: 'success',
        message: <Translate id="successMsgInstructorServiceUpdate" />,
      }
    case STORE_INSTRUCTOR_MULTIPLE_ALL:
      return {
        type: 'success',
        message: <Translate id="successMsgInstructorServiceCreateAll" />,
      }
    case STORE_INSTRUCTOR_MULTIPLE_EXIST:
      return {
        type: 'success',
        message: <Translate id="successMsgInstructorServiceUpdate" />,
      }

    case TEACHER_CHANGE_PASSWORD:
      return {
        type: 'success',
        message: <Translate id="successMsgInstructorServiceChangePassword" />,
      }
    case TEACHER_UPDATE_PROFILE:
      return {
        type: 'success',
        message: <Translate id="successMsgInstructorServiceProfileUpdate" />,
      }

    case INSTRUCTOR_STUDENT_CHANGE_PASSWORD_BY_STUDENT_ID:
      return {
        type: 'success',
        message: <Translate id="successMsgStudentServiceChangePassword" />,
      }

    case INSTRUCTOR_STUDENT_UPDATE_BY_STUDENT_ID:
      return {
        type: 'success',
        message: <Translate id="successMsgStudentServiceUpdate" />,
      }

    case INSTRUCTOR_STUDENT_CHANGE_STATUS_BY_STUDENT_ID:
      return {
        type: 'success',
        message: <Translate id="successMsgStudentServiceChangeStatus" />,
      }
    case STUDENT_CHANGE_PASSWORD:
      return {
        type: 'success',
        message: (
          <Translate id="successMsgStudentStudentServiceChangePassword" />
        ),
      }

    case LOGOUT_USER:
      return {
        type: 'success',
        message: ``,
      }

    case ORGANIZATION_LOGOUT_USER:
      return {
        type: 'success',
        message: ``,
      }

    case PRINCIPAL_LOGOUT_USER:
      return {
        type: 'success',
        message: '',
      }

    case TEACHER_LOGOUT_USER:
      return {
        type: 'success',
        message: '',
      }

    case STUDENT_LOGOUT_USER:
      return {
        type: 'success',
        message: '',
      }

    case TEACHER_RESET_ACTIVATION:
      return {
        type: 'success',
        message: <Translate id="successMsgStudentServiceResetActivation" />,
      }

    default:
      return state
  }
}

export default notyReducer
