import {
  ORGANIZATION_LOGIN_USER,
  ORGANIZATION_LOGOUT_USER,
} from '../actions/auth'

const authOrganizationUser = JSON.parse(
  localStorage.getItem('authOrganizationUser'),
)

const authOrganizationReducer = (
  state = {
    user: authOrganizationUser ? authOrganizationUser.user : null,
    accessToken: authOrganizationUser ? authOrganizationUser.token : null,
  },
  action,
) => {
  switch (action.type) {
    case ORGANIZATION_LOGIN_USER:
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.token,
      }
    case ORGANIZATION_LOGOUT_USER:
      return {
        ...state,
        user: null,
        accessToken: null,
      }
    default:
      return state
  }
}

export default authOrganizationReducer
