import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { getTranslate } from 'react-localize-redux'
import TableRowDateProgressBar from 'components/TableRowDateProgressBar'
import TableRowLicenseProgressBar from 'components/TableRowLicenseProgressBar'
import DateTimeWithTimezone from 'components/DateTimeWithTimezone'

const ClassDetail = props => {
  const { translate, row, sessions } = props
  const tagArr = row && row.tags ? row.tags.split(',') : []
  return (
    <div className="row match-height">
      <div className="col-md-12 col-lg-12 pull-left">
        <h5 className="card-title text-bold-700 my-2">
          {translate('classDetailTitle')}
        </h5>
        <div className="card">
          <div className="card-content collapse show">
            <div className="card-footer text-center p-1">
              <div className="row">
                <div
                  className="col-md-6 col-12 border-right-blue-grey 
                    border-right-lighten-5 text-left"
                >
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowName')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.name}
                  </p>
                </div>
                <div className="col-md-6 col-12 text-left">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowSession')}
                  </p>
                  <div className="scroll_sessions">
                    {sessions &&
                      sessions.map(session => (
                        <p
                          key={session.id}
                          className="font-medium-6 text-bold-400"
                        >
                          <DateTimeWithTimezone
                            dateTime={session.created_at}
                            isTime="false"
                            isRed="false"
                          />
                        </p>
                      ))}
                  </div>
                </div>
                <div
                  className="col-md-6 col-12 border-right-blue-grey 
                    border-right-lighten-5 text-left"
                >
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowDescription')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.description}
                  </p>
                </div>
                <div
                  className="col-md-6 col-12 border-right-blue-grey 
                    border-right-lighten-5 text-left"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="card pull-up">
                        <div className="card-header">
                          <h6 className="card-title float-left">
                            {translate('classDetailRowStartDate')}
                            <span className="start_date_color">
                              <DateTimeWithTimezone
                                dateTime={row && row.start_date}
                                isTime="false"
                                isRed="false"
                              />
                            </span>
                          </h6>
                          <h6 className="card-title float-right">
                            {translate('classDetailRowEndDate')}
                            <span className="end_date_color">
                              <DateTimeWithTimezone
                                // TODO: Fix format/moment constructor deprecation
                                dateTime={row && row.end_date}
                                isTime="false"
                                isRed="true"
                                start_date={row && row.start_date}
                                end_date={row && row.end_date}
                              />
                            </span>
                          </h6>
                        </div>
                        <div className="card-content collapse show">
                          <div className="card-body pt-0 pb-1">
                            <TableRowDateProgressBar
                              start_date={row && row.start_date}
                              end_date={row && row.end_date}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div
                  className="col-md-4 col-12 border-right-blue-grey 
                    border-right-lighten-5 text-center"
                >
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowSchoolName')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.school_name}
                  </p>
                </div>
                <div
                  className="col-md-4 col-12 border-right-blue-grey 
                    border-right-lighten-5 text-center"
                >
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowTeacherName')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.instructor_name}
                  </p>
                </div>
                <div
                  className="col-md-4 col-12 border-right-blue-grey 
                    border-right-lighten-5 text-center"
                >
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('classDetailRowLicensesActivated')}
                  </p>
                  <span className="text-center">
                    <NumberFormat
                      thousandSeparator
                      value={
                        row && row.total_active_license
                          ? row.total_active_license
                          : 0
                      }
                      displayType="text"
                    />{' '}
                    /{' '}
                    <NumberFormat
                      thousandSeparator
                      value={row && row.total_license ? row.total_license : 0}
                      displayType="text"
                    />
                  </span>
                  <TableRowLicenseProgressBar
                    activeLicense={row && row.total_active_license}
                    totalLicense={row && row.total_license}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-12">
                  <p className="blue-grey lighten-2 mb-0 text-left">
                    {translate('classTableRowTags')}
                  </p>
                  <div className="tags_div">
                    {tagArr && tagArr.map(tag => <p>{tag}</p>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ClassDetail.defaultProps = {
  row: null,
  sessions: [],
}

ClassDetail.propTypes = {
  translate: PropTypes.func.isRequired,
  row: PropTypes.shape({
    tags: PropTypes.string,
    total_license: PropTypes.number,
    total_active_license: PropTypes.number,
    instructor_name: PropTypes.string,
    school_name: PropTypes.string,
    end_date: PropTypes.string,
    start_date: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  sessions: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default connect(mapStateToProps)(ClassDetail)
