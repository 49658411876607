/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import config from './../../../../../config'
import { exportTeacherReport } from './../../../../../store/actions/report'
/**
 * import translation related files
 *
 */
import { getTranslate, withLocalize } from 'react-localize-redux'
import englishTablesTranslations from './../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from './../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from './../../../../../lang/organization/tables/zh.json'

class AggregateDate extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }
  handleDownloadPDF = async () => {
    console.log('handleDownloadPDF')
    await this.props.exportTeacherReport(this.props)
  }
  render() {
    const { translate, print } = this.props
    return (
      <div className="row">
        <div className="col-md-12 col-lg-8 pull-left">
          <h5 className="card-title text-bold-700 my-2 pull-right">
            <Link className="add_btn btn-floating" to="#" onClick={print}>
              {translate('exportPDF')}
            </Link>
          </h5>
        </div>
        <div className="clearfix" />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({
  exportTeacherReport: props => {
    dispatch(exportTeacherReport(props))
  },
})

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(AggregateDate))
