/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import base64 from 'file-base64'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { getCountries, getCountriesCode } from '../../../store/actions/country'
import {
  updateOrganization,
  getOrganizationDetails,
  downloadOrganizationDOC,
} from '../../../store/actions/organizations'
import EditForm from './EditForm'
import config from '../../../config'

class EditOrganization extends React.Component {
  constructor() {
    super()
    this.state = {
      files: [],
      organizationId: '',
    }
  }

  handleDownload = async key => {
    await this.props.downloadOrganizationDOC(key)
  }

  componentWillMount() {
    const { match } = this.props
    const organizationId = match.params.id
    this.setState({ organizationId })
    this.props.getCountries()
    this.props.getCountriesCode()
    this.props.getOrganizationDetails(organizationId)
  }

  handleCancel = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'You want to cancel this form?',
      buttons: [
        {
          label: `${config.btnYesText}`,
          onClick: () => {
            this.props.history.push(
              `${config.adminUrl}/organization/${this.state.organizationId}`,
            )
          },
        },
        {
          label: `${config.btnNoText}`,
        },
      ],
    })
  }

  handleRedirect = () => {
    this.props.history.push(
      `${config.adminUrl}/organization/${this.state.organizationId}`,
    )
  }

  handleSubmit = async values => {
    // console.log('handleSubmit');
    const file = this.state.files
    // console.log(file);
    await this.props.updateOrganization(this.state.organizationId, values, file)
    this.props.history.push(
      `${config.adminUrl}/organization/${this.state.organizationId}`,
    )
  }

  componentWillReceiveProps(nextProps) {
    // console.log('step r1');
    // console.log(nextProps);
    // console.log('step r2');
    // console.log(nextProps.response);
    if (nextProps.response) {
      // console.log('step r3');
      // console.log(nextProps.response.data);
      if (nextProps.response.data != null) {
        // console.log('step r4');
        // console.log(nextProps.response.data.status);
        if (nextProps.response.data.status === 'success') {
          // return this.props.history.push('/home')
        }
      }
    }
  }

  handleInputChange = e => {
    // console.log('step 3');
    // get the files
    const { files } = e.target
    // console.log(files);
    // Process each file
    const allFiles = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // Make new FileReader
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // console.log(reader.result);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        }
        this.setState({ files: fileInfo })
        // console.log(fileInfo);
      } // reader.onload
    } // for
  }

  render() {
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">Edit Organization</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Organization</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <EditForm
          countries={this.props.countries}
          countriesCode={this.props.countriesCode}
          onSubmit={this.handleSubmit}
          handleInputChange={this.handleInputChange}
          row={this.props.organizationDetailRow}
          handleCancel={this.handleCancel}
          handleRedirect={this.handleRedirect}
          handleDownload={this.handleDownload}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  countries: state.countries.data.countries,
  countriesCode: state.adminCountryCode.data.countries,
  organizationDetailRow: state.organizationDetail.data.row,
  response: state.organizationUpdate,
})

const mapDispatchToProps = dispatch => ({
  getCountries: () => {
    dispatch(getCountries())
  },
  getCountriesCode: () => {
    dispatch(getCountriesCode())
  },
  getOrganizationDetails: organizationId => {
    dispatch(getOrganizationDetails(organizationId))
  },
  updateOrganization: (organizationId, values, file) =>
    dispatch(updateOrganization(organizationId, values, file)),
  downloadOrganizationDOC: file => dispatch(downloadOrganizationDOC(file)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganization)
