/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { withLocalize, getTranslate } from 'react-localize-redux'
import config from '../../../../../config'
import TableRowDateProgressBar from '../../../../../components/TableRowDateProgressBar'
import TableRowLicenseProgressBar from '../../../../../components/TableRowLicenseProgressBar'
import { getPrincipalClassesByinstructorId } from '../../../../../store/actions/class'
import DateTimeWithTimezone from '../../../../../components/DateTimeWithTimezone'
/**
 * import translation related files
 *
 */
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'

class InstructorsClasses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
      loading: false,
    }
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }

  componentWillMount() {
    this.setState({ loading: true })
    this.props.getPrincipalClassesByinstructorId(
      this.props,
      this.props.instructorId,
      this.state.page,
      this.state.orderBy,
      this.state.order,
    )
    this.setState({ page: this.state.page + 1 })
  }

  handlePageChange = async e => {
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: this.state.page + 1 })
        if (this.props.data.totalPage > this.state.page) {
          this.setState({ counter: this.state.counter + 1 })
          this.setState({ loading: true })
          this.setState({ scrollTop: element.scrollTop })
          await this.props.getPrincipalClassesByinstructorId(
            this.props,
            this.props.instructorId,
            this.state.page,
            this.state.orderBy,
            this.state.order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    let order = 'ASC'
    if (this.state.orderBy === field) {
      order = this.state.order === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    this.setState({ loading: true })
    await this.props.getPrincipalClassesByinstructorId(
      this.props,
      this.props.instructorId,
      0,
      field,
      order,
    )
    this.setState({ page: 1 })
  }

  render() {
    const { translate } = this.props
    let loadingTable = this.state.loading
    loadingTable = this.props.loading
    const sortClass = this.state.order === 'ASC' ? 'ascending' : 'desending'
    return (
      <div className="row match-height">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <h5 className="card-title text-bold-700 my-2">
            {translate('classTableTitle')}
          </h5>
          <div className="card" style={{}}>
            <div
              className={
                this.props.data && this.props.data.totalPage > 0
                  ? 'card-content '
                  : 'card-content'
              }
            >
              <div
                id="recent-projects"
                className="media-list position-relative"
                onScroll={this.handlePageChange.bind(this)}
              >
                <LoadingOverlay
                  active={loadingTable}
                  spinner
                  text={translate('loadingOverlayText')}
                >
                  <div
                    className={
                      this.props.classes.length === 0
                        ? 'table-responsive table-height'
                        : 'table-responsive'
                    }
                  >
                    {this.props.classes && this.props.classes.length > 0 && (
                      <div id="tableContainer" className="tableContainer">
                        <table
                          className="scrollTable instructor-classes-list"
                          width="100%"
                          cellSpacing={0}
                          cellPadding={0}
                          border={0}
                        >
                          <thead className="fixedHeader">
                            <tr>
                              <th
                                align="left"
                                width="15%"
                                onClick={() => this.handleSorting('name')}
                                className={
                                  this.state.orderBy === 'name' ? sortClass : ''
                                }
                              >
                                {translate('classTableRowName')}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="left"
                                width="10%"
                                onClick={() => this.handleSorting('created_at')}
                                className={
                                  this.state.orderBy === 'created_at'
                                    ? sortClass
                                    : ''
                                }
                              >
                                {translate('classTableRowCreated')}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="20%"
                                onClick={() =>
                                  this.handleSorting('total_active_license')
                                }
                                className={
                                  this.state.orderBy === 'total_active_license'
                                    ? sortClass
                                    : ''
                                }
                              >
                                {translate('classTableRowLicenses')}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="20%"
                                onClick={() => this.handleSorting('start_date')}
                                className={
                                  this.state.orderBy === 'start_date'
                                    ? sortClass
                                    : ''
                                }
                              >
                                {translate('classTableRowStart')}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="10%"
                                onClick={() => this.handleSorting('status')}
                                className={
                                  this.state.orderBy === 'status'
                                    ? sortClass
                                    : ''
                                }
                              >
                                {translate('classTableRowStatus')}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th
                                align="center"
                                width="20%"
                                onClick={() => this.handleSorting('tags')}
                                className={
                                  this.state.orderBy === 'tags' ? sortClass : ''
                                }
                              >
                                {translate('classTableRowTags')}
                                <i className="fas fa-sort-up" />
                              </th>
                              <th align="center" width="5%">
                                {translate('classTableRowView')}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="scrollContent">
                            {this.props.classes &&
                              this.props.classes.length > 0 &&
                              this.props.classes.map(row => (
                                <tr>
                                  <td align="left" width="15%">
                                    <Link
                                      to={`${config.principalUrl}/class/${row.id}`}
                                    >
                                      {row.name}
                                    </Link>
                                  </td>
                                  <td align="center" width="10%">
                                    <DateTimeWithTimezone
                                      dateTime={row.created_at}
                                      isTime="false"
                                      isRed="false"
                                    />
                                  </td>
                                  <td align="center" width="20%">
                                    <span className="text-center">
                                      <NumberFormat
                                        thousandSeparator
                                        value={Number(row.total_active_license)}
                                        displayType="text"
                                      />{' '}
                                      /{' '}
                                      <NumberFormat
                                        thousandSeparator
                                        value={Number(row.total_license)}
                                        displayType="text"
                                      />
                                    </span>
                                    <TableRowLicenseProgressBar
                                      activeLicense={
                                        row && row.total_active_license
                                      }
                                      totalLicense={row && row.total_license}
                                    />
                                  </td>
                                  <td align="center" width="20%">
                                    <span className="text-center">
                                      <DateTimeWithTimezone
                                        dateTime={row.start_date}
                                        isTime="false"
                                        isRed="false"
                                      />{' '}
                                      -{' '}
                                      <DateTimeWithTimezone
                                        dateTime={row.end_date}
                                        isTime="false"
                                        isRed="true"
                                        start_date={row.start_date}
                                        end_date={row.end_date}
                                      />
                                    </span>
                                    <TableRowDateProgressBar
                                      start_date={row.start_date}
                                      end_date={row.end_date}
                                    />
                                  </td>
                                  <td align="center" width="10%">
                                    <span
                                      className={
                                        row.status === 'Active'
                                          ? 'status_approved'
                                          : 'status_pending'
                                      }
                                    >
                                      {row.status}
                                    </span>
                                  </td>
                                  <td align="center" width="20%">
                                    {row.tags}
                                  </td>
                                  <td align="center" width="5%">
                                    <Link
                                      to={`${config.principalUrl}/class/${row.id}`}
                                    >
                                      <i className="fa fa-eye" />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.props.classes &&
                      this.props.classes.length === 0 &&
                      loadingTable === false && (
                        <div className="table-no-record">
                          {translate('tableNoRecord')}
                        </div>
                      )}
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.principalInstructorClasses.data,
  classes: state.principalInstructorClasses.classes,
  loading: state.principalInstructorClasses.loading,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalClassesByinstructorId: (
    props,
    instructorId,
    page,
    orderBy,
    order,
  ) =>
    dispatch(
      getPrincipalClassesByinstructorId(
        props,
        instructorId,
        page,
        orderBy,
        order,
      ),
    ),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(InstructorsClasses),
)
