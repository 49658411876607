// TODO: Fix these eslint disables
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Vendor CSS
import 'app-assets/vendors/css/charts/chartist.css'
import 'app-assets/vendors/css/charts/chartist-plugin-tooltip.css'

// Theme CSS
import 'app-assets/vendors/css/forms/selects/select2.min.css'

// Page CSS
import 'app-assets/css/core/menu/menu-types/vertical-menu.css'
import 'app-assets/css/pages/chat-application.css'
import 'app-assets/css/pages/dashboard-analytics.css'

// Custom CSS-
import 'assets/css/organization-style.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Header from 'pages/Home/Common/Header'
import LeftSideNavigation from 'pages/Home/Common/LeftSideNavigation'
import Footer from 'pages/Home/Common/Footer'
import Noty from 'components/Noty'
import config from 'config'
import { removeToster } from 'store/actions/form'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHamburger: false,
    }
  }

  handleHamburger = () => {
    const { isHamburger } = this.state
    const currentStatus = isHamburger !== true
    this.setState({ isHamburger: currentStatus })
  }

  render() {
    const { children, handleCloseNoty } = this.props
    const { isHamburger } = this.state
    return (
      <div
        onClick={handleCloseNoty}
        className={
          isHamburger === true
            ? 'vertical-layout vertical-menu 2-columns fixed-navbar menu-collapsed'
            : 'vertical-layout vertical-menu 2-columns fixed-navbar menu-expanded'
        }
        id="main-div"
        data-open="click"
        data-menu="vertical-menu"
        data-color="bg-gradient-x-purple-blue"
        data-col="2-columns"
      >
        <Noty />
        <Header handleHamburger={this.handleHamburger} />
        <LeftSideNavigation isHamburger={isHamburger} />
        <div className="app-content content">{children}</div>
        <Footer />
      </div>
    )
  }
}

Dashboard.propTypes = {
  children: PropTypes.element.isRequired,
  handleCloseNoty: PropTypes.func.isRequired,
}

class DashboardRoute extends Component {
  componentDidMount() {
    const { user, history } = this.props
    if (!user) {
      return history.push(`${config.adminUrl}/login`)
    }
    return ''
  }

  handleCloseNoty = () => {
    const { removeToster } = this.props
    removeToster()
  }

  render() {
    const { component: Component, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Dashboard handleCloseNoty={this.handleCloseNoty}>
            <Component {...matchProps} />
          </Dashboard>
        )}
      />
    )
  }
}

DashboardRoute.defaultProps = {
  user: {},
}

DashboardRoute.propTypes = {
  component: PropTypes.element.isRequired,
  removeToster: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
  removeToster: () => {
    dispatch(removeToster())
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardRoute),
)
