import { GET_PRINCIPAL_REPORT_DETAILS } from '../../actions/report'

const principalReportDetailReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_PRINCIPAL_REPORT_DETAILS:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default principalReportDetailReducer
