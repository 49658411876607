import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { withLocalize, getTranslate } from 'react-localize-redux'

import config from 'config'
import { getTeacherClassDetailByClassId } from 'store/actions/class'
import { getInstructorStudentsByClassId } from 'store/actions/student'
import Teacher from 'pages/Teacher/Home/Common/Teacher'
import StudentsTable from 'pages/Teacher/Home/Tables/StudentsTable'
import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'
import Detail from './Detail'

const ClassDetails = ({ addTranslationForLanguage, match, history }) => {
  const [classId, setClassId] = useState(match.params.cid)

  const dispatch = useDispatch()

  const translate = useSelector(state => getTranslate(state.localize))
  const row = useSelector(state => state.teacherClassDetail.data.row)

  useEffect(() => {
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }, [addTranslationForLanguage])

  useEffect(() => {
    const classId = match.params.cid
    if (classId === ' ' || classId === 'undefined') {
      history.push(`${config.teacherUrl}/home`)
    }
    setClassId(classId)
    dispatch(getTeacherClassDetailByClassId(classId))
  }, [dispatch, history, match.params.cid])

  return (
    <div className="content-wrapper">
      <div className="content-wrapper-before" />
      <div className="content-header row">
        <div className="content-header-left col-md-4 col-12 mb-2">
          <h3 className="content-header-title">
            {translate('breadCrumbClassDetail')}
          </h3>
        </div>
        <div className="content-header-right col-md-8 col-12">
          <div className="breadcrumbs-top float-md-right">
            <div className="breadcrumb-wrapper mr-1">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`${config.teacherUrl}/home`}>
                    {translate('breadCrumbHome')}
                  </Link>{' '}
                </li>
                <li className="breadcrumb-item active">
                  {translate('breadCrumbClassDetail')}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content-body">
        <Teacher />
        <Detail row={row} />
        <StudentsTable
          getStudentData={getInstructorStudentsByClassId}
          classId={classId}
        />
      </div>
    </div>
  )
}

ClassDetails.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: { cid: PropTypes.number } }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  row: PropTypes.shape({}).isRequired,
}

export default withLocalize(ClassDetails)
