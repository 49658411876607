/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../../config'

/**
 * import translation related files
 *
 */

class Detail extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { translate, row } = this.props
    const tagArr = row && row.tags ? row.tags.split(',') : []
    return (
      <div className="col-md-12 col-lg-12 pull-left">
        <h5 className="card-title text-bold-700 my-2">
          {translate('principalDetailTitle')}
          <Link
            className="add_btn pull-right"
            to={`${config.organizationUrl}/edit-principal/${row && row.id}`}
          >
            {translate('btnEditPrincipal')}
          </Link>
        </h5>
        <div className="card">
          <div className="card-content collapse show">
            <div className="card-footer text-center p-1">
              <div className="row">
                <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('principalDetailRowId')}
                  </p>
                  <p className="font-medium-6 text-bold-400">{row && row.id}</p>
                </div>
                <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('principalDetailRowName')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.name}
                  </p>
                </div>

                <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('principalTableRowSchool')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.school_name}
                  </p>
                </div>

                <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                  <p className="blue-grey lighten-2 mb-0">
                    {translate('principalDetailRowEmail')}
                  </p>
                  <p className="font-medium-6 text-bold-400">
                    {row && row.email}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-12">
                  <p className="blue-grey lighten-2 mb-0 text-left">
                    {translate('principalTableRowTags')}
                  </p>
                  <div className="tags_div">
                    {tagArr && tagArr.map(tag => <p>{tag}</p>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
