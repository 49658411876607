/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { confirmAlert } from 'react-confirm-alert'
import LoadingOverlay from 'react-loading-overlay'
import { withLocalize, getTranslate } from 'react-localize-redux'
import {
  getTeacherProfile,
  getStats,
  updateTeacherProfile,
} from 'store/actions/instructors'
import config from 'config'
import EditProfileModelBox from 'pages/Teacher/Home/Profile/EditProfileModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import RenderImage from 'components/RenderImage'
import instructorImg from 'app-assets/images/instructor.png'
import { dispatchFormField, resetForm } from 'store/actions/form'

import englishStatsTranslations from 'lang/organization/stats/en.json'
import spanishStatsTranslations from 'lang/organization/stats/es.json'
import chineseStatsTranslations from 'lang/organization/stats/zh.json'

class Teacher extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowEditProfile: false,
      imagePreviewUrl: instructorImg,
    }

    const {
      addTranslationForLanguage,
      getTeacherProfile,
      getStats,
    } = this.props
    addTranslationForLanguage(englishStatsTranslations, 'en')
    addTranslationForLanguage(spanishStatsTranslations, 'es')
    addTranslationForLanguage(chineseStatsTranslations, 'zh')
    getTeacherProfile(this.props)
    getStats(this.props)
  }

  handleCancel = () => {
    const { translate, resetForm } = this.props
    confirmAlert({
      title: translate('alertHeading'),
      message: translate('alertFormText'),
      buttons: [
        {
          label: translate('btnYes'),
          onClick: () => {
            resetForm('teacher-change-profile')
            this.setState({ isShowEditProfile: false })
          },
        },
        {
          label: translate('btnNo'),
        },
      ],
    })
  }

  handleEditProfileModelShow = () => {
    const { resetForm, teacherProfile } = this.props
    resetForm('teacher-change-profile')
    this.setState({
      isShowEditProfile: true,
      imagePreviewUrl: instructorImg,
      files: null,
    })
    if (teacherProfile) {
      if (teacherProfile.photo_path) {
        if (teacherProfile.photo_path !== null) {
          const imageObj = JSON.parse(teacherProfile.photo_path)
          if (imageObj) {
            if (imageObj.key && imageObj.type) {
              const url = `${config.apiTeacherUrl}/image/${imageObj.key}`
              this.setState({ imagePreviewUrl: url })
            }
          }
        }
      }
    }
  }

  handleEditProfileModelClose = () => {
    const { resetForm } = this.props
    resetForm('teacher-change-profile')
    this.setState({
      isShowEditProfile: false,
    })
  }

  handleSubmitEditProfile = async values => {
    const { resetForm, updateTeacherProfile, getTeacherProfile } = this.props
    const { files } = this.state
    await updateTeacherProfile(values, files, this.props)
    await getTeacherProfile(this.props)
    resetForm('teacher-change-profile')
    this.setState({ isShowEditProfile: false })
  }

  handleInputChange = e => {
    e.preventDefault()
    const { dispatchFormField } = this.props
    const { files } = e.target
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i]
      // Make new FileReader
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // on reader load something...
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        }
        this.setState({ files: fileInfo })
        this.setState({
          imagePreviewUrl: reader.result,
        })
        dispatchFormField('teacher-change-profile', 'image', fileInfo)
      }
    }
  }

  render() {
    const { teacherProfile, row, translate, loading } = this.props
    const { isShowEditProfile, imagePreviewUrl } = this.state
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <LoadingOverlay
              active={loading}
              spinner
              text={translate('loadingOverlayText')}
            >
              <div className="row">
                <div className="col-md-12 col-lg-8 pull-left">
                  <div className="card class_card">
                    <React.Fragment>
                      <div className="heading-elements_edit teacher_edit">
                        <Link
                          className="add_btn pull-right"
                          to="#"
                          onClick={this.handleEditProfileModelShow}
                        >
                          {translate('btnEdit')}
                        </Link>
                      </div>
                      {teacherProfile && (
                        <RenderImage
                          className="profile_img"
                          image={teacherProfile && teacherProfile.photo_path}
                          apiUrl={config.apiTeacherUrl}
                          source="2"
                          name={row && row.name}
                        />
                      )}
                      <div className="class_description inst_card_des">
                        <div className="card-header card-header_name">
                          <h4 className="org-id">
                            {translate('id')}:{' '}
                            {teacherProfile && teacherProfile.id}
                          </h4>
                          <h4 className="card-title">
                            {teacherProfile && teacherProfile.name}
                          </h4>
                          <h4 className="org-id student_name">
                            {teacherProfile && teacherProfile.email}
                          </h4>
                          <div className="pb-0 card_margin student_s_name">
                            <p className="address_Org">{translate('school')}</p>
                            <h6 className="contact_heading">
                              {teacherProfile && teacherProfile.school_name}
                            </h6>
                          </div>
                          {teacherProfile && teacherProfile.bio !== null && (
                            <div>
                              <p className="lighten-2 mb-0 text-left tag_headings">
                                {translate('bio')}
                              </p>
                              <div className="tags_div class_p_tags">
                                <span>
                                  {teacherProfile && teacherProfile.bio}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="clearfix" />
                    </React.Fragment>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 pull-right">
                  <div className="card">
                    <div className="card-header p-1">
                      <h4 className="card-title float-left">
                        {translate('totalInstructorStats')}
                      </h4>
                    </div>
                    <div className="card-content collapse show">
                      <div className="card-footer text-center p-1">
                        <div className="row">
                          <div
                            className="col-md-6 col-12 border-right-blue-grey 
                            border-right-lighten-5 text-center"
                          >
                            <p className="blue-grey lighten-2 mb-0">
                              {translate('totalClasses')}
                            </p>
                            <p className="font-medium-5 text-bold-400">
                              <NumberFormat
                                thousandSeparator
                                value={
                                  row && row.total_classes
                                    ? row.total_classes
                                    : 0
                                }
                                displayType="text"
                              />
                            </p>
                          </div>
                          <div
                            className="col-md-6 col-12 border-right-blue-grey 
                            border-right-lighten-5 text-center"
                          >
                            <p className="blue-grey lighten-2 mb-0">
                              {translate('totalSchoolLicenses')}
                            </p>
                            <p className="font-medium-5 text-bold-400">
                              <NumberFormat
                                thousandSeparator
                                value={
                                  row && row.totalActiveLicense
                                    ? row.totalActiveLicense
                                    : 0
                                }
                                displayType="text"
                              />{' '}
                              /{' '}
                              <NumberFormat
                                thousandSeparator
                                value={
                                  row && row.total_licenses
                                    ? row.total_licenses
                                    : 0
                                }
                                displayType="text"
                              />
                            </p>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </LoadingOverlay>
          </div>
        </div>
        <EditProfileModelBox
          isShowEditProfile={isShowEditProfile}
          handleEditProfileModelClose={this.handleEditProfileModelClose}
          onSubmit={this.handleSubmitEditProfile}
          handleCancel={this.handleCancel}
          handleInputChange={this.handleInputChange}
          imagePreviewUrl={imagePreviewUrl}
        />
      </React.Fragment>
    )
  }
}

Teacher.defaultProps = {
  loading: true,
  row: {},
  teacherProfile: null,
}

Teacher.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  getTeacherProfile: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  getStats: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  updateTeacherProfile: PropTypes.func.isRequired,
  dispatchFormField: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  teacherProfile: PropTypes.shape({
    photo_path: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    school_name: PropTypes.string,
    bio: PropTypes.string,
  }),
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  teacherProfile: state.teacherProfile.data.row,
  loading: state.teacherProfile.loading,
  row: state.teacherStats.data,
})

const mapDispatchToProps = dispatch => ({
  getTeacherProfile: props => dispatch(getTeacherProfile(props)),
  getStats: props => dispatch(getStats(props)),
  updateTeacherProfile: (values, file, props) =>
    dispatch(updateTeacherProfile(values, file, props)),
  resetForm: formName => dispatch(resetForm(formName)),
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Teacher),
)
