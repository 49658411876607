/* TODO: Fix eslint */
/* eslint-disable */

import isEquals from 'validator/lib/equals'

const validateForm = (values, props) => {
  const errors = {}
  const regexpWithoutSpace = /^\S*$/

  // password
  if (!values.password) {
    errors.password = [`errorMsgStudentServiceChangePasswordPasswordMissing`]
  } else if (values.password.length < 5) {
    errors.password = [`errorMsgStudentServiceChangePasswordPasswordIsLength`]
  } else if (values.password.length > 20) {
    errors.password = [`errorMsgStudentServiceChangePasswordPasswordIsLength`]
  }

  // confirm_password
  if (!values.confirm_password) {
    errors.confirm_password = [
      `errorMsgStudentServiceChangePasswordConfirmPasswordMissing`,
    ]
  } else if (!isEquals(values.confirm_password, values.password)) {
    errors.confirm_password = [
      `errorMsgStudentServiceChangePasswordConfirmPasswordNotMatched`,
    ]
  }

  return errors
}

export default validateForm
