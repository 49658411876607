/* TODO: Fix eslint */
/* eslint-disable */

//import Noty from 'noty';

import React from 'react'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class NotyToaster extends React.Component {
  render() {
    toast.dismiss()
    const { noty } = this.props
    toast.configure({
      autoClose: false,
    })
    if (noty.type && noty.message) {
      toast.dismiss()
      if (noty.type === 'success') {
        toast.dismiss()

        toast.success(noty.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          pauseOnFocusLoss: false,
          onClose: () => {
            toast.dismiss()
          },
        })
      }
      /*
      else if(noty.type==='error'){
        toast.error(noty.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }else if(noty.type==='warn'){
        toast.warn(noty.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }else if(noty.type==='info'){
        toast.info(noty.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }else{
        toast(noty.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      */
    } else {
      toast.dismiss()
    }
    return <ToastContainer hideProgressBar={true} autoClose={false} />
  }
}

export default connect(state => ({
  noty: state.noty,
}))(NotyToaster)

/*

closeButton={false}

 */
