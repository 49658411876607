/* TODO: Fix eslint */
/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactFlagsSelect from 'react-flags-select'
import { getTranslate, withLocalize } from 'react-localize-redux'
import { confirmAlert } from 'react-confirm-alert'
import config from '../../../../../config'
import { studentLogoutUser } from '../../../../../store/actions/auth'
import {
  getStudentProfile,
  studentChangePassword,
} from '../../../../../store/actions/student'

import 'react-flags-select/css/react-flags-select.css'
import ChnagePasswordModelBox from '../../Profile/ChnagePasswordModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { resetForm } from '../../../../../store/actions/form'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowChangePassword: false,
    }
  }

  componentWillMount() {
    const { getStudentProfile } = this.props
    getStudentProfile(this.props)
  }

  onSelectFlag = countryCode => {
    const { setActiveLanguage } = this.props
    let langCode = 'en'
    if (countryCode === 'US') {
      langCode = 'en'
    } else if (countryCode === 'ES') {
      langCode = 'es'
    } else if (countryCode === 'CN') {
      langCode = 'zh'
    }
    setActiveLanguage(langCode)
    const languages = {
      defaultLanguage: langCode,
      defaultCountry: countryCode,
    }
    localStorage.setItem('currentLanguage', JSON.stringify(languages))
  }

  handleChangePasswordModelShow = () => {
    const { resetForm } = this.props
    resetForm('student-change-password')
    this.setState({
      isShowChangePassword: true,
    })
  }

  handleChangePasswordModelClose = () => {
    const { resetForm } = this.props
    resetForm('student-change-password')
    this.setState({
      isShowChangePassword: false,
    })
  }

  handleSubmitChangePassword = async values => {
    const { resetForm, studentChangePassword } = this.props
    await studentChangePassword(values, this.props)
    resetForm('student-change-password')
    this.setState({ isShowChangePassword: false })
  }

  handleCancel = () => {
    const { resetForm, translate } = this.props
    confirmAlert({
      title: translate('alertHeading'),
      message: translate('alertFormText'),
      buttons: [
        {
          label: translate('btnYes'),
          onClick: () => {
            resetForm('student-change-password')
            this.setState({ isShowChangePassword: false })
          },
        },
        {
          label: translate('btnNo'),
        },
      ],
    })
  }

  render() {
    const {
      translate,
      studentLogoutUser,
      studentProfile,
      handleHamburger,
    } = this.props
    const { isShowChangePassword } = this.state
    const currentSetLanguage = JSON.parse(
      localStorage.getItem('currentLanguage'),
    )
    return (
      <React.Fragment>
        <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light">
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div className="collapse navbar-collapse show" id="navbar-mobile">
                <ul className="nav navbar-nav mr-auto float-left">
                  <li className="nav-item mobile-menu d-md-none mr-auto">
                    <Link
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      to="#"
                    >
                      <i className="ft-menu font-large-1" />
                    </Link>
                  </li>
                  <li className="nav-item d-none d-md-block">
                    <Link
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      to="#"
                      onClick={handleHamburger}
                      style={{ transition: 'all 0s ease 0s' }}
                    >
                      <i className="ft-menu" />
                    </Link>
                  </li>
                </ul>
                <ul className="nav navbar-nav float-right">
                  <ReactFlagsSelect
                    className="selectpicker"
                    data-width="fit"
                    countries={['US', 'ES', 'CN']}
                    customLabels={{
                      US: 'English',
                      ES: 'español',
                      CN: '中文(简体',
                    }}
                    defaultCountry={currentSetLanguage.defaultCountry}
                    showSelectedLabel
                    onSelect={this.onSelectFlag}
                  />
                  <li className="dropdown dropdown-user nav-item">
                    <Link
                      className="dropdown-toggle nav-link dropdown-user-link"
                      href="#"
                      data-toggle="dropdown"
                    >
                      {studentProfile &&
                        studentProfile.alias !== null &&
                        (studentProfile.alias.length > 13
                          ? `${studentProfile.alias.substring(0, 10)}...`
                          : studentProfile.alias)}
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={this.handleChangePasswordModelShow}
                      >
                        <i className="ft-lock" />
                        {translate('btnChangePassword')}
                      </Link>
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item"
                        to={`${config.studentUrl}/login`}
                        onClick={studentLogoutUser}
                      >
                        <i className="ft-power" />
                        {translate('topNavMenuLogout')}
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <ChnagePasswordModelBox
          isShowChangePassword={isShowChangePassword}
          handleChangePasswordModelClose={this.handleChangePasswordModelClose}
          onSubmit={this.handleSubmitChangePassword}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  studentProfile: state.studentProfile.data.row,
})

const mapDispatchToProps = dispatch => ({
  studentLogoutUser: () => dispatch(studentLogoutUser()),
  getStudentProfile: props => dispatch(getStudentProfile(props)),
  studentChangePassword: (values, props) =>
    dispatch(studentChangePassword(values, props)),
  resetForm: formName => dispatch(resetForm(formName)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Header),
)
