/* TODO: Fix eslint */
/* eslint-disable */

import axios from 'axios'
import { saveAs } from 'file-saver'
import { reset } from 'redux-form'
import config from '../../config'
import handleResponse from './handleResponse'
import handleResponseTranslate from './handleResponseTranslate'

export const GET_SCHOOL_LIST_BY_ORGANIZATION_ID =
  'GET_SCHOOL_LIST_BY_ORGANIZATION_ID'
export const GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADING =
  'GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADING'
export const GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADED =
  'GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADED'

export const GET_SCHOOL_DROPDOWN_LIST_BY_ORGANIZATION_ID =
  'GET_SCHOOL_DROPDOWN_LIST_BY_ORGANIZATION_ID'
export const GET_SCHOOL_DETAILS_BY_SCHOOL_ID = 'GET_SCHOOL_DETAILS_BY_SCHOOL_ID'
export const GET_ORGANIZATION_SCHOOL_LIST = 'GET_ORGANIZATION_SCHOOL_LIST'
export const GET_ORGANIZATION_SCHOOL_LIST_LOADING =
  'GET_ORGANIZATION_SCHOOL_LIST_LOADING'
export const GET_ORGANIZATION_SCHOOL_LIST_LOADED =
  'GET_ORGANIZATION_SCHOOL_LIST_LOADED'
export const GET_ORGANIZATION_SCHOOL_DROPDOWN_LIST =
  'GET_ORGANIZATION_SCHOOL_DROPDOWN_LIST'
export const GET_ORGANIZATION_SCHOOL_DETAILS_BY_SCHOOL_ID =
  'GET_SCHOOL_DETAILS_BY_SCHOOL_ID'
export const STORE_ORGANIZATION_SCHOOL = 'STORE_ORGANIZATION_SCHOOL'
export const UPDATE_ORGANIZATION_SCHOOL = 'UPDATE_ORGANIZATION_SCHOOL'
export const CHANGE_STATUS_ORGANIZATION_SCHOOL =
  'CHANGE_STATUS_ORGANIZATION_SCHOOL'

/**
 * To get schools of a organization
 *
 * @param  int organizationId
 * @return data
 */
export const getSchoolsByOrganizationId = (
  organizationId,
  page,
  orderBy,
  order,
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/schools/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_SCHOOL_LIST_BY_ORGANIZATION_ID,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_SCHOOL_LIST_BY_ORGANIZATION_ID_LOADED,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get schools of a organization for dropdown
 *
 * @param  int organizationId
 * @param  int instructorId
 * @return id,name
 */
export const getSchoolsListByOrganizationId = organizationId => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/school-list`,
      headers,
    )
    dispatch({
      type: GET_SCHOOL_DROPDOWN_LIST_BY_ORGANIZATION_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get school details
 *
 * @param  int organizationId
 * @param  int schoolId
 * @return data
 */
export const getDetailByschoolId = (organizationId, schoolId) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/school/${schoolId}`,
      headers,
    )
    dispatch({
      type: GET_SCHOOL_DETAILS_BY_SCHOOL_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get schools of a organization for Organization
 *
 * @param  object  props
 * @return data
 */
export const getOrganizationSchools = (props, page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_SCHOOL_LIST_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/school/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_SCHOOL_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_SCHOOL_LIST_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get schools of a organization for dropdown for Organization
 *
 * @param  int props
 * @param  object  props
 * @return id,name
 */
export const getOrganizationSchoolsList = props => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/school/list`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_SCHOOL_DROPDOWN_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get school details for Organization
 *
 * @param  object  props
 * @param  int schoolId
 * @return data
 */
export const getOrganizationSchoolDetailBySchoolId = (
  props,
  schoolId,
) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiOrganizationUrl}/school/${schoolId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_SCHOOL_DETAILS_BY_SCHOOL_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To store a school for organizatoin
 *
 * @param  object values
 * @param  object  props
 */
export const storeSchool = (values, props) => async (dispatch, getState) => {
  try {
    const tag_ids = []
    const new_tags = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        if (item.id) {
          tag_ids.push(item.id)
        }
        if (item.newid) {
          new_tags.push(item.name)
        }
      })
    }

    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const schoolValues = {
      name: values.name,
      principal: values.principal,
      total_license: values.total_license,
      tags: tag_ids.length > 0 ? tag_ids.toString() : null,
      new_tags: new_tags.length > 0 ? new_tags.toString() : null,
    }
    // console.log(schoolValues);
    const response = await axios.post(
      `${config.apiOrganizationUrl}/school`,
      schoolValues,
      headers,
    )
    dispatch({
      type: STORE_ORGANIZATION_SCHOOL,
      payload: response.data.result,
    })
    dispatch(reset('organization-create-school'))
    return response.data.result.data.id
  } catch (error) {
    await handleResponseTranslate(error, props, 1)
  }
}

/**
 * To update a school for organizatoin
 *
 * @param  int schoolId
 * @param  object values
 * @param  object  props
 */
export const updateSchool = (schoolId, values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const tag_ids = []
    const new_tags = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        if (item.id) {
          tag_ids.push(item.id)
        }
        if (item.newid) {
          new_tags.push(item.name)
        }
      })
    }
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const schoolValues = {
      name: values.name,
      principal: values.principal,
      total_license: values.total_license,
      tags: tag_ids.length > 0 ? tag_ids.toString() : null,
      new_tags: new_tags.length > 0 ? new_tags.toString() : null,
    }
    // console.log(schoolValues);
    const response = await axios.put(
      `${config.apiOrganizationUrl}/school/${schoolId}`,
      schoolValues,
      headers,
    )
    dispatch({
      type: UPDATE_ORGANIZATION_SCHOOL,
      payload: response.data.result,
    })
    // dispatch(reset('organization-create-school'));
    return response.data.result.data.id
  } catch (error) {
    await handleResponseTranslate(error, props, 1)
  }
}

/**
 * To chnage status  of a school for organizatoin
 *
 * @param  int schoolId
 * @param  object  props
 */
export const changeSchoolStatus = (schoolId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.put(
      `${config.apiOrganizationUrl}/school/change-status/${schoolId}`,
      '',
      headers,
    )
    dispatch({
      type: CHANGE_STATUS_ORGANIZATION_SCHOOL,
      payload: response.data.result,
    })
    // dispatch(reset('organization-create-school'));
    // return response.data.result.data.id;
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To download school related PDF file for organization
 *
 * @return pdf file
 */
export const downloadPDFOrganization = (schoolId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      access_token: `${getState().authOrganization.accessToken}`,
      responseType: 'blob',
    }
    // const headers =  {
    //                   headers: {
    //                     'access_token':`${getState().authOrganization.accessToken}`,
    //                      responseType:'blob'
    //                   }
    //                 };

    await axios
      .get(`${config.apiOrganizationUrl}/school/pdf/${schoolId}`, headers)
      .then(res => {
        console.log('dd')
        console.log(res.data)
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
        saveAs(pdfBlob, 'school-details')
      })
  } catch (error) {
    console.log(error)
    await handleResponseTranslate(error, props, 3)
  }
}
