/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactFlagsSelect from 'react-flags-select'
import { confirmAlert } from 'react-confirm-alert'
import {
  getTranslate,
  withLocalize,
  setActiveLanguage,
} from 'react-localize-redux'
import config from '../../../../../config'
import { teacherLogoutUser } from '../../../../../store/actions/auth'
import {
  getTeacherProfile,
  updateTeacherPassword,
  updateTeacherProfile,
} from '../../../../../store/actions/instructors'

import 'react-flags-select/css/react-flags-select.css'
import ChnagePasswordModelBox from '../../Profile/ChnagePasswordModelBox'
import EditProfileModelBox from '../../Profile/EditProfileModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { resetForm } from '../../../../../store/actions/form'
import instructorImg from '../../../../../app-assets/images/instructor.png'
import { dispatchFormField } from '../../../../../store/actions/form'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowChangePassword: false,
      isShowEditProfile: false,
      imagePreviewUrl: instructorImg,
    }

    const { getTeacherProfile } = this.props
    getTeacherProfile(this.props)
  }

  onSelectFlag = countryCode => {
    const { setActiveLanguage } = this.props
    let langCode = 'en'
    if (countryCode === 'US') {
      langCode = 'en'
    } else if (countryCode === 'ES') {
      langCode = 'es'
    } else if (countryCode === 'CN') {
      langCode = 'zh'
    }
    setActiveLanguage(langCode)
    const languages = {
      defaultLanguage: langCode,
      defaultCountry: countryCode,
    }
    localStorage.setItem('currentLanguage', JSON.stringify(languages))
  }

  handleChangePasswordModelShow = () => {
    const { resetForm } = this.props
    resetForm('teacher-change-password')
    this.setState({
      isShowChangePassword: true,
    })
  }

  handleChangePasswordModelClose = () => {
    const { resetForm } = this.props
    resetForm('teacher-change-password')
    this.setState({
      isShowChangePassword: false,
    })
  }

  handleSubmitChangePassword = async values => {
    const { resetForm, updateTeacherPassword } = this.props
    await updateTeacherPassword(values, this.props)
    resetForm('teacher-change-password')
    this.setState({ isShowChangePassword: false })
  }

  handleCancel = () => {
    const { resetForm, translate } = this.props
    confirmAlert({
      title: translate('alertHeading'),
      message: translate('alertFormText'),
      buttons: [
        {
          label: translate('btnYes'),
          onClick: () => {
            resetForm('teacher-change-password')
            resetForm('teacher-change-profile')
            this.setState({ isShowChangePassword: false })
            this.setState({ isShowEditProfile: false })
          },
        },
        {
          label: translate('btnNo'),
        },
      ],
    })
  }

  handleEditProfileModelShow = () => {
    const { resetForm } = this.props
    resetForm('teacher-change-profile')
    this.setState({
      isShowEditProfile: true,
      imagePreviewUrl: instructorImg,
      files: null,
    })
    const { teacherProfile } = this.props
    if (teacherProfile) {
      if (teacherProfile.photo_path) {
        const imageObj = JSON.parse(teacherProfile.photo_path)
        if (imageObj) {
          if (imageObj.key && imageObj.type) {
            const url = `${config.apiTeacherUrl}/image/${imageObj.key}`
            this.setState({ imagePreviewUrl: url })
          }
        }
      }
    }
  }

  handleEditProfileModelClose = () => {
    const { resetForm } = this.props
    resetForm('teacher-change-profile')
    this.setState({
      isShowEditProfile: false,
    })
  }

  handleSubmitEditProfile = async values => {
    const { resetForm, updateTeacherProfile, getTeacherProfile } = this.props
    const file = this.state.files
    await updateTeacherProfile(values, file, this.props)
    await getTeacherProfile(this.props)
    resetForm('teacher-change-profile')
    this.setState({ isShowEditProfile: false })
  }

  handleInputChange = e => {
    e.preventDefault()
    const { dispatchFormField } = this.props
    const { files } = e.target
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // Make new FileReader
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // on reader load something...
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        }
        this.setState({ files: fileInfo })
        this.setState({
          imagePreviewUrl: reader.result,
        })
        dispatchFormField('teacher-change-profile', 'image', fileInfo)
      }
    }
  }

  render() {
    const {
      translate,
      teacherLogoutUser,
      teacherProfile,
      handleHamburger,
    } = this.props
    const {
      isShowChangePassword,
      isShowEditProfile,
      imagePreviewUrl,
    } = this.state
    const currentSetLanguage = JSON.parse(
      localStorage.getItem('currentLanguage'),
    )
    return (
      <React.Fragment>
        <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light">
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div className="collapse navbar-collapse show" id="navbar-mobile">
                <ul className="nav navbar-nav mr-auto float-left">
                  <li className="nav-item mobile-menu d-md-none mr-auto">
                    <Link
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      to="#"
                    >
                      <i className="ft-menu font-large-1" />
                    </Link>
                  </li>
                  <li className="nav-item d-none d-md-block">
                    <Link
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      to="#"
                      onClick={handleHamburger}
                      style={{ transition: 'all 0s ease 0s' }}
                    >
                      <i className="ft-menu" />
                    </Link>
                  </li>
                </ul>
                <ul className="nav navbar-nav float-right">
                  <ReactFlagsSelect
                    className="selectpicker"
                    data-width="fit"
                    countries={['US', 'ES', 'CN']}
                    customLabels={{
                      US: 'English',
                      ES: 'español',
                      CN: '中文(简体',
                    }}
                    defaultCountry={currentSetLanguage.defaultCountry}
                    showSelectedLabel
                    onSelect={this.onSelectFlag}
                  />
                  <li className="dropdown dropdown-user nav-item">
                    <Link
                      className="dropdown-toggle nav-link dropdown-user-link"
                      href="#"
                      data-toggle="dropdown"
                      to="#"
                    >
                      {teacherProfile &&
                        (teacherProfile.name.length > 13
                          ? `${teacherProfile.name.substring(0, 10)}...`
                          : teacherProfile.name)}
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={this.handleEditProfileModelShow}
                      >
                        <i className="ft-user" />
                        {translate('topNavMenuEditProfile')}
                      </Link>
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={this.handleChangePasswordModelShow}
                      >
                        <i className="ft-lock" />
                        {translate('btnChangePassword')}
                      </Link>
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item"
                        to={`${config.teacherUrl}/login`}
                        onClick={teacherLogoutUser}
                      >
                        <i className="ft-power" />
                        {translate('topNavMenuLogout')}
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <ChnagePasswordModelBox
          isShowChangePassword={isShowChangePassword}
          handleChangePasswordModelClose={this.handleChangePasswordModelClose}
          onSubmit={this.handleSubmitChangePassword}
          handleCancel={this.handleCancel}
        />
        <EditProfileModelBox
          isShowEditProfile={isShowEditProfile}
          handleEditProfileModelClose={this.handleEditProfileModelClose}
          onSubmit={this.handleSubmitEditProfile}
          handleCancel={this.handleCancel}
          handleInputChange={this.handleInputChange}
          imagePreviewUrl={imagePreviewUrl}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  teacherProfile: state.teacherProfile.data.row,
})

const mapDispatchToProps = dispatch => ({
  teacherLogoutUser: () => dispatch(teacherLogoutUser()),
  getTeacherProfile: props => dispatch(getTeacherProfile(props)),
  updateTeacherPassword: (values, props) =>
    dispatch(updateTeacherPassword(values, props)),
  updateTeacherProfile: (values, file, props) =>
    dispatch(updateTeacherProfile(values, file, props)),
  resetForm: formName => dispatch(resetForm(formName)),
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Header),
)
