/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import NumberFormat from 'react-number-format'

const Stats = ({ row }) => (
  <div className="col-md-12 col-lg-4 pull-right">
    <div className="card">
      <div className="card-header p-1">
        <h4 className="card-title float-left">Instructor Stats</h4>
      </div>
      <div className="card-content collapse show">
        <div className="card-footer text-center p-1">
          <div className="row">
            <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-center">
              <p className="blue-grey lighten-2 mb-0">Total Classes</p>
              <p className="font-medium-5 text-bold-400">
                <NumberFormat
                  thousandSeparator
                  value={row && row.total_class}
                  displayType="text"
                />
              </p>
            </div>
            <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-center">
              <p className="blue-grey lighten-2 mb-0">Total Licenses / Assigned</p>
              <p className="font-medium-5 text-bold-400">
                <NumberFormat
                  thousandSeparator
                  value={row && Number(row.total_active_license)}
                  displayType="text"
                />{' '}
                /{' '}
                <NumberFormat
                  thousandSeparator
                  value={row && Number(row.total_license)}
                  displayType="text"
                />
              </p>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
)

export default Stats
