/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { getTranslate, withLocalize } from 'react-localize-redux'
import config from '../../../../../config'
import EditForm from './EditForm'
import Stats from '../Details/Stats'
import {
  getOrganizationSchoolDetailBySchoolId,
  updateSchool,
  changeSchoolStatus,
} from '../../../../../store/actions/school'
import { getOrganizationPrincipalListWithSchoolId } from '../../../../../store/actions/principal'
import { getOrganizationTags } from '../../../../../store/actions/tag'
import 'react-confirm-alert/src/react-confirm-alert.css'

import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
    this.state = { schoolId: '' }
  }

  componentWillMount() {
    const { match } = this.props
    const schoolId = match.params.sid
    if (schoolId === ' ' || schoolId === 'undefined') {
      this.props.history.push(`${config.organizationUrl}/home`)
    }
    this.setState({ schoolId })
    this.props.getOrganizationSchoolDetailBySchoolId(this.props, schoolId)
    this.props.getOrganizationPrincipalListWithSchoolId(schoolId, this.props)
    this.props.getOrganizationTags(this.props)
  }

  handleRedirect = () => {
    this.props.history.push(
      `${config.organizationUrl}/school/${this.state.schoolId}`,
    )
  }

  handleCancel = async () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            this.props.history.push(
              `${config.organizationUrl}/school/${this.state.schoolId}`,
            )
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  handleSubmit = async values => {
    const schoolId = await this.props.updateSchool(
      this.state.schoolId,
      values,
      this.props,
    )
    return this.props.history.push(
      `${config.organizationUrl}/school/${this.state.schoolId}`,
    )
  }

  handleChangeStatus = async () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message:
        this.props.row && this.props.row.status === 'Active'
          ? this.props.translate('alertChangeStatusSchoolDeactivate')
          : this.props.translate('alertChangeStatusSchoolTextActivate'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            await this.props.changeSchoolStatus(this.state.schoolId, this.props)
            this.props.history.push(
              `${config.organizationUrl}/school/${this.state.schoolId}`,
            )
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  render() {
    const { translate, row, principal, tags } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              {translate('breadCrumbSchoolEdit')}
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/home`}>
                      {translate('breadCrumbHome')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`${config.organizationUrl}/schools`}>
                      {translate('breadCrumbSchool')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.organizationUrl}/school/${this.state.schoolId}`}
                    >
                      {translate('breadCrumbSchoolView')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {translate('breadCrumbSchoolEdit')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <EditForm
                  principal={principal}
                  tags={tags}
                  row={row}
                  tags={tags}
                  onSubmit={this.handleSubmit}
                  handleCancel={this.handleCancel}
                  handleRedirect={this.handleRedirect}
                  handleChangeStatus={this.handleChangeStatus}
                />
                <div className="clearfix" />
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  row: state.organizationOrganizationSchoolDetail.data.row,
  principal:
    state.organizationOrganizationPrincipalDropdownSchoolId.data.principal,
  tags: state.organizationOrganizationTags.data.tags,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationSchoolDetailBySchoolId: (props, schoolId) =>
    dispatch(getOrganizationSchoolDetailBySchoolId(props, schoolId)),
  getOrganizationPrincipalListWithSchoolId: (schoolId, props) =>
    dispatch(getOrganizationPrincipalListWithSchoolId(schoolId, props)),
  getOrganizationTags: props => dispatch(getOrganizationTags(props)),
  updateSchool: (schoolId, values, props) =>
    dispatch(updateSchool(schoolId, values, props)),
  changeSchoolStatus: (schoolId, props) =>
    dispatch(changeSchoolStatus(schoolId, props)),
})

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Edit))
