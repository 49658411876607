/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert' // Import
import jsPDF from 'jspdf'
import { renderToString } from 'react-dom/server'
import Chart from 'react-google-charts'
import moment from 'moment'
import { getTranslate, withLocalize } from 'react-localize-redux'
import {
  getReportDetails,
  removeReport,
  getAdminAdventureStatsReportByReportId,
  getAdminAssessmentAdventureStatsReportByReportId,
} from '../../../../../store/actions/report'
import OrganizationCommonDetail from '../../../Common/Organization'
import OrganizationStats from '../../OrganizationStats'
import Reports from './Reports'
import AggregateDate from './AggregateDate'
import Detail from './Detail'
import ClassAttendance from './ClassAttendance'
import PhonemeScores from './PhonemeScores'
import AdventureStats from './AdventureStats'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import config from '../../../../../config'
import 'jspdf-autotable'
import CommonAdventureStats from '../../../../Common/Report/AdventureStats'
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'

class ReportDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationId: '',
      reportId: '',
      chartClassAttendanceWrapper: null,
      chartClassPhonemeScoresWrapper: null,
      loading: true,
    }
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
    this.handleChartClassAttendanceWrapper = this.handleChartClassAttendanceWrapper.bind(
      this,
    )
  }

  handleDelete = reportId => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'You want to delete this report?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.removeReport(this.state.organizationId, reportId)
            this.props.history.push(
              `${config.adminUrl}/organization/${this.state.organizationId}/reports`,
            )
          },
        },
        {
          label: 'No',
        },
      ],
    })
  }

  componentWillMount() {
    const { match } = this.props
    const organizationId = match.params.id
    const reportId = match.params.rid
    if (organizationId === ' ' || organizationId === 'undefined') {
      this.props.history.push(`${config.adminUrl}/home`)
    }
    if (reportId === ' ' || reportId === 'undefined') {
      this.props.history.push(`${config.adminUrl}/home`)
    }
    this.setState({ organizationId })
    this.setState({ reportId })
    this.props.getReportDetails(organizationId, reportId)
    const loadingTable = this.state.loading
    this.props.getAdminAdventureStatsReportByReportId(organizationId, reportId)
    this.props.getAdminAssessmentAdventureStatsReportByReportId(
      organizationId,
      reportId,
    )
  }

  print = () => {
    let height = 10
    const width = 10
    const pdf = new jsPDF('p', 'mm', 'a3')
    // // this.props.translate("reportAdventureAssessmentStatsTableRowAverageQuestionsCorrect"),
    const columns = [
      this.props.translate('reportAdventureAssessmentStatsTableRowAdventure'),
      this.props.translate('reportAdventureAssessmentStatsTableRowFocusWords'),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowGrammarPrincipal',
      ),
      this.props.translate('reportAdventureAssessmentStatsTableRowAverageTime'),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowAverageScore',
      ),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowAverageStars',
      ),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowNumberClassesPlayed',
      ),
      this.props.translate(
        'reportAdventureAssessmentStatsTableRowNumberStudentsPlayed',
      ),
    ]
    const rows = []
    if (this.props.dataAdventures.length > 0) {
      for (const row of this.props.dataAdventures) {
        const averageQuestionsCorrect = row.id / row.completed
        rows.push([
          row.name,
          row.focus_words,
          row.grammar_principles,
          (Math.round(row.time * 100) / 100).toFixed(1),
          (Math.round(row.score * 100) / 100).toFixed(1),
          (Math.round(row.stars * 100) / 100).toFixed(1),
          parseInt(row.classPlayed),
          parseInt(row.studentPlayed),
        ])
      }
    }

    const rows1 = []
    if (this.props.dataAssessmentAdventures.length > 0) {
      for (const row of this.props.dataAssessmentAdventures) {
        const dataAssessmentAdventures = row.id / row.completed
        rows1.push([
          row.name,
          row.focus_words,
          row.grammar_principles,
          (Math.round(row.time * 100) / 100).toFixed(1),
          (Math.round(row.score * 100) / 100).toFixed(1),
          (Math.round(row.stars * 100) / 100).toFixed(1),
          parseInt(row.classPlayed),
          parseInt(row.studentPlayed),
        ])
      }
    }

    const columnsReport = [
      this.props.translate('reportDetailRowName'),
      this.props.translate('reportDetailRowSchool'),
      this.props.translate('reportDetailRowDescription'),
      this.props.translate('classDetailRowStartDate'),
      this.props.translate('classDetailRowEndDate'),
      this.props.translate('reportTableRowTags'),
    ]

    pdf.text(this.props.translate('breadCrumbReportView'), width, height)
    height += 10
    const format = config.dateFormat
    const currentHH = moment()
      .utc()
      .format('HH')
    const currentMM = moment()
      .utc()
      .format('mm')
    const currentSS = moment()
      .utc()
      .format('ss')
    const time = ` ${currentHH}:${currentMM}:${currentSS}`
    let stateDate = ''
    if (this.props.organizationReportRow.start_date) {
      const dbDate = moment(
        this.props.organizationReportRow.start_date + time,
        'YYYY-MM-DD HH:mm:ss',
      ).format('YYYY-MM-DD HH:mm:ss')
      const utcFormat = moment(dbDate)
        .utc()
        .format('YYYY-MM-DD HH:MM:SS')
      stateDate = moment
        .utc(utcFormat, 'YYYY-MM-DD HH:MM:SS')
        .local()
        .format(format)
    }
    let endDate = ''
    if (this.props.organizationReportRow.end_date) {
      const dbDate1 = moment(
        this.props.organizationReportRow.end_date + time,
        'YYYY-MM-DD HH:mm:ss',
      ).format('YYYY-MM-DD HH:mm:ss')
      const utcFormat1 = moment(dbDate1)
        .utc()
        .format('YYYY-MM-DD HH:MM:SS')
      endDate = moment
        .utc(utcFormat1, 'YYYY-MM-DD HH:MM:SS')
        .local()
        .format(format)
    }
    pdf.autoTable({
      startY: height,
      head: [columnsReport],
      body: [
        [
          this.props.organizationReportRow.report_name,
          this.props.organizationReportRow.school_name,
          this.props.organizationReportRow.description,
          stateDate,
          endDate,
          this.props.organizationReportRow.tags,
        ],
      ],
    })
    height += 40
    if (this.state.chartClassAttendanceWrapper !== null) {
      const classAttendanceImage = this.state.chartClassAttendanceWrapper
        .getChart()
        .getImageURI()
      pdf.text(this.props.translate('reportClassAttendance'), width, height)
      height += 1
      pdf.addImage(classAttendanceImage, 'JPEG', width, height)
    }
    if (this.state.chartClassPhonemeScoresWrapper !== null) {
      const classPhonemeScoresImage = this.state.chartClassPhonemeScoresWrapper
        .getChart()
        .getImageURI()
      height += 100
      pdf.text(this.props.translate('reportPhonemeScores'), width, height)
      height += 1
      pdf.addImage(classPhonemeScoresImage, 'JPEG', width, height)
    }
    height += 120
    pdf.text(this.props.translate('reportAdventureStats'), width, height)
    height += 5
    pdf.autoTable({
      columnStyles: {
        0: { columnWidth: 30 },
        3: { columnWidth: 20 },
        4: { columnWidth: 20 },
        5: { columnWidth: 20 },
      },
      startY: height,
      head: [columns],
      body: rows,
    })

    height += 120
    pdf.text(
      this.props.translate('reportAssessmentAdventureStats'),
      width,
      height,
    )
    height += 5
    pdf.autoTable({
      columnStyles: {
        0: { columnWidth: 30 },
        3: { columnWidth: 20 },
        4: { columnWidth: 20 },
        5: { columnWidth: 20 },
      },
      startY: height,
      head: [columns],
      body: rows1,
    })
    pdf.save(`${this.props.organizationReportRow.report_name}.pdf`)
  }

  handleChartClassAttendanceWrapper = chartWrapper => {
    this.setState({ chartClassAttendanceWrapper: chartWrapper })
  }

  handleChartClassPhonemeScoresWrapper = chartWrapper => {
    this.setState({ chartClassPhonemeScoresWrapper: chartWrapper })
  }

  render() {
    const {
      translate,
      dataAdventures,
      dataAssessmentAdventures,
      loading,
    } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">View Report Details</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.adminUrl}/organization/${this.state.organizationId}`}
                    >
                      Organization
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.adminUrl}/organization/${this.state.organizationId}/reports`}
                    >
                      View Reports
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    View Report Details
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <OrganizationCommonDetail />
          <div className="row">
            <Detail
              row={this.props.organizationReportRow}
              handleDelete={this.handleDelete}
            />
            <Reports organizationId={this.state.organizationId} />
          </div>
          <ClassAttendance
            handleChartClassAttendanceWrapper={
              this.handleChartClassAttendanceWrapper
            }
            organizationId={this.state.organizationId}
            reportId={this.state.reportId}
          />
          <PhonemeScores
            handleChartClassPhonemeScoresWrapper={
              this.handleChartClassPhonemeScoresWrapper
            }
            organizationId={this.state.organizationId}
            reportId={this.state.reportId}
          />
          <CommonAdventureStats
            dataAssessment={dataAssessmentAdventures}
            data={dataAdventures}
            loading={loading}
          />
          <AggregateDate
            row={this.props.organizationReportRow}
            print={this.print}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  organizationReportRow: state.organizationReportDetail.data.row,
  dataAdventures: state.adminAdventureStats.data.adventures,
  dataAssessmentAdventures: state.adminAssessmentAdventureStats.data.adventures,
  loading: state.adminAdventureStats.loading,
})

const mapDispatchToProps = dispatch => ({
  getReportDetails: (organizationId, reportId) => {
    dispatch(getReportDetails(organizationId, reportId))
  },
  removeReport: (organizationId, reportId) => {
    dispatch(removeReport(organizationId, reportId))
  },
  getAdminAdventureStatsReportByReportId: (organizationId, reportId) => {
    dispatch(getAdminAdventureStatsReportByReportId(organizationId, reportId))
  },
  getAdminAssessmentAdventureStatsReportByReportId: (
    organizationId,
    reportId,
  ) => {
    dispatch(
      getAdminAssessmentAdventureStatsReportByReportId(
        organizationId,
        reportId,
      ),
    )
  },
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ReportDetail),
)
