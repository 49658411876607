/* TODO: Fix eslint */
/* eslint-disable */

/** images **/
import logo from './../../../../app-assets/images/logo/fluent-world-logo.png'

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import Loader from './../../../../components/Loader'
import RenderField from './../../../../components/RenderFieldTranlate'
import validateForm from './helpers'
import { getTranslate } from 'react-localize-redux'
import config from '././../../../../config'

class RecoverPasswordForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      error,
      emailField,
      successMessage,
      translate,
    } = this.props
    return (
      <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
        <div className="card-header border-0">
          <div className="text-center mb-1">
            <Link to={`${config.teacherUrl}/login`}>
              <img src={logo} alt="branding logo" width="60%;" />
            </Link>
          </div>
          <div className="font-large-1  text-center">{translate('recoverPassword')}</div>
        </div>
        <div className="card-content">
          {emailField === true && (
            <div className="card-body">
              <form className="form-horizontal resetPassword" onSubmit={handleSubmit}>
                <fieldset className="form-group position-relative has-icon-left">
                  <Field
                    name="email"
                    component={RenderField}
                    type="text"
                    placeholder={translate('fieldEmail')}
                  />
                  <div className="form-control-position">
                    <i className="ft-mail" />
                  </div>
                </fieldset>
                <h6 className="card-subtitle f-p-detail text-muted text-center font-small-3">
                  {/*} <span>{translate("recoverPasswordMessage1")}</span>{*/}
                </h6>
                <div className="form-group text-center">
                  {error && (
                    <h4 className="text-danger font-weight-bold mb-2">{translate(`${error}`)}</h4>
                  )}
                  <button
                    type="submit"
                    className="btn round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1"
                    disabled={pristine || submitting}
                  >
                    {' '}
                    {submitting ? <Loader /> : translate('btnSubmit')}{' '}
                  </button>
                </div>
              </form>
            </div>
          )}
          {emailField === false && (
            <div className="card-body">
              <div className="email_send f-p-detail text-muted text-center font-small-3">
                {translate(successMessage)}
              </div>
              <div className="form-group text-center">
                <Link
                  to={`${config.teacherUrl}/login`}
                  className="btn round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1"
                >
                  {translate('btnReturnToLogin')}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

RecoverPasswordForm = reduxForm({
  form: 'teacher-recover-password',
  validate: validateForm,
})(RecoverPasswordForm)

RecoverPasswordForm = connect(state => ({
  translate: getTranslate(state.localize),
}))(RecoverPasswordForm)

export default RecoverPasswordForm
