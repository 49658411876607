/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import moment from 'moment'

class TableRowDateProgressBar extends React.Component {
  render() {
    const { start_date, end_date } = this.props
    let datePercentage = 0
    if (start_date && end_date) {
      let totalDay = 0
      let leftDay = 0
      const startDate = moment(start_date)
      const currentDate = moment()
      const endDate = moment(end_date)
      totalDay = endDate.diff(startDate, 'days')
      leftDay = currentDate.diff(startDate, 'days')
      if (totalDay === 0) {
        datePercentage = 100
      } else if (leftDay === 0 && totalDay == 0) {
        datePercentage = 100
      } else if (leftDay > totalDay) {
        datePercentage = 100
      } else {
        datePercentage = parseInt(Number((leftDay * 100) / totalDay))
      }
    }
    return (
      <Fragment>
        <div className="progress progress-sm mb-0 box-shadow-2">
          <div
            className="progress-bar bg-gradient-x-success"
            role="progressbar"
            style={{
              width: `${parseInt(datePercentage)}%`,
              backgroundImage: `${
                datePercentage >= 90
                  ? 'linear-gradient(to right, #ec6b6b 0%, #ff4747 100%)'
                  : 'linear-gradient(to right, #8f84ff 0%, #8f84ff 100%)'
              }`,
            }}
            aria-valuenow={75}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
      </Fragment>
    )
  }
}

export default TableRowDateProgressBar
