/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { exportReport } from '../../../../../../store/actions/report'

class AggregateDate extends React.Component {
  handleDownloadPDF = async () => {
    await this.props.exportReport(this.props.row.id, this.props.row.id)
  }

  render() {
    const { row, print } = this.props
    return (
      <div className="row">
        <div className="col-md-12 col-lg-12 pull-left">
          <h5 className="card-title text-bold-700 my-2 pull-right">
            <Link className="add_btn btn-floating" to="#" onClick={print}>
              Export to PDF
            </Link>
          </h5>
        </div>
        <div className="clearfix" />
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  exportReport: (organizationId, reportId) => {
    dispatch(exportReport(organizationId, reportId))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(AggregateDate)
