/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import Moment from 'react-moment'
import { Link, withRouter } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { confirmAlert } from 'react-confirm-alert' // Import
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { withLocalize, getTranslate } from 'react-localize-redux'
import {
  getOrganizationSchools,
  storeSchool,
} from '../../../../../store/actions/school'
import { resetForm } from '../../../../../store/actions/form'
import config from '../../../../../config'
import CreateNewFormModelBox from '../../Schools/CreateNewFormModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import TableRowLicenseProgressBar from '../../../../../components/TableRowLicenseProgressBar'

/**
 * import translation related files
 *
 */
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class Schools extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowNewSchoolForm: false,
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
      loading: false,
    }
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentWillMount() {
    this.setState({ loading: true })
    this.props.getOrganizationSchools(
      this.props,
      this.state.page,
      this.state.orderBy,
      this.state.order,
    )
    this.setState({ page: this.state.page + 1 })
  }

  handlePageChange = async e => {
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: this.state.page + 1 })
        if (this.props.data.totalPage > this.state.page) {
          this.setState({ counter: this.state.counter + 1 })
          this.setState({ loading: true })
          this.setState({ scrollTop: element.scrollTop })
          await this.props.getOrganizationSchools(
            this.props,
            this.state.page,
            this.state.orderBy,
            this.state.order,
          )
        }
      }
    }
  }

  handleSorting = async field => {
    let order = 'ASC'
    if (this.state.orderBy === field) {
      order = this.state.order === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    this.setState({ loading: true })
    await this.props.getOrganizationSchools(this.props, 0, field, order)
    this.setState({ page: 1 })
  }

  handleNewSchoolModelShow = () => {
    this.setState({
      isShowNewSchoolForm: true,
    })
  }

  handleNewSchoolModelClose = () => {
    this.props.resetForm('organization-create-school')
    this.setState({
      isShowNewSchoolForm: false,
    })
  }

  handleSubmit = async values => {
    const schoolId = await this.props.storeSchool(values, this.props)
    this.setState({ isShowNewSchoolForm: false })
    return this.props.history.push(
      `${config.organizationUrl}/school/${schoolId}`,
    )
  }

  handleCancel = async organizationUserId => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            this.props.resetForm('organization-create-school')
            this.setState({ isShowNewSchoolForm: false })
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  render() {
    const { translate, tags } = this.props
    let loadingTable = this.state.loading
    loadingTable = this.props.loading
    const sortClass = this.state.order === 'ASC' ? 'ascending' : 'desending'
    return (
      <React.Fragment>
        <div className="row match-height">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('schoolTableTitle')}
              <Link
                className="add_btn pull-right"
                to="#"
                onClick={this.handleNewSchoolModelShow}
              >
                {translate('btnAddSchool')}
              </Link>
            </h5>
            <div className="card" style={{}}>
              <div
                className={
                  this.props.data && this.props.data.totalPage > 0
                    ? 'card-content '
                    : 'card-content'
                }
              >
                <div
                  id="recent-projects"
                  className="media-list position-relative"
                  onScroll={this.handlePageChange.bind(this)}
                >
                  <LoadingOverlay
                    active={loadingTable}
                    spinner
                    text={translate('loadingOverlayText')}
                  >
                    <div
                      className={
                        this.props.schools.length === 0
                          ? 'table-responsive table-height'
                          : 'table-responsive'
                      }
                    >
                      {this.props.schools && this.props.schools.length > 0 && (
                        <div id="tableContainer" className="tableContainer">
                          <table
                            className="scrollTable school-list"
                            width="100%"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                          >
                            <thead className="fixedHeader">
                              <tr>
                                <th
                                  align="left"
                                  width="16%"
                                  onClick={() =>
                                    this.handleSorting('school_name')
                                  }
                                  className={
                                    this.state.orderBy === 'school_name'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('schoolTableRowName')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="left"
                                  width="16%"
                                  onClick={() =>
                                    this.handleSorting('principal_name')
                                  }
                                  className={
                                    this.state.orderBy === 'principal_name'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('schoolTableRowPrincipals')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="16%"
                                  onClick={() =>
                                    this.handleSorting('total_instructors')
                                  }
                                  className={
                                    this.state.orderBy === 'total_instructors'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('schoolTableRowTeachers')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="16%"
                                  onClick={() =>
                                    this.handleSorting('total_class')
                                  }
                                  className={
                                    this.state.orderBy === 'total_class'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('schoolTableRowClasses')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() =>
                                    this.handleSorting('total_active_license')
                                  }
                                  className={
                                    this.state.orderBy ===
                                    'total_active_license'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('schoolTableRowLicensesActivated')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="16%"
                                  onClick={() => this.handleSorting('tags')}
                                  className={
                                    this.state.orderBy === 'tags'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('schoolTableRowTags')}
                                  <i className="fas fa-sort-up" />
                                </th>
                              </tr>
                            </thead>
                            <tbody className="scrollContent">
                              {this.props.schools &&
                                this.props.schools.length > 0 &&
                                this.props.schools.map(row => (
                                  <tr>
                                    <td align="left" width="16%">
                                      <Link
                                        to={`${config.organizationUrl}/school/${row.id}`}
                                      >
                                        {row.school_name}
                                      </Link>
                                    </td>
                                    <td align="left" width="16%">
                                      <Link
                                        to={`${config.organizationUrl}/principal/${row.principal_id}`}
                                      >
                                        {row.principal_name}
                                      </Link>
                                    </td>
                                    <td align="center" width="16%">
                                      <NumberFormat
                                        thousandSeparator
                                        value={
                                          row.total_instructors
                                            ? row.total_instructors
                                            : 0
                                        }
                                        displayType="text"
                                      />
                                    </td>
                                    <td align="center" width="16%">
                                      <NumberFormat
                                        thousandSeparator
                                        value={
                                          row.total_class ? row.total_class : 0
                                        }
                                        displayType="text"
                                      />
                                    </td>
                                    <td align="center" width="20%">
                                      <span className="text-center">
                                        <NumberFormat
                                          thousandSeparator
                                          value={
                                            row.total_active_license
                                              ? row.total_active_license
                                              : 0
                                          }
                                          displayType="text"
                                        />{' '}
                                        /{' '}
                                        <NumberFormat
                                          thousandSeparator
                                          value={
                                            row.total_license
                                              ? row.total_license
                                              : 0
                                          }
                                          displayType="text"
                                        />
                                      </span>
                                      <TableRowLicenseProgressBar
                                        activeLicense={
                                          row && row.total_active_license
                                        }
                                        totalLicense={row && row.total_license}
                                      />
                                    </td>
                                    <td align="center" width="16%">
                                      {row.tags}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {this.props.schools &&
                        this.props.schools.length === 0 &&
                        loadingTable === false && (
                          <div className="table-no-record">
                            {translate('tableNoRecord')}
                          </div>
                        )}
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateNewFormModelBox
          isShowNewSchoolForm={this.state.isShowNewSchoolForm}
          handleNewSchoolModelClose={this.handleNewSchoolModelClose}
          onSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
          tags={tags}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.organizationOrganizationSchools.data,
  schools: state.organizationOrganizationSchools.schools,
  loading: state.organizationOrganizationSchools.loading,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationSchools: (props, page, orderBy, order) =>
    dispatch(getOrganizationSchools(props, page, orderBy, order)),
  storeSchool: (values, props) => dispatch(storeSchool(values, props)),
  resetForm: formName => dispatch(resetForm(formName)),
})

export default withRouter(
  withLocalize(connect(mapStateToProps, mapDispatchToProps)(Schools)),
)
