/* TODO: Fix eslint */
/* eslint-disable */

import isEmail from 'validator/lib/isEmail'
import moment from 'moment'

const validateEditOrganizationForm = values => {
  const errors = {}
  const numbers = /^[0-9]+$/
  const phoneRegex = /^\d{3}-\d{3}-\d{4}?$/

  // organization_name
  if (!values.organization_name) {
    errors.organization_name = [`Organization Name is required`]
  } else if (values.organization_name.length < 5) {
    errors.organization_name = [
      `Organization Name should be in between 5 to 100 characters in length`,
    ]
  } else if (values.organization_name.length > 100) {
    errors.organization_name = [
      `Organization Name should be in between 5 to 100 characters in length`,
    ]
  }

  // address_1
  if (!values.address_1) {
    errors.address_1 = [`Address Line 1 is required`]
  } else if (values.address_1.length < 5) {
    errors.address_1 = [
      `Address Line 1 should be in between 5 to 100 characters in length`,
    ]
  } else if (values.address_1.length > 100) {
    errors.address_1 = [
      `Address Line 1 should be in between 5 to 100 characters in length`,
    ]
  }

  // address_2
  if (values.address_2) {
    if (values.address_2.length < 2) {
      errors.address_2 = [
        `Address Line 2 should be in between 2 to 100 characters in length`,
      ]
    } else if (values.address_2.length > 100) {
      errors.address_2 = [
        `Address Line 2 should be in between 2 to 100 characters in length`,
      ]
    }
  }
  // address_3
  if (values.address_3) {
    if (values.address_3.length < 2) {
      errors.address_3 = [
        `Address Line 3 should be in between 2 to 100 characters in length`,
      ]
    } else if (values.address_3.length > 100) {
      errors.address_3 = [
        `Address Line 3 should be in between 2 to 100 characters in length`,
      ]
    }
  }

  // country_id
  if (!values.country_id) {
    errors.country_id = [`Country is required`]
  }

  // state
  if (!values.state) {
    errors.state = [`State is required`]
  } else if (values.state.length < 2) {
    errors.state = [`State should be in between 2 to 52 characters in length`]
  } else if (values.state.length > 50) {
    errors.state = [`State should be in between 2 to 50 characters in length`]
  }

  // city
  if (!values.city) {
    errors.city = [`City is required`]
  } else if (values.city.length < 2) {
    errors.city = [`City should be in between 2 to 50 characters in length`]
  } else if (values.city.length > 50) {
    errors.city = [`City should be in between 2 to 50 characters in length`]
  }

  // zip_code
  if (!values.zip_code) {
    errors.zip_code = [`Zip/Postal Code is required`]
  } else if (values.zip_code.length < 5) {
    errors.zip_code = [
      `Zip/Postal Code should be in between 5 to 10 characters in length`,
    ]
  } else if (values.zip_code.length > 10) {
    errors.zip_code = [
      `Zip/Postal Code should be in between 5 to 10 characters in length`,
    ]
  }

  // name
  if (!values.name) {
    errors.name = [`Contact Name is required`]
  } else if (values.name.length < 2) {
    errors.name = [`Contact Name be in between 2 to 50 characters in length`]
  } else if (values.name.length > 50) {
    errors.name = [`Contact Name be in between 2 to 50 characters in length`]
  }

  // email
  if (!values.email) {
    errors.email = [`Email address is required`]
  } else if (!isEmail(values.email)) {
    errors.email = [`Please provide valid email address`]
  } else if (values.email.length > 100) {
    errors.email = [
      `Email address should be less than 100 characters in length`,
    ]
  }

  // country_code_phone
  if (!values.phone_country_code || values.phone_country_code === null) {
    errors.phone = [`Country Code is required`]
  }

  // phone
  if (!values.phone) {
    // country_code_phone
    if (!values.phone_country_code || values.phone_country_code === null) {
      errors.phone = [`Country Code is required`]
    } else {
      errors.phone = [`Phone is required`]
    }
  } else {
    // if(!isNumeric(values.phone)){
    //    errors.phone = [`Phone should be integer`]
    // }else if(!values.phone.match(numbers)){
    //    errors.phone = [`Phone should be integer, no symbols are allowed`]
    // }else if (values.phone.length <8) {
    //   errors.phone = [`Phone should be in between 8 to 15 digit`]
    // }else if (values.phone.length > 15) {
    //   errors.phone = [`Phone should be in between 8 to 15 digit`]
    // }
    // if (!phoneRegex.test(values.phone)) {
    //   errors.phone = [`Phone should be xxx-xxx-xxxx format only`];
    // }else{
    //    const  phone  = values.phone.split('-');
    //    if(values.phone==='000-000-0000')
    //    {
    //      errors.phone = [`Phone should be xxx-xxx-xxxx format only`];
    //    }
    //    if(phone[0]==='000'){
    //      errors.phone = [`Phone should be xxx-xxx-xxxx format only`];
    //    }
    // }
  }

  // fax
  if (values.fax) {
    // country_code_phone
    if (!values.fax_country_code || values.fax_country_code === null) {
      errors.fax = [`Country Code is required`]
    }
  }

  // date_ranger
  if (!values.contract_start_date) {
    errors.contract_start_date = [`Start Date is required`]
  }
  if (!values.contract_end_date) {
    errors.contract_end_date = [`End Date is required`]
  }
  if (values.contract_start_date && values.contract_end_date) {
    const contract_start_date = moment(
      values.contract_start_date,
      'MM/DD/YYYY',
    ).format('YYYY-MM-DD')
    const contract_end_date = moment(
      values.contract_end_date,
      'MM/DD/YYYY',
    ).format('YYYY-MM-DD')
    if (contract_start_date === contract_end_date) {
      errors.contract_end_date = [`End Date must be greater than Start Date`]
    } else if (
      moment(contract_start_date).isAfter(contract_end_date) === true
    ) {
      errors.contract_start_date = [`Start Date must be less than End Date`]
    }
  }

  // total_license
  if (!values.total_license) {
    errors.total_license = [`License Count is required`]
  } else {
    // if(!isNaN(total_license)){
    if (isNaN(Number(values.total_license))) {
      errors.total_license = [`License Count should be integer`]
    } else if (!numbers.test(values.total_license)) {
      errors.total_license = [
        `License Count should be integer, no symbols are allowed`,
      ]
    } else if (values.total_license <= 0) {
      errors.total_license = [
        `License Count should not be less than or equal to zero`,
      ]
    } else if (values.total_license.length < 0) {
      errors.total_license = [`License Count should be in less than 8 digit`]
    } else if (parseInt(values.total_license) > 2147483647) {
      errors.total_license = [`License Count should be in less than 2147483647`]

      // }else if (values.total_license.length > 7) {
      //   errors.total_license = [`License Count should be in less than 8 digit`]
    }
  }

  if (values.document) {
    const fileExt = values.document[0].name.split('.').pop()
    if (fileExt.toLowerCase() !== 'pdf') {
      errors.document = [`Contract Upload should be only pdf file`]
    }
  }

  return errors
}

export default validateEditOrganizationForm
