import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {
  getAdminUserList,
  storeAdminUser,
  removeAdminUser,
  getAdminUserProfile,
} from 'store/actions/adminUser'
import UserList from 'pages/Home/Profile/SubAdminUser/UserList'
import EditProfile from 'pages/Home/Profile/EditProfile'
import NewUserForm from 'pages/Home/Profile/SubAdminUser/NewUserForm'
import config from 'config'

class Profile extends React.Component {
  constructor(props) {
    super(props)

    const { getAdminUserList, getAdminUserProfile } = props
    getAdminUserList()
    getAdminUserProfile()
  }

  handleCancel = async (adminUserId, name) => {
    const { removeAdminUser, getAdminUserList } = this.props
    confirmAlert({
      title: 'Are you sure?',
      message: `You want to remove ${name} as an administration?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await removeAdminUser(adminUserId)
            getAdminUserList()
          },
        },
        {
          label: 'No',
        },
      ],
    })
  }

  handleSubmit = async values => {
    const { storeAdminUser, getAdminUserList } = this.props
    await storeAdminUser(values)
    getAdminUserList()
  }

  render() {
    const { user, adminUserList } = this.props
    const columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
      },
      {
        name: 'Action',
        cell: row => (
          <button
            type="button"
            onClick={() => this.handleCancel(row.id, row.name)}
            className="btn btn-danger btn-sm"
          >
            Remove
          </button>
        ),
      },
    ]

    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">Edit Admin</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Admin</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="row">
            <div className="col-lg-5 col-md-12 pull-left">
              <EditProfile />
            </div>
            {user && user.is_super_admin === 1 && (
              <div className="col-lg-7 col-md-12 pull-right">
                <h4 className="card-title">Admin Accounts</h4>
                <div className="card" style={{}}>
                  <div className="card-content my_accounts">
                    <NewUserForm onSubmit={this.handleSubmit} />
                    <UserList columns={columns} data={adminUserList.users} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Profile.defaultProps = {
  user: {
    is_super_admin: false,
  },
}

Profile.propTypes = {
  user: PropTypes.shape({
    is_super_admin: PropTypes.bool,
  }),
  adminUserList: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  getAdminUserList: PropTypes.func.isRequired,
  getAdminUserProfile: PropTypes.func.isRequired,
  removeAdminUser: PropTypes.func.isRequired,
  storeAdminUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  adminUserList: state.adminUserList.data,
  user: state.adminUserProfile.data.row,
})

const mapDispatchToProps = dispatch => ({
  getAdminUserList: () => dispatch(getAdminUserList()),
  storeAdminUser: values => dispatch(storeAdminUser(values)),
  removeAdminUser: adminUserId => {
    dispatch(removeAdminUser(adminUserId))
  },
  getAdminUserProfile: () => dispatch(getAdminUserProfile()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
