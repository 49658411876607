import { REPORT_STORE } from '../actions/report'

const organizationStoreReportReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case REPORT_STORE:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationStoreReportReducer
