/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'

const RenderField = ({
  input,
  meta: { error, touched },
  type,
  placeholder,
  id,
  minlength,
  maxlength,
  disabled,
}) => (
  <Fragment>
    <input
      minLength={minlength}
      maxLength={maxlength}
      disabled={disabled}
      placeholder={placeholder}
      id={id}
      type={type}
      {...input}
      className={touched && error ? 'form-control square is-invalid' : 'form-control square'}
    />
    {touched &&
      error &&
      error.map((e, index) => (
        <span key={index} className="invalid-feedback">
          <strong>{e}</strong>
        </span>
      ))}
  </Fragment>
)

export default RenderField
