/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { reduxForm, Field } from 'redux-form'
import Loader from '../../../../../components/Loader'
import RenderField from '../../../../../components/RenderField'
import validateForm from './helpers'

class NewUserForm extends React.Component {
  render() {
    const { pristine, handleSubmit, submitting } = this.props

    return (
      <form className="form" onSubmit={handleSubmit} autoComplete="off">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="name">
              <span>*</span>
              Name
            </label>
            <Field name="name" component={RenderField} type="text" placeholder="Name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">
              <span>*</span>
              Email
            </label>
            <Field name="email" component={RenderField} type="text" placeholder="Email" />
          </div>
          <div className="form-group">
            <label htmlFor="verify_email">
              <span>*</span>
              Verify Email
            </label>
            <Field
              name="verify_email"
              component={RenderField}
              type="text"
              placeholder="Verify Email"
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
            {submitting ? <Loader /> : 'Add Admin'}
          </button>
        </div>
      </form>
    )
  }
}

export default reduxForm({
  form: 'new-admin-user-form',
  validate: validateForm,
})(NewUserForm)
