/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import Organization from '../Common/Organization'
import InstructorsTable from '../Tables/Instructors'
import Stats from './Stats'

/**
 * import translation related files
 *
 */

class Instructors extends React.Component {
  render() {
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row" />
        <div className="content-body">
          <Organization />
          <Stats />
          <InstructorsTable />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Instructors)
