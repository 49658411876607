/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import Principal from '../Common/Principal'
import Instructors from '../Tables/Instructors'
import Classes from '../Tables/Classes'

/**
 * import translation related files
 *
 */

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {}

  render() {
    const { translate } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row" />
        <div className="content-body">
          <Principal />
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <Instructors />
              <Classes />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
