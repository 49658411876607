import { GET_SCHOOL_DROPDOWN_LIST_BY_ORGANIZATION_ID } from '../actions/school'

const organizationSchoolDropdownReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_SCHOOL_DROPDOWN_LIST_BY_ORGANIZATION_ID:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

export default organizationSchoolDropdownReducer
