/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Chart from 'react-google-charts'
/**
 * import translation related files
 *
 */
import { getTranslate } from 'react-localize-redux'
import config from '../../../../config'

class PhonemeScores extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  render() {
    const {
      translate,
      handleChartClassPhonemeScoresWrapper,
      interval,
    } = this.props
    const data = []
    if (interval && interval.length > 0) {
      if (interval[0].total > 0) {
        const headerT = [
          { type: 'string', label: 'x' },
          { type: 'number', label: 'values' },
        ]
        if (this.props && this.props.data) {
          for (let i = 0; i < interval[0].total; i++) {
            headerT.push({ id: `i${i}`, type: 'number', role: 'interval' })
          }
        }
        data.push(headerT)

        for (const row of this.props.data) {
          const rowData = [`${row.phoneme_IPA}\n${row.phoneme_REG}`, row.score]
          const scores = row.scores.split(',')
          if (scores.length > 0) {
            let k = 0
            for (const score of scores) {
              rowData.push(score)
              k++
            }
            for (let m = k; m < interval[0].total; m++) {
              rowData.push(0)
            }
          }
          data.push(rowData)
        }
      }
    }
    return (
      <div className="row">
        <div className="col-12">
          <div className="card card-transparent">
            <div className="card-header bg-transparent pl-0">
              <h5 className="card-title text-bold-700">
                {translate('reportPhonemeScores')}
              </h5>
              <a className="heading-elements-toggle">
                <i className="la la-ellipsis-v font-medium-3" />
              </a>
            </div>
            <div className="card-content">
              <div className="table-responsive">
                {interval && interval.length > 0 && (
                  <Chart
                    width="100%"
                    height={400}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={data}
                    options={{
                      intervals: { style: 'sticks' },
                      legend: 'none',
                      chartArea: { width: '80%' },
                    }}
                    getChartWrapper={chartWrapper => {
                      handleChartClassPhonemeScoresWrapper(chartWrapper)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PhonemeScores)
/*

 data={[
                      [
                        { type: 'number', label: 'x' },
                        { type: 'number', label: 'values' },
                        { id: 'i0', type: 'number', role: 'interval' },
                        { id: 'i1', type: 'number', role: 'interval' },
                        { id: 'i2', type: 'number', role: 'interval' },
                        { id: 'i2', type: 'number', role: 'interval' },
                        { id: 'i2', type: 'number', role: 'interval' },
                        { id: 'i2', type: 'number', role: 'interval' },
                      ],
                      [1, 100, 90, 110, 85, 96, 104, 120],
                      [2, 120, 95, 130, 90, 113, 124, 140],
                      [3, 130, 105, 140, 100, 117, 133, 139],
                      [4, 90, 85, 95, 85, 88, 92, 95],
                      [5, 70, 74, 63, 67, 69, 70, 72],
                      [6, 30, 39, 22, 21, 28, 34, 40],
                      [7, 80, 77, 83, 70, 77, 85, 90],
                      [8, 100, 90, 110, 85, 95, 102, 110],

                      [9, 90, 85, 95, 85, 88, 92, 95],
                      [10, 70, 74, 63, 67, 69, 70, 72],
                      [11, 30, 39, 22, 21, 28, 34, 40],
                      [12, 80, 77, 83, 70, 77, 85, 90],
                      [13, 100, 90, 110, 85, 95, 102, 110],
                    ]}


                 data={[
                      [
                        { type: 'string', label: 'x' },
                        { type: 'number', label: 'values' },
                        { id: 'i0', type: 'number', role: 'interval' },
                        { id: 'i1', type: 'number', role: 'interval' },
                        { id: 'i2', type: 'number', role: 'interval' },
                        { id: 'i2', type: 'number', role: 'interval' },
                        { id: 'i2', type: 'number', role: 'interval' },
                        { id: 'i2', type: 'number', role: 'interval' },
                      ],
                      ['AA \n ɑ', 100, 90, 110, 85, 96, 104, 120],
                      ['AA R \n ɑɹ', 120, 95, 130, 90, 113, 124, 140],
                      ['AE \n æ', 130, 105, 140, 100, 117, 133, 139],
                      ['AH \n ə', 90, 85, 95, 85, 88, 92, 95],
                      ['AO \n ɔ', 70, 74, 63, 67, 69, 70, 72],
                      ['AO R \n ɔɹ', 30, 39, 22, 21, 28, 34, 40],
                      ['AW \n aʊ', 80, 77, 83, 70, 77, 85, 90],
                      ['AW R \n aʊɹ', 100, 90, 110, 85, 95, 102, 110],
                    ]}
          */
