/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import DataTable from 'react-data-table-component'

const UserList = ({ columns, data }) => (
  <div id="recent-projects" className="media-list position-relative">
    <div className="table-responsive sub-admin-list">
      <DataTable columns={columns} data={data} />
    </div>
  </div>
)
export default UserList
