/* TODO: Fix eslint */
/* eslint-disable */

import React, { Fragment } from 'react'
import { connect } from 'react-redux'

/**
 * import translation related files
 *
 */
import { getTranslate } from 'react-localize-redux'

class FileInput extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      input: { value: omitValue, onChange, onBlur, ...inputProps },
      meta: { error, touched, omitMeta },
      translate,
      ...props
    } = this.props
    return (
      <Fragment>
        <input
          onChange={onChange}
          {...inputProps}
          {...props}
          className={
            touched && error
              ? 'form-control square file-type is-invalid'
              : 'form-control square file-type '
          }
        />
        {touched &&
          error &&
          error.map((e, index) => (
            <span key={index} className="invalid-feedback">
              <strong>{e}</strong>
            </span>
          ))}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FileInput)
