/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert' // Import
import { getTranslate, withLocalize } from 'react-localize-redux'
import config from '../../../../../config'
import CreateForm from './CreateForm'
import { getPrincipalTags } from '../../../../../store/actions/tag'
import { storePrincipalReport } from '../../../../../store/actions/report'
import { getPrincipalProfile } from '../../../../../store/actions/principal'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

/**
 * import translation related files
 *
 */
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class CreateReport extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentDidMount() {
    if (this.props.user && this.props.user.is_admin_report_creation === 0) {
      this.props.history.push(`${config.principalUrl}/home`)
    }
  }

  componentWillMount() {
    const { match } = this.props
    this.props.getPrincipalTags(this.props)
    // this.props.getPrincipalProfile(this.props)
  }

  handleCancel = () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: () => {
            this.props.history.push(`${config.principalUrl}/reports`)
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  handleRedirect = () => {
    this.props.history.push(`${config.principalUrl}/reports`)
  }

  handleSubmit = async values => {
    const reportId = await this.props.storePrincipalReport(values, this.props)
    this.props.history.push(`${config.principalUrl}/report/${reportId}`)
  }

  render() {
    const { translate, row, tags, principalProfile } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              {translate('breadCrumbReportCreate')}
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.principalUrl}/home`}>
                      {translate('breadCrumbHome')}
                    </Link>{' '}
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`${config.principalUrl}/reports`}>
                      {translate('breadCrumbReport')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {translate('breadCrumbReportCreate')}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <CreateForm
            tags={tags}
            schools={principalProfile}
            onSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
            handleRedirect={this.handleRedirect}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  tags: state.principalTags.data.tags,
  principalProfile: state.principalProfile.data.row,
  user: state.authPrincipal.user,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalTags: props => dispatch(getPrincipalTags(props)),
  // getPrincipalProfile: (props) => dispatch(getPrincipalProfile(props)),
  storePrincipalReport: (values, props) =>
    dispatch(storePrincipalReport(values, props)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(CreateReport),
)
