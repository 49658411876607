/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm, Field } from 'redux-form'
import { getTranslate } from 'react-localize-redux'
import config from '../../../../../config'
import {
  getOrganizationPrincipalDetailByprincipalId,
  updatePrincipal,
  deletePrincipal,
} from '../../../../../store/actions/principal'
import { getOrganizationTags } from '../../../../../store/actions/tag'
import RenderCheckBox from '../../../../../components/RenderCheckBox'

class CreateReportByTags extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.getOrganizationTags(this.props)
  }

  render() {
    const { translate, tags, checked, handleCheck } = this.props
    return (
      <div className="card" style={{}}>
        <div className="card-content">
          <div
            id="createReports"
            className="cr_reports media-list position-relative"
          >
            <h5 className="card-title text-bold-700 my-2">
              {translate('reportTableTitleTag')}
              <Link className="add_btn pull-right" to="#">
                <i className="fa fa-question" />
              </Link>
            </h5>
            {tags && (
              <Field
                type="checkbox"
                name="tag_ids"
                label="Weekdays"
                component={RenderCheckBox}
                tags={tags}
                formName="organization-create-report"
                fieldName="tag_ids"
              />
            )}
            <Link
              type="button"
              className="btn btn-primary"
              to={`${config.organizationUrl}/create-report`}
            >
              {translate('btnReportCreate')}
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

CreateReportByTags = reduxForm({
  form: 'organization-create-report-tag',
  enableReinitialize: true,
})(CreateReportByTags)

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  tags: state.organizationOrganizationTags.data.tags,
})

const mapDispatchToProps = dispatch => ({
  getOrganizationTags: props => dispatch(getOrganizationTags(props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateReportByTags)
