import {
  GET_ORGANIZATION_PRINCIPAL_LIST,
  GET_ORGANIZATION_PRINCIPAL_LOADING,
  GET_ORGANIZATION_PRINCIPAL_LOADED,
} from 'store/actions/principal'

const initialState = {
  principal: [],
}

const organizationOrganizationPrincipalReducer = (
  state = initialState,
  action = {},
) => {
  const newState = state
  switch (action.type) {
    case GET_ORGANIZATION_PRINCIPAL_LIST:
      if (action.payload.currentPage === 0) {
        newState.principal = action.payload.principal
        return {
          principal: newState.principal,
          ...newState,
          data: action.payload,
        }
      }
      newState.principal = action.payload.principal
      return {
        principal: newState.principal,
        ...newState,
        data: action.payload,
      }

    case GET_ORGANIZATION_PRINCIPAL_LOADING:
      return {
        ...newState,
        loading: true,
      }
    case GET_ORGANIZATION_PRINCIPAL_LOADED:
      return {
        ...newState,
        loading: false,
      }
    default:
      return newState
  }
}
export default organizationOrganizationPrincipalReducer
