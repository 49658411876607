/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert' // Import
import { withLocalize, getTranslate } from 'react-localize-redux'
import config from '../../../../../config'
import Principal from '../../Common/Principal'
import Detail from './Detail'
import InstructorsClasses from '../../Tables/InstructorsClasses'
import { dispatchFormField, resetForm } from '../../../../../store/actions/form'
import {
  getPrincipalInstructorDetailByinstructorId,
  updateInstructor,
} from '../../../../../store/actions/instructors'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import EditFormModelBox from '../EditFormModelBox'

import instructorImg from '../../../../../app-assets/images/instructor.png'
/**
 * import translation related files
 *
 */
import englishTablesTranslations from '../../../../../lang/organization/tables/en.json'
import spanishTablesTranslations from '../../../../../lang/organization/tables/es.json'
import chineseTablesTranslations from '../../../../../lang/organization/tables/zh.json'
import englishFormTranslations from '../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../lang/organization/form/zh.json'

class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowNewInstructorForm: false,
      files: null,
      imagePreviewUrl: instructorImg,
      instructorId: '',
    }
    this.props.addTranslationForLanguage(englishTablesTranslations, 'en')
    this.props.addTranslationForLanguage(spanishTablesTranslations, 'es')
    this.props.addTranslationForLanguage(chineseTablesTranslations, 'zh')
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  componentWillMount() {
    const { match } = this.props
    const instructorId = match.params.iid
    if (instructorId === ' ' || instructorId === 'undefined') {
      this.props.history.push(`${config.principalUrl}/home`)
    }
    this.setState({ instructorId })
    this.props.getPrincipalInstructorDetailByinstructorId(
      instructorId,
      this.props,
    )
  }

  handleNewInstructorModelShow = () => {
    this.setState({
      isShowNewInstructorForm: true,
      files: null,
      imagePreviewUrl: instructorImg,
    })
    this.props.dispatchFormField('principal-edit-instructor', 'image', null)
    const { row } = this.props
    if (row) {
      if (row.row.photo_path !== null) {
        const imageObj = JSON.parse(row.row.photo_path)
        if (imageObj) {
          if (imageObj.key && imageObj.type) {
            const url = `${config.apiPrincipalUrl}/image/${imageObj.key}`
            this.setState({ imagePreviewUrl: url })
          }
        }
      }
    }
  }

  handleNewInstructorModelClose = () => {
    this.props.resetForm('principal-edit-instructor')
    this.setState({
      isShowNewInstructorForm: false,
    })
  }

  handleSubmit = async values => {
    const file = this.state.files
    const instructorId = await this.props.updateInstructor(
      this.state.instructorId,
      values,
      file,
      this.props,
    )
    this.props.getPrincipalInstructorDetailByinstructorId(
      instructorId,
      this.props,
    )
    this.setState({ isShowNewInstructorForm: false })
    return this.props.history.push(
      `${config.principalUrl}/instructor/${instructorId}`,
    )
  }

  handleCancel = async () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: async () => {
            this.props.resetForm('principal-edit-instructor')
            this.setState({ isShowNewInstructorForm: false })
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  handleInputChange = e => {
    e.preventDefault()
    const { files } = e.target
    const allFiles = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // Make new FileReader
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        }
        this.setState({ files: fileInfo })
        this.setState({
          imagePreviewUrl: reader.result,
        })
        this.props.dispatchFormField(
          'principal-edit-instructor',
          'image',
          fileInfo,
        )
      } // reader.onload
    } // for
  }

  render() {
    const { translate, row } = this.props
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-4 col-12 mb-2">
              <h3 className="content-header-title">
                {translate('breadCrumbInstructorDetail')}
              </h3>
            </div>
            <div className="content-header-right col-md-8 col-12">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`${config.principalUrl}/home`}>
                        {translate('breadCrumbHome')}
                      </Link>{' '}
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`${config.principalUrl}/instructors`}>
                        {translate('breadCrumbInstructor')}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {translate('breadCrumbInstructorDetail')}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <Principal />
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-md-12 col-lg-12 pull-left">
                    <Detail
                      row={row.row}
                      handleNewInstructorModelShow={
                        this.handleNewInstructorModelShow
                      }
                    />
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            </div>
            <InstructorsClasses instructorId={this.state.instructorId} />
          </div>
        </div>
        <EditFormModelBox
          isShowNewInstructorForm={this.state.isShowNewInstructorForm}
          handleNewInstructorModelClose={this.handleNewInstructorModelClose}
          onSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
          handleInputChange={this.handleInputChange}
          imagePreviewUrl={this.state.imagePreviewUrl}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  row: state.principalInstructorDetail.data,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalInstructorDetailByinstructorId: (instructorId, props) =>
    dispatch(getPrincipalInstructorDetailByinstructorId(instructorId, props)),
  updateInstructor: (instructorId, values, file, props) =>
    dispatch(updateInstructor(instructorId, values, file, props)),
  resetForm: formName => dispatch(resetForm(formName)),
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Details),
)
