import React from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import LoadingOverlay from 'react-loading-overlay'
import config from 'config'
import { getStats } from 'store/actions/organizations'

class OrganizationStats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }

    const { getStats } = props
    getStats()
  }

  render() {
    const { stats, loading: loadingTable } = this.props
    return (
      <div className="row">
        <div className="col-md-12 col-lg-12 pull-left">
          <div className="card">
            <LoadingOverlay active={loadingTable} spinner text={`${config.loadingOverlayText}`}>
              <div className="card-header p-1">
                <h4 className="card-title">
                  FluentWorlds Total Stats
                </h4>
              </div>
              <div className="card-content collapse show">
                <div className="card-footer text-center p-1">
                  <div className="row">
                    <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                      <p className="blue-grey lighten-2 mb-0">Total Organizations</p>
                      <p className="font-medium-5 text-bold-400">
                        <NumberFormat
                          thousandSeparator
                          value={Number(stats.total_organizations)}
                          displayType="text"
                        />
                      </p>
                    </div>
                    <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                      <p className="blue-grey lighten-2 mb-0">Total Classes</p>
                      <p className="font-medium-5 text-bold-400">
                        <NumberFormat
                          thousandSeparator
                          value={Number(stats.total_classes)}
                          displayType="text"
                        />
                      </p>
                    </div>
                    <div className="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                      <p className="blue-grey lighten-2 mb-0">Total Teachers</p>
                      <p className="font-medium-5 text-bold-400">
                        <NumberFormat
                          thousandSeparator
                          value={Number(stats.total_teachers)}
                          displayType="text"
                        />
                      </p>
                    </div>
                    <div className="col-md-3 col-12 text-center">
                      <p className="blue-grey lighten-2 mb-0">Total Licenses</p>
                      <p className="font-medium-5 text-bold-400">
                        <NumberFormat
                          thousandSeparator
                          value={Number(stats.total_licenses)}
                          displayType="text"
                        />
                      </p>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  stats: state.stats.data,
  loading: state.stats.loading,
})

const mapDispatchToProps = dispatch => ({
  getStats: () => {
    dispatch(getStats())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationStats)
