import axios from 'axios'
import config from '../../config'
import handleResponse from './handleResponse'
import handleResponseTranslate from './handleResponseTranslate'
import handleResponsePrincipalTranslate from './handleResponsePrincipalTranslate'

/**
 * Define actions
 *
 */
export const GET_TAG_LIST_BY_ORGANIZATION_ID = 'GET_TAG_LIST_BY_ORGANIZATION_ID'
export const GET_ORGANIZATION_TAG_LIST = 'GET_ORGANIZATION_TAG_LIST'
export const GET_PRINCIPAL_TAG_LIST = 'GET_PRINCIPAL_TAG_LIST'

/**
 * To get tags of a organization
 *
 * @param  int organizationId
 * @return data
 */
export const getTagsByOrganizationId = organizationId => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().auth.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiUrl}/organization/${organizationId}/tag-list`,
      headers,
    )
    dispatch({
      type: GET_TAG_LIST_BY_ORGANIZATION_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponse(error, 3)
  }
}

/**
 * To get tags of a organization for organization
 *
 * @param  object  props
 * @return data
 */
export const getOrganizationTags = props => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/tag-list`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_TAG_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get tags of a principal for principal
 *
 * @param  object  props
 * @return data
 */
export const getPrincipalTags = props => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiPrincipalUrl}/tag-list`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_TAG_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}
