/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { withLocalize, getTranslate } from 'react-localize-redux'
import Loader from '../../../../../../../components/Loader'
import RenderField from '../../../../../../../components/RenderFieldTranlate'
import config from '../../../../../../../config'
import validateForm from './helpers'

/**
 * import translation related files
 *
 */
import englishFormTranslations from '../../../../../../../lang/organization/form/en.json'
import spanishFormTranslations from '../../../../../../../lang/organization/form/es.json'
import chineseFormTranslations from '../../../../../../../lang/organization/form/zh.json'

class ChangePasswordModelBox extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishFormTranslations, 'en')
    this.props.addTranslationForLanguage(spanishFormTranslations, 'es')
    this.props.addTranslationForLanguage(chineseFormTranslations, 'zh')
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      error,
      reset,
      handleCancel,
      isShowChangePassword,
      handleChangePasswordModelClose,
      translate,
    } = this.props

    return (
      <Modal
        show={isShowChangePassword}
        onHide={handleChangePasswordModelClose}
        animation={false}
        id="changePassword"
        dialogClassName=""
        aria-labelledby="basicModalLabel1"
      >
        <div className="modal-content">
          <form
            className="form-horizontal"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="modal-header">
              <h4 className="modal-title" id="basicModalLabel1">
                {translate('formChangePasswordTitle')}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={handleChangePasswordModelClose}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="password">
                  <span>*</span>
                  {translate('formChangePassworFieldNewPassword')}
                </label>
                <Field
                  type="password"
                  className="form-control round"
                  placeholder={translate('formChangePassworFieldNewPassword')}
                  component={RenderField}
                  name="password"
                  id="password"
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirm_password">
                  <span>*</span>
                  {translate('formChangePassworFieldConfirmPassword')}
                </label>
                <Field
                  type="password"
                  className="form-control round"
                  placeholder={translate(
                    'formChangePassworFieldConfirmPassword',
                  )}
                  component={RenderField}
                  name="confirm_password"
                  id="confirm_password"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={
                  pristine || submitting
                    ? handleChangePasswordModelClose
                    : handleCancel
                }
              >
                {translate('formChangePassworBtnCancel')}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {submitting ? (
                  <Loader />
                ) : (
                  translate('formChangePassworBtnSave')
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

ChangePasswordModelBox = withLocalize(
  reduxForm({
    form: 'teacher-student-change-password',
    validate: validateForm,
    enableReinitialize: true,
  })(ChangePasswordModelBox),
)

ChangePasswordModelBox = connect(state => ({
  translate: getTranslate(state.localize),
}))(ChangePasswordModelBox)

export default ChangePasswordModelBox
