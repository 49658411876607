/* TODO: Fix eslint */
/* eslint-disable */

import axios from 'axios'
import { reset } from 'redux-form'
import config from '../../config'
import handleResponseTranslate from './handleResponseTranslate'
/**
 * Define actions
 *
 */
export const GET_ORGANIZATION_USER_PROFILE = 'GET_ORGANIZATION_USER_PROFILE'
export const GET_ORGANIZATION_USER_LIST = 'GET_ORGANIZATION_USER_LIST'
export const ORGANIZATION_USER_STORE = 'ORGANIZATION_USER_STORE'
export const ORGANIZATION_USER_PROFILE_UPDATE =
  'ORGANIZATION_USER_PROFILE_UPDATE'
export const REMOVE_ORGANIZATION_USER = 'REMOVE_ORGANIZATION_USER'
export const ORGANIZATION_CHANGE_PASSWORD = 'ORGANIZATION_CHANGE_PASSWORD'

/**
 * To get organization users
 *
 * @param  object  props
 * @return data
 */
export const getOrganizationUserList = props => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/user`,
      headers,
    )

    dispatch({
      type: GET_ORGANIZATION_USER_LIST,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get organization user profile details
 *
 * @param  object  props
 * @return data
 */
export const getOrganizationUserProfile = props => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/user/profile`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_USER_PROFILE,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To store an organization user
 *
 * @param  object values
 * @param  object  props
 */
export const storeOrganizationUser = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const adminUserValues = {
      name: values.name,
      email: values.email,
      verify_email: values.verify_email,
    }
    const response = await axios.post(
      `${config.apiOrganizationUrl}/user`,
      adminUserValues,
      headers,
    )
    dispatch({
      type: ORGANIZATION_USER_STORE,
      payload: response.data.result,
    })
    dispatch(reset('organization-sub-admin-form'))
    // return response.data.result.data.id;
  } catch (error) {
    await handleResponseTranslate(error, props, 1)
  }
}

/**
 * To update an organization user user profile
 *
 * @param  object values
 * @param  object  props
 */
export const updateOrganizationProfile = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const adminUserValues = {
      name: values.name,
      email: values.email,
    }
    const response = await axios.put(
      `${config.apiOrganizationUrl}`,
      adminUserValues,
      headers,
    )
    dispatch({
      type: ORGANIZATION_USER_PROFILE_UPDATE,
      payload: response.data.result.data,
    })

    return response.data.result.data.id
  } catch (error) {
    await handleResponseTranslate(error, props, 1)
  }
}

/**
 * To remove an organization user
 *
 * @param  int organizationUserId
 * @param  object  props
 */
export const removeOrganizationUser = (organizationUserId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.delete(
      `${config.apiOrganizationUrl}/user/${organizationUserId}`,
      headers,
    )

    dispatch({
      type: REMOVE_ORGANIZATION_USER,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To update password of an organization user
 *
 * @param  object values
 * @param  object  props
 */
export const updateOrganizationPassword = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const userValues = {
      current_password: values.current_password,
      password: values.password,
      confirm_password: values.confirm_password,
    }
    const response = await axios.put(
      `${config.apiOrganizationUrl}/user/change-password`,
      userValues,
      headers,
    )

    dispatch({
      type: ORGANIZATION_CHANGE_PASSWORD,
      payload: response.data.result,
    })
    dispatch(reset('organization-change-password'))
    return response.data.result.data.id
  } catch (error) {
    await handleResponseTranslate(error, props, 1)
  }
}
