/* TODO: Fix eslint */
/* eslint-disable */

import isEquals from 'validator/lib/equals'

const validateForm = (values, props) => {
  const errors = {}
  const regexpWithoutSpace = /^\S*$/
  // current_password
  if (!values.current_password) {
    errors.current_password = [
      `errorMsgPrincipalServiceChangePasswordCurrentPasswordMissing`,
    ]
  } else {
    // if (!regexpWithoutSpace.test(values.current_password)) {
    //  // errors.current_password = [`${props.translate("errorMsgUserServiceChangePasswordCurrentPasswordMissing")}`];
    // }
  }

  // password
  if (!values.password) {
    errors.password = [`errorMsgPrincipalServiceChangePasswordPasswordMissing`]
  } else if (values.password.length < 5) {
    errors.password = [`errorMsgPrincipalServiceChangePasswordPasswordIsLength`]
  } else if (values.password.length > 20) {
    errors.password = [`errorMsgPrincipalServiceChangePasswordPasswordIsLength`]
  }

  if (values.current_password && values.password) {
    if (isEquals(values.current_password, values.password)) {
      errors.password = [`errorMsgPrincipalServiceChangePasswordPasswordEqual`]
    }
  }
  // confirm_password

  if (values.password) {
    if (!values.confirm_password) {
      errors.confirm_password = [
        `errorMsgPrincipalServiceChangePasswordConfirmPasswordMissing`,
      ]
    } else if (!isEquals(values.confirm_password, values.password)) {
      errors.confirm_password = [
        `errorMsgPrincipalServiceChangePasswordConfirmPasswordNotMatched`,
      ]
    }
  }
  return errors
}

export default validateForm
