/* TODO: Fix eslint */
/* eslint-disable */

/** images * */

import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import logo from '../../../../app-assets/images/logo/fluent-world-logo.png'
import Loader from '../../../../components/Loader'
import RenderField from '../../../../components/RenderField'
import validateRecoverPasswordForm from './helpers'
import config from '../../../../config'

const RecoverPasswordForm = ({
  pristine,
  handleSubmit,
  submitting,
  error,
  emailField,
  successMessage,
}) => {
  return (
    <React.Fragment>
      <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
        <div className="card-header border-0">
          <div className="text-center mb-1">
            <Link to={`${config.adminUrl}/login`}>
              <img src={logo} alt="branding logo" width="60%;" />
            </Link>
          </div>
          <div className="font-large-1  text-center">Recover Password</div>
        </div>
        <div className="card-content">
          {emailField === true && (
            <div className="card-body">
              <form className="form-horizontal resetPassword" onSubmit={handleSubmit}>
                <fieldset className="form-group position-relative has-icon-left">
                  <Field
                    name="email"
                    component={RenderField}
                    type="text"
                    placeholder="Your Email"
                  />
                  <div className="form-control-position">
                    <i className="ft-mail" />
                  </div>
                </fieldset>
                <h6 className="card-subtitle f-p-detail text-muted text-center font-small-3">
                  {/* } <span>We will send you new password.</span> { */}
                </h6>
                <div className="form-group text-center">
                  {error && <h4 className="text-danger font-weight-bold mb-2">{error}</h4>}
                  <button
                    type="submit"
                    className="btn round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1"
                    disabled={pristine || submitting}
                  >
                    {' '}
                    {submitting ? <Loader /> : 'Submit'}{' '}
                  </button>
                </div>
              </form>
            </div>
          )}
          {emailField === false && (
            <div className="card-body">
              <div className="email_send f-p-detail text-muted text-center font-small-3">
                {successMessage}
              </div>
              <div className="form-group text-center">
                <Link
                  to={`${config.adminUrl}/login`}
                  className="btn round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1"
                >
                  Return to Login
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default reduxForm({
  form: 'recover-password',
  validate: validateRecoverPasswordForm,
})(RecoverPasswordForm)
