import isEmail from 'validator/lib/isEmail'

const validateForm = values => {
  const errors = {}
  // name
  if (!values.name) {
    errors.name = [`errorMsgInstructorServiceUpdateNameMissing`]
  } else if (values.name.length < 2) {
    errors.name = [`errorMsgInstructorServiceUpdateNameIsLength`]
  } else if (values.name.length > 50) {
    errors.name = [`errorMsgInstructorServiceUpdateNameIsLength`]
  }

  // email
  if (!values.email) {
    errors.email = [`errorMsgInstructorServiceUpdateEmailMissing`]
  } else if (!isEmail(values.email)) {
    errors.email = [`errorMsgInstructorServiceUpdateEmailIsEmail`]
  } else if (values.email.length > 100) {
    errors.email = [`errorMsgInstructorServiceUpdateEmailIsLength`]
  }

  // bio
  if (values.bio) {
    if (values.bio.length > 500) {
      errors.bio = [`errorMsgInstructorServiceBioMissing`]
    }
  }

  return errors
}

export default validateForm
