import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Link, withRouter } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { getTeacherClasses } from 'store/actions/class'
import config from 'config'
import TableRowDateProgressBar from 'components/TableRowDateProgressBar'
import TableRowLicenseProgressBar from 'components/TableRowLicenseProgressBar'
import DateTimeWithTimezone from 'components/DateTimeWithTimezone'

import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'

class Classes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      counter: 0,
      orderBy: 'id',
      order: 'DESC',
    }
    const { addTranslationForLanguage, getTeacherClasses } = this.props
    const { page, orderBy, order } = this.state
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
    getTeacherClasses(this.props, page, orderBy, order)
  }

  componentDidMount() {
    const { page } = this.state
    this.setState({ page: page + 1 })
  }

  handlePageChange = async e => {
    const { data, getTeacherClasses } = this.props
    const { page, orderBy, order, counter } = this.state
    const element = e.target
    if (element.scrollTop > 0) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState({ page: page + 1 })
        if (data.totalPage > page) {
          this.setState({ counter: counter + 1 })
          await getTeacherClasses(this.props, page, orderBy, order)
        }
      }
    }
  }

  handleSorting = async field => {
    const { getTeacherClasses } = this.props
    const { orderBy, order: stateOrder } = this.state
    let order = 'ASC'
    if (orderBy === field) {
      order = stateOrder === 'DESC' ? 'ASC' : 'DESC'
    }
    this.setState({ orderBy: field, order })
    await getTeacherClasses(this.props, 0, field, order)
    this.setState({ page: 1 })
  }

  render() {
    const { translate, loading, data, classes } = this.props
    const { order, orderBy } = this.state
    const sortClass = order === 'ASC' ? 'ascending' : 'desending'
    return (
      <React.Fragment>
        <div className="row match-height">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <h5 className="card-title text-bold-700 my-2">
              {translate('classTableTitle')}
            </h5>
            <div className="card" style={{}}>
              <div
                className={
                  data && data.totalPage > 0 ? 'card-content ' : 'card-content'
                }
              >
                <div
                  id="recent-projects"
                  className="media-list position-relative"
                  onScroll={this.handlePageChange.bind(this)}
                >
                  <LoadingOverlay
                    active={loading}
                    spinner
                    text={translate('loadingOverlayText')}
                  >
                    <div
                      className={
                        classes.length === 0
                          ? 'table-responsive table-height'
                          : 'table-responsive'
                      }
                    >
                      {classes && classes.length > 0 && (
                        <div id="tableContainer" className="tableContainer">
                          <table
                            className="scrollTable classes-list-teacher"
                            width="100%"
                            cellSpacing={0}
                            cellPadding={0}
                            border={0}
                          >
                            <thead className="fixedHeader">
                              <tr>
                                <th
                                  align="left"
                                  width="20%"
                                  onClick={() => this.handleSorting('name')}
                                  className={
                                    orderBy === 'name' ? sortClass : ''
                                  }
                                >
                                  {translate('classTableRowName')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() =>
                                    this.handleSorting('total_active_license')
                                  }
                                  className={
                                    orderBy === 'total_active_license'
                                      ? sortClass
                                      : ''
                                  }
                                >
                                  {translate('classTableRowLicenses')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() =>
                                    this.handleSorting('start_date')
                                  }
                                  className={
                                    orderBy === 'start_date' ? sortClass : ''
                                  }
                                >
                                  {translate('classTableRowStart')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() => this.handleSorting('status')}
                                  className={
                                    orderBy === 'status' ? sortClass : ''
                                  }
                                >
                                  {translate('classTableRowStatus')}
                                  <i className="fas fa-sort-up" />
                                </th>
                                <th
                                  align="center"
                                  width="20%"
                                  onClick={() => this.handleSorting('tags')}
                                  className={
                                    orderBy === 'tags' ? sortClass : ''
                                  }
                                >
                                  {translate('classTableRowTags')}
                                  <i className="fas fa-sort-up" />
                                </th>
                              </tr>
                            </thead>
                            <tbody className="scrollContent">
                              {classes &&
                                classes.length > 0 &&
                                classes.map(row => (
                                  <tr key={`class-${row.id}`}>
                                    <td align="left" width="20%">
                                      <Link
                                        to={`${config.teacherUrl}/class/${row.id}`}
                                      >
                                        {row.name}
                                      </Link>
                                    </td>
                                    <td align="center" width="20%">
                                      <span className="text-center">
                                        <NumberFormat
                                          thousandSeparator
                                          value={Number(
                                            row.total_active_license,
                                          )}
                                          displayType="text"
                                        />{' '}
                                        /{' '}
                                        <NumberFormat
                                          thousandSeparator
                                          value={Number(row.total_license)}
                                          displayType="text"
                                        />
                                      </span>
                                      <TableRowLicenseProgressBar
                                        activeLicense={
                                          row && row.total_active_license
                                        }
                                        totalLicense={row && row.total_license}
                                      />
                                    </td>
                                    <td align="center" width="20%">
                                      <span className="text-center">
                                        <DateTimeWithTimezone
                                          dateTime={row.start_date}
                                          isTime="false"
                                          isRed="false"
                                        />{' '}
                                        -{' '}
                                        <DateTimeWithTimezone
                                          dateTime={row.end_date}
                                          isTime="false"
                                          isRed="true"
                                          start_date={row.start_date}
                                          end_date={row.end_date}
                                        />
                                      </span>
                                      <TableRowDateProgressBar
                                        start_date={row.start_date}
                                        end_date={row.end_date}
                                      />
                                    </td>
                                    <td align="center" width="20%">
                                      <span
                                        className={
                                          row.status === 'Active'
                                            ? 'status_approved'
                                            : 'status_pending'
                                        }
                                      >
                                        {row.status}
                                      </span>
                                    </td>
                                    <td align="center" width="20%">
                                      {row.tags}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {classes && classes.length === 0 && loading === false && (
                        <div className="table-no-record">
                          {translate('tableNoRecord')}
                        </div>
                      )}
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Classes.defaultProps = {
  loading: false,
  data: {},
}

Classes.propTypes = {
  addTranslationForLanguage: PropTypes.func.isRequired,
  getTeacherClasses: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  data: PropTypes.shape({
    totalPage: PropTypes.number,
  }),
  classes: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  data: state.teacherClasses.data,
  classes: state.teacherClasses.classes,
  loading: state.teacherClasses.loading,
})

const mapDispatchToProps = dispatch => ({
  getTeacherClasses: (props, page, orderBy, order) =>
    dispatch(getTeacherClasses(props, page, orderBy, order)),
})

export default withRouter(
  withLocalize(connect(mapStateToProps, mapDispatchToProps)(Classes)),
)
