/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import Moment from 'react-moment'
import { confirmAlert } from 'react-confirm-alert' // Import
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { withLocalize, getTranslate } from 'react-localize-redux'
import {
  getPrincipalProfile,
  getStats,
  updatePrincipalProfile,
} from '../../../../../store/actions/principal'
import config from '../../../../../config'
import EditProfileModelBox from '../../Profile/EditProfileModelBox'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { resetForm } from '../../../../../store/actions/form'
import { dispatchFormField } from '../../../../../store/actions/form'

/**
 * import translation related files
 *
 */
import englishStatsTranslations from '../../../../../lang/organization/stats/en.json'
import spanishStatsTranslations from '../../../../../lang/organization/stats/es.json'
import chineseStatsTranslations from '../../../../../lang/organization/stats/zh.json'

class Principal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowEditProfile: false,
      loading: false,
    }
    this.props.addTranslationForLanguage(englishStatsTranslations, 'en')
    this.props.addTranslationForLanguage(spanishStatsTranslations, 'es')
    this.props.addTranslationForLanguage(chineseStatsTranslations, 'zh')
  }

  componentWillMount() {
    this.setState({ loading: true })
    this.props.getPrincipalProfile(this.props)
    this.props.getStats(this.props)
  }

  handleCancel = () => {
    confirmAlert({
      title: this.props.translate('alertHeading'),
      message: this.props.translate('alertFormText'),
      buttons: [
        {
          label: this.props.translate('btnYes'),
          onClick: () => {
            this.props.resetForm('principal-change-profile')
            this.setState({ isShowChangePassword: false })
            this.setState({ isShowEditProfile: false })
          },
        },
        {
          label: this.props.translate('btnNo'),
        },
      ],
    })
  }

  handleEditProfileModelShow = () => {
    this.props.resetForm('principal-change-profile')
    this.setState({
      isShowEditProfile: true,
    })
  }

  handleEditProfileModelClose = () => {
    this.props.resetForm('principal-change-profile')
    this.setState({
      isShowEditProfile: false,
    })
  }

  handleSubmitEditProfile = async values => {
    // console.log("handleSubmitEditProfile");
    await this.props.updatePrincipalProfile(values, this.props)
    await this.props.getPrincipalProfile(this.props)
    this.props.resetForm('principal-change-profile')
    this.setState({ isShowEditProfile: false })
  }

  render() {
    const { principalRow, row, translate } = this.props
    let loadingTable = this.state.loading
    loadingTable = this.props.loading
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 col-md-12 mrg-btn-2">
            <LoadingOverlay
              active={loadingTable}
              spinner
              text={`${config.loadingOverlayText}`}
            >
              <div className="card display_card ins_pri_card">
                <Link
                  className="add_btn pull-right"
                  to="#"
                  onClick={this.handleEditProfileModelShow}
                >
                  {translate('btnEdit')}
                </Link>
                <div className="col-6 pull-left">
                  <div className="card-header">
                    <h4 className="org-id">
                      {translate('id')}:{principalRow && principalRow.id}
                    </h4>
                    <h4 className="card-title">
                      {principalRow && principalRow.name}
                    </h4>
                  </div>
                  <div className="card-content collapse show">
                    <div className="card-body pb-0 card_margin">
                      <h6 className="contact_heading">{translate('email')}</h6>
                      <ul className="contact_informations">
                        <li>{principalRow && principalRow.email}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-6 pull-right">
                  <div className="card-content collapse show">
                    <div className="card-body pb-0 card_margin">
                      <h6 className="contact_heading">{translate('school')}</h6>
                      <ul className="contact_informations">
                        <li>{principalRow && principalRow.school_name}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 principal-license text-left">
                    <p className="contact_heading">
                      {translate('licenses')} /{translate('activated')} :{' '}
                      <span>
                        <NumberFormat
                          thousandSeparator
                          value={
                            row && row.totalActiveLicense
                              ? row.totalActiveLicense
                              : 0
                          }
                          displayType="text"
                        />{' '}
                        /{' '}
                        <NumberFormat
                          thousandSeparator
                          value={
                            row && row.total_licenses ? row.total_licenses : 0
                          }
                          displayType="text"
                        />
                      </span>
                    </p>
                    <div className="progress progress-sm mb-0 box-shadow-2">
                      <div
                        className="progress-bar bg-gradient-x-info"
                        role="progressbar"
                        style={{
                          width: `${row &&
                            parseInt(
                              (row.totalActiveLicense / row.total_licenses) *
                                100,
                            )}%`,
                          backgroundImage: `${
                            row &&
                            (row.totalActiveLicense / row.total_licenses) *
                              100 >=
                              90
                              ? 'linear-gradient(to right, #ec6b6b 0%, #ff4747 100%)'
                              : ''
                          }`,
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </LoadingOverlay>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12 stats_principal">
            <div className="card principal-card">
              <LoadingOverlay
                active={loadingTable}
                spinner
                text={`${config.loadingOverlayText}`}
              >
                <div className="card-header p-1">
                  <h4 className="card-title">{translate('totalStats')}</h4>
                </div>
                <div className="card-content collapse show">
                  <div className="card-footer text-center p-1">
                    <div className="row">
                      <div className="col-md-4 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                        <p className="blue-grey lighten-2 mb-0">
                          {translate('totalTeachers')}
                        </p>
                        <p className="font-medium-5 text-bold-400">
                          <NumberFormat
                            thousandSeparator
                            value={
                              row && row.total_teachers ? row.total_teachers : 0
                            }
                            displayType="text"
                          />
                        </p>
                      </div>
                      <div className="col-md-4 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                        <p className="blue-grey lighten-2 mb-0">
                          {translate('totalClasses')}
                        </p>
                        <p className="font-medium-5 text-bold-400">
                          <NumberFormat
                            thousandSeparator
                            value={
                              row && row.total_classes ? row.total_classes : 0
                            }
                            displayType="text"
                          />
                        </p>
                      </div>
                      <div className="col-md-4 col-12 text-center">
                        <p className="blue-grey lighten-2 mb-0">
                          {translate('totalStudents')}
                        </p>
                        <p className="font-medium-5 text-bold-400">
                          <NumberFormat
                            thousandSeparator
                            value={
                              row && row.totalActiveLicense
                                ? row.totalActiveLicense
                                : 0
                            }
                            displayType="text"
                          />
                        </p>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
        <EditProfileModelBox
          isShowEditProfile={this.state.isShowEditProfile}
          handleEditProfileModelClose={this.handleEditProfileModelClose}
          onSubmit={this.handleSubmitEditProfile}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  principalRow: state.principalProfile.data.row,
  row: state.principalStats.data,
  loading: state.principalStats.loading,
})

const mapDispatchToProps = dispatch => ({
  getPrincipalProfile: props => dispatch(getPrincipalProfile(props)),
  getStats: props => dispatch(getStats(props)),
  updatePrincipalProfile: (values, props) =>
    dispatch(updatePrincipalProfile(values, props)),
  resetForm: formName => dispatch(resetForm(formName)),
  dispatchFormField: (formName, fieldName, values) =>
    dispatch(dispatchFormField(formName, fieldName, values)),
})

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(Principal),
)
