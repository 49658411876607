/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import Organization from '../Common/Organization'
import Stats from './Stats'
import SchoolsTable from '../Tables/Schools'
import PrincipalTable from '../Tables/Principal'

/**
 * import translation related files
 *
 */

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {}

  render() {
    const { translate } = this.props
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />
        <div className="content-header row" />
        <div className="content-body">
          <Organization />
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <Stats />
              <PrincipalTable view="0" />
              <SchoolsTable />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
