import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import OrganizationCommonDetail from 'pages/Home/Common/Organization'
import Detail from 'pages/Home/Organization/Instructor/Details/Detail'
import Stats from 'pages/Home/Organization/Instructor/Details/Stats'
import Classes from 'pages/Home/Organization/Instructor/Details/Classes'
import { getDetailByinstructorId } from 'store/actions/instructors'
import config from 'config'

class InstructorDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationId: '',
      instructorId: '',
    }
  }

  componentWillMount() {
    const { match, history, getDetailByinstructorId } = this.props
    const organizationId = match.params.id
    const instructorId = match.params.iid
    if (organizationId === ' ' || organizationId === 'undefined') {
      history.push(`${config.adminUrl}/home`)
    }
    if (instructorId === ' ' || instructorId === 'undefined') {
      history.push(`${config.adminUrl}/home`)
    }
    this.setState({
      organizationId,
      instructorId,
    })
    getDetailByinstructorId(organizationId, instructorId)
  }

  render() {
    const { organizationInstructorRow } = this.props
    const { organizationId, instructorId } = this.state
    return (
      <div className="content-wrapper">
        <div className="content-wrapper-before" />

        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">View Instructor Details</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`${config.adminUrl}/home`}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${config.adminUrl}/organization/${organizationId}`}
                    >
                      Organization
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    View Instructor Details
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <OrganizationCommonDetail />
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <Detail row={organizationInstructorRow.row} />
                <Stats row={organizationInstructorRow.row} />
                <div className="clearfix" />
              </div>
            </div>
          </div>
          <Classes
            organizationId={organizationId}
            instructorId={instructorId}
          />
        </div>
      </div>
    )
  }
}

InstructorDetail.propTypes = {
  organizationInstructorRow: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        row: PropTypes.shape({}),
      }),
    ),
    PropTypes.shape({
      row: PropTypes.shape({}),
    }),
  ]).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      iid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  getDetailByinstructorId: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  organizationInstructorRow: state.organizatoinInstructorDetail.data,
})

const mapDispatchToProps = dispatch => ({
  getDetailByinstructorId: (organizationId, instructorId) => {
    dispatch(getDetailByinstructorId(organizationId, instructorId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(InstructorDetail)
