import {
  GET_ORGANIZATION_INSTRUCTOR_LIST,
  GET_ORGANIZATION_INSTRUCTOR_LIST_LOADING,
  GET_ORGANIZATION_INSTRUCTOR_LIST_LOADED,
} from 'store/actions/instructors'

const initialState = {
  instructors: [],
}
const organizatoinOrganizatoinInstructorListReducer = (
  state = initialState,
  action = {},
) => {
  const newState = state
  switch (action.type) {
    case GET_ORGANIZATION_INSTRUCTOR_LIST:
      if (action.payload.currentPage === 0) {
        newState.instructors = action.payload.instructors
        return {
          instructors: newState.instructors,
          ...newState,
          data: action.payload,
        }
      }
      newState.instructors = action.payload.instructors
      return {
        instructors: newState.instructors,
        ...newState,
        data: action.payload,
      }

    case GET_ORGANIZATION_INSTRUCTOR_LIST_LOADING:
      return {
        ...newState,
        loading: true,
      }
    case GET_ORGANIZATION_INSTRUCTOR_LIST_LOADED:
      return {
        ...newState,
        loading: false,
      }
    default:
      return newState
  }
}

export default organizatoinOrganizatoinInstructorListReducer
