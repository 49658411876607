/* TODO: Fix eslint */
/* eslint-disable */

import React from 'react'

// BEGIN: Image
// import img1 from './../assets/root/images/1.jpg';
import { withLocalize } from 'react-localize-redux'
import videoWebm from '../../../assets/root/videos/video.webm'
import videoMp4 from '../../../assets/root/videos/video.mp4'
import videoOgg from '../../../assets/root/videos/video.ogg'
// END: Image

import englishRootHomeTranslations from '../../../lang/root/home/en.json'
import spanishRootHomeTranslations from '../../../lang/root/home/es.json'
import chineseRootHomeTranslations from '../../../lang/root/home/zh.json'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.props.addTranslationForLanguage(englishRootHomeTranslations, 'en')
    this.props.addTranslationForLanguage(spanishRootHomeTranslations, 'es')
    this.props.addTranslationForLanguage(chineseRootHomeTranslations, 'zh')
  }

  render() {
    const { translate } = this.props
    return (
      <React.Fragment>
        <div id="video_section">
          <div className="just_pattern" />
          <div className="yellowbox">
            <center>
              <h2 className="tagline">
                {translate('videoText1')}
                <span className="Jasmine">{translate('videoText2')}</span>
                {translate('videoText3')}
                <span className="typewrite">{translate('videoText4')}</span>.
              </h2>
            </center>
          </div>
          <video
            id="video_background"
            preload="auto"
            autoPlay="true"
            loop="loop"
            muted="muted"
            volume={0}
            poster="images/1.jpg"
          >
            <source src={videoWebm} type="video/webm" />
            <source src={videoMp4} type="video/mp4" />
            <source src={videoOgg} type="video/ogg" />
          </video>
        </div>

        <div id="about" className="aboutus">
          <div className="container">
            <h3 className="wow animate fadeInDown delay-06s">
              {translate('introducingFluentViewer')}
            </h3>
            {translate('aboutUsDetail')}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withLocalize(Home)
