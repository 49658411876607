/* TODO: Fix eslint */
/* eslint-disable */

import { SubmissionError } from 'redux-form'
import config from '../../config'

const handleResponse = async (error, type) => {
  // console.log('handleResponse');
  // console.log(response);

  // if (error.response) {
  //   console.log('step -1');
  //    console.log('step -data');
  //   console.log(error.response.data);
  //   console.log('step -status');
  //   console.log(error.response.status);
  //   console.log('step -headers');
  //   console.log(error.response.headers);
  // } else if (error.request) {
  //    console.log('step -2');
  //     console.log(error.request);
  // } else {
  //    console.log('step -3');
  //     console.log('Error', error.message);
  // }
  // console.log('step -4');
  // console.log(error.config);

  if (error.response) {
    const { status } = error.response
    const { data } = error.response
    if (status === 401 && data === 'Unauthorized') {
      // console.log(status);
      localStorage.removeItem('authUser')
      window.location.reload(true)
      // }else if(status===400){
      // 	//console.log('4000');
      // 	window.location.replace(`${config.adminUrl}/home`);
    } else if (status === 422 || status === 400) {
      const errors = {}
      let failed = ''
      if (error.response.data.result.errors) {
        for (const obj of error.response.data.result.errors) {
          if (obj) {
            for (const obj1 in obj) {
              const message = obj[obj1]
              if (obj1 === 'failed') {
                errors.error = [`${message}`]
                failed = `${message}`
              } else {
                errors[obj1] = [`${message}`]
                failed = `${message}`
              }
            }
          } else {
          }
        }
        if (type === 1) {
          throw new SubmissionError({
            ...errors,
            _error: 'Something went wrong. Please check for validation errors.',
          })
        } else if (type === 2) {
          throw new SubmissionError({
            _error: failed,
          })
        } else if (type === 3) {
          // throw new SubmissionError({
          //   ...errors
          // })
        }
      }
    }
  } else {
  }
}

export default handleResponse
