/* TODO: Fix eslint */
/* eslint-disable */

import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import { localizeReducer } from 'react-localize-redux'

import notyReducer from './noty'
import authReducer from './auth'
import recoverPasswordReducer from './recoverPassword'
import statsReducer from './stats'
import organizationStatsReducer from './organizationStats'
import organizationListReducer from './organizationList'
import organizationStoreReducer from './organizationStore'
import organizationUpdateReducer from './organizationUpdate'
import countryListReducer from './country'
import organizationDetailReducer from './organizationDetail'
import organizationSchoolsReducer from './organizationSchools'
import organizationSchoolDetailReducer from './organizationSchoolDetail'
import organizationInstructorReducer from './organizatoinInstructorList'
import organizationInstructorDetailReducer from './organizatoinInstructorDetail'
import organizatoinClassesReducer from './organizatoinClassList'
import organizatoinClassDetailReducer from './organizatoinClassDetail'
import adminUserListReducer from './adminUserList'
import adminUserStoreReducer from './adminUserStore'
import adminUserRemoveReducer from './adminUserRemove'
import adminUserProfileUpdateReducer from './adminUserProfileUpdate'
import adminUserProfileReducer from './adminUserProfile'
import organizationSchoolDropdownReducer from './organizationSchoolDropdown'
import organizationTagsReducer from './organizationTags'
import organizationReportReducer from './organizationReport'
import organizationReportDetailReducer from './organizationReportDetail'
import organizationStoreReportReducer from './organizationReportStore'
import adminSchoolInstructorReducer from './admin/schoolInstructors'
import adminSchoolClassesReducer from './admin/schoolClasses'
import adminInstructorClassesReducer from './admin/instructorClasses'
import adminStateListReducer from './admin/state'
import adminClassAttendanceReducer from './admin/classAttendance'
import adminPhonemeScoresReducer from './admin/phonemeScores'
import adminAdventureStatsReducer from './admin/adventureStats'
import adminCountryCodeReducer from './admin/countryCode'
import isHamburgerReducer from './isHamburger'
import adminAssessmentAdventureStatsReducer from './admin/assessmentAdventureStats'

/**
 *
 * For Organization
 */

import authOrganizationReducer from './authOrganization'
import organizationOrganizationStatsReducer from './organizationOrganizationStats'
import organizationOrganizationDetailReducer from './organizationOrganizationDetail'
import organizationOrganizationSchoolsReducer from './organizationOrganizationSchools'
import organizationOrganizationPrincipalReducer from './organizationOrganizationPrincipal'
import organizationUserListReducer from './organizationUserList'
import organizationUserStoreReducer from './organizationUserStore'
import organizationUserRemoveReducer from './organizationUserRemove'
import organizationUserProfileUpdateReducer from './organizationUserProfileUpdate'
import organizationUserProfileReducer from './organizationUserProfile'
import organizationCountryReducer from './organizationCountry'
import organizationOrganizationTagsReducer from './organizationOrganizationTags'
import organizationOrganizationPrincipalDropdownReducer from './organizationOrganizationPrincipalDropdown'
import organizationOrganizationPrincipalDropdownSchoolIdReducer from './organizationOrganizationPrincipalDropdownSchoolId'
import organizationOrganizationSchoolDetailReducer from './organizationOrganizationSchoolDetail'
import organizationOrganizationPrincipalDetailReducer from './organizationOrganizationPrincipalDetail'
import organizatoinOrganizatoinInstructorListReducer from './organizatoinOrganizatoinInstructorList'
import organizatoinOrganizatoinInstructorDetailReducer from './organizatoinOrganizatoinInstructorDetail'
import organizatoinOrganizatoinClassListReducer from './organizatoinOrganizatoinClassList'
import organizatoinOrganizatoinClassDetailReducer from './organizatoinOrganizatoinClassDetail'
import organizationOrganizationReportReducer from './organizationOrganizationReport'
import organizationOrganizationReportDetailReducer from './organizationOrganizationReportDetail'
import organizationOrganizationSchoolDropdownReducer from './organizationOrganizationSchoolDropdown'
import organizationInstructorClassesReducer from './organization/instructorClass'
import organizationStateListReducer from './organization/state'
import organizationClassAttendanceReducer from './organization/classAttendance'
import organizationPhonemeScoresReducer from './organization/phonemeScores'
import organizationAdventureStatsReducer from './organization/adventureStats'
import organizationCountryCodeReducer from './organization/countryCode'
import organizationAssessmentAdventureStatsReducer from './organization/assessmentAdventureStats'

/**
 *
 * For Principal
 */
import authPrincipalReducer from './principal/auth'
import principalProfileReducer from './principal/profile'
import principalStatsReducer from './principal/stats'
import principalInstructorReducer from './principal/instructors'
import principalInstructorDetailReducer from './principal/instructorDetail'
import principalClassesReducer from './principal/classes'
import principalClassDetailReducer from './principal/classDetail'
import principalReportReducer from './principal/reports'
import principalReportDetailReducer from './principal/reportDetail'
import principalTagsReducer from './principal/tags'
import principalInstructorDropdownReducer from './principal/instructorList'
import principalInstructorClassesReducer from './principal/instructorClass'
import principalClassAttendanceReducer from './principal/classAttendance'
import principalPhonemeScoresReducer from './principal/phonemeScores'
import principalAdventureStatsReducer from './principal/adventureStats'
import principalAssessmentAdventureStatsReducer from './principal/assessmentAdventureStats'

/**
 *
 * For Teacher/Instructor
 */
import authTeacherReducer from './teacher/auth'
import teacherProfileReducer from './teacher/profile'
import teacherStatsReducer from './teacher/stats'
import countryReducer from './teacher/country'
import teacherClassesReducer from './teacher/classes'
import teacherClassDetailReducer from './teacher/classDetail'
import teacherStudentReducer from './teacher/students'
import teacherStudentDetailReducer from './teacher/studentDetail'
import teacherClassAttendanceReducer from './teacher/classAttendance'
import teacherPhonemeScoresReducer from './teacher/phonemeScores'
import teacherAdventureStatsReducer from './teacher/adventureStats'
import teacherAssessmentAdventureStatsReducer from './teacher/assessmentAdventureStats'

/**
 *
 * For Student
 */
import authStudentReducer from './student/auth'
import studentProfileReducer from './student/profile'
import studentPhonemeScoresReducer from './student/phonemeScores'
import studentAdventureAssessmentStatsReducer from './student/adventureAssessmentStats'
import studentAdventureAssessmentStatsDetailReducer from './student/adventureAssessmentStatsDetail'

const reducer = combineReducers({
  localize: localizeReducer,
  form: formReducer,
  router: routerReducer,
  auth: authReducer,
  noty: notyReducer,
  recoverPassword: recoverPasswordReducer,
  stats: statsReducer,
  organizationStats: organizationStatsReducer,
  organizationList: organizationListReducer,
  organizationStore: organizationStoreReducer,
  organizationUpdate: organizationUpdateReducer,
  countries: countryListReducer,
  organizationDetail: organizationDetailReducer,
  organizationSchools: organizationSchoolsReducer,
  organizationSchoolDetail: organizationSchoolDetailReducer,
  organizatoinInstructors: organizationInstructorReducer,
  organizatoinInstructorDetail: organizationInstructorDetailReducer,
  organizatoinClasses: organizatoinClassesReducer,
  organizatoinClassDetail: organizatoinClassDetailReducer,
  adminUserList: adminUserListReducer,
  adminUserStore: adminUserStoreReducer,
  adminUserRemove: adminUserRemoveReducer,
  adminUserProfileUpdate: adminUserProfileUpdateReducer,
  adminUserProfile: adminUserProfileReducer,
  organizationSchoolDropdown: organizationSchoolDropdownReducer,
  organizationTags: organizationTagsReducer,
  organizationReport: organizationReportReducer,
  organizationReportDetail: organizationReportDetailReducer,
  organizationReportStore: organizationStoreReportReducer,
  adminSchoolInstructor: adminSchoolInstructorReducer,
  adminSchoolClasses: adminSchoolClassesReducer,
  adminInstructorClasses: adminInstructorClassesReducer,
  adminStateList: adminStateListReducer,
  adminClassAttendance: adminClassAttendanceReducer,
  adminPhonemeScores: adminPhonemeScoresReducer,
  adminAdventureStats: adminAdventureStatsReducer,
  adminCountryCode: adminCountryCodeReducer,
  adminAssessmentAdventureStats: adminAssessmentAdventureStatsReducer,

  authOrganization: authOrganizationReducer,
  organizationOrganizationStats: organizationOrganizationStatsReducer,
  organizationOrganizationDetail: organizationOrganizationDetailReducer,
  organizationOrganizationSchools: organizationOrganizationSchoolsReducer,
  organizationOrganizationPrincipal: organizationOrganizationPrincipalReducer,
  organizationUserList: organizationUserListReducer,
  organizationUserStore: organizationUserStoreReducer,
  organizationUserRemove: organizationUserRemoveReducer,
  organizationUserProfileUpdate: organizationUserProfileUpdateReducer,
  organizationUserProfile: organizationUserProfileReducer,
  organizationCountry: organizationCountryReducer,
  organizationOrganizationTags: organizationOrganizationTagsReducer,
  organizationOrganizationPrincipalDropdown: organizationOrganizationPrincipalDropdownReducer,
  organizationOrganizationPrincipalDropdownSchoolId: organizationOrganizationPrincipalDropdownSchoolIdReducer,
  organizationOrganizationSchoolDetail: organizationOrganizationSchoolDetailReducer,
  organizationOrganizationPrincipalDetail: organizationOrganizationPrincipalDetailReducer,
  isHamburger: isHamburgerReducer,
  organizatoinOrganizatoinInstructorList: organizatoinOrganizatoinInstructorListReducer,
  organizatoinOrganizatoinInstructorDetail: organizatoinOrganizatoinInstructorDetailReducer,
  organizatoinOrganizatoinClassList: organizatoinOrganizatoinClassListReducer,
  organizatoinOrganizatoinClassDetail: organizatoinOrganizatoinClassDetailReducer,
  organizationOrganizationReport: organizationOrganizationReportReducer,
  organizationOrganizationReportDetail: organizationOrganizationReportDetailReducer,
  organizationOrganizationSchoolDropdown: organizationOrganizationSchoolDropdownReducer,
  organizationInstructorClasses: organizationInstructorClassesReducer,
  organizationStateList: organizationStateListReducer,
  organizationClassAttendance: organizationClassAttendanceReducer,
  organizationPhonemeScores: organizationPhonemeScoresReducer,
  organizationAdventureStats: organizationAdventureStatsReducer,
  organizationCountryCode: organizationCountryCodeReducer,
  organizationAssessmentAdventureStats: organizationAssessmentAdventureStatsReducer,

  authPrincipal: authPrincipalReducer,
  principalProfile: principalProfileReducer,
  principalStats: principalStatsReducer,
  principalInstructors: principalInstructorReducer,
  principalInstructorDetail: principalInstructorDetailReducer,
  principalClasses: principalClassesReducer,
  principalClassDetail: principalClassDetailReducer,
  principalReport: principalReportReducer,
  principalReportDetail: principalReportDetailReducer,
  principalTags: principalTagsReducer,
  principalInstructorDropdownList: principalInstructorDropdownReducer,
  principalInstructorClasses: principalInstructorClassesReducer,
  principalClassAttendance: principalClassAttendanceReducer,
  principalPhonemeScores: principalPhonemeScoresReducer,
  principalAdventureStats: principalAdventureStatsReducer,
  principalAssessmentAdventureStats: principalAssessmentAdventureStatsReducer,

  authTeacher: authTeacherReducer,
  teacherProfile: teacherProfileReducer,
  teacherStats: teacherStatsReducer,
  country: countryReducer,
  teacherClasses: teacherClassesReducer,
  teacherClassDetail: teacherClassDetailReducer,
  teacherStudents: teacherStudentReducer,
  teacherStudentDetail: teacherStudentDetailReducer,
  teacherClassAttendance: teacherClassAttendanceReducer,
  teacherPhonemeScores: teacherPhonemeScoresReducer,
  teacherAdventureStats: teacherAdventureStatsReducer,
  teacherAssessmentAdventureStats: teacherAssessmentAdventureStatsReducer,
  authStudent: authStudentReducer,
  studentProfile: studentProfileReducer,
  studentPhonemeScores: studentPhonemeScoresReducer,
  studentAdventureAssessmentStats: studentAdventureAssessmentStatsReducer,
  studentAdventureAssessmentStatsDetail: studentAdventureAssessmentStatsDetailReducer,
})

export default reducer
