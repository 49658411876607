/* TODO: Fix eslint */
/* eslint-disable */

import axios from 'axios'
import { reset } from 'redux-form'
import { saveAs } from 'file-saver'
import config from '../../config'
import handleResponse from './handleResponse'
import handleResponseTranslate from './handleResponseTranslate'
import handleResponsePrincipalTranslate from './handleResponsePrincipalTranslate'

/**
 * Define actions
 *
 */
export const GET_ORGANIZATION_PRINCIPAL_LIST = 'GET_ORGANIZATION_PRINCIPAL_LIST'
export const GET_ORGANIZATION_PRINCIPAL_LOADING =
  'GET_ORGANIZATION_PRINCIPAL_LOADING'
export const GET_ORGANIZATION_PRINCIPAL_LOADED =
  'GET_ORGANIZATION_PRINCIPAL_LOADED'
export const GET_ORGANIZATION_PRINCIPAL_DROPDOWN =
  'GET_ORGANIZATION_PRINCIPAL_DROPDOWN'
export const GET_ORGANIZATION_PRINCIPAL_DROPDOWN_SCHOOL_ID =
  'GET_ORGANIZATION_PRINCIPAL_DROPDOWN_SCHOOL_ID'
export const GET_ORGANIZATION_PRINCIPAL_DETAILS_BY_PRINCIPAL_ID =
  'GET_ORGANIZATION_PRINCIPAL_DETAILS_BY_PRINCIPAL_ID'
export const STORE_ORGANIZATION_PRINCIPAL = 'STORE_ORGANIZATION_PRINCIPAL'
export const UPDATE_ORGANIZATION_PRINCIPAL = 'UPDATE_ORGANIZATION_PRINCIPAL'
export const DELETE_ORGANIZATION_PRINCIPAL = 'DELETE_ORGANIZATION_PRINCIPAL'
export const GET_PRINCIPAL_PROFILE = 'GET_PRINCIPAL_PROFILE'
export const GET_PRINCIPAL_STATS = 'GET_PRINCIPAL_STATS'
export const GET_PRINCIPAL_STATS_LOADING = 'GET_PRINCIPAL_STATS_LOADING'
export const GET_PRINCIPAL_STATS_LOADED = 'GET_PRINCIPAL_STATS_LOADED'
export const PRINCIPAL_CHANGE_PASSWORD = 'PRINCIPAL_CHANGE_PASSWORD'
export const PRINCIPAL_UPDATE_PROFILE = 'PRINCIPAL_UPDATE_PROFILE'
export const STORE_PRINCIPAL_MULTIPLE_ALL = 'STORE_PRINCIPAL_MULTIPLE_ALL'
export const STORE_PRINCIPAL_MULTIPLE_EXIST = 'STORE_PRINCIPAL_MULTIPLE_EXIST'

/**
 * To get principal of a organization for Organization
 *
 * @param  object  props
 * @return data
 */
export const getOrganizationPrincipal = (props, page, orderBy, order) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: GET_ORGANIZATION_PRINCIPAL_LOADING,
    })
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/principal/${page}/${orderBy}/${order}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_PRINCIPAL_LIST,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_ORGANIZATION_PRINCIPAL_LOADED,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get principal dropdown list  of a organization for Organization
 *
 * @param  object  props
 * @return data
 */
export const getOrganizationPrincipalList = props => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/principal/list`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_PRINCIPAL_DROPDOWN,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get principal dropdown list  of a organization for Organization
 *
 * @param  object  props
 * @param  int  schoolId
 * @return data
 */
export const getOrganizationPrincipalListWithSchoolId = (
  schoolId,
  props,
) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.get(
      `${config.apiOrganizationUrl}/principal/list/${schoolId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_PRINCIPAL_DROPDOWN_SCHOOL_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To get principal details for Organization
 *
 * @param  object  props
 * @param  int principalId
 * @return data
 */
export const getOrganizationPrincipalDetailByprincipalId = (
  props,
  principalId,
) => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiOrganizationUrl}/principal/${principalId}`,
      headers,
    )
    dispatch({
      type: GET_ORGANIZATION_PRINCIPAL_DETAILS_BY_PRINCIPAL_ID,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To store a principal for organizatoin
 *
 * @param  object values
 * @param  object  props
 */
export const storePrincipal = (values, props) => async (dispatch, getState) => {
  try {
    const tag_ids = []
    const new_tags = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        if (item.id) {
          tag_ids.push(item.id)
        }
        if (item.newid) {
          new_tags.push(item.name)
        }
      })
    }

    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const schoolValues = {
      name: values.name,
      email: values.email,
      verify_email: values.verify_email,
      tags: tag_ids.length > 0 ? tag_ids.toString() : null,
      new_tags: new_tags.length > 0 ? new_tags.toString() : null,
    }
    // console.log(schoolValues);
    const response = await axios.post(
      `${config.apiOrganizationUrl}/principal`,
      schoolValues,
      headers,
    )
    dispatch({
      type: STORE_ORGANIZATION_PRINCIPAL,
      payload: response.data.result,
    })
    dispatch(reset('organization-create-principal'))
    return response.data.result.data.id
  } catch (error) {
    await handleResponseTranslate(error, props, 1)
  }
}

/**
 * To update a principal for organizatoin
 *
 * @param  int principalId
 * @param  object values
 * @param  object  props
 */
export const updatePrincipal = (principalId, values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const tag_ids = []
    const new_tags = []
    if (values.tag_ids) {
      values.tag_ids.forEach(function(item) {
        if (item.id) {
          tag_ids.push(item.id)
        }
        if (item.newid) {
          new_tags.push(item.name)
        }
      })
    }

    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const schoolValues = {
      name: values.name,
      email: values.email,
      verify_email: values.verify_email,
      tags: tag_ids.length > 0 ? tag_ids.toString() : null,
      new_tags: new_tags.length > 0 ? new_tags.toString() : null,
    }
    // console.log(schoolValues);
    const response = await axios.put(
      `${config.apiOrganizationUrl}/principal/${principalId}`,
      schoolValues,
      headers,
    )
    // console.log(response)
    dispatch({
      type: UPDATE_ORGANIZATION_PRINCIPAL,
      payload: response.data.result,
    })
    // /dispatch(reset('organization-create-principal'));
    return response.data.result.data.id
  } catch (error) {
    await handleResponseTranslate(error, props, 1)
  }
}

/**
 * To remove/delete a principal for organizatoin
 *
 * @param  int principalId
 * @param  object  props
 */
export const deletePrincipal = (principalId, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }
    const response = await axios.delete(
      `${config.apiOrganizationUrl}/principal/${principalId}`,
      headers,
    )
    // console.log(response)
    dispatch({
      type: DELETE_ORGANIZATION_PRINCIPAL,
      payload: response.data.result,
    })
    // /dispatch(reset('organization-create-principal'));
  } catch (error) {
    await handleResponseTranslate(error, props, 3)
  }
}

/**
 * To store/save instructor Multiple from imported CSV,XLSX for organizatoin
 *
 * @param  object  values
 * @param  object  file
 * @param  object  props
 */
export const storePrincipalMultiple = (file, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authOrganization.accessToken}`,
      },
    }

    const instructorValues = {
      file,
    }
    // console.log(instructorValues);
    const response = await axios.post(
      `${config.apiOrganizationUrl}/principal/multiple`,
      instructorValues,
      headers,
    )
    // console.log(response);
    // console.log(response.data.result.data);
    const created = response.data.result.data.created.length
    const exist = response.data.result.data.exist.length
    //    console.log(`created=${created}`);
    // console.log(`exist=${exist}`);
    // if(response.data.result.data.)
    if (exist === 0) {
      dispatch({
        type: STORE_PRINCIPAL_MULTIPLE_ALL,
        payload: `successMsgPrincipalServiceCreateAll_${created}`,
      })
    } else {
      dispatch({
        type: STORE_PRINCIPAL_MULTIPLE_EXIST,
        payload: `successMsgPrincipalServiceCreateExist_${created}_${exist}`,
      })
    }

    return response.data.result.data.id
  } catch (error) {
    // console.log(error);
    await handleResponseTranslate(error, props, 1)
  }
}

/**
 * To download sample file of principal for organizatoin
 *
 * @param  string  key
 * @return pdf file
 */
export const sampleFilePrincipal = (name, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      access_token: `${getState().authOrganization.accessToken}`,
      responseType: 'blob',
    }
    // console.log(`${config.apiPrincipalUrl}/download/sample/${name}`);
    const fileTypeArr = name.split('.')
    const type = fileTypeArr[fileTypeArr.length - 1]
    let applicationType = ''
    if (type === 'xlsx') {
      applicationType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    } else {
      applicationType = 'text/csv'
    }

    await axios
      .get(`${config.apiOrganizationUrl}/download/sample/${name}`, headers)
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: applicationType })
        saveAs(pdfBlob, name)
      })
  } catch (error) {
    await handleResponseTranslate(error, props, 2)
  }
}

/**
 * To get principal profile for principal
 *
 * @param  object  props
 * @return data
 */
export const getPrincipalProfile = props => async (dispatch, getState) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }

    const response = await axios.get(
      `${config.apiPrincipalUrl}/profile`,
      headers,
    )
    dispatch({
      type: GET_PRINCIPAL_PROFILE,
      payload: response.data.result.data,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 3)
  }
}

/**
 * To get total stats of principal
 *
 * @return data
 */
export const getStats = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PRINCIPAL_STATS_LOADING,
    })

    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const response = await axios.get(`${config.apiPrincipalUrl}/stats`, headers)
    dispatch({
      type: GET_PRINCIPAL_STATS,
      payload: response.data.result.data,
    })
    dispatch({
      type: GET_PRINCIPAL_STATS_LOADED,
    })
  } catch (error) {
    await handleResponsePrincipalTranslate(error, 3)
  }
}

/**
 * To update password of an principal
 *
 * @param  object values
 * @param  object  props
 */
export const updatePrincipalPassword = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const userValues = {
      current_password: values.current_password,
      password: values.password,
      confirm_password: values.confirm_password,
    }
    const response = await axios.put(
      `${config.apiPrincipalUrl}/change-password`,
      userValues,
      headers,
    )

    dispatch({
      type: PRINCIPAL_CHANGE_PASSWORD,
      payload: response.data.result,
    })
    dispatch(reset('principal-change-password'))
    return response.data.result.data.id
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}

/**
 * To update profile of an principal
 *
 * @param  object values
 * @param  object  props
 */
export const updatePrincipalProfile = (values, props) => async (
  dispatch,
  getState,
) => {
  try {
    const headers = {
      headers: {
        access_token: `${getState().authPrincipal.accessToken}`,
      },
    }
    const userValues = {
      name: values.name,
      email: values.email,
    }
    const response = await axios.put(
      `${config.apiPrincipalUrl}`,
      userValues,
      headers,
    )

    dispatch({
      type: PRINCIPAL_UPDATE_PROFILE,
      payload: response.data.result,
    })
    dispatch(reset('principal-change-profile'))
    return response.data.result.data.id
  } catch (error) {
    await handleResponsePrincipalTranslate(error, props, 1)
  }
}
