import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import DataTable from 'react-data-table-component'
import LoadingOverlay from 'react-loading-overlay'
import { connect } from 'react-redux'
import { withLocalize, getTranslate } from 'react-localize-redux'
import { StarRating } from 'components/StarRating'
import ExpandableComponent from 'components/Data/ExpandableComponent'

import config from 'config'

import englishTablesTranslations from 'lang/organization/tables/en.json'
import spanishTablesTranslations from 'lang/organization/tables/es.json'
import chineseTablesTranslations from 'lang/organization/tables/zh.json'

class AdventureTable extends React.Component {
  constructor(props) {
    super(props)

    const { addTranslationForLanguage } = this.props
    addTranslationForLanguage(englishTablesTranslations, 'en')
    addTranslationForLanguage(spanishTablesTranslations, 'es')
    addTranslationForLanguage(chineseTablesTranslations, 'zh')
  }

  render() {
    const { translate, loading, data } = this.props
    const columns = [
      {
        name: '#',
        selector: 'id',
        sortable: true,
        width: '6%',
      },
      {
        name: translate('adventureAssessmentStatsTableRowAdventure'),
        selector: 'name',
        sortable: true,
        width: '10%',
      },
      {
        name: translate('adventureAssessmentStatsTableRowPlayed'),
        selector: 'played',
        sortable: true,
        width: '10%',
        cell: row => (
          <NumberFormat
            thousandSeparator
            value={Number(row.played)}
            displayType="text"
          />
        ),
      },
      {
        name: translate('adventureAssessmentStatsTableRowCompleted'),
        selector: 'completed',
        sortable: true,
        width: '10%',
        cell: row => (
          <Fragment>
            {row.completed === 1 ? translate('btnYes') : translate('btnYes')}
          </Fragment>
        ),
      },
      {
        name: translate('adventureAssessmentStatsTableRowFocusWords'),
        selector: 'focus_words',
        sortable: true,
        width: '20%',
      },
      {
        name: translate('adventureAssessmentStatsTableRowGrammarPrincipal'),
        selector: 'grammar_principles',
        sortable: true,
        width: '15%',
      },
      {
        name: translate('adventureAssessmentStatsTableRowScore'),
        selector: 'score',
        sortable: true,
        width: '10%',
        cell: row => (
          <NumberFormat
            thousandSeparator
            value={Number(row.score)}
            displayType="text"
          />
        ),
      },
      {
        name: translate('adventureAssessmentStatsTableRowStars'),
        selector: 'stars',
        sortable: true,
        right: true,
        width: '10%',
        cell: row => (
          <StarRating number={Math.round(row.stars * 100) / 100} count={3} />
        ),
      },
    ]
    return (
      <div className="row">
        <div className="col-md-12">
          <h5 className="card-title text-bold-700 my-2">
            {translate('adventureAssessmentStatsTableTitle')}
          </h5>
          <div className="card" style={{}}>
            <div className="card-content">
              <LoadingOverlay
                active={loading}
                spinner
                text={`${config.loadingOverlayText}`}
              >
                <div
                  className={
                    loading === true
                      ? 'table-responsive table-height'
                      : 'table-responsive'
                  }
                >
                  <DataTable
                    className="school-agg_table "
                    columns={columns}
                    data={data}
                    selectableRowsComponentProps={{ inkDisabled: true }}
                    expandableDisabledField="expanderDisabled"
                    expandableRows
                    expandableRowsComponent={
                      <ExpandableComponent datas={data.all} />
                    }
                  />
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AdventureTable.defaultProps = {
  data: [],
  loading: false,
}

AdventureTable.propTypes = {
  translate: PropTypes.func.isRequired,
  addTranslationForLanguage: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
})

export default withLocalize(connect(mapStateToProps)(AdventureTable))
